<template>
  <block-container
    title="Mastery Mode"
    fa-icon="crown"
    previous-page-name="new-settings-overview"
    >
    <div class="mastery__component">
      <div class="mastery__component--mode-selection">
        <div @click="activeMode = 'normal'"
          class="mastery__component--mode-selection--item"
          :class="{'mastery__component--mode-selection--item-active': activeMode == 'normal'}">
          <i class="fa-solid fa-book-open mastery-mode--icon"></i>
          <div class="mastery-mode--header">
            <span class="mastery-mode--header-main">
              Normal Mode
            </span>
            <span class="mastery-mode--header-sub">
              This mode focuses on the Normal Curriculum
            </span>
          </div>
        </div>
        <div @click="activeMode = 'mastery'"
          class="mastery__component--mode-selection--item"
          :class="{'mastery__component--mode-selection--item-active': activeMode == 'mastery'}">
          <i class="fa-solid fa-crown mastery-mode--icon"></i>
          <div class="mastery-mode--header">
            <span class="mastery-mode--header-main">
              Mastery Mode
            </span>
            <span class="mastery-mode--header-sub">
              Strengthen your Foundation for the Selected Level:
            </span>
            <ul class="mastery-mode--level-select">
              <li v-for="(level, index) in validLevels"
                :key="index"
                @click.prevent="activeLevel=level"
                class="mastery-mode--level-select--item"
                :class="{'mastery-mode--level-select--item-active': activeLevel == level && activeMode == 'mastery'}">
                {{ level | shorternLevel }}
              </li>
            </ul>
            <div class="mastery-mode--level-select--progress">
              <div class="mastery-mode--level-select--progress-bar">
                <!-- actual -->
                <!-- <div
                  :style="{width: progressBarWidth}"
                  class="mastery-mode--level-select--progress-bar--fill">
                </div> -->
                <!-- placeholders -->
                <div v-if="activeLevel == 'primary_one'"
                  :style="{width: '50%'}"
                  class="mastery-mode--level-select--progress-bar--fill">
                </div>
                <div v-if="activeLevel == 'primary_two'"
                  :style="{width: '20%'}"
                  class="mastery-mode--level-select--progress-bar--fill">
                </div>
                <div v-if="activeLevel == 'primary_three'"
                  :style="{width: '30%'}"
                  class="mastery-mode--level-select--progress-bar--fill">
                </div>
                <div v-if="activeLevel == 'primary_four'"
                  :style="{width: '90%'}"
                  class="mastery-mode--level-select--progress-bar--fill">
                </div>
                <div v-if="activeLevel == 'primary_five'"
                  :style="{width: '80%'}"
                  class="mastery-mode--level-select--progress-bar--fill">
                </div>
                <div v-if="activeLevel == 'primary_six'"
                  :style="{width: '100%'}"
                  class="mastery-mode--level-select--progress-bar--fill">
                </div>
              </div>
              <i class="fa-solid fa-circle-info"></i>
            </div>
          </div>
        </div>
      </div>
      <button @click="confirm" class="mastery__component--btn-confirm">Confirm</button>
    </div>
    <modal
        :show="showModal"
        title="Revision Mode"
        :message="modalMessage"
        closeButtonText="Close"
        @close="showModal = false"
      />
  </block-container>
</template>

<script>

import axios from 'axios';
import {mapState, mapMutations, mapGetters} from 'vuex/dist/vuex.common.js';

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';

import ModalComponent from '../common/ModalComponent.vue';

export default {
  name: 'NewSettingsMasteryMode',
  components: {
    BlockContainer: BlockContainerComponent,
    Modal: ModalComponent,
  },
  data() {
    return {
      activeMode: 'normal',
      activeLevel: 'primary_one',
      modalMessage: '',
      showModal: false,
      // placeholder progress bar info
      progressBarWidth: '20%',
    };
  },
  computed: {
    ...mapState(['masteryMode']),
    ...mapGetters(['validLevels']),
  },
  mounted() {
    // setting default values
    if (!this.masteryMode) {
      this.activeMode = 'normal';
      this.activeLevel = 'primary_one';
    } else {
      this.activeMode = this.masteryMode.mode || 'normal';
      this.activeLevel = this.masteryMode.level || 'primary_one';
    }
  },
  methods: {
    ...mapMutations(['setMasteryMode']),
    confirm() {
      axios
          .post(`/api/v1/core/student/${this.studentId}/mastery_mode/`, {
            mode: this.activeMode,
            level: this.activeLevel,
          })
          .then((response) => {
            if (this.activeMode === 'mastery') {
              this.modalMessage = 'Activated Mastery Mode.';
            } else {
              this.modalMessage = 'Normal Revision activated.';
            }
            this.showModal = true;
            this.setMasteryMode(response.data);
          })
          .catch((error) => {
            this.modalMessage = 'Error setting mastery mode';
            this.showModal = true;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  .mastery__component {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
    overflow-y: auto;
    &--btn-confirm {
      background: #24bdf9;
      color: #fff;
      border-radius: .5rem;
      padding: .5rem 4rem;
      font-weight: 500;
      font-size: 1rem;
      cursor: pointer;
      border: none;
      border-bottom: 6px solid #2295c2;
      &:active {
        border-bottom: 4px solid #2295c2;
        margin-top: 2px;
      }
    }
    &--mode-selection {
      display: flex;
      width: 100%;
      height: 100%;
      flex-wrap: wrap;
      justify-content: center;
      gap: .5rem;
      margin-bottom: 1rem;
      &--item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: #9facd4;
        padding: 1rem;
        border-radius: .75rem;
        cursor: pointer;
        opacity: .4;
        border: 6px solid transparent;
        &-active {
          background: #fff;
          opacity: 1;
          border: 6px solid #00daff;
        }
        .mastery-mode {
          &--icon {
            font-size: 8rem;
            font-weight: 700;
            line-height: 10rem;
          }
          &--header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &-main {
              font-weight: 600;
              line-height: 1rem;
            }
            &-sub {
              font-style: italic;
              font-size: .8rem;
              text-align: center;
            }
          }
          &--level-select {
            display: flex;
            margin-top: .5rem;
            flex-wrap: wrap;
            &--item {
              border: 4px solid #7b57a9;
              color: #7b57a9;
              font-weight: 600;
              background: #fff;
              padding: .1rem;
              flex: 1;
              width: 80px;
              border-radius: .5rem;
              font-size: .9rem;
              margin-right: .5rem;
              text-align: center;
              cursor: pointer;
              &-active {
                opacity: 1;
                color: #fff;
                background: #7b57a9;
                font-weight: 600;
                border: 4px solid #7b57a9;
                box-shadow: 0 0px 1px 3px #00ffea;
              }
              &:hover {
                box-shadow: 0 0px 1px 3px #f0d;
              }
            }
            &--progress {
              display: flex;
              width: 100%;
              justify-content: center;
              align-items: center;
              margin-top: 1rem;
              &-bar {
                height: 1.25rem;
                border-radius: 2rem;
                background: #c6c6c65c;
                width: 100%;
                &--fill {
                  height: 100%;
                  background: #ffb350;
                  border-radius: 2rem;
                }
              }
              i {
                margin-left: .5rem;
                color: #b5b5b5;
                &:hover {
                  color: #555;
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-height: 1088px) {
    .mastery__component--mode-selection--item {
      .mastery-mode--icon {
        font-size: 6rem;
        line-height: 8rem;
      }
    }
  }
  @media only screen and (max-height: 980px) {
    .mastery__component--mode-selection--item {
      .mastery-mode--icon {
        font-size: 5rem;
        line-height: 6rem;
      }
    }
  }
  @media only screen and (max-height: 900px) {
    .mastery__component--mode-selection--item {
      .mastery-mode--icon {
        font-size: 4rem;
        line-height: 5rem;
      }
      .mastery-mode--level-select--item {
        font-size: .75rem;
        padding: 0;
      }
    }
  }
  @media only screen and (max-height: 820px) {
    .mastery__component--mode-selection--item {
      .mastery-mode--icon {
        font-size: 3rem;
        line-height: 3rem;
      }
    }
  }

  @media only screen and (max-width: 560px) {
    .mastery__component {
      padding: 1rem;
    }
    .mastery__component--mode-selection {
      height: auto;
    }
    .mastery__component--mode-selection--item {
      padding: .5rem;
      .mastery-mode--level-select {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: .5rem;
      }
    }
    .mastery__component--mode-selection--item .mastery-mode--icon {
      font-size: 3rem;
      line-height: 4rem;
    }
    .mastery__component--btn-confirm {
      width: 100%;
    }
  }
</style>
