<template>
  <div class="free-writing-practice__component">
    <div class="free-writing-practice__header">
      <div class="free-writing-practice__header-left">
        <button @click="goBack" class="back-button">
          <i class="fa-solid fa-chevron-left"></i>
        </button>
        <h3>Writing Drills - Free Practice</h3>
      </div>
      <div class="free-writing-practice__drill-selector" v-if="drills.length > 1">
        <question-tracker-buttons-carousel
          :total-questions="totalQuestions"
          :current-question-index="currentDrillIndex"
          :question-statuses="questionStatuses"
          :on-question-click="handleQuestionClick"
        />
      </div>
    </div>

    <div class="free-writing-practice__content" v-if="loading">
      <div class="loading-spinner">Loading drills...</div>
    </div>

    <div class="free-writing-practice__content" v-else-if="drills.length === 0">
      <div class="no-drills-message">
        <p>No free writing drills available for this pattern.</p>
      </div>
    </div>

    <div class="free-writing-practice__content" v-else>
      <div class="free-writing-practice__current-drill" v-if="currentDrill">
        <div class="free-writing-practice__image-container" v-if="currentDrill.image">
          <img :src="currentDrill.image" alt="Writing prompt image" />
        </div>

        <div class="free-writing-practice__guidance">
          <div class="free-writing-practice__instructions">
            <p>Write a sentence that uses the pattern: <strong>{{ pattern.name }}</strong> and includes the below keywords.</p>
          </div>
          <div class="free-writing-practice__keywords" v-if="currentGuidance && currentGuidance.keywords">
            <div class="keyword-pills">
              <span v-for="(keyword, idx) in currentGuidance.keywords" :key="idx" class="keyword-pill chinese-character">
                {{ keyword }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <ai-practice-textbox
          class="free-writing-practice__textbox"
          :placeholder-text="placeholderText"
          :drill="currentDrill"
          category="writing-drill-free-writing"
          @continue="handleContinue"
          @score-change="handleScoreChange"
          @validation-error="handleValidationError"
        />
    </div>

    <!-- Error Modal -->
    <modal
      :show="showErrorModal"
      title="Try Again"
      closeButtonText="Ok"
      min-width="300px"
      max-width="500px"
      @close="showErrorModal = false"
    >
      <p>{{ errorMessage }}</p>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import {mapMutations} from 'vuex';
import AiPracticeTextboxComponent from './AiPracticeTextboxComponent.vue';
import QuestionTrackerButtonsCarouselComponent from '../../common/QuestionTrackerButtonsCarouselComponent.vue';
import ModalComponent from '../../common/ModalComponent.vue';

export default {
  name: 'WritingDrillsFreeWritingPracticeComponent',
  components: {
    AiPracticeTextbox: AiPracticeTextboxComponent,
    QuestionTrackerButtonsCarousel: QuestionTrackerButtonsCarouselComponent,
    Modal: ModalComponent,
  },
  props: {
    pattern: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      drills: [],
      currentDrillIndex: 0,
      score: 0,
      errorMessage: '',
      showErrorModal: false,
      questionStatuses: [],
    };
  },
  computed: {
    placeholderText() {
      return `按照"${this.pattern.name}"的模式写一个句子...`;
    },
    currentDrill() {
      if (!this.drills.length) return null;
      return this.drills[this.currentDrillIndex];
    },
    currentGuidance() {
      if (!this.currentDrill || !this.currentDrill.free_writing_guidance) return null;
      return this.currentDrill.free_writing_guidance;
    },
    totalQuestions() {
      return this.drills.length;
    },
  },
  mounted() {
    this.fetchFreeWritingDrills();
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    async fetchFreeWritingDrills() {
      this.loading = true;
      this.setShowGlobalLoadingScreen(true);
      // note that aipracticegtextbox component already checks for english and minimum length
      // so we don't need to do that here
      try {
        // Build the URL with pattern filter
        const url = '/writing/drills/free_writing/';
        const params = {
          pattern: this.pattern.id,
        };

        const response = await axios.get(url, {params});

        if (response.data.drills && response.data.drills.length > 0) {
          this.drills = response.data.drills;
          this.questionStatuses = new Array(this.drills.length).fill('unanswered');
        } else {
          this.drills = [];
          this.questionStatuses = [];
        }
      } catch (error) {
        console.error('Error fetching free writing drills:', error);
        this.drills = [];
        this.questionStatuses = [];
      } finally {
        this.loading = false;
        this.setShowGlobalLoadingScreen(false);
      }
    },
    selectDrill(index) {
      this.currentDrillIndex = index;
    },
    handleQuestionClick(index) {
      this.currentDrillIndex = index;
    },
    goBack() {
      this.$emit('back');
    },
    handleContinue() {
      // Move to the next drill if available
      if (this.currentDrillIndex < this.drills.length - 1) {
        this.currentDrillIndex++;
      } else {
        // If we're on the last drill, emit drill-completed event
        this.$emit('drill-completed');
        // Go back to pattern selection
        this.$emit('back');
      }
    },
    handleScoreChange(newScore) {
      this.score = newScore;
      // Update question status based on score
      if (newScore >= 4) {
        this.$set(this.questionStatuses, this.currentDrillIndex, 'correct');
      } else if (newScore > 0) {
        this.$set(this.questionStatuses, this.currentDrillIndex, 'incorrect');
      }
    },
    handleValidationError(error) {
      this.errorMessage = error;
      this.showErrorModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.free-writing-practice {
  &__component {
    border-radius: 0.75rem;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    h2 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }

    p {
      color: #666;
    }
    &-left {
      display: flex;
      align-items: center;
    }

    .back-button {
      background: none;
      border: none;
      color: #3a87ff;
      cursor: pointer;
      font-weight: 600;
      padding: 0;
      font-size: 0.9rem;
      margin-right: .5rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__drill-selector {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-left: auto;
    min-width: 300px;
    flex: 1;
    justify-content: flex-end;
  }

  &__image-container {
    margin-bottom: 1.5rem;
    border-radius: 0.75rem;
    overflow: hidden;

    img {
      width: 100%;
      max-height: 400px;
      object-fit: cover;
    }
  }

  &__guidance {
    margin-bottom: 1.5rem;

    h3 {
      font-size: 1rem;
      margin-bottom: 0.5rem;
      color: #333;
    }
  }

  &__keywords {
    .keyword-pills {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    .keyword-pill {
      background: #f0f7ff;
      border: 1px solid #c2dcff;
      color: #3a87ff;
      padding: 0.25rem 0.75rem;
      border-radius: 1rem;
      font-size: 1rem;
    }
  }
  &__current-drill {
    display: flex;
    gap: 1rem;
  }
  &__instructions {
    margin-bottom: 1rem;

    p {
      font-size: 1rem;
      color: #333;
      margin-bottom: 0.5rem;
    }
  }
  &__textbox {
  }
  .loading-spinner, .no-drills-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 1.25rem;
    color: #666;
  }
}
@media only screen and (max-width: 760px) {
  .free-writing-practice__image-container {
    flex: 1;
  }
  .free-writing-practice__guidance {
    flex: 1;
  }
}

@media only screen and (max-width: 600px) {
  .free-writing-practice__component {
    padding: .25rem;
  }
  .free-writing-practice__current-drill {
    flex-direction: column;
  }
  .free-writing-practice__guidance, .free-writing-practice__image-container {
    flex: none;
  }
  .free-writing-practice__image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    img {
      max-width: 300px;
    }
   }
   .free-writing-practice__header {
    margin-bottom: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
   }
   .free-writing-practice__guidance {
    margin-bottom: 0.5rem;
   }
}
@media only screen and (max-width: 460px) {
  .free-writing-practice__header {
    font-size: .9rem;
  }
  .free-writing-practice__image-container img {
    max-width:250px;
  }
  .free-writing-practice__instructions p {
    font-size: .9rem;
  }
  .free-writing-practice__instructions {
    margin-bottom: 0;
  };
  .free-writing-practice__keywords .keyword-pill {
    font-size: .9rem;
  }
}

@media only screen and (max-width: 420px) {
  .free-writing-practice__drill-selector {
    margin: auto;
  }
}


</style>
