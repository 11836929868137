<template>
  <div v-if="show" class="vocabulary-modal">
    <div class="vocabulary-modal-content">
      <div class="vocabulary-modal-header">
        <div class="title-group">
          <h3>Helpful Vocabulary & Quotations</h3>
          <p class="subtitle">Use these words and phrases to enhance your response</p>
        </div>
        <button class="close-btn" @click="closeModal">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>

      <div class="vocabulary-tabs">
        <div
          class="vocabulary-tab"
          :class="{active: vocabTab === 'general'}"
          @click="vocabTab = 'general'">
          General Vocabulary
        </div>
        <div
          class="vocabulary-tab"
          :class="{active: vocabTab === 'quotations'}"
          @click="vocabTab = 'quotations'">
          Quotations
        </div>
      </div>

      <!-- General Vocabulary Tab Content -->
      <div v-if="vocabTab === 'general'" class="vocabulary-content">
        <div class="vocabulary-section" v-if="basicVocabulary.length > 0">
          <h4 class="vocabulary-section-title">Basic Vocabulary</h4>
          <div class="vocabulary-chips">
            <div class="vocabulary-chip" v-for="(word, index) in basicVocabulary" :key="'basic-' + index">{{ word }}</div>
          </div>
        </div>

        <div class="vocabulary-section" v-if="intermediateVocabulary.length > 0">
          <h4 class="vocabulary-section-title">Intermediate Vocabulary</h4>
          <div class="vocabulary-chips">
            <div class="vocabulary-chip" v-for="(word, index) in intermediateVocabulary" :key="'intermediate-' + index">{{ word }}</div>
          </div>
        </div>

        <div class="vocabulary-section" v-if="advancedVocabulary.length > 0">
          <h4 class="vocabulary-section-title">Advanced Vocabulary</h4>
          <div class="vocabulary-chips">
            <div class="vocabulary-chip" v-for="(word, index) in advancedVocabulary" :key="'advanced-' + index">{{ word }}</div>
          </div>
        </div>

        <div class="vocabulary-section" v-if="!hasVocabulary">
          <p class="no-vocab-message">No vocabulary available for this topic.</p>
        </div>
      </div>

      <!-- Quotations Tab Content -->
      <div v-if="vocabTab === 'quotations'" class="vocabulary-content">
        <div class="vocabulary-section" v-if="basicQuotations.length > 0">
          <h4 class="vocabulary-section-title">Basic Quotations</h4>
          <div class="quotation-item" v-for="(quote, index) in basicQuotations" :key="'basic-quote-' + index">
            <div class="quotation-text">"{{ quote.text || quote }}"</div>
            <div class="quotation-usage" v-if="quote.usage">Usage: {{ quote.usage }}</div>
          </div>
        </div>

        <div class="vocabulary-section" v-if="intermediateQuotations.length > 0">
          <h4 class="vocabulary-section-title">Intermediate Quotations</h4>
          <div class="quotation-item" v-for="(quote, index) in intermediateQuotations" :key="'intermediate-quote-' + index">
            <div class="quotation-text">"{{ quote.text || quote }}"</div>
            <div class="quotation-usage" v-if="quote.usage">Usage: {{ quote.usage }}</div>
          </div>
        </div>

        <div class="vocabulary-section" v-if="advancedQuotations.length > 0">
          <h4 class="vocabulary-section-title">Advanced Quotations</h4>
          <div class="quotation-item" v-for="(quote, index) in advancedQuotations" :key="'advanced-quote-' + index">
            <div class="quotation-text">"{{ quote.text || quote }}"</div>
            <div class="quotation-usage" v-if="quote.usage">Usage: {{ quote.usage }}</div>
          </div>
        </div>

        <div class="vocabulary-section" v-if="!hasQuotations">
          <p class="no-vocab-message">No quotations available for this topic.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VocabularyModalComponent',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    topicVocabulary: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      vocabTab: 'general', // 'general' or 'quotations'
    };
  },
  computed: {
    hasVocabulary() {
      return this.basicVocabulary.length > 0 ||
             this.intermediateVocabulary.length > 0 ||
             this.advancedVocabulary.length > 0;
    },
    hasQuotations() {
      return this.basicQuotations.length > 0 ||
             this.intermediateQuotations.length > 0 ||
             this.advancedQuotations.length > 0;
    },
    basicVocabulary() {
      if (!this.topicVocabulary || !this.topicVocabulary.general || !this.topicVocabulary.general.basic) {
        return [];
      }
      return this.topicVocabulary.general.basic;
    },
    intermediateVocabulary() {
      if (!this.topicVocabulary || !this.topicVocabulary.general || !this.topicVocabulary.general.intermediate) {
        return [];
      }
      return this.topicVocabulary.general.intermediate;
    },
    advancedVocabulary() {
      if (!this.topicVocabulary || !this.topicVocabulary.general || !this.topicVocabulary.general.advanced) {
        return [];
      }
      return this.topicVocabulary.general.advanced;
    },
    basicQuotations() {
      if (!this.topicVocabulary || !this.topicVocabulary.quotations || !this.topicVocabulary.quotations.basic) {
        return [];
      }
      return this.topicVocabulary.quotations.basic;
    },
    intermediateQuotations() {
      if (!this.topicVocabulary || !this.topicVocabulary.quotations || !this.topicVocabulary.quotations.intermediate) {
        return [];
      }
      return this.topicVocabulary.quotations.intermediate;
    },
    advancedQuotations() {
      if (!this.topicVocabulary || !this.topicVocabulary.quotations || !this.topicVocabulary.quotations.advanced) {
        return [];
      }
      return this.topicVocabulary.quotations.advanced;
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
// Vocabulary Modal
.vocabulary-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.vocabulary-modal-content {
  background-color: white;
  border-radius: 0.75rem;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.vocabulary-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;

  .title-group {
    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0;
      color: #155eef;
      line-height: 1.3;
    }

    .subtitle {
      margin: 0.25rem 0 0;
      color: #6c757d;
      font-size: 0.9rem;
      font-weight: normal;
    }
  }

  .close-btn {
    background: none;
    border: none;
    font-size: 1.25rem;
    color: #98a2b3;
    cursor: pointer;
    margin-top: 0.25rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.2s ease;

    i {
      font-size: 1.25rem;
    }

    &:hover {
      background-color: #f1f5f9;
      color: #101828;
    }
  }
}

.vocabulary-tabs {
  display: flex;
  border: 1px solid #d0d5dd;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  overflow: hidden;
  background-color: #f9fafb;

  .vocabulary-tab {
    flex: 1;
    padding: 0.75rem;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    user-select: none;
    color: #667085;

    &.active {
      background-color: white;
      color: #101828;
      font-weight: 600;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    }
  }
}

.vocabulary-content {
  background-color: #f5f8ff;
  border-radius: 0.75rem;
  padding: 1.5rem;
}

.vocabulary-section-title {
  font-size: 1.05rem;
  font-weight: 600;
  color: #155eef;
  margin: 0.75rem 0 1rem;
}

.vocabulary-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;

  .vocabulary-chip {
    background-color: white;
    border: 1px solid #e4e7ec;
    border-radius: 2rem;
    padding: 0.5rem 1.25rem;
    font-size: 0.95rem;
    color: #155eef;
    cursor: pointer;

    &:hover {
      background-color: #edf2ff;
      border-color: #d1e0ff;
    }
  }
}

.quotation-item {
  background-color: white;
  border: 1px solid #e4e7ec;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;

  .quotation-text {
    color: #155eef;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .quotation-usage {
    color: #667085;
    font-size: 0.9rem;
  }
}

.no-vocab-message {
  text-align: center;
  color: #667085;
  padding: 1.5rem;
  font-style: italic;
}
</style>
