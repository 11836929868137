<template>
  <div class="english-oral__component">
    <!-- Close button -->
    <!-- <div v-if="state === -1" class="english-oral__component--close-btn-container">
      <router-link
        class="english-oral__component--close-btn"
        :to="closeButtonRouterPath">
        <i @click="close" class="fa-solid fa-xmark"></i>
      </router-link>
    </div> -->

    <div class="english-oral__component--content--container">
      <!-- Initial state - topic selection -->
      <div v-if="state === -1"
        class="content--topic-selection animated fadeIn faster">
        <div class="topic-selection-header">
          <div class="topic-selection-header--main">
            <div class="practice-label">PSLE English</div>
            <h1 class="content--topic-selection--title">Oral Skills Practice</h1>
          </div>
          <div class="content--topic-selection--description">
            <p>
              Select a topic to begin practicing your English oral skills.
            </p>
            <p>
              Each topic contains three question types to help you prepare for your PSLE.
            </p>
          </div>
        </div>
        <div class="content--topic-selection--topics">
          <div v-for="topic in freeResponseTopics" :key="topic.id"
            class="topic-card"
            @click="selectTopic(topic)">
            <div class="topic-card__image-placeholder" v-if="!topic.thumbnail_image">
              <i class="fa-regular fa-image"></i>
            </div>
            <div class="topic-card__image" v-else :style="{backgroundImage: `url(${topic.thumbnail_image})`}"></div>
            <div class="topic-card__content">
              <h3>{{ topic.english_title || topic.title }}</h3>
              <div class="topic-card__footer">
                <span class="question-type-label">{{ topic.questions.length || 0 }} question{{ topic.questions.length !== 1 ? 's' : '' }}</span>
                <span class="topic-card__arrow">
                  <i class="fa-solid fa-arrow-right"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Topic selected, question selection -->
      <div v-if="state === 0 && selectedTopic"
        class="content--question-selection animated fadeIn faster">
        <div class="content--question-selection--header">
          <button class="btn-back" @click="backToTopics">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
          <!-- <div class="practice-label">Selected Topic</div> -->
          <h1 class="question-selection-header">{{ selectedTopic.english_title || selectedTopic.title }}</h1>
          <!-- <p class="question-selection-description">Select one of the following questions to practice.</p> -->
        </div>
        <div class="content--question-selection--questions">
          <div v-for="question in selectedTopic.questions" :key="question.question_index"
            class="question-card"
            @click="selectQuestion(question.id)">
            <div class="question-type" :style="{
              backgroundColor: getQuestionTypeColor(question.question_category).label.background,
              color: getQuestionTypeColor(question.question_category).label.text
            }">{{ getQuestionTypeLabel(question.question_category) }}</div>
            <div class="question-content">
              <div class="question-icon" :style="{
                backgroundColor: getQuestionTypeColor(question.question_category).background,
                color: getQuestionTypeColor(question.question_category).icon
              }">
                <i :class="['fa-solid', getQuestionTypeIcon(question.question_category)]"></i>
              </div>
              <h3>{{ question.question_text }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import axios from 'axios';

export default {
  name: 'EnglishOralAiPracticeIndex',
  components: {
  },
  props: {
    subject: {
      type: String,
      default: 'english',
    },
  },
  data() {
    return {
      /**
       * -1 - initial state - topic selection
       * 0 - topic selected, question selection
       */
      state: -1,
      selectedTopic: null,
      selectedQuestion: null,
    };
  },
  computed: {
    ...mapState(['studentType']),
    ...mapState('guidedReading', ['freeResponseTopics']),
    // closeButtonRouterPath() {
    //   return {
    //     name: 'profile-main',
    //   };
    // },
    progressBarWidth() {
      const percentage = Math.ceil((this.timerCount) / this.totalTime * 100);
      return `${percentage}%`;
    },
  },
  mounted() {
    // Check if user has access to feature
    if (this.studentType !== 'digital_trial' && this.studentType !== 'digital_subscription') {
      alert('Please check with your admin for access');
      this.$router.push({name: 'profile-main'});
    }

    if (this.freeResponseTopics.length === 0) {
      this.loadTopics();
    }
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    ...mapMutations('guidedReading', ['setFreeResponseTopics']),
    loadTopics() {
      this.setShowGlobalLoadingScreen(true);
      axios
          .get('/vocab/oralfreeresponse/english_topics/')
          .then((response) => {
            console.log(response.data);
            this.setFreeResponseTopics(response.data);
          })
          .finally(() => {
            this.setShowGlobalLoadingScreen(false);
          });
    },
    getQuestionTypeLabel(type) {
      const labels = {
        'describe_picture': 'Picture-based Question',
        'personal_experience': 'Personal Experience',
        'suggestion': 'Opinion or Suggestion',
        'opinion': 'Opinion or Suggestion',
      };
      return labels[type] || '';
    },
    getQuestionTypeIcon(type) {
      const icons = {
        'describe_picture': 'fa-image',
        'personal_experience': 'fa-user',
        'suggestion': 'fa-comment',
        'opinion': 'fa-comment',
      };
      return icons[type] || '';
    },
    getQuestionTypeColor(type) {
      const colors = {
        'describe_picture': {
          background: '#e6f3ff',
          icon: '#1c64f2',
          label: {
            background: '#e6f3ff',
            text: '#1c64f2',
          },
        },
        'personal_experience': {
          background: '#f0fdf4',
          icon: '#16a34a',
          label: {
            background: '#f0fdf4',
            text: '#16a34a',
          },
        },
        'suggestion': {
          background: '#fef3c7',
          icon: '#d97706',
          label: {
            background: '#fef3c7',
            text: '#d97706',
          },
        },
        'opinion': {
          background: '#fef3c7',
          icon: '#d97706',
          label: {
            background: '#fef3c7',
            text: '#d97706',
          },
        },
      };
      return colors[type] || colors['describe_picture'];
    },
    selectTopic(topic) {
      this.selectedTopic = topic;
      this.state = 0;
    },
    selectQuestion(questionId) {
      if (!questionId) return;

      // Find the question in the selectedTopic's questions
      const question = this.selectedTopic.questions.find((q) => q.id === questionId);
      if (!question) {
        console.error('Question not found:', questionId);
        return;
      }
      // Store the selected question
      this.selectedQuestion = question;

      // Navigate to the standalone question page
      this.$router.push({
        name: 'new-oral-english-ai-practice-question',
        params: {
          questionId: String(question.id),
        },
        query: {
          type: question.question_category || 'describe_picture',
        },
      });
    },
    backToTopics() {
      this.selectedTopic = null;
      this.state = -1;
    },
    close() {
      this.reset();
    },
    reset() {
      this.state = -1;
      this.selectedTopic = null;
      this.selectedQuestion = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.english-oral__component {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  z-index: 9999;
  overflow-y: auto;
  overflow-x: hidden;

  &--close-btn-container {
    position: fixed;
    top: 2rem;
    right: 2rem;
    z-index: 10000;
  }

  &--close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
    background-color: transparent;
    text-decoration: none;

    &:hover {
      color: #000;
    }
  }

  &--content {
    &--container {
      margin-top: 2rem;
      flex: 1;
      width: 100%;
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// Loading indicators
.loading-indicator, .no-topics-message, .no-questions-message {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  width: 100%;
  text-align: center;
  color: #666;
  font-size: 1.1rem;

  i {
    margin-right: 0.5rem;
  }
}

// Topic selection styling
.topic-selection-header, .content--question-selection--header {
  text-align: center;
  margin-bottom: 3rem;
  border-bottom: 2px solid #f2f2f2;
  .practice-label {
    display: inline-block;
    background-color: #e6f3ff;
    color: #1c64f2;
    font-size: 0.85rem;
    font-weight: 600;
    padding: 0.35rem 1rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
  }
}

.content--topic-selection {
  &--title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-top: 1rem;
  }

  &--description {
    font-size: 1.1rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto 3rem;
    line-height: 1.6;
  }

  &--topics {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 2rem;
    padding: 0 1rem;
    width: 100%;
    margin: 0 auto;
  }
}

.topic-card {
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }

  &__image-placeholder {
    background-color: #f1f1f1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    position: relative;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2rem;
      color: #ccc;
    }
  }

  &__image {
    background-size: cover;
    background-position: center;
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
  }

  &__content {
    padding: 1.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      color: #333;
      margin: 0 0 1.5rem 0;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;

    .question-type-label {
      font-size: 0.9rem;
      color: #666;
    }
  }

  &__arrow {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    background-color: #f0f7ff;
    color: #1c64f2;
    border-radius: 50%;

    i {
      font-size: 0.85rem;
    }
  }
}

// Question selection styling
.content--question-selection {
  &--header {
    margin-bottom: 2rem;
    .question-selection-header {
      margin: 1rem 0;
      font-size: 2.5rem;
    }
    .question-selection-description {
      margin: 0 auto 3rem;
      font-size: 1.1rem;
    }
    .btn-back {
      background: none;
      border: none;
      color: #666;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      margin-bottom: 1rem;
      font-size: 1.2rem;
      position: fixed;
      top: 2rem;
      left: 2rem;
      z-index: 10000;
      i {
        margin-right: 0.5rem;
      }

      &:hover {
        color: #333;
      }
    }

    h2 {
      font-size: 1.75rem;
      font-weight: bold;
      color: #333;
      margin: 0;
    }
  }

  &--questions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
    margin-bottom: 3rem;
  }
}

.question-card {
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e7e7e7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .question-type {
    display: inline-block;
    padding: 0.35rem 0.75rem;
    border-radius: 1rem;
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }

  .question-content {
    display: flex;
    align-items: flex-start;
    gap: 1rem;

    .question-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      flex-shrink: 0;

      i {
        font-size: 1.1rem;
      }
    }

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      color: #333;
      margin: 0;
      flex: 1;
    }
  }
}

// Animation classes
.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.fadeIn {
  animation-name: fadeIn;
}

.faster {
  animation-duration: 0.3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes flipInY {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
</style>
