<template>
  <!-- this will pull out the components/startcomponent.vue -->
  <div id="popup">
    <!-- :quiz-id="topicId" -->
    <quiz-main
      quiz-type="revision"
    ></quiz-main>
  </div>
</template>

<script>
import QuizMainComponent from '../../common/quiz/QuizMainComponent.vue';

export default {
  name: 'NewRevisionQuiz',
  components: {
    QuizMain: QuizMainComponent,
  },
  props: {
  },
  data() {
    return {};
  },
  computed: {
  },
};

</script>

<style lang="scss" scoped>
</style>
