import axios from 'axios';

import Vue from 'vue';
import {TRAIN_PET_ENERGY_COSTS, BUILDING_CHAINS_DATA, PET_CHAINS_DATA, RESOURCE_CHAINS_DATA} from '../../components/new/play/kingdom/gamevalues.js';

const getDefaultState = () => {
  // note: index resets for every subtrack
  const results = {
    resources: {
      energy: 0,
      wood: 0,
    },
    spawns: {},
    remainingDailyAttempts: 200,
    // grid is a 2D array of objects representing row, colum.
    // value should be a string representing the item to spawn (e.g. 'resource_coin_0')
    grid: [],
    // selectedCells is an array of objects representing row, column of cells that are selected
    selectedCells: [],
    king: {
      level: 1,
      xp: 0,
      total_xp_required_for_next_level: 25,
    },
    clearCellsOnNextClick: false,
    // Delivery system
    deliveries: {
      next_request_id: 1,
      slots: [],
      completed: [],
    },
    deliveryCompletionData: null, // To store data about completed deliveries
  };
  return results;
};

const state = getDefaultState();

export const kingdom = {
  namespaced: true,
  state,
  mutations: {
    resetStateMutation(state) {
      const deliveryCompletionData = state.deliveryCompletionData;
      Object.assign(state, getDefaultState());
      state.deliveryCompletionData = deliveryCompletionData;
    },
    setDeliveryCompletionData(state, data) {
      state.deliveryCompletionData = data;
    },
    clearDeliveryCompletionData(state) {
      state.deliveryCompletionData = null;
    },
    setRemainingDailyAttempts(state, value) {
      state.remainingDailyAttempts = value;
    },
    setSpawns(state, value) {
      state.spawns = value;
    },
    setClearCellsOnNextClick(state, value) {
      state.clearCellsOnNextClick = value;
    },
    setSelectedCells(state, value) {
      state.selectedCells = value;
    },
    clearSelectedCells(state) {
      state.selectedCells = [];
    },
    setKing(state, value) {
      state.king = value;
    },
    incrementKingXp(state, value) {
      state.king.xp += value;
    },
    setDeliveries(state, deliveries) {
      state.deliveries = deliveries;
    },
    // value is a 2D array
    setGrid(state, value) {
      if (!Array.isArray(value) || !value.every((row) => Array.isArray(row))) {
        alert('Error running setGrid: value must be a 2D array');
        return false;
      }
      Vue.set(state, 'grid', value);
    },
    // payload should contain row, col, and value
    setGridCell(state, payload) {
      const {row, col, value} = payload;
      if (typeof value !== 'string') {
        alert('Error running setGridCell: value must be a string');
        return false;
      }
      Vue.set(state.grid[row], col, value);
    },
    incrementSpawnIndex(state, spawnKey) {
      Vue.set(state.spawns[spawnKey], 'next_index', state.spawns[spawnKey].next_index + 1);
    },
    subtractResource(state, payload) {
      const {resourceName, value} = payload;
      if (typeof state.resources[resourceName] === 'undefined') {
        alert('Resources not found');
        return false;
      }
      if (state.resources[resourceName] < value) {
        alert(`Not enough ${resourceName}`);
        return false;
      }
      state.resources[resourceName] = Math.floor(state.resources[resourceName] - value);
      return true;
    },
    addResource(state, payload) {
      const {resourceName, value} = payload;
      if (typeof state.resources[resourceName] === 'undefined') {
        alert('Resources not found');
        return false;
      }
      state.resources[resourceName] = Math.floor(state.resources[resourceName] + value);
      return true;
    },
    setResource(state, payload) {
      const {resourceName, value} = payload;
      Vue.set(state.resources, resourceName, value);
    },
  },
  actions: {
    setData({commit, state}, data) {
      // Update all resources
      for (const resourceName in data.resources) {
        commit('setResource', {resourceName, value: data.resources[resourceName]});
      }

      commit('setSpawns', data.spawns);
      commit('setGrid', data.grid);
      commit('setKing', data.king);
      commit('setRemainingDailyAttempts', data.remaining_daily_attempts);
      commit('setDeliveries', data.deliveries);
    },
    // Unlock a premium delivery slot
    async unlockDeliverySlot({commit, state, dispatch}, slotIndex) {
      try {
        const response = await axios.post('/minigames/kingdomgame/unlock_delivery_slot/', {
          slotIndex,
        });

        // Refresh deliveries
        commit('setDeliveries', {
          slots: response.data.slots,
          completed: state.deliveries.completed,
        });

        return response.data;
      } catch (error) {
        console.error('Error unlocking delivery slot:', error);
        throw error;
      }
    },
    async getNextSpawnSequence({commit, state}, spawnKey) {
      return axios.post('/minigames/kingdomgame/spawn_sequence/', {
        next_index: state.spawns[spawnKey].next_index,
      })
          .then((response) => {
            commit('setSpawns', response.data);
            return response.data;
          })
          .catch((error) => {
            console.log('Error fetching spawn sequence:', error);
            throw error;
          });
    },
    // assumes that child can sufficient energy to train pet
    // determines what item to spawn, reduces energy and upgrades grid
    // spawnKey is the key of the building that is being trained
    async trainPet({commit, state, getters, dispatch}, spawnKey) {
      // checks that we have enough energy
      if (getters.getResource('energy') < TRAIN_PET_ENERGY_COSTS['building|fire_valley|0']) {
        alert('Not enough energy');
        return {itemKey: null, isSuccess: false};
      }

      if (state.remainingDailyAttempts <= 0) {
        alert('No more trains left today. Try again tomorrow!');
        return {itemKey: null, isSuccess: false};
      }

      if (typeof state.spawns[spawnKey] === 'undefined') {
        alert('No spawn system found for this building');
        return {itemKey: null, isSuccess: false};
      }

      // Collect all free cells
      const freeCells = [];
      for (let row = 0; row < state.grid.length; row++) {
        for (let col = 0; col < state.grid[row].length; col++) {
          if (!state.grid[row][col]) {
            freeCells.push({row, col});
          }
        }
      }

      if (freeCells.length === 0) {
        alert('No available space.');
        return {itemKey: null, isSuccess: false};
      }
      const spawnSystem = state.spawns[spawnKey];
      // If we're running low on items, wait for new sequence before continuing
      if (spawnSystem.next_index >= spawnSystem.items.length) {
        try {
          await dispatch('getNextSpawnSequence', spawnKey);
        } catch (error) {
          console.error('Failed to get next spawn sequence:', error);
          return {itemKey: null, isSuccess: false};
        }
      }
      // Verify we have items available after potentially fetching more
      if (spawnSystem.next_index >= spawnSystem.items.length) {
        alert('No items available to spawn.');
        return {itemKey: null, isSuccess: false};
      }
      const nextItemSpawn = spawnSystem.items[spawnSystem.next_index];

      // Choose a random free cell
      const randomIndex = Math.floor(Math.random() * freeCells.length);
      const {row, col} = freeCells[randomIndex];

      // Update the grid with the new item
      commit('setGridCell', {row, col, value: nextItemSpawn});
      // after child has trained pet, if child clicks on another cell, we clear the building cell
      commit('setClearCellsOnNextClick', true);
      commit('subtractResource', {resourceName: 'energy', value: TRAIN_PET_ENERGY_COSTS[spawnKey]});
      commit('setRemainingDailyAttempts', state.remainingDailyAttempts - 1);

      axios.post('/minigames/kingdomgame/train_pet/', {
        grid: state.grid,
        petKey: nextItemSpawn,
        energyCost: TRAIN_PET_ENERGY_COSTS[spawnKey],
        newEnergy: getters.getResource('energy'),
        remainingDailyAttempts: state.remainingDailyAttempts,
        // which building is this, and which item is being spawned - for backend validation
        spawnKey: spawnKey,
        spawnIndex: spawnSystem.next_index,
      })
          .then((response) => {
            // console.log('purchase item response');
            // console.log(response.data);
          })
          .catch((error) => {
            console.log('Error fetching spawn sequence:', error);
            throw error;
          });

      commit('incrementSpawnIndex', spawnKey);
      return {itemKey: nextItemSpawn, isSuccess: true};
    },
    // Simplified selection logic - only one cell can be selected at a time
    selectCell({state, commit, getters}, {row, col}) {
      const position = {row, col};

      // e.g. if child has trained pet, and then clicks on another cell, we clear the building cell
      if (state.clearCellsOnNextClick) {
        commit('setClearCellsOnNextClick', false);
        commit('setSelectedCells', [position]);
        return;
      }

      // If clicking on the already selected cell, deselect it
      if (state.selectedCells.length === 1 &&
          state.selectedCells[0].row === row &&
          state.selectedCells[0].col === col) {
        commit('setSelectedCells', []);
      } else {
        // Otherwise, select this cell (and only this cell)
        commit('setSelectedCells', [position]);
      }
    },
    swapCells({state, commit}) {
      if (state.selectedCells.length !== 2) return;

      const [pos1, pos2] = state.selectedCells;
      const temp = state.grid[pos1.row][pos1.col];

      commit('setGridCell', {
        row: pos1.row,
        col: pos1.col,
        value: state.grid[pos2.row][pos2.col],
      });
      commit('setGridCell', {
        row: pos2.row,
        col: pos2.col,
        value: temp,
      });

      commit('setSelectedCells', []);
      // we aren't waiting for response from backend, so we don't need to do anything with it
      // note: this is really bad security practice, but we don't care about security for this game for now
      axios.post('/minigames/kingdomgame/swap_cells/', {
        grid: state.grid,
      });
    },
    // under other operations, we wait for response from backend before proceeding
    async redeemItem({state, commit, getters, dispatch}) {
      if (!getters.isRedeemable) return;

      const selectedCell = state.selectedCells[0];
      const itemKey = getters.getCellValue(selectedCell);

      try {
        axios.post('/minigames/kingdomgame/redeem_item/', {
          gridBeforeRedemption: state.grid,
          position: selectedCell,
          itemKey: itemKey,
        })
            .then((response) => {
              commit('clearSelectedCells');
              dispatch('setData', response.data);
            })
            .catch((error) => {
              console.log('Error redeeming item:', error);
              throw error;
            });
      } catch (error) {
        console.error('Error redeeming item:', error);
        alert('Failed to redeem item. Please try again.');
      }
    },
    mergeCells({state, commit, getters}) {
      if (!getters.canMergeSelection) return;
      if (state.selectedCells.length !== 2) {
        alert('Please select 2 items to merge!');
        return;
      }
      const firstCellKey = getters.getCellValue(state.selectedCells[0]);
      const [category, type, _currentLevel] = firstCellKey.split('|');
      const currentLevel = parseInt(_currentLevel);
      const itemData = getters.getItemData(firstCellKey);

      if (!itemData.isMergeable) {
        alert('Item is not mergeable');
        return;
      }

      // Create new merged cell with increased level
      const newCell = `${category}|${type}|${currentLevel + 1}`;

      // Place merged cell in last position (for drag and drop, we want to make it feel like cells are being merged)
      const lastCell = state.selectedCells[state.selectedCells.length - 1];
      commit('setGridCell', {
        row: lastCell.row,
        col: lastCell.col,
        value: newCell,
      });

      // Clear other cells
      for (let i = 0; i < state.selectedCells.length - 1; i++) {
        const pos = state.selectedCells[i];
        commit('setGridCell', {
          row: pos.row,
          col: pos.col,
          value: '',
        });
      }
      commit('setSelectedCells', []);
      // for now, we just send the grid to the backend
      // we don't need to wait for response
      axios.post('/minigames/kingdomgame/merge_cells/', {
        grid: state.grid,
      });
    },
    // sanity check: if king is about to level from feeding, then we wait for backend response before proceeding
    async feedPetToKing({state, commit, getters, dispatch}, petKey) {
      if (!getters.isFeedable) return;
      const xpGenerated = getters.selectedCellItemData.info.xpGeneratedWhenFedToKing;
      let shouldWaitForBackendResponse = state.king.xp + xpGenerated >= state.king.total_xp_required_for_next_level;

      // Check if this pet is part of any outstanding deliveries; if so, we wait for backend response
      const outstandingDeliveries = state.deliveries.slots;
      const matchingDeliveryIndex = outstandingDeliveries.findIndex((delivery) =>
        delivery.item_key === petKey
      );
      if (matchingDeliveryIndex > -1) shouldWaitForBackendResponse = true;

      // for speed, we handle the calculations on frontend and send response to backend
      // this is for when king isn't supposed to level up
      if (!shouldWaitForBackendResponse) {
        commit('incrementKingXp', xpGenerated);
        commit('setGridCell', {
          row: state.selectedCells[0].row,
          col: state.selectedCells[0].col,
          value: '',
        });
        commit('setSelectedCells', []);
        axios.post('/minigames/kingdomgame/feed_pet_to_king/', {
          calculationHandledByFrontend: true,
          grid: state.grid,
          newKingXp: state.king.xp,
        });
        return;
      }

      // however, if king is supposed to level up or part of an outstanding delivery, we wait for backend response for sanity checks
      try {
        axios.post('/minigames/kingdomgame/feed_pet_to_king/', {
          calculationHandledByFrontend: false,
          gridBeforeRedemption: state.grid,
          position: state.selectedCells[0],
          petKey: petKey,
        })
            .then((response) => {
              console.log(response.data);
              commit('clearSelectedCells');
              dispatch('setData', response.data);
              if (response.data.completed_delivery_id) {
                // Show the delivery completion modal by setting the Vuex state
                const completedDelivery = state.deliveries.completed.find(
                    (delivery) => delivery.id === response.data.completed_delivery_id
                );

                if (completedDelivery) {
                  // Set delivery completion data in Vuex state
                  const petItemData = getters.getItemData(petKey);
                  // Using commit to set the data in the store
                  commit('setDeliveryCompletionData', {
                    petKey: petKey,
                    petName: petItemData ? petItemData.name : 'Pet',
                    petImageUrl: petItemData ? petItemData.imageUrl : '',
                    completedDelivery: completedDelivery,
                    kingLevel: state.king.level,
                    kingLevelUp: response.data.king_leveled_up,
                    xpGained: petItemData ? petItemData.info.xpGeneratedWhenFedToKing : 0,
                  });
                }
              }
            })
            .catch((error) => {
              console.log('Error redeeming item:', error);
              throw error;
            });
      } catch (error) {
        console.error('Error redeeming item:', error);
        alert('Failed to redeem item. Please try again.');
      }
    },
    // end selection/merging logic
    // similar to redeemItem, we don't wait for response from backend
    async purchaseBuilding({commit, state, getters, dispatch}, buildingKey) {
      const building = getters.buildableBuildings.find((b) => b.key === buildingKey);
      if (!building) {
        alert('Invalid building');
        return false;
      }

      if (!getters.canAffordBuilding(building)) {
        alert('Not enough resources to purchase building');
        return false;
      }

      if (!getters.isEmptyCell) {
        alert('Please select an empty cell to purchase building');
        return false;
      }
      const selectedCell = state.selectedCells[0];

      try {
        axios.post('/minigames/kingdomgame/purchase_building/', {
          gridBeforeRedemption: state.grid,
          position: selectedCell,
          buildingKey: buildingKey,
        })
            .then((response) => {
              commit('clearSelectedCells');
              dispatch('setData', response.data);
            })
            .catch((error) => {
              console.log('Error redeeming item:', error);
              throw error;
            });
      } catch (error) {
        console.error('Error purchasing building:', error);
        alert('Failed to purchase building. Please try again.');
      }
    },
  },
  getters: {
    isCellSelected: (state) => (position) => {
      return state.selectedCells.some((pos) => pos.row === position.row && pos.col === position.col);
    },
    getCellValue: (state) => (position) => {
      return state.grid[position.row][position.col];
    },
    selectedCellItemData: (state, getters) => {
      if (state.selectedCells.length !== 1) return null;
      const firstCell = getters.getCellValue(state.selectedCells[0]);
      if (!firstCell) {
        return {
          isRedeemable: false,
          isMergeable: false,
        };
      }
      return getters.getItemData(firstCell);
    },
    getItemData: (state, getters) => (itemKey) => {
      if (!itemKey) return null;
      const [category, type, _level] = itemKey.split('|');
      const level = parseInt(_level);

      // Look up in appropriate chain data based on category
      let chain;
      switch (category) {
        case 'building':
          chain = BUILDING_CHAINS_DATA[type];
          break;
        case 'pet':
          chain = PET_CHAINS_DATA[type];
          break;
        case 'resource':
          chain = RESOURCE_CHAINS_DATA[type];
          break;
        default:
          return null;
      }

      if (!chain) return null;
      return chain.find((item) => item.level === level);
    },
    canMergeSelection: (state, getters) => {
      if (state.selectedCells.length !== 2) return false;

      // Get the first selected cell's type
      const firstCell = getters.getCellValue(state.selectedCells[0]);
      if (!firstCell) return false;

      const firstCellItemData = getters.getItemData(firstCell);
      // check whether the cell has reached max mergeable level
      if (!firstCellItemData.isMergeable) return false;

      // Check if all selected cells are the same type and level
      return state.selectedCells.every((pos) => {
        const cell = getters.getCellValue(pos);
        // cell/firstCell is a string representing the item key so simple comparison works
        return cell && cell === firstCell;
      });
    },
    getResource: (state) => (resourceName) => {
      if (typeof state.resources[resourceName] === 'undefined') {
        return 0;
      }
      return state.resources[resourceName];
    },
    // tests if single selected cell is a certain kind of cell
    isEmptyCell: (state, getters) => {
      if (state.selectedCells.length !== 1) return false;
      const cellValue = getters.getCellValue(state.selectedCells[0]);
      return cellValue === '';
    },
    // note difference in isRedeeable vs getResource (the way it is called)
    // => { vs => () =>; => { returns a direct value, => () => returns a function
    isRedeemable: (state, getters) => {
      if (state.selectedCells.length !== 1) return false;

      const itemData = getters.selectedCellItemData;
      return (typeof itemData.isRedeemable !== 'undefined') && itemData.isRedeemable;
    },
    isPetGeneratorBuilding: (state, getters) => {
      if (state.selectedCells.length !== 1) return false;
      const firstCellKey = getters.getCellValue(state.selectedCells[0]);
      // key is a string in the format of 'building|fire_valley|0'
      if (firstCellKey.split('|')[0] !== 'building') return false;
      const buildingData = getters.getItemData(firstCellKey);
      return buildingData.info.buildingType === 'pet_generator';
    },
    isFeedable: (state, getters) => {
      if (state.selectedCells.length !== 1) return false;
      const firstCellKey = getters.getCellValue(state.selectedCells[0]);
      // key is a string in the format of 'pet|flamebun|0'
      if (firstCellKey.split('|')[0] !== 'pet') return false;
      const petData = getters.getItemData(firstCellKey);
      return (typeof petData.info.xpGeneratedWhenFedToKing !== 'undefined') && petData.info.xpGeneratedWhenFedToKing > 0;
    },
    // end
    // returns true if the player can afford the building by checking all resource types that a building requires
    canAffordBuilding: (state, getters) => (building) => {
      for (const costInfo of building.cost) {
        const currentResource = getters.getResource(costInfo.type);
        if (currentResource < costInfo.value) {
          return false;
        }
      }
      return true;
    },
    // returns a list of buildings that are buildable - note that this includes buildings that player doesn't have sufficient resources for
    // as we want to show the player all the buildings they can potentially build
    // include a flag "canAfford" to indicate if the player can afford the building
    buildableBuildings: (state, getters) => {
      const toReturn = [];
      for (const info of Object.values(BUILDING_CHAINS_DATA)) {
        for (const building of info) {
          // only first level of buildings can be built
          if (building.level === 0) {
            // we now check if player has enough resources to build this building
            // we do this by checking if the player has enough of each resource in the building's cost
            // if they do, we add the building to the list
            building.canAfford = getters.canAffordBuilding(building);
            toReturn.push(building);
          }
        }
      }
      return toReturn;
    },

  },
};
