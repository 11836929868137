<!-- layout for ./learningtracks/modules -->
<template>
  <div class="term-index__component row">
    <div class="term-index__header">
      <router-link
        class="term-index__header--back"
        :to="{name: 'new-learn-vocab-index'}">
        <i class="fas fa-angle-left"></i>
      </router-link>
      <!-- shows which learning track user is on -->
      <h2>Current Level</h2>
    </div>
    <div class="term-index__content">
      <p class="term-index__content--main"
        v-if="!mainLearningTrack">
        There is currently no vocabulary track for your level.
      </p>
      <learning-track-container
        v-if="mainLearningTrack"
        :learning-track="mainLearningTrack"
        class="term-index__content--main">
      </learning-track-container>
    </div>
    <div
      v-if="otherLearningTracks.length > 0"
      class="term-index__header">
      <h2>Other Levels</h2>
    </div>
      <!-- shows available subtracks in current learning track -->
      <!-- descending order -->
    <div
      v-if="otherLearningTracks.length > 0"
      class="revision--tip" v-show="!toggle">
      <div>
        <div class="revision--tip-icon">
          <i class="fas fa-thumbs-up"></i>
          <div>
            <h6>Recommended<span>By Teachers</span></h6>
          </div>
        </div>
        <div class="revision--tip-header">
          <i class="fas fa-question-circle"></i>
          <h4>Did you know?</h4>
        </div>
        <p class="revision--tip-text">
          Revising words from previous levels is very important!
        </p>
      </div>
      <button class="revise-btn" @click='toggle = !toggle'>
        Let's Revise!
      </button>
    </div>
    <transition-group
      class="term-index__content term-index__content--unlocked slide-group"
      name="slide" mode="out-in">
      <learning-track-container
        v-show="toggle"
        v-for="learningTrack in otherLearningTracks"
        :key="learningTrack.pk"
        :learning-track="learningTrack"
        class="term-index__content--revision">
      </learning-track-container>
    </transition-group>
  </div>
</template>

<script>

import {mapState, mapGetters} from 'vuex';
import _filter from 'lodash.filter';

import LearningTrackContainerComponent from
  '../LearningTrackContainerComponent.vue';

export default {
  name: 'LearningTrackVocabularyIndex',
  components: {
    LearningTrackContainer: LearningTrackContainerComponent,
  },
  data() {
    return {
      toggle: true,
    };
  },
  computed: {
    ...mapState(['level']),
    ...mapGetters('learningTrack', ['getLearningTracksByType']),
    // filtered by the learningTypeTrack
    learningTracks() {
      return this.getLearningTracksByType('vocabulary');
    },
    // filters the learning track that is the same as current level
    // kiv -> assumes learning tracks are named after levels
    mainLearningTrack() {
      if (!this.learningTracks) return;

      const filteredLearningTracks =
        _filter(this.learningTracks, (learningTrack) => {
          return learningTrack.level === this.level;
        });
      return filteredLearningTracks.length > 0 ?
        filteredLearningTracks[0] : null;
    },
    otherLearningTracks() {
      if (!this.learningTracks) return;

      return _filter(this.learningTracks, (learningTrack) => {
        return learningTrack.level !== this.level;
      });
    },
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .term-index {
    &__component {
      text-align: center;
      margin: 20px auto 0 auto;
    }
    &__header {
      background: #5a4179;
      color: #fff;
      padding: 10px;
      border-radius: 3px;
      margin-bottom: 25px;
      position: relative;
      &--back {
        position: absolute;
        left: 10px;
        font-size: 1.5em;
        color: #fff;
        margin-left: 5px;
      }
    }
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
      margin-bottom: 30px;
      &--revision {
      }
      &--unlocked {
        padding-bottom: 10px;
      }
      &--main {
        grid-column: 1/ span 2;
      }
      &--locked {
      }
    }
  }

  // TO DELETE AFTER INACCESSIBLE TRACKS BACKEND DONE
  // Styles for inaccesible tracks
    .term-index-locked__item {
    color: #8a8a8a;
    background: #f1f1f1;
    margin: 20px;
    padding: 35px;
    border-radius: 3px;
    text-decoration: none;
    cursor: not-allowed;
    &--header {
      display: inline-block;
      padding: 5px 80px;
      border-bottom: 2px solid #d4d4d4;
    }
    &--subheader {
      margin-top: 5px;
      font-size: 16px;
      font-style: italic;
    }
    &--badges {
      margin-top: 15px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 15px;
    }
    &--badge {
      background: #f7f7f7;
      color: #efefef;
      border-radius: 10px;
      padding: 20px 10px;
      border: 5px solid transparent;
      text-decoration: none;
      &-icon {
        font-size: 300%;
        margin-bottom: 5px;
      }
      &-text {
        font-weight: 700;
        font-size: 85%;
      }
      &-completed {
        background: #F7B34E;
        color: #fff;
        border: 5px solid #ffca51;
      }
    }
    &:nth-child(odd) {
      margin-left: 0;
    }
    &:nth-child(even) {
      margin-right: 0;
    }
  }
  .slide-enter-active, .slide-leave-active {
    transition: opacity .2s ease-in-out, transform .2s ease-in-out;
  }

  .slide-enter, .slide-leave-to {
    opacity: 0;
    // transform: translateX(-20px);
  }
  .revise-btn {
    cursor: pointer;
    background: #f9f9f9;
    color: #777;
    font-size: 20px;
    border-radius: 10px;
    padding: 10px;
    border: 5px solid transparent;
    text-decoration: none;
    transition: all 0.2s;
    margin: 10px 10px;
    &:focus {
      outline: none;
    }
    &:hover {
      color: #6e9fec;
      background: #fff;
      border: 5px solid #6e9fec;
      .term-index__item--progressbar-container {
        background: #efefef;
      }
    }
  }
  .revision--tip {
    background: #fff;
    padding: 40px;
    // max-width: 720px;
    margin: auto;
    position: relative;
    h4 {
      font-size: 25px;
      display: inline-block;
      margin-bottom: 10px;
      margin-left: 10px;
    }
    &-header {
      display: flex;
      justify-content: center;
      align-items:center;
      i {
        font-size: 60px;
        color: #6e9fec;
        margin-bottom: 10px;
      }
    }
    &-icon {
      position: absolute;
      right: 15px;
      top: 15px;
      border: 2px solid #6e9fec;
      padding: 5px 5px 0 5px;
      border-radius: 5px;
      i {
        font-size: 25px;
        color: #6e9fec;
        margin-bottom: 10px;
      }
      div {
        display: inline-block;
      }
      h6 {
        font-size: 10px;
        text-align: left;
        margin-left: 5px;
        span {
          display: block;
        }
      }
    }
    &-text {
      margin: auto;
      max-width: 550px;
      padding: 20px 0;
      border-bottom: 1px solid #eee;
      border-top: 1px solid #eee;
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 560px) {
    .revision--tip {
      padding-top: 60px;
    }
  }

  @media only screen and (max-width: 1140px) {
    .term-index {
      &__component {
        margin: 10px auto 0 auto;
      }
      &__header {
        font-size: 16px;
        margin-bottom: 15px;
      }
      &__content {
        &:last-of-type {
          margin-bottom: 55px;
        }
        grid-gap: 20px;
        grid-template-columns: 1fr;
      }
    }
  }
</style>
