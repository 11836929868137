<template>
  <div class="play__component">
    <router-view class="play__component--content"></router-view>
  </div>
</template>

<script>


export default {
  name: 'NewMalayGrammarMain',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>


<style lang="scss" scoped>
</style>
