<template>
  <transition name="modal">
    <div v-if="show" class="modal-overlay" @click.self="$emit('close')">
      <div class="modal-content animated bounceIn">
        <h2 class="title">Purchase Building</h2>
        <div class="building-list">
          <div class="building-grid">
            <modal-cell
              v-for="(building, index) in buildableBuildings"
              :key="index"
              :building="building"
              @close="$emit('close')"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button @click="$emit('close')">
            <i class="fa fa-times-circle"></i>
            Close
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCellComponent from './ModalCellComponent.vue';

import {mapGetters} from 'vuex';

export default {
  name: 'PurchaseBuildingModal',
  props: {
    show: Boolean,
  },
  components: {
    ModalCell: ModalCellComponent,
  },
  computed: {
    ...mapGetters('kingdom', ['buildableBuildings']),
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 16px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  border: 2px solid #f0f0f0;

  h2 {
    margin-bottom: 1.5rem;
    color: #3c3c3c;
    font-size: 1.8rem;
    text-align: center;
    font-weight: 700;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 3px;
      background-color: #4CAF50;
      border-radius: 3px;
    }
  }
}

.modal-footer {
  margin-top: 20px;
  text-align: right;
}

.modal-footer {
  padding: 20px 10px 10px;
  width: 100%;

  button {
    cursor: pointer;
    font-family: "baloo da 2";
    width: 100%;
    margin-top: 0;
    font-weight: 600;
    border-radius: 12px;
    font-size: 1.15rem;
    border: none;
    padding: 12px 0;
    color: white;
    background: linear-gradient(to bottom, #4CAF50, #388E3C);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;

    i {
      margin-right: .5rem;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      background: linear-gradient(to bottom, #5CBF60, #439A4A);
      transform: translateY(-2px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    &:active {
      background: linear-gradient(to bottom, #388E3C, #2E7D32);
      transform: translateY(1px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
}

.building-list {
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;
  margin-bottom: 10px;
  padding: 0 5px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;

    &:hover {
      background: #a8a8a8;
    }
  }
}

.building-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
}

.modal-enter-active, .modal-leave-active {
  transition: all 0.3s ease;
}

.modal-enter, .modal-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.animated {
  animation-duration: 0.5s;
}

.bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
</style>
