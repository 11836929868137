<template>
  <div class="level-progress" :style="containerStyle">
    <div v-if="showLevelBadge" class="level-badge" :style="levelBadgeStyle">
      Level {{ displayLevel }}
    </div>
    <div class="xp-progress" :style="progressContainerStyle">
      <div class="xp-bar" :style="[progressBarStyle, {width: progressBarWidth}]"></div>
      <span class="xp-text" :style="textStyle" v-if="showText">
        {{ displayXP | abbrNum }} / {{ displayTotalXP | abbrNum }} <i :class="iconClass" v-if="iconClass"></i>
      </span>
    </div>
  </div>
</template>

<script>
/**
 * @component LevelProgressBar
 * @description A reusable progress bar component for displaying level and experience progress.
 * Features smooth animations during level-up transitions.
 *
 * @example
 * <LevelProgressBar
 *   :level="hero.level"
 *   :xp="hero.xp"
 *   :total-xp-required="hero.xpNeeded"
 *   icon-class="fa fa-sword"
 *   bar-color="#8BC34A"
 * />
 */
export default {
  name: 'LevelProgressBar',
  props: {
    /**
     * Current level of the entity
     * @type {Number}
     * @required
     */
    level: {
      type: Number,
      required: true,
    },
    /**
     * Current XP/progress of the entity
     * @type {Number}
     * @required
     */
    xp: {
      type: Number,
      required: true,
    },
    /**
     * Total XP/progress required for next level
     * @type {Number}
     * @required
     */
    totalXpRequired: {
      type: Number,
      required: true,
    },
    /**
     * Font Awesome icon class to display next to XP text
     * @type {String}
     * @default 'fa fa-star'
     */
    iconClass: {
      type: String,
      default: 'fa fa-star',
    },
    /**
     * Duration of level-up animation in milliseconds
     * @type {Number}
     * @default 800
     */
    animationDuration: {
      type: Number,
      default: 800,
    },
    /**
     * Width of the progress bar
     * @type {String}
     * @default '100%'
     */
    width: {
      type: String,
      default: '100%',
    },
    /**
     * Height of the progress bar
     * @type {String}
     * @default '20px'
     */
    height: {
      type: String,
      default: '20px',
    },
    /**
     * Color of the progress bar fill
     * @type {String}
     * @default '#8BC34A'
     */
    barColor: {
      type: String,
      default: '#8BC34A',
    },
    /**
     * Background color of the progress bar
     * @type {String}
     * @default '#ddd'
     */
    backgroundColor: {
      type: String,
      default: '#ddd',
    },
    /**
     * Color of the XP text
     * @type {String}
     * @default '#333'
     */
    textColor: {
      type: String,
      default: '#333',
    },
    /**
     * Background color of the level badge
     * @type {String}
     * @default '#4CAF50'
     */
    badgeColor: {
      type: String,
      default: '#4CAF50',
    },
    /**
     * Text color of the level badge
     * @type {String}
     * @default 'white'
     */
    badgeTextColor: {
      type: String,
      default: 'white',
    },
    /**
     * Font size for text elements
     * @type {String}
     * @default '0.9em'
     */
    fontSize: {
      type: String,
      default: '0.9em',
    },
    /**
     * Border radius of the progress bar
     * @type {String}
     * @default '10px'
     */
    borderRadius: {
      type: String,
      default: '10px',
    },
    /**
     * Bottom margin of the component
     * @type {String}
     * @default '20px'
     */
    marginBottom: {
      type: String,
      default: '20px',
    },
    /**
     * Whether to show the level badge
     * @type {Boolean}
     * @default true
     */
    showLevelBadge: {
      type: Boolean,
      default: true,
    },
    /**
     * Whether to show the XP text
     * @type {Boolean}
     * @default true
     */
    showText: {
      type: Boolean,
      default: true,
    },
    /**
     * Whether to use compact styling
     * @type {Boolean}
     * @default false
     */
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      previousLevel: null,
      isLevelingUp: false,
      levelUpTimer: null,
      displayLevel: 1,
      displayXP: 0,
      displayTotalXP: 0,
    };
  },
  computed: {
    progressBarWidth() {
      if (this.isLevelingUp) {
        return '100%';
      }
      const progress = this.displayXP / this.displayTotalXP;
      return `${progress * 100}%`;
    },
    containerStyle() {
      return {
        marginBottom: this.marginBottom,
      };
    },
    levelBadgeStyle() {
      return {
        backgroundColor: this.badgeColor,
        color: this.badgeTextColor,
        padding: this.compact ? '2px 8px' : '4px 12px',
        borderRadius: this.compact ? '8px' : '12px',
        fontSize: this.compact ? '0.8em' : this.fontSize,
      };
    },
    progressContainerStyle() {
      return {
        height: this.height,
        backgroundColor: this.backgroundColor,
        borderRadius: this.borderRadius,
        width: this.width,
      };
    },
    progressBarStyle() {
      return {
        backgroundColor: this.barColor,
      };
    },
    textStyle() {
      return {
        color: this.textColor,
        fontSize: this.fontSize,
      };
    },
  },
  watch: {
    level: {
      handler(newLevel) {
        if (this.previousLevel !== null && newLevel > this.previousLevel) {
          // Level up detected
          this.handleLevelUp();
        } else {
          // Normal update
          this.updateDisplayValues();
        }
        this.previousLevel = newLevel;
      },
      immediate: true,
    },
    xp: {
      handler() {
        if (!this.isLevelingUp) {
          this.updateDisplayValues();
        }
      },
    },
  },
  methods: {
    updateDisplayValues() {
      this.displayLevel = this.level;
      this.displayXP = this.xp;
      this.displayTotalXP = this.totalXpRequired;
    },
    handleLevelUp() {
      // First show full progress bar
      this.isLevelingUp = true;
      this.displayXP = this.displayTotalXP;

      // Clear any existing timer
      if (this.levelUpTimer) {
        clearTimeout(this.levelUpTimer);
      }

      // After a short delay, update to the new level values
      this.levelUpTimer = setTimeout(() => {
        this.isLevelingUp = false;
        this.updateDisplayValues();
      }, this.animationDuration);
    },
  },
  beforeDestroy() {
    if (this.levelUpTimer) {
      clearTimeout(this.levelUpTimer);
    }
  },
};
</script>

<style lang="scss" scoped>
.level-progress {
  .level-badge {
    display: inline-block;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .xp-progress {
    position: relative;
    overflow: hidden;
  }
  .xp-bar {
    height: 100%;
    transition: width 0.3s ease;
  }
  .xp-text {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    text-shadow: 0 0 2px white;
  }
}
</style>
