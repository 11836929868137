<template>
  <div class="writing-pattern-selection__component">
    <div class="writing-pattern-selection__content">
      <h2>Writing Patterns</h2>
      <div class="writing-pattern-selection__table">
        <div v-for="pattern in patterns"
             :key="pattern.id"
             class="writing-pattern-selection__item">
          <div class="writing-pattern-selection__item-title">
            <div class="writing-pattern-selection__item-title-main">
              <p>{{ pattern.name }}</p>
            </div>
            <div class="writing-pattern-selection__item-title-sub">
              <p>{{ pattern.description }}</p>
            </div>
          </div>
          <div class="writing-pattern-selection__item-btns">
            <div class="writing-pattern-selection__btn-container">
              <button @click="selectPracticeMode(pattern, 'scaffolded')"
                class="writing-pattern-selection__item-btn btn-scaffolded">
                <span>Fill in the blanks</span>
                <rating
                  class="scaffolded-rating"
                  :max-score="3"
                  :current-score="getCompletionScore(pattern.id, 'scaffolded')"
                />
              </button>
            </div>
            <button @click="selectPracticeMode(pattern, 'free')"
              class="writing-pattern-selection__item-btn btn-free">
              Free Practice
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapMutations} from 'vuex';
import RatingComponent from '../../../common/RatingComponent.vue';

export default {
  name: 'WritingPatternSelectionComponent',
  components: {
    Rating: RatingComponent,
  },
  data() {
    return {
      // For tracking pattern completion (would normally come from API/Vuex store)
      completionStatus: {},
    };
  },
  mounted() {
    // // Fetch drills from the backend when component is mounted
    // if (this.patterns.length === 0) {
    //   this.setShowGlobalLoadingScreen(true);
    //   this.fetchPatterns();
    // }
    this.setShowGlobalLoadingScreen(true);
    this.fetchPatterns();
  },
  beforeDestroy() {
  },
  computed: {
    ...mapState('composition', ['patterns']),
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    ...mapMutations('composition', ['setPatterns']),
    async fetchPatterns() {
      try {
        console.log('fetching patterns');
        const response = await axios.get('/writing/patterns/');
        console.log('response', response.data);
        this.setPatterns(response.data);
      } catch (error) {
        console.error('Error fetching writing patterns:', error);
        // Just throw the error - no fallback data
        throw error;
      } finally {
        this.setShowGlobalLoadingScreen(false);
      }
    },
    selectPracticeMode(pattern, mode) {
      // Emit event to parent component to load the appropriate practice component
      this.$emit('practice-selected', {
        pattern,
        mode,
      });
    },
    getCompletionScore(patternId, mode) {
      // Find the pattern by ID
      const pattern = this.patterns.find((p) => p.id === patternId);
      if (pattern && pattern.proficiency_stats) {
        const numCorrect = pattern.proficiency_stats.num_correct;
        const totalQuestions = pattern.proficiency_stats.total_questions;
        // If no questions have been attempted, return 0
        if (totalQuestions === 0) {
          return 0;
        }
        // Calculate percentage, multiply by 3 and floor it to get a score from 0-3
        // Mode is ignored for now since we're only tracking scaffolded mode
        // Only return a score for scaffolded mode
        if (mode === 'scaffolded') {
          const percentage = numCorrect / totalQuestions;
          return Math.min(Math.floor(percentage * 3), 3);
        }
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.writing-pattern-selection {
  &__component {
    height: 100%;
    overflow: auto;
  }

  &__content {

    h2 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }

    p {
      color: #666;
      margin-bottom: .75rem;
    }
  }

  &__table {
    text-align: center;
  }

  &__table {
    width: 100%;
  }

  &__item {
    width: 100%;
    padding: 1.5rem;
    background: #fff;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    position: relative;
    border: 1px solid #eee;
    gap: 1rem;
    display: flex;
    align-items: center;
    &-title {
      font-weight: 600;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;

      &-main {
        text-align: left;
        font-size: 1.1rem;
      }

      &-sub {
        text-align: left;
        font-size: 0.9rem;
        color: #666;
        margin-top: 0.25rem;
        margin-left: 1rem;
        font-style: italic;
        font-weight: 300;
      }
    }

    &-btns {
      display: flex;
      min-width: 300px;
      gap: 0.5rem;
      margin-left: 1rem;
    }

    &-btn {
      text-shadow: 1px 1px rgba(0,0,0,0.3);
      color: #fff;
      border-radius: 0.25rem;
      padding: 0.5rem;
      border: none;
      cursor: pointer;
      font-weight: 600;
      font-family: "baloo da 2";
      width: 200px;
      &:active {
        transform: translateY(2px);
      }
    }

    .btn-scaffolded {
      background: #3a87ff;
      border-bottom: 4px solid #1e5ecc;

      &:active {
        border-bottom: 2px solid #1e5ecc;
        margin-bottom: 2px;
      }
      span {
        display: block;
        margin-bottom: -.4rem;
      }
    }

    .btn-free {
      background: #a03aff;
      border-bottom: 4px solid #7222cc;

      &:active {
        border-bottom: 2px solid #7222cc;
      }
    }
  }
  &__btn-container {
    display: flex;
    flex-direction: column;
    .scaffolded-rating {
      margin-top: 4px;
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 932px) {
  .writing-pattern-selection__item {
    flex-direction: column;
    align-items: flex-start;
    gap: .25rem;
  }
  .writing-pattern-selection__item-btns {
    margin-left: 0;
  }
}
@media only screen and (max-width: 768px) {
  .writing-pattern-selection__item {
    flex-direction: column;
    padding: 1.25rem;
    &-btns {
      min-width: 100%;
    }
  }
}
@media only screen and (max-width: 500px) {
  .writing-pattern-selection__item-btn {
    width: 150px;
  }
  .writing-pattern-selection__item-btns {
    justify-content: center;
  }
}
@media only screen and (max-width: 400px) {
  .writing-pattern-selection__item-btn {
    width: 125px;
  }
  .writing-pattern-selection__content h2 {
    font-size: 1.2rem;
  }
  .writing-pattern-selection__item-title-main {
    font-size: 1rem;
  }
}
</style>
