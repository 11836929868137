<template>
  <block-container
    title="Change Difficulty"
    fa-icon="gauge-high"
    previous-page-name="new-settings-overview"
    >
    <div class="difficulty__component">
      <div class="btn-option-grp">
          <div v-for="(_d, index) in DIFFICULTIES" :key="index"
          class="btn-option"
          :class="{'btn-option--current': index === currentDifficulty}">
            <input :id="index" type="radio" :value="index" v-model="selectedDifficulty">
            <label :for="index">
              <i v-if="_d.name == 'DIFFICULTY I'"
                class="fa-solid fa-gauge-simple-high level-one--icon"></i>
              <i v-if="_d.name == 'DIFFICULTY II'"
                class="fa-solid fa-gauge-simple"></i>
              <i v-if="_d.name == 'DIFFICULTY III'"
              class="fa-solid fa-gauge-simple-high"></i>
              {{ _d.name }}
              <span v-if="index === currentDifficulty">
                Current
              </span>
            </label>
            <!-- <h5>Pet Battle Question Breakdown</h5> -->
            <!-- <ul>
              <li v-for="(entry, index) in selectedDifficultyData['questionBreakdown']" :key="index">
                {{ entry['text'] }}: {{ entry['value'] }}%
              </li>
            </ul> -->
          </div>
        </div>
      <div class="difficulty__component--desc">
        <pie-chart
          :chart-data="questionBreakdownPieChartData"
          :chart-options="questionBreakdownPieChartOptions"
          class="pie-chart"></pie-chart>
        <div class="description">
          <p> {{ selectedDifficultyData['description'] }}</p>
        </div>
      </div>
      <button
        class="btn-submit"
        type="submit"
        @click.prevent="changeDifficulty">
        Confirm
      </button>
    </div>
  </block-container>
</template>

<script>

import {mapState, mapMutations, mapActions} from 'vuex';
import axios from 'axios';

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';

import PieChart from '../../common/charts/Pie.vue';


export default {
  name: 'NewSettingsChangeDifficulty',
  components: {
    BlockContainer: BlockContainerComponent,
    PieChart,
  },
  data() {
    return {
      selectedDifficulty: 2,
    };
  },
  computed: {
    ...mapState(['studentName', 'studentId', 'currentDifficulty']),
    DIFFICULTIES() {
      return [
        {
          name: 'DIFFICULTY I',
          description: `Difficulty I focuses on strengthening ${this.studentName}'s foundation by mastering high frequency words from P1 onwards. As ${this.studentName}'s vocab improves, simple assessment book questions are selected to practise reading.`,
          questionBreakdown: [
            {
              text: 'Vocab (High Frequency)',
              value: 70,
            },
            {
              text: 'Assessment Book (Foundational)',
              value: 30,
            },
          ],
        },
        {
          name: 'DIFFICULTY II',
          description: `Difficulty II is a bridging difficulty, starting by mastering the full wordlist and high frequency words. Standard assessment book questions are also introduced to help ${this.studentName} prepare for exams.`,
          questionBreakdown: [
            {
              text: 'Vocab (Full)',
              value: 25,
            },
            {
              text: 'Vocab (High Frequency)',
              value: 25,
            },
            {
              text: 'Assessment Book (Standard)',
              value: 25,
            },
            {
              text: 'Assessment Book (Foundational)',
              value: 25,
            },
          ],
        },
        {
          name: 'DIFFICULTY III',
          description: `Difficulty III is the standard difficulty and prepares ${this.studentName} to excel for the Paper 2 exam, with greater focus placed on assessment book questions. Harder questions are added to expand ${this.studentName}'s understanding.`,
          questionBreakdown: [
            {
              text: 'Assessment Book (Standard)',
              value: 60,
            },
            {
              text: 'Assessment Book (Hard)',
              value: 40,
            },
          ],
        },
      ];
    },
    selectedDifficultyData() {
      return this.DIFFICULTIES[this.selectedDifficulty];
    },
    questionBreakdownPieChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'Question Breakdown (Pet Battle)',
          },
        },
      };
    },
    questionBreakdownPieChartData() {
      const labels = [];
      const data = [];
      for (const entry of this.DIFFICULTIES[this.selectedDifficulty]['questionBreakdown']) {
        labels.push(entry['text']);
        data.push(entry['value']);
      }
      return {
        labels: labels,
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#ece540'],
            data: data,
          },
        ],
      };
    },
  },
  mounted() {
    this.selectedDifficulty = this.currentDifficulty;
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen', 'setCurrentDifficulty']),
    ...mapActions(['loadToken', 'pullData']),
    changeDifficulty() {
      if (this.selectedDifficulty === this.currentDifficulty) return;
      axios
          .post(`/api/v1/core/student/${this.studentId}/changedifficulty/`, {
            newDifficulty: this.selectedDifficulty,
          })
          .then((response) => {
            this.setCurrentDifficulty(this.selectedDifficulty);
            alert('Successfully changed difficulty.');
          })
          .catch((error) => {
            alert('Error changing difficulty');
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  .difficulty__component {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    text-align: center;
    background: #fff;
    padding: 2rem;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    &--desc {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 500px;
      flex: 1;
    }
  }
  .level-one--icon {
    transform: scaleX(-1);
  }
  .btn-option {
    flex: 1;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
    input[type="radio"] {
      display: none;
    }
    label {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      background: white;
      border: 3px solid transparent;
      border-radius: .5rem;
      box-shadow: 0px 3px 10px -1px #cecece;
      position: relative;
      padding: 1rem;
      color: #e9e9e9;
      &:hover {
        border: 3px solid #2be7f8;
        border-radius: .5rem;
      }
      i {
        font-size: 1.5rem;
        margin-right: .5rem;
      }
      span {
        position: absolute;
        top: -1rem;
        border-radius: .5rem;
        font-size: .8rem;
        text-wrap: nowrap;
        color: #2b92f8;
        background: #fff;
        padding: 0 .5rem;
        box-shadow: 0px 3px 8px -4px #0f4c8a;
        border: 2px solid #2b92f8;
      }
    }
    input[type="radio"]:checked + label {
      background: #2b92f8;
      color: #fff;
    }
    input[type="radio"]:not(:checked) + label {
      &:hover {
        color: #2be7f8;
      }
    }

    &-grp {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .description {
    height: 85%;
    width: 50%;
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: flex-start;
    border-top: 2px solid #e3e3e3;
    border-bottom: 2px solid #e3e3e3;
    font-weight: 500;
    flex-direction: column;
    h3 {
      margin-bottom: 1rem;
      text-decoration: underline;
    }
  }
  .btn-submit {
    position: relative;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    display: block;
    border: 2px solid #1c6cd9;
    width: 250px;
    height: 60px;
    border-radius: .5rem;
    background: #5881fc;
    padding: .5rem  ;
    cursor: pointer;
    transition: all 0.2s;
    &:focus {
      outline: none;
    }
    &:hover {
      transform: scale(1.04);
      background-color: #58bdfc;
      border: 2px solid #3a9edd;
    }
    &:active {
      transform: scale(1.02);
      background: #5881fc;
      border: 2px solid #1c6cd9;
    }
  }
  .btn-option--current {
    label {
      border: 3px solid #2b92f8;
      border-radius: .5rem;
      color: #2b92f8;
    }
  }
  .pie-chart {
      height: 300px;
      width: 300px;
      width: 50%;
  }

  @media only screen and (max-width: 1770px) {
  }
  @media only screen and (max-width: 1670px) {
  }
  @media only screen and (max-width: 1550px) {
  }
  @media only screen and (max-width: 1366px),
  screen and (max-height: 870px)  {
    .btn-option label {
      padding: .5rem;
      font-size: .95rem;
      span {
        font-size: .7rem;
      }
    }
    .pie-chart {
      width: 45%;
    }
    .description {
      width: 55%;
      h3 {
        margin-bottom: 0.5rem;
        font-size: 1.1rem;
      }
      p {
        font-size: .9rem;
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    .pie-chart {
      width: 40%;
    }
    .description {
      width: 60%;
    }
  }

  @media only screen and (max-width: 1150px),
  screen and (max-height: 720px) {
    .difficulty__component {
      padding: 1rem;
    }
    .btn-option label {
      font-size: .85rem;
      margin-top: .5rem;
      span {
        font-size: .65rem;
      }
    }
    .btn-option {
      margin-right: .5rem;
    }
    .pie-chart {
      width: 50%;
    }
    .description {
      width: 50%;
      h3 {
        font-size: 1rem;
      }
      p {
        font-size: .85rem;
      }
    }

  }
  @media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
    .difficulty__component {
    }
    .difficulty__component--desc {
      flex-direction: column;
    }
    .pie-chart {
      width: 100%;
      height: 200px;
    }
    .btn-option label {
      font-size: .8rem;
      margin-top: .75rem;
      flex-direction: column;
      padding: .25rem;
      margin-bottom: .5rem;
      span {
        font-size: .6rem;
      }
      i {
        margin-right: 0;
        margin-bottom: .25rem;
        margin-top: .25rem;
      }
    }
    .description {
      width: 100%;
      padding: 1rem 2rem;
      margin-bottom: .75rem;
      h3 {
        font-size: .9rem;
      }
      p {
        font-size: .75rem;
      }
    }
  }

</style>
