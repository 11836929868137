require('./iosweblinks');

import axios from 'axios';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import vSelect from 'vue-select';
import ImageUploader from 'vue-image-upload-resize';
// https://medium.com/dailyjs/tips-tricks-for-vue-analytics-87a9d2838915
import VueAnalytics from 'vue-analytics';
// https://github.com/fengyuanchen/vue-countdown
import VueCountdown from '@chenfengyuan/vue-countdown';
import VueQrcodeReader from 'vue-qrcode-reader';
import VueCookies from 'vue-cookies';
import VTooltip from 'v-tooltip';
import VueMeta from 'vue-meta';

import * as Sentry from '@sentry/vue';
// https://github.com/lancedikson/bowser
import * as Bowser from 'bowser';

// opera is annoying to use as it doenst support mp3. for now, throw a warning to user
const browser = Bowser.getParser(window.navigator.userAgent);
if (browser.getBrowser()['name'].toLowerCase() === 'opera') {
  alert('Warning: VocabKing audio files will not play on Opera. Please use another broswer');
}

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

Vue.use(Vuex);
Vue.use(VueCookies);
Vue.use(ImageUploader);
Vue.use(VueQrcodeReader);
Vue.use(VTooltip);
// hacked code - to override bootstrap, which is used in analytics page
VTooltip.options.defaultTemplate =
  '<div role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';
Vue.component(VueCountdown.name, VueCountdown);


import App from './App.vue';
import './filters';
import {routes} from './routes';
import {store} from './store/store';
import {mapState, mapActions, mapMutations} from 'vuex';

// allows us to use the vue debugger
Vue.config.devtools = true;
Vue.config.productionTip = false;

Vue.$cookies.config('30d');

Vue.use(VueRouter);
Vue.component('v-select', vSelect);

const router = new VueRouter({
  routes,
  mode: 'history',
  base: '/',
});

// Configure axios interceptor to handle HTML responses (session expiration)
axios.interceptors.response.use(
    (response) => {
      // Check if response data is a string containing DOCTYPE html
      if (typeof response.data === 'string' && response.data.includes('<!DOCTYPE html>')) {
        console.warn('Received HTML response instead of expected data. Session may have expired.');

        // Reset application state
        store.dispatch('resetState');
        store.commit('setInitialRoute', null);

        // Clear authentication data
        localStorage.removeItem('user-token');
        axios.defaults.headers.common['Authorization'] = '';

        // Redirect to login
        router.push('/login');

        return Promise.reject(new Error('Session expired'));
      }
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
);

const isProd = process.env.NODE_ENV === 'production';

if (isProd) {
  Vue.use(VueAnalytics, {
    id: 'UA-141539620-1',
    router,
    debug: {
      enabled: !isProd,
      sendHitTask: isProd,
    },
  });
};

// console.error('test error');
// https://stackoverflow.com/questions/42603909/accessing-vuex-state-when-defining-vue-router-routes
router.beforeEach((to, from, next) => {
  // hack to check if user put logout to force reset
  if (window.location.pathname.toLowerCase().includes('logout')) {
    store.dispatch('resetState');
    store.commit('setInitialRoute', null);
    window.location.href = '/login/';
    return;
  }

  // if not valid path, just go to profile
  if (!to || (typeof to.name === 'undefined')) {
    next('/');
    return;
  };

  // if at profile/login, don't need to validate further
  if (to.name === 'profile-main' || to.name === 'login-main' || to.name === 'login-main-malay' || to.name === 'wordle' ||
      to.name === 'registration-parent' || to.name === 'registration-parent-new') {
    next();
    return;
  }

  // for registration/xxx/student, we need to store the xxx value
  // as it refers to the parent signup token
  if (to.name.startsWith('registration')) {
    if (to.name === 'registration-student') {
      store.commit('setInitialRoute', to);
    }
    next();
    return;
  }

  // if not initialised, redirect to profile
  // also store the initial route (the route that user first accessed portal)
  // that way we can redirect there after we have pulled data
  if (store.state.studentId === -1) {
    store.commit('setInitialRoute', to);
    next('/');
    return;
  };

  // all other routes
  next();
});

Sentry.init({
  Vue,
  dsn: 'https://d15a90036340467aa926e1dc71b5140a@o291313.ingest.sentry.io/1532376',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// // Initialize Firebase if it is supported
// const Firebase = window.firebase;
// let messaging = null;
// if (window.firebaseConfig && Firebase.messaging.isSupported()) {
//   Firebase.initializeApp(window.firebaseConfig);
//   messaging = Firebase.messaging();
// }

new Vue({
  el: '#app',
  store,
  router,
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['authenticated', 'initialRoute', 'subject']),
  },
  created() {
    // this.$store.watch((state) => state.authenticated, (newValue, oldValue) => {
    //   if (!oldValue && newValue) {
    //     console.log('Now is authenticated!');
    //     this.setupNotification();
    //   }
    // });
  },
  // we pull basic information about the student
  mounted: function() {
    // determines if app is in webapp mode
    const isWebAppiOS = (window.navigator.standalone == true);
    const isWebAppChrome =
      (window.matchMedia('(display-mode: standalone)').matches);
    const setIsWebApp = isWebAppiOS || isWebAppChrome;
    store.commit('setIsWebApp', setIsWebApp);

    const COMPONENTS_NOT_REQUIRING_AUTHENTICATION = ['wordle', 'registration-parent', 'registration-parent-new'];

    if (COMPONENTS_NOT_REQUIRING_AUTHENTICATION.indexOf(this.$route.name) > -1) {
      return;
    }

    // logout() {
    //   this.resetState();

    //   // redirects to login page
    //   this.$router.push({name: 'login-main'});
    // },

    // if a parent goes directly to student registration page, dont need validation
    if (this.initialRoute && this.initialRoute['name'] === 'registration-student') {
      this.$router.push({
        name: this.initialRoute.name,
        params: this.initialRoute.params,
        query: this.initialRoute.query,
      });
      return;
    }

    // if token not in local storage (aka not authenticated),
    // redirect to login page
    this.loadToken()
        .then((response) => {
          this.pullData()
              .then((response) => {
                // // temporary hack to ennsure malay students only see psle oral
                // if (this.subject === 'malay') {
                //   this.$router.push({
                //     name: 'profile-main',
                //     params: {
                //       subject: 'malay',
                //     },
                //   });
                //   return;
                // }

                console.log('subject', this.subject);
                // initial route refers to the situation when user tries
                // to access specific route when they first access portal
                // after we pull data, then we go to the requested route
                if (this.initialRoute) {
                  this.$router.push({
                    name: this.initialRoute.name,
                    params: this.initialRoute.params,
                    query: this.initialRoute.query,
                  });
                  // then reset the initialRoute
                  store.commit('setInitialRoute', null);
                } else {
                  this.$router.push({name: 'profile-main'}).catch((err) => {});
                };
                // this.$router.push({name: 'profile-main'});
              })
              // KIV -> should bubble up to next catch statement eventually
              .catch((error) => {
                console.log('error', error);
                localStorage.removeItem('user-token');
                axios.defaults.headers.common['Authorization'] = '';
                this.$router.push({name: 'login-main'});
              });
        })
        // not authenticated
        .catch((error) => {
          console.log('Not authenticated');
          // clears token and axios
          localStorage.removeItem('user-token');
          axios.defaults.headers.common['Authorization'] = '';
          this.setFetchingInitialData(false);
          const url = window.location.href.toLowerCase();
          if (url.includes('nusa')) {
            this.$router.push({name: 'login-main-malay'});
          } else {
            this.$router.push({name: 'login-main'});
          }
        });
  },
  methods: {
    ...mapMutations(['setFetchingInitialData']),
    ...mapActions(['loadToken', 'pullData', 'sendNotifyTokenToServer']),
    // setupNotification() {
    //   if (messaging) {
    //     Notification.requestPermission().then((permission) => {
    //       if (permission === 'granted') {
    //         messaging.getToken().then((currentToken) => {
    //           console.log('Got Notify token');
    //           this.sendNotifyTokenToServer(currentToken);
    //         });
    //       } else {
    //         console.log('Unable to get permission to notify.');
    //       }
    //     });
    //   }
    // },
  },
  render: (h) => h(App),
});
