import { render, staticRenderFns } from "./LanguageFeedbackComponent.vue?vue&type=template&id=229a2971&scoped=true&"
import script from "./LanguageFeedbackComponent.vue?vue&type=script&lang=js&"
export * from "./LanguageFeedbackComponent.vue?vue&type=script&lang=js&"
import style0 from "./LanguageFeedbackComponent.vue?vue&type=style&index=0&id=229a2971&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "229a2971",
  null
  
)

export default component.exports