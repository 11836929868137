<template>
  <div class="free-response-component chinese-character">
    <div v-if="topic" class="free-response-component__container">
      <!-- {{ topic }} -->
      <div class="free-response-component__header">
        <div class="header-left">
          <router-link
            class="close-button chinese-character"
            :to="{name: 'new-oral-category-index', params: {category: 'freeResponse'}}">
            <i class="fas fa-times"></i>
          </router-link>
          <h2>
            <span class="desktop-title">会话练习：{{ topic.title }}</span>
            <span class="mobile-title">{{ topic.title }}</span>
          </h2>
        </div>
        <div class="header-right">
          <button
            class="pdf-button"
            @click="downloadPdf"
            title="Download PDF of all questions and answers"
          >
            <i class="fas fa-file-pdf"></i>
          </button>
          <p>
            <span class="desktop-prefix">问题：</span>{{ this.questionIndex + 1 }} / {{ this.numQuestions }}
          </p>
        </div>
      </div>
      <div class="free-response-component__content">
        <div class="free-response-component__content--qns-container">
          <div class="free-response-component__nav">
            <div @click="nextQns"
              :class="{ 'nav-disabled': questionIndex === 0 }"
              class="free-response-component__nav-btn">
              <i class="fas fa-chevron-left"></i>
            </div>
            <transition name="fade" mode="out-in">
              <div class="free-response-component__content--qns"
              >{{ currentQuestion['question_text'] }}</div>
            </transition>
            <div @click="nextQns"
              :class="{ 'nav-disabled': questionIndex === numQuestions - 1 }"
              class="free-response-component__nav-btn">
              <i class="fas fa-chevron-right"></i>
            </div>
          </div>
        </div>
        <div class="free-response-component__content--ans">
          <transition name="fade" mode="out-in">
            <div>
              <!-- for now, we only have 1 answer per question -->
              <!-- if we have different answers of different difficulty, then replace 0 with something variable -->
              <p v-for="(text, index) in currentQuestion.answers[0]['clean_text']" :key="index">
                {{ text }}
              </p>
            </div>
          </transition>
        </div>
        <div class="free-response-component__btn-practice--container">
          <router-link
            class="free-response-component__btn-practice"
            :to="{name: 'oral-new-passage',
            params: {
              type: 'freeresponse',
              entityId: currentQuestion.answers[0].id,
            }}">
              Practice
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapMutations} from 'vuex';
import axios from 'axios';

export default {
  name: 'OralFreeResponseTopic',
  components: {
  },
  props: {
    topicId: Number,
  },
  data() {
    return {
      questionIndex: 0,
      numQuestions: 0,
      topic: null,
    };
  },
  computed: {
    currentQuestion() {
      if (!this.topic || this.questionIndex >= this.topic.questions.length) return null;
      return this.topic.questions[this.questionIndex];
    },
  },
  mounted() {
    // checks to see if we need to pull free response topics
    this.setShowGlobalLoadingScreen(true);

    axios
        .get(`/vocab/oralfreeresponse/${this.topicId}/topic/`)
        .then((response) => {
          this.topic = response.data;
          this.numQuestions = this.topic['questions'].length;
          console.log(this.topic);
          this.setShowGlobalLoadingScreen(false);
        });
    // }
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    nextQns() {
      this.questionIndex++;
      if (this.questionIndex >= this.numQuestions) {
        this.questionIndex = 0;
        return;
      };
    },
    downloadPdf() {
      // Show loading indication
      this.setShowGlobalLoadingScreen(true);
      // Make an authenticated request to the PDF endpoint
      axios({
        url: `/vocab/oralfreeresponse/${this.topicId}/generate_pdf/`,
        method: 'GET',
        responseType: 'blob', // Important for handling binary data
        timeout: 30000, // 30 second timeout for larger PDFs
      })
          .then((response) => {
            // Get filename from Content-Disposition header if available
            let filename = `free_response_${this.topic.title || this.topicId}.pdf`;
            const contentDisposition = response.headers['content-disposition'];
            if (contentDisposition) {
              const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
              if (filenameMatch && filenameMatch[1]) {
                filename = filenameMatch[1].replace(/['"]/g, '');
              }
            }
            // Create a blob URL for the PDF data
            const blob = new Blob([response.data], {type: 'application/pdf'});
            const url = window.URL.createObjectURL(blob);
            // Create a temporary link and trigger download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            // Clean up
            setTimeout(() => {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(link);
              this.setShowGlobalLoadingScreen(false);
            }, 100);
          })
          .catch((error) => {
            this.setShowGlobalLoadingScreen(false);
            // Provide specific error feedback
            if (error.response) {
              // Server responded with an error
              if (error.response.status === 401) {
                alert('Authentication required. Please try logging in again.');
              } else if (error.response.status === 404) {
                alert('The requested topic could not be found.');
              } else {
                alert(`Error downloading PDF: ${error.response.status} ${error.response.statusText}`);
              }
            } else if (error.request) {
              // Request was made but no response received
              alert('No response received from server. Please check your connection and try again.');
            } else {
              // Something else went wrong
              alert(`Failed to download PDF: ${error.message}`);
            }
            console.error('Error downloading PDF:', error);
          });
    },
  },
};

</script>

<style lang="scss" scoped>
.free-response-component {
  background: #fff;
  height: 100vh;
  width: 100vw;
  top: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__container {
    height: 80%;
    max-width: 820px;
  }
  &__header {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
    h2 {
      font-size: 1.25rem;
      font-weight: 600;
    }
    p {
      font-size: .9rem;
      font-weight: 600;
      padding: 0.25rem 0.5rem;
      border-radius: 20px;
      background: #ffcf6d;
    }
  }
  &__content {
    height: 90%;
    width: 100%;
    &--qns {
      margin: 0 1rem;
      &-container {
        height: 20%;
        text-align: center;
        border-top: 2px solid #ccc;
        border-bottom: 2px solid #ccc;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.25rem;
        font-weight: 600;
      }
    }
    &--ans{
      height: 70%;
      padding: 0rem 3rem;
      border-radius: 1rem;
      margin: 1rem auto;
      overflow: auto;
      p {
        font-size: 1.2rem;
        text-indent: 40px;
        margin-bottom: 1rem;
        line-height: 2rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    &-btn {
      padding: 0.5rem;
      border-radius: 1rem;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
  &__btn-practice {
    &--container {
      height: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    text-decoration: none;
    width: 250px;
    text-align: center;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    background: #22b492;
    box-shadow: 0px 5px #2e8771;
    color: #fff;
    padding: .5rem 1rem;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      transform: scale(1.03);
      background: #3de7c0;
      box-shadow: 0px 5px #3bc6a4;
      &::after {
        transform: scaleX(1.1) scaleY(1.3);
        opacity: 0;
      }
    }
    &:active {
      transform: translateY(-1px);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .2s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}

.nav-disabled {
  cursor: not-allowed;
  opacity: 0.1;
}

.close-button {
  color: #555;
  font-size: 1.5rem;
  text-decoration: none;
  cursor: pointer;
  margin-right: 0.75rem;

  &:hover {
    color: #000;
  }
}

.pdf-button {
  background: #D55B5B;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  margin-right: 10px;

  &:hover {
    background: #C74A4A;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }

  i {
    margin-right: 5px;
  }
}

.header-left,
.header-right {
  display: flex;
  align-items: center;
}

/* Default styles (desktop) */
.desktop-title {
  display: inline;
}
.mobile-title {
  display: none;
}
/* Show prefix by default */
.desktop-prefix {
  display: inline;
}

@media only screen and (max-width: 820px) {
  /* Mobile-specific styles */
  .desktop-title {
    display: none;
  }
  .mobile-title {
    display: inline;
  }
  /* Hide prefix on mobile */
  .desktop-prefix {
    display: none;
  }

  .free-response-component__content--ans {
    padding: 0 1rem;
  }
  .free-response-component__header {
    h2 {
      /* Adjust margin as prefix is removed */
      margin-left: 0.5rem; /* Or remove if no margin needed */
    }
    p {
      margin-right: 0.5rem;
    }
  }
  .free-response-component__container {
    height: 100%;
  }
  .free-response-component__btn-practice {
    width: 100%;
    border-radius: 0;
    &--container {
      position: fixed;
      bottom: 0;
      width: 100%;
      justify-content: flex-start;
      align-items: flex-end;
    }
  }
  .close {
    // Removed old .close styles
    // top: 25px;
    // left: 10px;
  }
}
@media only screen and (max-width: 400px) {
  .free-response-component__header {
    h2 {
      font-size: 1rem;
    }
    p {
      font-size: 0.8rem;
    }
  }
}
</style>
