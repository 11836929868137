<template>
  <div class="inline-editor">
    <h4 v-show="recordingState == 0">Your answer (minimum {{ minLength || 2 }} characters)</h4>
    <div v-if="hint" class="inline-editor__hint">
      <i class="fa-solid fa-lightbulb"></i>
      <span>Hint: {{ hint }}</span>
    </div>
    <div class="inline-editor__controls">
      <!-- Recording features -->
      <recording-wave-animation v-if="isRecording && recordingState == 1" />
      <div class="editor-inputs">
        <transcription-recorder-button
          @recording-state-change="handleRecordingStateChange"
          @transcription-complete="handleTranscriptionComplete"
        />
        <div
          v-if="!isRecording && recordingState == 2"
          class="inline-editor__transcribing">
          TRANSCRIBING IN PROGRESS...
        </div>
        <!-- TODO: placeholder text replaced with hints -->
        <input
          v-show="recordingState == 0"
          :class="{'chinese-character': subject === 'chinese'}"
          type="text"
          v-model="inputValue"
          class="blank-input"
          placeholder="请输入你的答案..."
          @keyup.enter="submitWord"
          @input="handleInput"
          @paste="preventPaste"
          ref="inputField"
        />
      </div>
      <div v-show="recordingState == 0" class="editor-buttons">
        <button class="submit-btn" @click="submitWord">
          <i class="fa-solid fa-check"></i>
        </button>
        <button class="cancel-btn" @click="cancel">
          <i class="fa-solid fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

import {utilities} from '../../../../mixins/utilities';

import RecordingWaveAnimationComponent from '../../oral/components/RecordingWaveAnimationComponent.vue';
import TranscriptionRecorderButtonComponent from '../../oral/components/TranscriptionRecorderButtonComponent.vue';

export default {
  name: 'InlineEditorComponent',
  components: {
    'recording-wave-animation': RecordingWaveAnimationComponent,
    'transcription-recorder-button': TranscriptionRecorderButtonComponent,
  },
  props: {
    initialValue: {
      type: String,
      default: '',
    },
    blankIndex: {
      type: Number,
      required: true,
    },
    wordsToAvoid: {
      type: Array,
      default: () => [],
    },
    minLength: {
      type: Number,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState(['subject']),
  },
  mixins: [utilities],
  data() {
    return {
      inputValue: this.initialValue,
      isRecording: false,
      recordingState: 0, // 0: idle, 1: recording, 2: transcribing
    };
  },
  watch: {
    initialValue(newValue) {
      this.inputValue = newValue;
      this.$nextTick(() => {
        if (this.recordingState === 0) {
          this.$refs.inputField.focus();
        }
      });
    },
    blankIndex() {
      this.$nextTick(() => {
        if (this.recordingState === 0) {
          this.$refs.inputField.focus();
        }
      });
    },
  },
  methods: {
    /**
     * Prevents pasting into the textarea
     *
     * @param {Event} e - Th`e paste event
     */
    preventPaste(e) {
      e.preventDefault();
      alert('Please type your answer instead of pasting.');
    },
    /**
     * Counts the length of Chinese text with special handling for AABB patterns.
     * AABB patterns (like 高高兴兴) count as 2 characters instead of 4.
     *
     * Chinese text often contains reduplicated character patterns like AABB where:
     * - A is one Chinese character that repeats (e.g., 高高)
     * - B is another Chinese character that also repeats (e.g., 兴兴)
     * - Together they form a common expression (e.g., 高高兴兴 meaning "very happy")
     *
     * This function specifically handles the AABB pattern in Chinese text which
     * semantically often represents a single concept despite using 4 characters.
     *
     * Examples:
     * - "高高兴兴" (very happy) counts as 2
     * - "干干净净" (very clean) counts as 2
     * - "明明白白" (very clear) counts as 2
     *
     * Note: This function only handles the specific AABB pattern where:
     * - The first two characters are identical
     * - The third and fourth characters are identical
     * - The first character differs from the third character
     *
     * It does not handle other patterns like "AAAA" (e.g., 哈哈哈哈) or "ABAB" (e.g., 高兴高兴).
     *
     * @param {string} text - The Chinese text to analyze
     * @return {number} - The adjusted character count
     */
    /**
     * @function getChineseTextLength
     * @description Calculates the semantic length of Chinese text by treating AABB patterns as 2 characters
     * @param {string} text - Input Chinese text
     * @return {number} - Semantic character count
     */
    getChineseTextLength(text) {
      // Handle empty or undefined input
      if (!text) return {length: 0, aabbPatterns: []};

      // Convert string to array of Unicode characters (handles surrogate pairs correctly)
      const chars = [...text];

      let count = 0;
      let i = 0;
      const aabbPatterns = [];

      while (i < chars.length) {
        // Check for AABB pattern
        if (i + 3 < chars.length &&
            chars[i] === chars[i + 1] &&
            chars[i + 2] === chars[i + 3] &&
            chars[i] !== chars[i + 2]) {
          // AABB pattern found, count as 2 characters
          const aabbPattern = chars[i] + chars[i + 1] + chars[i + 2] + chars[i + 3];
          aabbPatterns.push(aabbPattern);
          count += 2;
          i += 4; // Skip the entire pattern
        } else {
          // Regular character
          count += 1;
          i += 1;
        }
      }

      return {length: count, aabbPatterns};
    },
    submitWord() {
      console.log('submitWord');
      const trimmedInput = this.inputValue.trim();

      // if user is trying to clear the input, don't show error
      if (trimmedInput.length === 0) {
        this.$emit('word-submitted', {
          word: trimmedInput,
          index: this.blankIndex,
        });
        return;
      }

      // Check for minimum length
      if (this.minLength && trimmedInput.length < this.minLength) {
        this.$emit('validation-error', `Your answer must be at least ${this.minLength} characters long.`);
        return;
      }

      // For Chinese, check if answer contains English
      if (this.subject === 'chinese') {
        if (this.containsEnglish(this.inputValue)) {
          this.$emit('validation-error', 'Your answer should not contain English characters.');
          return;
        }
        // checks if the answer is too short after accounting for AABB patterns (E.G. 高高兴兴 counts as 2 characters)
        const {length, aabbPatterns} = this.getChineseTextLength(this.inputValue);
        if (length < this.minLength) {
          this.$emit('validation-error', `Your answer must be at least ${this.minLength} characters long (note: ${aabbPatterns} count as 2 characters rather than 4.`);
          return;
        }
      }

      // Check if any word to avoid is contained in the input
      for (const wordToAvoid of this.wordsToAvoid) {
        if (trimmedInput.includes(wordToAvoid)) {
          this.$emit('validation-error', `Your answer should not contain the word "${wordToAvoid}".`);
          return;
        }
      }

      this.$emit('word-submitted', {
        word: trimmedInput,
        index: this.blankIndex,
      });
    },
    cancel() {
      this.$emit('cancel');
    },
    handleRecordingStateChange(newState) {
      this.recordingState = newState;
      this.isRecording = newState === 1;
    },
    handleTranscriptionComplete(transcribedText) {
      // add code to remove ending punctuation from transcribedText
      const endingPunctuation = transcribedText.slice(-1);
      const punctuation = ['。', '，', '.', '，', '！', '？', '！', '？'];
      if (punctuation.includes(endingPunctuation)) {
        transcribedText = transcribedText.slice(0, -1);
      }
      this.inputValue = (this.inputValue + ' ' + transcribedText).trim();
      this.recordingState = 0;
      this.isRecording = false;
      this.$nextTick(() => {
        this.$refs.inputField.focus();
      });
    },
    handleInput() {
      this.$emit('input-change', {
        value: this.inputValue,
        index: this.blankIndex,
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.recordingState === 0) {
        this.$refs.inputField.focus();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.inline-editor {
  border-radius: .75rem;
  // padding: 1rem;
  // margin-bottom: 1.5rem;
  &__transcribing {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    flex: 1;
    border: 3px solid #d9d9d9;
    border-radius: .5rem;
  }

  &__hint {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    margin: 0.5rem 0;
    background-color: #fffbeb;
    border: 1px solid #ffe4a0;
    border-radius: 0.5rem;
    font-style: italic;

    i {
      color: #f6b93b;
    }

    span {
      color: #6b5900;
      font-size: 0.95rem;
    }
  }

  &__controls {
    display: flex;
    gap: 0.75rem;
    position: relative;
    min-height: 65px;
    .blank-input {
      font-size: 1.25rem;
      padding: 0.75rem;
      border: 3px solid #d1bdff;
      border-radius: 0.5rem;
      background: white;
      flex: 1;
      &:focus {
        outline: none;
        border-color: #873fff;
      }
    }
    .editor-inputs {
      display: flex;
      gap: .5rem;
      flex: 1;
    }
    .editor-buttons {
      display: flex;
      gap: 0.5rem;

      button {
        border: none;
        border-radius: 0.5rem;
        padding: 0.5rem 1rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s;

        i {
          font-size: 1.1rem;
        }
      }

      .record-btn {
        background: #f1e7ff;
        color: #873fff;
        border-bottom: 3px solid #d1bdff;

        &:active {
          border-bottom: 1px solid #d1bdff;
          margin-top: 2px;
        }
      }

      .submit-btn {
        background: #6ad6b2;
        color: white;
        border-bottom: 3px solid #359776;
        flex-grow: 1;

        &:active {
          border-bottom: 1px solid #359776;
          margin-top: 2px;
        }
      }

      .cancel-btn {
        background: #f9f9f9;
        color: #666;
        border-bottom: 3px solid #ddd;

        &:active {
          border-bottom: 1px solid #ddd;
          margin-top: 2px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1560px) {
}
@media only screen and (max-width: 1366px),
            screen and (max-height: 870px) {
}
@media only screen and (max-width: 1150px),
  screen and (max-height: 690px) {
}
@media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
}

@media only screen and (max-width: 760px) {
  .inline-editor {
    padding: 0.75rem;

    &__controls {
      flex-direction: column;
      gap: .5rem;
      margin-bottom: .5rem;
      .blank-input {
        font-size: 1rem;
        padding: 0.5rem;
      }
    }
  }
}
@media only screen and (max-width: 580px) {
}
@media only screen and (max-width: 480px) {
}

</style>
