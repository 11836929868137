<template>
  <div v-if="info" class="vocab-proficiency">
    <div class="vocab-proficiency__header">
      <h2>Vocabulary Proficiency</h2>
      <ul v-if="!isPreschool" class="vocab-proficiency__header--filter">
        <li v-if="level !== 'primary_one'" class="vocab-proficiency__header--filter--btn">
          <button @click="select('core')"
            :class="{active: category === 'core'}">{{ coreTitle }}</button>
        </li>
        <!-- this isn't shown on mobile -->
        <li v-for="(level, index) in reverseOrderPrimarySchoolLevels" :key="index"
          class="vocab-proficiency__header--filter--btn hide-on-mobile">
          <button @click="select(level)"
            :class="{active: category === level}"> {{ level | shorternLevel }}</button>
        </li>
        <!-- currently only chinese has high frequency words -->
        <li v-if="subject === 'chinese'" class="vocab-proficiency__header--filter--btn">
          <button @click="select('highFrequency')"
            :class="{active: category === 'highFrequency'}">High Freq.</button>
        </li>
        <!-- currently only chinese has other words - can add back in future -->
        <li v-if="subject === 'chinese'" class="vocab-proficiency__header--filter--btn">
          <button @click="select('others')"
            :class="{active: category === 'others'}">Others</button>
        </li>
      </ul>
      <!-- for preschool, only show all option -->
      <ul v-else class="vocab-proficiency__header--filter">
        <li class="vocab-proficiency__header--filter--btn">
          <button @click="select('all')"
            :class="{active: category === 'all'}">All</button>
        </li>
      </ul>
    </div>
    <div class="vocab-proficiency__body">
      <!--
      v-for in this case is 1-indexed, so need to subtract by 1; which works for us since words_category_list is a dictionary
      0: untested
      1: unsure
      2: learning
      3: proficient
      4: mastered-->
      <list-of-words
        v-for="overallRating in numRatingCategories" :key="overallRating"
        :word-list="filteredWordList[overallRating - 1] || []"
        :overall-rating="overallRating - 1"
        @openMobileWordList="toggleModal"
      ></list-of-words>
      <!-- TODO: Link up word list based on what was clicked in list-of-words component -->
      <div v-if="isMobileWordListShown"
        class="mobile-word-list__container animated fadeIn faster">
        <div class="mobile-word-list__header">
          <i @click="toggleModal"
            class="fas fa-angle-left"></i>
          <h3>{{ mobileWordListTitle }}</h3>
        </div>
        <ul class="mobile-word-list__words">
          <li v-for="(word, index) in (filteredWordList[mobileWordListIndex] || [])" :key="index" class="mobile-word-list__word">
            <span class="chinese-character">{{ word.text }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="vocab-proficiency__footer">
      <button @click="startRevisionQuiz">Start Revision Quiz</button>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';

import {mapState, mapGetters} from 'vuex';
import ListOfWordsComponent from './ListOfWordsComponent.vue';
import 'large-small-dynamic-viewport-units-polyfill';

export default {
  name: 'WordsCategoryList',
  props: {
    info: Object,
  },
  components: {
    ListOfWords: ListOfWordsComponent,
  },
  data() {
    return {
      fetching: false,
      category: '',
      numRatingCategories: 5, // not tested yet, incorrect, learning, proficient, mastered
      isMobileWordListShown: false,
      // mobilee word list variables - in future, consider split this into separate component
      mobileWordListIndex: 0,
      mobileWordListTitle: '',
    };
  },
  computed: {
    ...mapState(['level', 'subject']),
    ...mapGetters(['isPreschool', 'validLevels', 'coreLevels', 'coreTitle']),
    reverseOrderPrimarySchoolLevels() {
      const toReturn = [];
      for (let i = this.validLevels.length - 1; i >= 0; i--) {
        toReturn.push(this.validLevels[i]);
      }
      return toReturn;
    },
    filteredWordList() {
      if (!this.info) return {};
      // for preschool, we only show all words just to make things easier + don't have that many preschool subscribers
      // refactor in future if we have time to show N2, K1 and K2
      if (this.isPreschool || this.category === 'all') return this.info;
      const wordList = {};

      // start helper function
      function helper(words, category, validLevels, coreLevels) {
        if (category === 'highFrequency') {
          return words.filter((w) => w.is_high_frequency);
        }

        // core level refers to last 3 levels for P6 student (e.g. P4-P6) or last 2 levels for P5 and below
        if (category === 'core') {
          return words.filter((w) => {
            for (const coreLevel of coreLevels) {
              if (w.levels.indexOf(coreLevel) > -1) return true;
            }
            return false;
          });
        }

        // when student passes in specific level
        // note that some words appear in multiple primary school levels - in that case, show the word in multiple levels
        // however if a word appears in both preschool and primary school level, only show in primary school level
        // otherwise it seems strange to have tons of p1 words appear under others (e.g. 一，我，你, etc)
        if (category !== 'others' ) {
          return words.filter((w) => w.levels.indexOf(category) > -1);
        }

        // for others - show words with blank level or words that have preschool levels (assuming it do)
        // if it's a high frequency word, ignore
        return words.filter((w) => {
          if (w.levels.length === 0) return true;

          const isPreschoolWord = w.levels.indexOf('nursery_two') > -1 ||
            w.levels.indexOf('kindergarten_one') > -1 || w.levels.indexOf('kindergarten_two') > -1;

          if (!isPreschoolWord || w.is_high_frequency) return false;

          // only show if it is preschool word and doesn't contain any other valid primary school levels
          for (const primarySchoolLevel of validLevels) {
            if (w.levels.indexOf(primarySchoolLevel) > -1) return false;
          }
          return true;
        });
      } // end helper function

      for (let i = 0; i < this.numRatingCategories; i++) {
        wordList[i] = helper(this.info[i], this.category, this.validLevels, this.coreLevels);
      }
      return wordList;
    },
  },
  mounted() {
    // for P1, since we don't show any other level besides it, shouldnt have core option
    if (this.isPreschool) {
      this.category = 'all';
    } else {
      this.category = this.level === 'primary_one' ? this.level : 'core';
    }
  },
  methods: {
    select(level) {
      this.category = level;
    },
    startRevisionQuiz() {
      this.$router.push({name: 'new-revision-quiz'});
      // console.log('starting revision quiz');
      // const untestedWordIds = this.filteredWordList[0].map((x) => x.pk);
      // console.log(untestedWordIds);
      // // console.log(this.filteredWordList[0]);
      // // console.log(this.filteredWordList[1]);
      // if (this.fetching) return;
      // // resetting variables
      // this.fetching = true;
      // const url = '/analytics/api/summary/';
      // return axios
      //     .post(url, {
      //       untestedWordIds: [],
      //       unsureWordIds: [],
      //     })
      //     .then((response) => {
      //       // -1 if not pulled; 0 if no questions done; 1 if successful
      //       this.status = response.data[0];
      //       this.results = response.data[1];
      //       this.fetching = false;
      //       console.log(this.results);
      //     })
    },
    toggleModal(args) {
      this.mobileWordListTitle = args.title;
      this.mobileWordListIndex = args.index;
      this.isMobileWordListShown = !this.isMobileWordListShown;
    },
  },
};
</script>

<style lang="scss" scoped>
  .vocab-proficiency {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1rem;
      color: #1f5ed0;
      &--filter {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: .5rem;
        &--btn {
          button {
            padding: .5rem;
            min-width: 3.5rem;
            text-transform: capitalize;
            font-weight: 600;
            background: #b5b5b5;
            color: #dadada;
            border: none;
            cursor: pointer;
            outline: inherit;
            border-radius: .25rem;
          }
          .active {
            color: #fff;
            background: #1f5ed0;
          }
        }
      }
    }
    &__body {
      display: flex;
      height: 550px;
    }
    &__footer {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      button {
        background: #991ece;
        color: #fff;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        padding: .5rem;
        border-radius: .5rem;
        width: 100%;
        text-align: center;
        font-weight: 500;
        &:hover {
          background: #2969e2;
        }
      }
      p {
        font-size: .8rem;
        font-style: italic;
        text-align: center;
        margin-top: .25rem;
      }
    }
  }
  .mobile-word-list {
    &__container {
      display: none;
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      background: #fff;
    }
    &__header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.2rem;
      background: #1f5ed0;
      color: #fff;
      padding-left: .75rem;
      i {
        margin-right: .75rem;
        cursor: pointer;
      }
    }
    &__words {
      display: flex;
      max-height: calc(100% - 48px);
      width: 100%;
      overflow: scroll;
      padding: 1rem;
      flex-wrap: wrap;
      grid-gap: .5rem;
    }
    &__word {
      text-align: center;
      padding: .25rem;
      border: 1px solid #e8e8e8;
      text-align: center;
      width: calc(50% - .25rem);
      border-radius: .5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
    }
  }
  @media only screen and (max-width: 960px) {
    .mobile-word-list {
      &__container {
        display: block;
      }
    }
    .vocab-proficiency__body {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 137px); /* For browsers that don't support CSS variables */
      height: calc((var(--1dvh, 1vh) * 100) - 137px); /* This is the "polyfill" */
      height: calc(100dvh - 137px); /* This is for future browsers that support svh, dvh and lvh viewport units */
      grid-gap: .75rem;
      padding-bottom: 1rem;
    }
  }
  @media only screen and (max-width: 920px) {
    .vocab-proficiency__header {
      flex-direction: column;
    }
    .vocab-proficiency__body {
      height: calc(100vh - 162px); /* For browsers that don't support CSS variables */
      height: calc((var(--1dvh, 1vh) * 100) - 162px); /* This is the "polyfill" */
      height: calc(100dvh - 162px); /* This is for future browsers that support svh, dvh and lvh viewport units */
    }
    .vocab-proficiency__header--filter {
      &--btn {
        flex: 1;
        button {
          width: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: 960px) {
    .vocab-proficiency {
      height: 90%;
    }

    .vocab-proficiency__body {
      height: calc(100vh - 177px); /* For browsers that don't support CSS variables */
      height: calc((var(--1dvh, 1vh) * 100) - 177px); /* This is the "polyfill" */
      height: calc(100dvh - 177px); /* This is for future browsers that support svh, dvh and lvh viewport units */
    }
    .vocab-proficiency__header--filter {
      &--btn {
        height: 100%;
        button {
          height: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: 525px) {
    .vocab-proficiency {
      height: 95%;
    }
    .vocab-proficiency__header--filter {
      flex-wrap: wrap;
    }
    .vocab-proficiency__header--filter {
      &--btn {
        height: auto;
        button {
          height: auto;
          min-width: 4.5rem;
        }
      }
    }
    .vocab-proficiency__body {
      grid-gap: .5rem;
      height: calc(100vh - 207px); /* For browsers that don't support CSS variables */
      height: calc((var(--1dvh, 1vh) * 100) - 207px); /* This is the "polyfill" */
      height: calc(100dvh - 207px); /* This is for future browsers that support svh, dvh and lvh viewport units */
    }
  }
  @media only screen and (max-width: 430px) {
    .vocab-proficiency__header--filter {
      &--btn {
        button {
          min-width: 3.5rem;
        }
      }
    }
  }

@media only screen and (max-width: 960px) {
  .hide-on-mobile {
    display: none;
  }
}
</style>
