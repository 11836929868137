<template>
  <div class="writing-proficiency">
    <div class="writing-proficiency__header">
      <h2>Writing Proficiency</h2>
      <h4>Overall Progress</h4>
    </div>
    <div v-if="writingData && writingData.total_words > 0" class="writing-proficiency__body">
      <!-- Overall stats card -->
      <div class="writing-proficiency__stats-card">
        <div class="stats-card__progress">
          <p class="stats-card__label">Average Writing Proficiency</p>
          <div class="progress-circle" :style="{ background: `conic-gradient(#6de29d ${writingData.avg_proficiency_percentage}%, #f4f4f4 0)` }">
            <div class="progress-circle__inner">{{ writingData.avg_proficiency_percentage }}%</div>
          </div>
        </div>

        <div class="stats-card__breakdown">
          <div class="breakdown-row">
            <div class="breakdown-label">Total Words Used</div>
            <div class="breakdown-value">{{ writingData.total_words }}</div>
          </div>
          <div class="breakdown-row">
            <div class="breakdown-label">Mastered</div>
            <div class="breakdown-value mastered">{{ writingData.mastered_words }}</div>
          </div>
          <div class="breakdown-row">
            <div class="breakdown-label">Proficient</div>
            <div class="breakdown-value proficient">{{ writingData.proficient_words }}</div>
          </div>
          <div class="breakdown-row">
            <div class="breakdown-label">Learning</div>
            <div class="breakdown-value learning">{{ writingData.learning_words }}</div>
          </div>
          <div class="breakdown-row">
            <div class="breakdown-label">Unsure</div>
            <div class="breakdown-value unsure">{{ writingData.unsure_words }}</div>
          </div>
        </div>
      </div>

      <!-- Words to work on section -->
      <div class="writing-proficiency__improvement">
        <h4>Words to Practice</h4>
        <div class="words-list">
          <div v-for="(word, index) in writingData.words_to_work_on" :key="index" class="word-card">
            <div class="word-card__text chinese-character">{{ word.text }}</div>
            <div class="word-card__pinyin">{{ word.pinyin }}</div>
            <div class="word-card__definition">{{ word.definition }}</div>
            <div :class="['word-card__proficiency-bar', getBarColorClass(word.value)]" :style="{width: getProficiencyWidth(word.value)}">
              <div class="proficiency-bar__fill"></div>
            </div>
            <div class="word-card__status">
              {{ getProficiencyLabel(word.known_category_integer) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="writing-proficiency__empty">
      <p>You haven't written any sentences yet. Use the <router-link :to="{name: 'new-oral-voicebot-index'}">Voicebot</router-link> or <router-link :to="{name: 'new-composition-general-ai-practice'}">AI Practice</router-link> to improve your writing skills!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WritingProficiency',
  props: {
    writingData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getProficiencyLabel(categoryInt) {
      const labels = {
        0: 'Untested',
        1: 'Unsure',
        2: 'Learning',
        3: 'Proficient',
        4: 'Mastered',
      };
      return labels[categoryInt] || 'Unknown';
    },
    getBarColorClass(value) {
      return value === 1 ? 'mastered-bar' : 'learning-bar';
    },
    getProficiencyWidth(value) {
      return `${value * 100}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
.writing-proficiency {
  width: 100%;
  min-height: 600px;

  &__header {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;

    h2 {
      color: #1f5ed0;
      line-height: 1.2rem;
    }

    h4 {
      color: #2772fb;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }

  &__stats-card {
    display: flex;
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    flex-direction: column;

    .stats-card__progress {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;

      .progress-circle {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &__inner {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          background: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.5rem;
          font-weight: bold;
          color: #1f5ed0;
        }
      }

      .stats-card__label {
        margin-bottom: .5rem;
        text-align: center;
        font-weight: 500;
      }
    }

    .stats-card__breakdown {
      flex: 1;
      display: flex;

      .breakdown-row {
        display: flex;
        align-items: center;
        margin-bottom: 0.75rem;
        border-right: 1px solid #f0f0f0;
        flex-direction: column;
        width: 20%;
        &:last-child {
          border-right: none;
        }

        .breakdown-label {
          font-weight: 500;
        }

        .breakdown-value {
          font-weight: 600;

          &.mastered { color: #6de29d; }
          &.proficient { color: #76d6d5; }
          &.learning { color: #a0bbe5; }
          &.unsure { color: #ff9f43; }
        }
      }
    }
  }

  &__improvement {
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    h4 {
      margin-bottom: 1rem;
      color: #1f5ed0;
    }

    .words-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 1rem;

      .word-card {
        padding: 1rem;
        border: 1px solid #e0e0e0;
        border-radius: 0.25rem;

        &__text {
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 0.25rem;
        }

        &__pinyin {
          font-size: 0.85rem;
          color: #666;
          margin-bottom: 0.25rem;
        }

        &__definition {
          font-size: 0.9rem;
          margin-bottom: 0.75rem;
        }

        &__proficiency-bar {
          height: 6px;
          border-radius: 3px;
          margin-bottom: 0.5rem;

          &.mastered-bar {
            background-color: gold;
          }

          &.learning-bar {
            background-color: rgb(13, 138, 255);
          }

          &__fill {
            height: 100%;
            background-color: #1f5ed0;
            border-radius: 3px;
          }
        }

        &__status {
          font-size: 0.8rem;
          font-weight: 500;
          text-align: right;
        }
      }
    }
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    height: 300px;

    p {
      text-align: center;
      max-width: 500px;
      line-height: 1.5;
      color: #666;

      a {
        color: #1f5ed0;
        font-weight: 500;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media only screen and (max-width: 920px) {
  .writing-proficiency__stats-card {
    flex-direction: column;

    .stats-card__progress {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
  }
}

@media only screen and (max-width: 760px) {
  .writing-proficiency {
    &__header {
      h2 {
        font-size: 1.4rem;
      }
      h4 {
        font-size: 0.9rem;
      }
    }
  }
}
</style>
