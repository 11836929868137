<template>
  <div class="level-info">
    <div class="level-badge">Level {{ king.level }}</div>
    <LevelProgressBar
      :level="king.level"
      :xp="king.xp"
      :total-xp-required="king.total_xp_required_for_next_level"
      :show-text="true"
      :icon-class="'fa fa-crown'"
    />
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';

import LevelProgressBarComponent from '../../../common/LevelProgressBarComponent.vue';

export default {
  name: 'KingInfoPanel',
  props: {
  },
  components: {
    LevelProgressBar: LevelProgressBarComponent,
  },
  computed: {
    ...mapState('kingdom', ['king']),
    ...mapGetters('kingdom', ['getResource']),
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .level-info {
    margin-bottom: 20px;
    .level-badge {
      display: inline-block;
      background-color: #4CAF50;
      color: white;
      padding: 4px 12px;
      border-radius: 12px;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }
</style>

