<template>
  <div class="mission-item">
    <div class="mission-item--col-1">
    <img src="../../../../assets/profile/diamond.png">
    <p class="mission-item--col-1--num">{{ info.diamond }}</p>
    </div>
    <div class="mission-item--col-2">
      <div class="mission-item--col-2--text">
        <h3>{{ info.description }}</h3>
      </div>
      <div class="mission-item--col-2--progress">
        <div class="mission-item--col-2--progress-bar">
          <div
            :style="{width: progressBarWidth}"
            class="mission-item--col-2--progress-bar--fill">
          </div>
        </div>
        <div class="mission-item--col-2--progress-text">
          {{ info.current_times_done }} / {{ info.target_times_done }}
        </div>
      </div>
    </div>
    <div @click="onClickNext" class="mission-item--col-3">
      <!-- not completed -->
      <i v-if="info.status <= 1" class="go-icon">Go</i>
      <!-- completed but not yet collected gift -->
      <i v-if="info.status === 2" class="fa-solid fa-gift large-heartbeat-animation"></i>
      <!-- collected already -->
      <i v-if="info.status === 3" class="fa fa-check"></i>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import {mapState, mapGetters} from 'vuex';

// breakdown of status
// NOT_STARTED: int = 0
// ONGOING: int = 1
// COMPLETED_UNCLAIMED: int = 2
// COMPLETED_CLAIMED: int = 3

export default {
  name: 'MissionRowComponent',
  components: {
  },
  props: {
    info: Object,
  },
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    ...mapState(['studentId']),
    ...mapGetters(['cocosPetBattleUrl']),
    progressBarWidth() {
      if (!this.info) return 0;
      const percentage = Math.ceil(this.info.current_times_done / this.info.target_times_done * 100);
      return `${percentage}%`;
    },
  },
  mounted() {
  },
  methods: {
    claimReward() {
      if (this.fetching) return;
      // don't need to reset fetching since we should only need to fetch once (can only claim once)
      this.fetching = true;
      axios.post(`/api/v1/core/student/${this.studentId}/missionreward/`, {
        timeCategory: this.info.time_category,
        name: this.info.name,
      })
          .then((response) => {
            const key = `${this.info.name}_${this.info.time_category}`;
            this.$emit('claim', {key: key, responseData: response.data});
            this.fetching = false;
          });
    },
    onClickNext() {
      // claimed already, do nothing
      if (this.info.status === 3) return;
      if (this.info.status === 2) {
        this.claimReward();
        return;
      }
      console.log(this.info.name);
      // if haven't completed, navigate
      if (this.info.name === 'daily_challenge') {
        this.$router.push({name: 'daily-challenge-overview'});
      } else if (this.info.name === 'pet_battle') {
        window.open(this.cocosPetBattleUrl);
      } else if (this.info.name === 'fishing_game') {
        this.$router.push({name: 'new-play-fishing-game'});
      } else if (this.info.name === 'tree_climb') {
        this.$router.push({name: 'new-play-tree-climb'});
      } else if (this.info.name === 'voicebot') {
        this.$router.push({name: 'new-oral-voicebot-index'});
      } else if (this.info.name === 'writing') {
        this.$router.push({name: 'new-composition-general-ai-practice'});
      }
      return;
    },
  },
};
</script>

<style lang="scss">
  .mission-item {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    background: #395da6;
    color: #fff;
    border-radius: .5rem;
    text-shadow: 0px 3px rgba(0,0,0,.4784313725);
    border: 3px solid #6cf0ff;
    &:last-child {
      margin-bottom: 0rem;
    }
    &--col {
      &-1, &-2, &-3 {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-1, &-3 {
        width: 100px;
        height: 100%;
      }
      &-1 {
        img {
          width: 45px;
        }
        width: 90px;
        background: #364689;
        color: #fff;
        font-size: 2rem;
        border-top-left-radius: .3rem;
        border-bottom-left-radius: .3rem;
        position: relative;
        &--num {
          position: absolute;
          bottom: 0px;
          right: 8px;
          font-size: 1rem;
        }
        .fa-paw {
          color: #1dffd1;
        }
        .fa-comments {
          color: #fff;
        }
        .fa-graduation-cap {
          color: #ff7d7d;
        }
      }
      &-2 {
        flex-direction: column;
        flex: 1;
        margin-left: 1rem;
        margin-top: .25rem;
        &--text {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 600;
          line-height: 1rem;
          font-size: .85rem;
        }
        &--progress {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          &-text {
            // width: 45px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-left: .5rem;
            font-size: .9rem;
          }
          &-bar {
            height: 1rem;
            border-radius: 2rem;
            background: #1a1a1a5c;
            flex: 1;
            &--fill {
              // width for mission completion
              // width: 50%;
              height: 100%;
              background: #ffb350;
              border-radius: 2rem;
            }
          }
        }
      }
      &-3 {
        font-size: 2rem;
        color: #fff;
        .fa-gift {
          color: #ffb350;
          cursor: pointer;
        }
        .go-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          font-size: 1.5rem;
          padding: 0.15rem 0.5rem;
          border-radius: .5rem;
          color: #fff;
          background: #50c09f;
          cursor: pointer;
          &:hover {
            box-shadow: 0 0px 1px 3px #ff00dd;
          }
        }
        .fa-check {
          color: #1dffd1;
        }
      }
    }
  }

  @media only screen and (max-width: 1375px),
  screen and (max-height: 720px) {
    .mission-item--col-2--text {
      font-size: .9rem;
    }
  }
  @media only screen and (max-width: 1150px),
  screen and (max-height: 720px) {
    .mission-item {
      height: 80px;
      &--col-1 {
        font-size: 1.5rem;
        width: 80px;
        img {
          width: 45px;
        }
        &--num {
          font-size: 0.9rem;
        }
      }
      &--col-2 {
        &--progress-bar {
          height: .7rem;
        }
        &--text, &--progress-text {
          font-size: 0.8rem;
        }
      }
      &--col-3 {
        font-size: 1.5rem;
        width: 80px;
        .go-icon {
          font-size: 1rem;
        }
      }
    }
  }
  @media only screen and (max-width: 520px) {
    .mission-item {
      &s {
        padding: 0 1rem 6rem 1rem;
      }
      &--col-1 {
        font-size: 1.25rem;
        width: 50px;
        img {
          width: 35px;
        }
        &--num {
          font-size: 0.8rem;
          right: 5px;
        }
      }
      &--col-2 {
        margin-left: .5rem;
        &--progress-bar {
          height: .7rem;
        }
        &--text, &--progress-text {
          font-size: 0.65rem;
        }
      }
      &--col-3 {
        font-size: 1.25rem;
        width: 50px;
        .go-icon {
          font-size: .75rem;
          padding: .15rem .35rem;
        }
      }
    }
    .mission-item--col-2--progress-text {
      width: 35px;
    }
  }

  .large-heartbeat-animation {
    animation: 3s infinite large-heartbeat;
  }

  @keyframes large-heartbeat {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(1.5);
    }
    25% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.5);
    }
    60% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
