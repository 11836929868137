<!-- eslint-disable -->
<template>
  <div class="test-voicebot">
    <div class="test-voicebot-container">
      <h1 class="title">VoiceBot System Prompt Tester</h1>
      
      <div class="settings-section">
        <div class="prompt-section">
          <h2>System Prompt</h2>
          <textarea 
            v-model="systemPrompt" 
            class="prompt-input" 
            placeholder="Enter your system prompt here..."
            rows="6"
          ></textarea>
        </div>
        
        <div class="options-section">
          <div class="option-group">
            <label for="model-select">Model:</label>
            <select id="model-select" v-model="selectedModel" class="select-input">
              <option value="qwen-turbo">Qwen Turbo</option>
              <option value="qwen-plus">Qwen Plus</option>
              <option value="qwen-max">Qwen Max</option>
            </select>
          </div>
          
          <div class="option-group">
            <label for="temperature-range">Temperature: {{ temperature.toFixed(1) }}</label>
            <input 
              type="range" 
              id="temperature-range" 
              v-model="temperature" 
              min="0" 
              max="1" 
              step="0.1" 
              class="range-input"
            >
          </div>
          
          <div class="option-group">
            <label for="language-select">Language:</label>
            <select id="language-select" v-model="language" class="select-input">
              <option value="english">English</option>
              <option value="chinese">Chinese</option>
              <option value="malay">Malay</option>
            </select>
          </div>
          
          <div class="option-group checkbox-group">
            <input type="checkbox" id="check-language" v-model="checkLanguage">
            <label for="check-language">Verify language match</label>
          </div>
        </div>
      </div>

      <div class="conversation-section">
        <h2>Conversation</h2>
        <div class="conversation-display" ref="conversationDisplay">
          <div v-for="(message, index) in conversation" :key="index" class="message" :class="message.role">
            <div class="message-role">{{ message.role === 'user' ? 'You' : 'Bot' }}</div>
            <div class="message-content">{{ message.content }}</div>
            <div v-if="message.processingTime" class="message-metadata">
              Model: {{ message.model }} | Processing time: {{ message.processingTime.toFixed(2) }}s
            </div>
          </div>
        </div>
      </div>

      <div class="status-section" v-if="connectionStatus">
        <div class="status-indicator" :class="connectionStatusClass">
          {{ connectionStatus }}
        </div>
      </div>

      <div class="controls-section">
        <div class="recording-controls">
          <div class="recorder-wrapper">
            <button 
              v-if="!isRecording" 
              @click="startRecording" 
              class="record-button"
              :disabled="!isConnected || !systemPrompt">
              <i class="fa-solid fa-microphone"></i>
            </button>
            <button 
              v-else 
              @click="stopRecording" 
              class="record-button recording">
              <i class="fa-solid fa-stop"></i>
            </button>
            
            <span v-if="isRecording" class="recording-indicator">Recording...</span>
            <span v-else-if="isTranscribing" class="processing-indicator">Transcribing...</span>
            <span v-else-if="isProcessing" class="processing-indicator">Processing...</span>
          </div>
        </div>
        
        <div class="action-buttons">
          <button class="clear-button" @click="clearConversation">Clear Conversation</button>
          <button class="save-button" @click="savePrompt">Save Prompt</button>
          <button class="load-button" @click="showSavedPrompts = !showSavedPrompts">
            {{ showSavedPrompts ? 'Hide Saved Prompts' : 'Load Prompt' }}
          </button>
        </div>
      </div>

      <div v-if="showSavedPrompts" class="saved-prompts">
        <h3>Saved Prompts</h3>
        <div v-if="savedPrompts.length === 0" class="no-prompts">No saved prompts yet</div>
        <ul v-else class="prompt-list">
          <li v-for="(prompt, index) in savedPrompts" :key="index" class="prompt-item">
            <div class="prompt-preview">{{ prompt.name || 'Unnamed Prompt' }}</div>
            <div class="prompt-actions">
              <button class="load-prompt-btn" @click="loadPrompt(prompt.content)">Load</button>
              <button class="delete-prompt-btn" @click="deletePrompt(index)">Delete</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console, no-alert, no-unused-vars */
import Recorder from '../../../../../vendor/recorder.js';

export default {
  name: 'TestVoicebotComponent',
  data() {
    return {
      // WebSocket
      socket: null,
      isConnected: false,
      connectionStatus: 'Disconnected',
      // Recording
      isRecording: false,
      recorder: null,
      audioContext: null,
      gumStream: null,
      // Processing states
      isTranscribing: false,
      isProcessing: false,
      // Conversation and settings
      systemPrompt: 'You are a helpful assistant that can answer questions and help with tasks.',
      conversation: [],
      savedPrompts: [],
      showSavedPrompts: false,
      // Options
      selectedModel: 'qwen-plus',
      language: 'english',
      checkLanguage: false,
      temperature: 0.7,
      isDevelopment: process.env.NODE_ENV === 'development',
    };
  },
  computed: {
    renderUrl() {
      // Endpoint for the transcription service
      return this.isDevelopment ? 'ws://localhost:8080/ws/staff_prompt_testing' : 'wss://learnsmartapi.onrender.com/ws/staff_prompt_testing';
    },
    connectionStatusClass() {
      return {
        'connected': this.connectionStatus === 'Connected',
        'connecting': this.connectionStatus === 'Connecting...',
        'disconnected': this.connectionStatus === 'Disconnected' || this.connectionStatus.includes('Error'),
      };
    },
  },
  mounted() {
    // Load saved prompts from localStorage
    const savedPrompts = localStorage.getItem('voicebotSavedPrompts');
    if (savedPrompts) {
      this.savedPrompts = JSON.parse(savedPrompts);
    }

    // Initialize WebSocket connection
    this.connectWebSocket();

    // Initialize AudioContext
    this.initializeAudioContext();
  },
  beforeDestroy() {
    // Clean up WebSocket connection
    this.disconnectWebSocket();
  },
  watch: {
    conversation() {
      this.$nextTick(() => {
        if (this.$refs.conversationDisplay) {
          this.$refs.conversationDisplay.scrollTop = this.$refs.conversationDisplay.scrollHeight;
        }
      });
    },
  },
  methods: {
    // WebSocket methods
    connectWebSocket() {
      this.connectionStatus = 'Connecting...';
      this.socket = new WebSocket(this.renderUrl);

      this.socket.onopen = () => {
        this.isConnected = true;
        this.connectionStatus = 'Connected';
        console.log('WebSocket connection established');
      };

      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        this.handleWebSocketMessage(data);
      };

      this.socket.onerror = (error) => {
        this.isConnected = false;
        this.connectionStatus = 'Error: WebSocket connection failed';
        console.error('WebSocket error:', error);
      };

      this.socket.onclose = () => {
        this.isConnected = false;
        this.connectionStatus = 'Disconnected';
        console.log('WebSocket connection closed');

        // Try to reconnect after a delay
        setTimeout(() => {
          if (!this.isConnected) {
            this.connectWebSocket();
          }
        }, 5000);
      };
    },
    disconnectWebSocket() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.close();
      }
    },
    handleWebSocketMessage(data) {
      const eventType = data.type;
      const payload = data.data || {};
      switch (eventType) {
        case 'TRANSCRIPTION_RESULT':
          this.isTranscribing = false;
          this.isProcessing = true;
          console.log(payload);
          // Add user message to conversation
          this.conversation.push({
            role: 'user',
            content: payload.transcription || 'No transcription available',
          });
          break;

        case 'LLM_RESPONSE':
          this.isProcessing = false;
          console.log(payload);
          // Add bot response to conversation
          this.conversation.push({
            role: 'assistant',
            content: payload.llm_response || 'No response text',
            model: payload.model || 'unknown',
            processingTime: payload.processing_time || 0,
          });
          break;

        case 'ERROR':
          this.isTranscribing = false;
          this.isProcessing = false;

          // Add error message to conversation
          this.conversation.push({
            role: 'system',
            content: `Error: ${payload.error || 'Unknown error'}`,
          });
          break;

        default:
          console.warn('Unknown WebSocket message type:', eventType);
      }
    },
    // Recording methods
    initializeAudioContext() {
      if (!this.audioContext) {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        this.audioContext = new AudioContext();

        // Resume audio context on user interaction for iOS/Safari
        document.addEventListener('touchstart', () => {
          if (this.audioContext && this.audioContext.state === 'suspended') {
            this.audioContext.resume();
          }
        }, {once: true});
      }
    },
    startRecording() {
      if (!this.isConnected) {
        alert('WebSocket is not connected. Please try again.');
        return;
      }
      if (!this.systemPrompt) {
        alert('Please enter a system prompt before recording.');
        return;
      }

      // Make sure AudioContext is resumed
      if (this.audioContext.state === 'suspended') {
        this.audioContext.resume();
      }

      this.isRecording = true;
      const constraints = {
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
        video: false,
      };

      navigator.mediaDevices.getUserMedia(constraints)
          .then((stream) => {
            this.gumStream = stream;

            // Use the stream
            const input = this.audioContext.createMediaStreamSource(stream);

            // Create the Recorder object and configure to record mono sound
            this.recorder = new Recorder(input, {
              numChannels: 1,
              workerPath: '../../../../../vendor/recorderWorker.js',
            });

            // Start recording
            this.recorder.record();
          })
          .catch((err) => {
            this.isRecording = false;
            alert(`Error accessing microphone: ${err.message}`);
            console.error('Error accessing microphone:', err);
          });
    },
    stopRecording() {
      if (!this.isRecording || !this.recorder) return;

      this.isRecording = false;
      this.isTranscribing = true;

      // Stop recording
      this.recorder.stop();

      // Stop microphone access
      this.gumStream.getTracks().forEach((track) => track.stop());

      // Export the audio as WAV and send to server
      this.recorder.exportWAV(this.sendAudioToServer);
    },
    sendAudioToServer(blob) {
      if (!this.isConnected) {
        alert('WebSocket is not connected. Please try again.');
        this.isTranscribing = false;
        return;
      }

      if (!blob || blob.size === 0) {
        alert('No audio data captured. Please try again.');
        this.isTranscribing = false;
        return;
      }

      // Create an audio element to verify and ensure proper encoding
      const au = document.createElement('audio');
      au.controls = false;
      au.src = URL.createObjectURL(blob);

      // Log the blob size and type for debugging
      console.log('Audio blob:', blob.size, 'bytes, type:', blob.type);

      // Convert blob to base64
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        try {
          const base64data = reader.result.split(',')[1];

          // Log the base64 length for debugging
          console.log('Base64 data length:', base64data.length);

          // Format previous messages for the server
          const previousMessages = this.conversation.map((msg) => ({
            role: msg.role,
            content: msg.content,
          }));

          // Prepare data to send
          const data = {
            audioData: base64data,
            systemPrompt: this.systemPrompt,
            previousMessages: previousMessages,
            model: this.selectedModel,
            language: this.language,
            checkLanguage: this.checkLanguage,
            temperature: this.temperature,
            sampleRate: this.audioContext.sampleRate,
            audioFormat: 'wav',
          };

          // Send data through WebSocket
          this.socket.send(JSON.stringify(data));
        } catch (error) {
          console.error('Error preparing audio data:', error);
          this.isTranscribing = false;
          this.conversation.push({
            role: 'system',
            content: `Error preparing audio data: ${error.message}`,
          });
        }
      };

      reader.onerror = (error) => {
        console.error('Error reading audio file:', error);
        this.isTranscribing = false;
        this.conversation.push({
          role: 'system',
          content: 'Error reading audio file',
        });
      };
    },
    // UI interaction methods
    clearConversation() {
      if (confirm('Are you sure you want to clear the conversation?')) {
        this.conversation = [];
      }
    },
    savePrompt() {
      const name = prompt('Enter a name for this prompt:', 'Untitled Prompt');
      if (name !== null) {
        const newPrompt = {
          name,
          content: this.systemPrompt,
          createdAt: new Date().toISOString(),
        };

        this.savedPrompts.push(newPrompt);
        localStorage.setItem('voicebotSavedPrompts', JSON.stringify(this.savedPrompts));
      }
    },
    loadPrompt(promptContent) {
      this.systemPrompt = promptContent;
      this.showSavedPrompts = false;
    },
    deletePrompt(index) {
      if (confirm('Are you sure you want to delete this prompt?')) {
        this.savedPrompts.splice(index, 1);
        localStorage.setItem('voicebotSavedPrompts', JSON.stringify(this.savedPrompts));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.test-voicebot {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f8f9fa;
  z-index: 9999;
  overflow-y: auto;

  &-container {
    max-width: 900px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 0.75rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }

  .title {
    font-size: 1.75rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .settings-section {
    display: flex;
    gap: 2rem;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .prompt-section {
    flex: 2;

    h2 {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 0.75rem;
      color: #4263eb;
    }

    .prompt-input {
      width: 100%;
      resize: vertical;
      padding: 0.75rem;
      border: 1px solid #dee2e6;
      border-radius: 0.5rem;
      font-family: inherit;
      font-size: 1rem;

      &:focus {
        outline: none;
        border-color: #4263eb;
        box-shadow: 0 0 0 2px rgba(66, 99, 235, 0.2);
      }
    }
  }

  .options-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .option-group {
      margin-bottom: 1.25rem;

      label {
        display: block;
        font-size: 0.9rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        color: #4263eb;
      }

      &.checkbox-group {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        label {
          margin-bottom: 0;
        }
      }

      .range-input {
        width: 100%;
        cursor: pointer;
      }
    }

    .select-input {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid #dee2e6;
      border-radius: 0.5rem;
      font-family: inherit;

      &:focus {
        outline: none;
        border-color: #4263eb;
      }
    }
  }

  .conversation-section {
    margin-bottom: 1.5rem;

    h2 {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 0.75rem;
      color: #4263eb;
    }

    .conversation-display {
      height: 500px;
      overflow-y: auto;
      padding: 1rem;
      border: 1px solid #dee2e6;
      border-radius: 0.5rem;
      background-color: #f8f9fa;

      .message {
        margin-bottom: 1rem;
        max-width: 80%;

        &.user {
          margin-left: auto;

          .message-role {
            text-align: right;
            color: #4263eb;
          }

          .message-content {
            background-color: #edf2ff;
            border-radius: 0.75rem 0.25rem 0.75rem 0.75rem;
          }
        }

        &.assistant {
          margin-right: auto;

          .message-role {
            color: #495057;
          }

          .message-content {
            background-color: #e9ecef;
            border-radius: 0.25rem 0.75rem 0.75rem 0.75rem;
          }
        }

        &.system {
          margin-right: auto;
          margin-left: auto;

          .message-role {
            color: #e74c3c;
          }

          .message-content {
            background-color: #fff5f5;
            border: 1px solid #ffcccc;
            border-radius: 0.5rem;
            color: #e74c3c;
          }
        }

        .message-role {
          font-size: 0.85rem;
          font-weight: 500;
          margin-bottom: 0.25rem;
        }

        .message-content {
          padding: 0.75rem 1rem;
          color: #495057;
          line-height: 1.5;
          word-break: break-word;
        }

        .message-metadata {
          font-size: 0.75rem;
          color: #868e96;
          margin-top: 0.25rem;
          text-align: right;
        }
      }
    }
  }

  .status-section {
    margin-bottom: 1rem;

    .status-indicator {
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      font-size: 0.85rem;
      text-align: center;

      &.connected {
        background-color: #d3f9d8;
        color: #2b8a3e;
      }

      &.connecting {
        background-color: #fff3bf;
        color: #e67700;
      }

      &.disconnected {
        background-color: #ffe3e3;
        color: #e03131;
      }
    }
  }

  .controls-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;

    .recording-controls {
      display: flex;
      align-items: center;
      gap: 1rem;

      .recorder-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        width: 60px;

        .recording-indicator,
        .processing-indicator {
          width: max-content;
          white-space: nowrap;
          text-align: center;
          font-size: 0.85rem;
        }

        .recording-indicator {
          color: #e74c3c;
          font-weight: 500;
          animation: blink 1s ease-in-out infinite;
        }

        .processing-indicator {
          color: #3498db;
          font-weight: 500;
        }
      }

      .record-button {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #4263eb;
        color: white;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        transition: all 0.2s;

        &:hover:not(:disabled) {
          background-color: #3b5bdb;
          transform: scale(1.05);
        }

        &:disabled {
          background-color: #adb5bd;
          cursor: not-allowed;
        }

        &.recording {
          background-color: #e03131;
          animation: pulse 2s infinite;

          &:hover {
            background-color: #c92a2a;
          }
        }
      }
    }

    .action-buttons {
      display: flex;
      gap: 0.75rem;

      button {
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 0.5rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s;

        &.clear-button {
          background-color: #f1f3f5;
          color: #495057;

          &:hover {
            background-color: #e9ecef;
          }
        }

        &.save-button {
          background-color: #4263eb;
          color: white;

          &:hover {
            background-color: #3b5bdb;
          }
        }

        &.load-button {
          background-color: #38d9a9;
          color: white;

          &:hover {
            background-color: #20c997;
          }
        }
      }
    }
  }

  .saved-prompts {
    padding: 1rem;
    border: 1px solid #dee2e6;
    border-radius: 0.5rem;
    background-color: #f8f9fa;

    h3 {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.75rem;
      color: #495057;
    }

    .no-prompts {
      text-align: center;
      color: #868e96;
      padding: 1rem 0;
    }

    .prompt-list {
      list-style-type: none;
      padding: 0;
      margin: 0;

      .prompt-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.75rem;
        border-bottom: 1px solid #dee2e6;

        &:last-child {
          border-bottom: none;
        }

        .prompt-preview {
          font-weight: 500;
          color: #495057;
        }

        .prompt-actions {
          display: flex;
          gap: 0.5rem;

          button {
            padding: 0.25rem 0.5rem;
            border: none;
            border-radius: 0.25rem;
            font-size: 0.85rem;
            cursor: pointer;

            &.load-prompt-btn {
              background-color: #38d9a9;
              color: white;

              &:hover {
                background-color: #20c997;
              }
            }

            &.delete-prompt-btn {
              background-color: #fa5252;
              color: white;

              &:hover {
                background-color: #e03131;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(224, 49, 49, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(224, 49, 49, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(224, 49, 49, 0);
  }
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
