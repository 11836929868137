<template>
  <div class="multiplayer-room__results--component"
    :class="isCorrect ? 'bg-correct' : 'bg-wrong'">
    <div class="multiplayer-room__results--text">
      <div v-if="isCorrect"
        class="multiplayer-room__results--text-correct">
        <p class="animated fadeInUp faster delay-1">
          <span v-if="subject === 'malay'">Betul!</span>
          <span v-else>答对了! 你真棒!</span>
        </p>
      </div>
      <div class="multiplayer-room__results--text-wrong" v-else>
        <p class="animated fadeInUp faster delay-1">
          <span v-if="subject === 'malay'">Tidak tepat.</span>
          <span v-else>喔喔! 答错了...</span>
        </p>
      </div>
    </div>
    <div class="multiplayer-room__results--icon animated flipInX">
      <i v-if="isCorrect" class="fas fa-check"></i>
      <i v-else class="fas fa-times"></i>
    </div>
    <div class="multiplayer-room__results--score animated fadeInUp
      faster delay-3">
      <i class="fas fa-plus"></i>
      <span>{{ questionScore }}</span>
      <i class="fas fa-trophy"></i>
      <!-- placeholder -->
      <div v-if="streakBonus > 0">
        <span>Streak Bonus!</span>
        <span>{{ streakBonus }}</span>
        <i class="fas fa-trophy"></i>
      </div>
    </div>
    <div v-if="!showNextQuestionButton"
      class="anim-waiting animated fadeInUp faster delay-3">
      <i class="fas fa-circle"></i>
      <i class="fas fa-circle"></i>
      <i class="fas fa-circle"></i>
    </div>
    <!-- to be shown only for student self study -->
    <button
      v-if="showTeachingScreenCallback && !isCorrect"
      class="singlePlayer__results--btn-next animated fadeInUp faster delay-3"
      @click="showTeachingScreenCallback">
      <span v-if="subject === 'malay'">Jawapan yang tepat.</span>
      <span v-else>Show Correct Answer</span>
    </button>
    <button
      v-if="showNextQuestionButton && isCorrect"
      class="singlePlayer__results--btn-next animated fadeInUp faster delay-3"
      @click="nextQuestionCallback">
      <span v-if="subject === 'malay'">Soalan seterusnya.</span>
      <span v-else>Next Question</span>
    </button>
    </div>
</template>
<script>

import {mapState} from 'vuex';

export default {
  name: 'MultiplayerRoomResult',
  props: {
    correctAnswer: String,
    studentAnswer: String,
    questionScore: Number,
    streakBonus: {
      type: Number,
      default: 0,
    },
    totalScore: Number,
    // for teacher led quizzes, this should be false
    // for student self study, this will show
    nextQuestionCallback: {
      type: Function,
      default: null,
    },
    showTeachingScreenCallback: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['subject']),
    isCorrect() {
      return this.correctAnswer === this.studentAnswer;
    },
    showNextQuestionButton() {
      if (typeof this.nextQuestionCallback === 'undefined' ||
        this.nextQuestionCallback === null) return false;
      return true;
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .delay-1 {
    animation-delay: 0.05s;
  }
  .delay-2 {
    animation-delay: 0.125s;
  }
  .delay-3 {
    animation-delay: 0.2s;
  }

  .multiplayer-room__results {
    &--component {
      height: calc(100% - 122px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      background: #e8e8e8;
      color: #fff;
    }
    &--icon {
      font-size: 4rem;
      background: #fff;
      border-radius: 50%;
      width: 135px;
      height: 135px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }
    &--text {
      text-align: center;
      font-size: 1.2rem;
      font-weight: 900;
      margin-bottom: 10px;
      &-correct,
      &-wrong {
        p {
          &:first-child {
            font-size: 1.75rem;
            margin-bottom: -0.65rem;
          }
        }
      }
    }
    &--score {
      background: #1b1b1b47;
      color: #fff;
      padding: 0 1.5rem;
      border-radius: 5px;
      margin-bottom: 1.25rem;
      .fa-plus {
        color: #fff;
      }
      .fa-trophy {
        color: #ffc542;
      }
      span {
        margin: 0 0.25rem;
        font-size: 1.25rem;
      }
    }
  }
  .bg-correct {
    background: #48c7ad;
    i {
      color: #307f75;
    }
  }
  .bg-wrong {
    background: #db6372;
    i {
      color: #7e3a46;
    }
  }
  @keyframes blink {
      0% {
        opacity: .2;
        transform: scale(1);
      }
      20% {
        opacity: 1;
        transform: scale(1.5);
      }
      100% {
        opacity: .2;
        transform: scale(1);
      }
  }
  .anim-waiting {
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      margin: 0 2.5px;
      font-size: 0.5rem;
      display: inline-block;
      animation-name: blink;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      margin-bottom: 20px;
      &:nth-child(2) {
        animation-delay: .2s;
      }
      &:nth-child(3) {
        animation-delay: .4s;
      }
    }
  }
  .singlePlayer__results--btn-next {
      margin: 0.25rem;
      cursor: pointer;
      display: inline-block;
      padding: 0.25rem 1rem;
      font-weight: 900;
      font-size: 1.1rem;
      border-radius: 5px;
      border: 4px solid #22222230;
      transition: all 0.3s;
      outline: none;
      background: #e8e8e8;
      color: #000;
      text-decoration: none;
      &:hover {
        display: inline-block;
        transform: scale(1.1);
        border: 4px solid #222222;
      }
      &:active {
        display: inline-block;
        transform: scale(1.075);
      }

  }
</style>
