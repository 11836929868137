<template>
  <div class="english-oral-practice">
    <div class="english-oral-practice--header">
      <button class="btn-back" @click="goBack">
        <i class="fa-solid fa-arrow-left"></i>
      </button>

      <div class="question-container">
        <div class="question-type-badge" v-if="question">{{ questionTypeLabel }}</div>
        <h2 v-if="question">{{ question.question_text }}</h2>
      </div>
    </div>

    <div class="english-oral-practice--content" v-if="question">
      <!-- Side-by-side layout for picture questions -->
      <div v-if="question.question_category === 'describe_picture' && question.image_url" class="picture-question-layout">
        <div class="question-panel">
          <div class="question-image-container">
            <img :src="question.image_url" alt="Question image" class="question-image" />
          </div>
          <div class="answer-method">
            <div>Answer using the PEEL structure</div>
            <button class="peel-help-button" @click="openPeelModal">
              <i class="fa-solid fa-circle-info"></i>
            </button>
          </div>
          <div class="helpful-vocabulary" @click="toggleVocabulary">
            <i class="fa-solid fa-book"></i>
            Helpful Vocabulary
          </div>
        </div>

        <div class="answer-panel">
          <!-- Answer tabs with new styling -->
          <div class="tab-container">
            <div class="answer-tabs">
              <div
                class="answer-tab"
                :class="{active: answerMode === 'form'}"
                @click="setAnswerMode('form')">
                Form Input
              </div>
              <div
                class="answer-tab"
                :class="{active: answerMode === 'audio'}"
                @click="setAnswerMode('audio')">
                Audio Recording
              </div>
            </div>
          </div>

          <!-- PEEL Form (Written Answer) -->
          <div v-if="answerMode === 'form'" class="peel-form-container animated fadeIn faster">
            <div class="peel-form">
              <div class="form-group">
                <label for="point">Point <span class="required">*</span> <span class="word-limit">(max 20 words)</span></label>
                <textarea
                  id="point"
                  v-model="peelResponse.point"
                  placeholder="State your main point that directly answers the question"
                  rows="2"></textarea>
              </div>
              <div class="form-group">
                <label for="explanation">Explanation <span class="required">*</span></label>
                <textarea
                  id="explanation"
                  v-model="peelResponse.explanation"
                  placeholder="Explain why you think this way"
                  rows="3"></textarea>
              </div>
              <div class="form-group">
                <label for="example">Example <span class="required">*</span></label>
                <textarea
                  id="example"
                  v-model="peelResponse.example"
                  placeholder="Give a specific example to support your explanation"
                  rows="3"></textarea>
              </div>
              <div class="form-group">
                <label for="link">Link <span class="required">*</span></label>
                <textarea
                  id="link"
                  v-model="peelResponse.link"
                  placeholder="Connect back to the question or extend to a broader context"
                  rows="2"></textarea>
              </div>
              <div class="submit-container">
                <button
                  class="btn-primary"
                  @click="submitPeelForm"
                  :disabled="!isPeelFormValid">
                  Submit Answer
                </button>
              </div>
            </div>
          </div>

          <!-- Audio Recording (Spoken Answer) -->
          <div v-if="answerMode === 'audio'" class="animated fadeIn faster">
            <recording-component
              @recording-complete="handleRecordingComplete"
              @recording-stopped="handleRecordingStopped"
              @recording-cancelled="handleRecordingCancelled"
            />
          </div>
        </div>
      </div>

      <!-- Regular layout for non-picture questions with similar styling -->
      <div v-else class="standard-question-layout">
        <!-- Answer tabs with new styling -->
        <div class="tab-container">
          <div class="answer-tabs">
            <div
              class="answer-tab"
              :class="{active: answerMode === 'form'}"
              @click="setAnswerMode('form')">
              Form Input
            </div>
            <div
              class="answer-tab"
              :class="{active: answerMode === 'audio'}"
              @click="setAnswerMode('audio')">
              Audio Recording
            </div>
          </div>
        </div>

        <!-- PEEL Form (Written Answer) -->
        <div v-if="answerMode === 'form'" class="peel-form-container animated fadeIn faster">
          <div class="peel-form">
            <div class="form-group">
              <label for="point">Point <span class="required">*</span> <span class="word-limit">(max 20 words)</span></label>
              <textarea
                id="point"
                v-model="peelResponse.point"
                placeholder="State your main point that directly answers the question"
                rows="2"></textarea>
            </div>
            <div class="form-group">
              <label for="explanation">Explanation <span class="required">*</span></label>
              <textarea
                id="explanation"
                v-model="peelResponse.explanation"
                placeholder="Explain why you think this way"
                rows="3"></textarea>
            </div>
            <div class="form-group">
              <label for="example">Example <span class="required">*</span></label>
              <textarea
                id="example"
                v-model="peelResponse.example"
                placeholder="Give a specific example to support your explanation"
                rows="3"></textarea>
            </div>
            <div class="form-group">
              <label for="link">Link <span class="required">*</span></label>
              <textarea
                id="link"
                v-model="peelResponse.link"
                placeholder="Connect back to the question or extend to a broader context"
                rows="2"></textarea>
            </div>
            <div class="submit-container">
              <button
                class="btn-primary"
                @click="submitPeelForm"
                :disabled="!isPeelFormValid">
                Submit Answer
              </button>
            </div>
          </div>
        </div>

        <!-- Audio Recording (Spoken Answer) -->
        <div v-if="answerMode === 'audio'" class="animated fadeIn faster">
          <recording-component
            @recording-complete="handleRecordingComplete"
            @recording-stopped="handleRecordingStopped"
            @recording-cancelled="handleRecordingCancelled"
          />
        </div>
      </div>
    </div>

    <!-- PEEL Modal Component -->
    <peel-modal-component
      :show="isPeelModalOpen"
      @close="closePeelModal"
    />

    <!-- Vocabulary Modal Component -->
    <vocabulary-modal-component
      :show="showVocabulary"
      :topic-vocabulary="topicVocabulary"
      @close="toggleVocabulary"
    />

    <!-- Processing Overlay (for transcription) -->
    <div class="processing-overlay animated fadeIn" v-if="processingSubmission">
      <div class="processing-content">
        <div class="processing-spinner">
          <div class="spinner"></div>
        </div>
        <h3>{{ processingMessage }}</h3>
        <p>Please wait while we process your recording...</p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {mapState} from 'vuex';
import RecordingWaveAnimationComponent from '../components/RecordingWaveAnimationComponent.vue';
import VocabularyModalComponent from '../components/VocabularyModalComponent.vue';
import PeelModalComponent from '../components/PeelModalComponent.vue';
import RecordingComponent from '../components/RecordingComponent.vue';

export default {
  name: 'EnglishAiPracticeComponent',
  components: {
    'recording-wave-animation': RecordingWaveAnimationComponent,
    'vocabulary-modal-component': VocabularyModalComponent,
    'peel-modal-component': PeelModalComponent,
    'recording-component': RecordingComponent,
  },
  props: {
    questionId: {
      type: String,
      required: true,
    },
    questionType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      processingSubmission: false,
      processingMessage: '',
      question: null,
      topic: null,
      answerMode: 'form', // 'form' or 'audio'
      peelResponse: {
        point: '',
        explanation: '',
        example: '',
        link: '',
      },
      isPeelModalOpen: false,
      showVocabulary: false,
      isDevelopment: process.env.NODE_ENV === 'development',
    };
  },
  computed: {
    ...mapState('guidedReading', ['freeResponseTopics']),
    renderUrl() {
      // Endpoint for the transcription service
      return this.isDevelopment ? 'http://localhost:8080/transcribe' : 'https://learnsmartapi.onrender.com/transcribe';
    },
    questionTypeLabel() {
      if (!this.question) return '';
      
      const labels = {
        'describe_picture': 'Picture-based Question',
        'personal_experience': 'Personal Experience',
        'suggestion': 'Opinion & Suggestion',
      };
      return labels[this.question.question_category] || this.question.question_category;
    },
    isPeelFormValid() {
      return this.peelResponse.point.trim() !== '' &&
        this.peelResponse.explanation.trim() !== '' &&
        this.peelResponse.example.trim() !== '' &&
        this.peelResponse.link.trim() !== '';
    },
    topicVocabulary() {
      if (!this.topic || !this.topic.reference_vocab) return null;
      return this.topic.reference_vocab;
    },
  },
  mounted() {
    this.fetchQuestionData();
  },
  methods: {
    fetchQuestionData() {
      // Check if we have the question data in Vuex by searching through all topics
      if (this.freeResponseTopics && this.freeResponseTopics.length > 0) {
        const questionId = parseInt(this.questionId);
        let foundQuestion = null;
        
        // Loop through all topics
        for (const topic of this.freeResponseTopics) {
          if (topic.questions && topic.questions.length > 0) {
            // Look for the question with matching ID in this topic
            const question = topic.questions.find((q) => q.id === questionId);
            if (question) {
              foundQuestion = question;
              this.topic = topic;
              break;
            }
          }
        }

        if (foundQuestion) {
          this.question = foundQuestion;
        } else {
          this.$router.push({name: 'new-oral-english-ai-practice-index'});
        }
      } else {
        this.$router.push({name: 'new-oral-english-ai-practice-index'});
      }
    },
    goBack() {
      // Navigate back to question selection
      this.$router.push({
        name: 'new-oral-english-ai-practice-index'
      });
    },
    setAnswerMode(mode) {
      this.answerMode = mode;
    },
    openPeelModal() {
      this.isPeelModalOpen = true;
    },
    closePeelModal() {
      this.isPeelModalOpen = false;
    },
    toggleVocabulary() {
      this.showVocabulary = !this.showVocabulary;
    },
    submitPeelForm() {
      if (!this.isPeelFormValid) return;
      // Combine PEEL structure into a single formatted text answer
      const combinedAnswer = `
${this.peelResponse.point.trim()}

${this.peelResponse.explanation.trim()}

${this.peelResponse.example.trim()}

${this.peelResponse.link.trim()}
      `.trim();
      
      // Call the new submission function
      this.submitAnswerToBackend(combinedAnswer, 0);
    },
    submitAnswerToBackend(transcriptionText, duration) {
      this.processingSubmission = true;
      this.processingMessage = 'Submitting your answer...';
      
      // Prepare form data for submission
      const formData = new FormData();
      formData.append('questionId', this.questionId);
      formData.append('transcription', transcriptionText);
      formData.append('subject', 'english');
      formData.append('duration', duration);
      formData.append('answerMethod', this.question.answer_methods[0]);
      
      // API endpoint
      const _url = '/api/v1/brain/aipracticesession/';
      
      // Send data to backend
      axios
        .post(_url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.status === 400) {
            this.processingSubmission = false;
            alert('Error submitting your answer. Please try again.');
            return;
          }
          
          console.log('Backend submission response:', response.data);
          // Navigate to feedback page
          this.$router.push({
            name: 'new-oral-english-ai-practice-feedback',
            params: {sessionId: response.data.session_id},
          });
        })
        .catch((err) => {
          console.error('Error submitting answer to backend:', err);
          this.processingSubmission = false;
          alert('Failed to submit your answer. Please try again later.');
        });
    },
    handleRecordingComplete(audioBlob, duration) {
      // Handle completed recording with the audio blob
      console.log('Recording complete, processing audio...');
      this.processingSubmission = true;
      this.processingMessage = 'Transcribing your audio...';

      // Prepare form data for transcription service
      const formData = new FormData();
      const filename = new Date().toISOString() + '.wav';
      formData.append('audioData', audioBlob, filename);
      formData.append('language', 'english'); // Set language to English

      // Send audio to transcription service
      axios
        .post(this.renderUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (!response.data['is_subject']) {
            this.processingSubmission = false;
            alert(`Your answer needs to be in English.`);
            return;
          }

          const transcription = response.data['transcription'].trim();
          console.log('Transcription received:', transcription);

          // Send transcription to main backend
          this.submitAnswerToBackend(transcription, duration);
        })
        .catch((err) => {
          console.error('Error during transcription:', err);
          this.processingSubmission = false;
          alert('Failed to transcribe your audio. Please try again later.');
        });
    },
    handleRecordingStopped() {
      // Handle when recording is manually stopped
      console.log('Recording stopped');
      this.processingSubmission = false;
    },
    handleRecordingCancelled() {
      // Handle when recording is cancelled
      console.log('Recording cancelled');
      this.processingSubmission = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.english-oral-practice {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: #f8f9fa;
  z-index: 9999;
  overflow-y: auto;
  overflow-x: hidden;
  
  &--header {
    background-color: #fff;
    padding: 1.5rem 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    
    .question-container {
      max-width: 1200px;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      h2 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #333;
        margin: 0.5rem 0;
        line-height: 1.4;
      }
    }
    
    .question-type-badge {
      display: inline-block;
      padding: 0.3rem 0.75rem;
      background-color: #edf2ff;
      color: #4263eb;
      border-radius: 0.75rem;
      font-size: 0.85rem;
      font-weight: 500;
      border-radius: 1rem;
      margin-bottom: 1rem;
    }

    .btn-back {
      background: none;
      border: none;
      color: #666;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      margin-bottom: 1rem;
      font-size: 1.2rem;
      position: absolute;
      top: 2rem;
      left: 2rem;
      z-index: 10000;

      i {
        margin-right: 0.5rem;
      }

      &:hover {
        color: #333;
      }
    }
  }
  
  &--content {
    padding: 2rem;
    margin: 0 auto;
    width: 100%;
    max-width: 1400px;
  }
}

// Picture question layout
.picture-question-layout {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 2rem;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
  
  .question-panel {
    background-color: #fff;
    border-radius: 0.75rem;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    
    .question-image-container {
      width: 100%;
      text-align: center;
      padding: 1rem;
      border-bottom: 1px solid #eee;
      
      img {
        max-width: 100%;
        border-radius: 0.5rem;
      }
    }
    
    .answer-method {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      font-weight: 500;
      border-bottom: 1px solid #eee;
      
      .peel-help-button {
        background: none;
        border: none;
        color: #4263eb;
        cursor: pointer;
        
        i {
          font-size: 1.25rem;
        }
      }
    }
    
    .helpful-vocabulary {
      padding: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #4263eb;
      font-weight: 500;
      cursor: pointer;
      
      &:hover {
        background-color: #f8f9fa;
      }
    }
  }
  
  .answer-panel {
    background-color: #fff;
    border-radius: 0.75rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}

.standard-question-layout {
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

// Tab styling to match design
.tab-container {
  width: 100%;
  overflow: hidden;
  margin-bottom: -1px; /* This ensures the bottom border overlaps correctly */
}

.answer-tabs {
  display: flex;
  width: 100%;
  border: 1px solid #dee2e6;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  
  .answer-tab {
    padding: 1rem;
    font-weight: 500;
    cursor: pointer;
    color: #6c757d;
    position: relative;
    text-align: center;
    flex: 1;
    user-select: none;
    
    &.active {
      color: #000;
      font-weight: 600;
      background-color: #fff;
      border-bottom: 2px solid #4263eb;
      z-index: 1;
    }
    
    &:not(.active) {
      background-color: #f8f9fa;
    }
    
    &:first-child {
      border-top-left-radius: 0.375rem;
    }
    
    &:last-child {
      border-top-right-radius: 0.375rem;
    }
    
    &:not(:last-child) {
      border-right: 1px solid #dee2e6;
    }
  }
}

// Form styling
.peel-form-container {
  padding: 2rem;
  border: 1px solid #dee2e6;
  border-top: none;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-color: #fff;

  .peel-form {
    max-width: 90%;
    margin: 0 auto;
    .form-group {
      margin-bottom: 1.5rem;
      label {
        display: block;
        font-weight: 600;
        color: #495057;
        margin-bottom: 0.5rem;
        .required {
          color: #e03131;
        }
        .word-limit {
          font-weight: normal;
          color: #868e96;
          font-size: 0.85rem;
        }
      }
      textarea {
        width: 100%;
        padding: 0.75rem 1rem;
        border: 1px solid #dee2e6;
        border-radius: 0.5rem;
        resize: vertical;
        font-size: 1rem;
        font-family: 'Inter', sans-serif;
        min-height: 200px;
        &:focus {
          outline: none;
          border-color: #4263eb;
          box-shadow: 0 0 0 3px rgba(66, 99, 235, 0.1);
        }
        &::placeholder {
          color: #adb5bd;
        }
      }
    }
    .submit-container {
      text-align: right;
      margin-top: 2rem;
    }
  }
}

// Audio recorder styling
.audio-recorder-container {
  padding: 3rem 2rem;
  text-align: center;
  border: 1px solid #dee2e6;
  border-top: none;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-color: #fff;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 320px;
  .record-instructions {
    max-width: 500px;
    margin: 0 auto 3rem;
    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }
    p {
      color: #6c757d;
      line-height: 1.5;
    }
  }
  
  .recorder-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    .recorder--btn-grp {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-bottom: 1rem;
    }
    .recorder--btn {
      border: none;
      width: 125px;
      height: 125px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0 4px 6px rgba(66, 99, 235, 0.2);
      transition: all 0.2s;
      cursor: pointer;
      i {
        font-size: 2.5rem;
      }
      &-start {
        background-color: #4263eb;
        border-top: 3px solid #8da9ff;
        border-bottom: 4px solid #3b5bdb;
        box-shadow: 0 5px 15px rgba(66, 99, 235, 0.4);
        &:hover {
          background-color: #3b5bdb;
          transform: scale(1.05);
        }
      }
    }
    
    .recorder--btn-text {
      margin-top: 1rem;
      font-size: 1rem;
      font-weight: 500;
      color: #6c757d;
      font-style: italic;
      .timer {
        bottom: 0;
      }
    }

    .recorder--countdown {
      text-align: center;
      margin-bottom: 2rem;
      h2 {
        font-size: 2.5rem;
        font-weight: 600;
        margin: 0;
        color: #4263eb;
      }
      p {
        color: #6c757d;
        font-size: 1rem;
        font-style: italic;
      }
    }
  }
}

// Button styling
.btn-back {
  background: none;
  border: none;
  color: #495057;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0;
  margin-bottom: 1rem;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: #212529;
  }
}

.btn-primary {
  background-color: #4263eb;
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: #3b5bdb;
  }
  &:disabled {
    background-color: #adb5bd;
    cursor: not-allowed;
  }
}

.btn-secondary {
  background-color: #f1f3f5;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: #e9ecef;
  }
}

// Animation classes
.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.fadeIn {
  animation-name: fadeIn;
}

.faster {
  animation-duration: 0.3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.cancel-recording-btn {
  margin-top: 1.5rem;
  text-align: center;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  button {
    background-color: #4263eb;
    color: white;
    border: none;
    border-radius: 0.5rem;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0 auto;
    
    &:hover {
      background-color: #3b5bdb;
    }
    
    i {
      font-size: 1rem;
    }
  }
}

// Fix the wave animation by ensuring it's positioned correctly
.wave-animation-container {
  position: absolute;
  width: 400px;
  height: 60px;
  margin-bottom: 20px;
  border-radius: 8px;
  z-index: -1;
  margin-top: 1.5rem;
}

.recording-ui-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
  width: 100%;
}

.side-waves {
  display: flex;
  height: 30px;
  gap: 10px;
  align-items: center;
  
  .wave-bar {
    width: 5px;
    height: 30px;
    background-color: #8C89FF;
    border-radius: 2px;
    animation: sound-wave 1.2s infinite ease-in-out alternate;
    
    &:nth-child(1) {
      animation-delay: 0s;
      height: 15px;
    }
    &:nth-child(2) {
      animation-delay: 0.2s;
      height: 25px;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
      height: 30px;
    }
    &:nth-child(4) {
      animation-delay: 0.6s;
      height: 20px;
    }
    &:nth-child(5) {
      animation-delay: 0.8s;
      height: 15px;
    }
  }
}

@keyframes sound-wave {
  0% {
    transform: scaleY(0.6);
  }
  100% {
    transform: scaleY(1.2);
  }
}

.recorder--btn-stop {
  background-color: #f366a2;
  border-top: 3px solid #f99bc1;
  border-bottom: 4px solid #d03e77;
  width: 90px;
  height: 90px;
  box-shadow: 0 5px 15px rgba(243, 102, 162, 0.4);
  animation: pulse 1.5s infinite;
  
  .square-icon {
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 3px;
  }
  
  &:hover {
    background-color: #e05390;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(243, 102, 162, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(243, 102, 162, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(243, 102, 162, 0);
  }
}

// Processing overlay
.processing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .processing-content {
    background-color: white;
    padding: 2rem;
    border-radius: 0.75rem;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    
    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      margin: 1rem 0 0.5rem 0;
      color: #333;
    }
    
    p {
      color: #6c757d;
      margin-bottom: 0;
    }
  }
  
  .processing-spinner {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    
    .spinner {
      width: 48px;
      height: 48px;
      border: 5px solid #f1f3f5;
      border-top-color: #4263eb;
      border-radius: 50%;
      animation: spinner 1s linear infinite;
    }
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(243, 102, 162, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(243, 102, 162, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(243, 102, 162, 0);
  }
}
</style>
