<template>
  <div class="building-card" :class="{ 'can-afford': building.canAfford }">
    <div class="building-header">
      <h3>{{ building.name }}</h3>
    </div>
    <div class="building-body">
      <div class="building-icon">
        <i class="fa fa-building"></i>
      </div>
      <div class="cost-list">
        <div v-for="(costInfo, index) in building.cost" :key="index" class="cost-item">
          <i class="fa" :class="getIconForType(costInfo.type)"></i>
          <span>{{ costInfo.value }}</span>
        </div>
      </div>
    </div>
    <div class="building-footer">
      <button
        @click="_purchaseBuilding"
        :disabled="!building.canAfford"
        class="buy-button"
        :class="{'disabled': !building.canAfford}"
      >
        <i class="fa fa-shopping-cart"></i>
        Buy
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'ModalCell',
  props: {
    building: Object,
  },
  computed: {
  },
  methods: {
    ...mapActions('kingdom', ['purchaseBuilding']),
    _purchaseBuilding() {
      this.purchaseBuilding(this.building.key);
      this.$emit('close');
    },
    getIconForType(type) {
      switch (type) {
        case 'energy':
          return 'fa-bolt';
        case 'wood':
          return 'fa-fence';
        case 'ruby':
          return 'fa-gem';
        default:
          return 'fa-coin';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.building-card {
  background-color: #f9f9f9;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border: 2px solid #e0e0e0;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-color: #bbb;
  }

  &.can-afford {
    border-color: #4CAF50;
  }
}

.building-header {
  background-color: #f0f0f0;
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;

  h3 {
    margin: 0;
    font-size: 1.1rem;
    color: #333;
    font-weight: 600;
  }
}

.building-body {
  padding: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.building-icon {
  font-size: 2rem;
  color: #555;
  margin-bottom: 8px;
  background-color: #e9e9e9;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cost-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.cost-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 0.95rem;

  i {
    color: #555;
  }
}

.building-footer {
  padding: 12px;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
}

.buy-button {
  width: 100%;
  padding: 8px 0;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s ease;

  &:hover:not(.disabled) {
    background-color: #45a049;
  }

  &.disabled {
    background-color: #cccccc;
    cursor: not-allowed;
    opacity: 0.7;
  }
}
</style>
