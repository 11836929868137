<template>
  <div class="question-tracker-buttons-carousel">
    <div class="question-tracker-label">Question Select:</div>
    <div class="carousel-container">
      <!-- Left navigation arrow -->
      <div
        v-if="totalQuestions > maxVisibleButtons"
        class="carousel-nav-arrow"
        :class="{ 'inactive': startIndex <= 1 }"
        @click="startIndex > 1 && navigateCarousel('left')"
      >
        <i class="fa-solid fa-caret-left"></i>
      </div>

      <!-- Question buttons -->
      <div class="question-buttons-container">
        <div
          class="question-buttons-wrapper"
          :style="{
            transform: `translateX(${translateX}px)`,
            width: wrapperWidth
          }"
        >
          <div
            v-for="index in totalQuestions"
            :key="index"
            :class="[
              'question-button',
              {'correct': questionStatuses[index - 1] === 'correct'},
              {'incorrect': questionStatuses[index - 1] === 'incorrect'},
              {'unanswered': questionStatuses[index - 1] === 'unanswered'},
              {'active': currentQuestionIndex === index - 1}
            ]"
            @click="handleQuestionClick(index - 1)"
          >
            <template v-if="questionStatuses[index - 1] === 'correct'">
              <i class="fa-solid fa-check"></i>
            </template>
            <template v-else-if="questionStatuses[index - 1] === 'incorrect'">
              <i class="fa-solid fa-xmark"></i>
            </template>
            <template v-else>
              <span>{{ index }}</span>
            </template>
          </div>
        </div>
      </div>

      <!-- Right navigation arrow -->
      <div
        v-if="totalQuestions > maxVisibleButtons"
        class="carousel-nav-arrow"
        :class="{ 'inactive': endIndex >= totalQuestions }"
        @click="endIndex < totalQuestions && navigateCarousel('right')"
      >
        <i class="fa-solid fa-caret-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionTrackerButtonsCarouselComponent',
  props: {
    // Total number of questions to display
    totalQuestions: {
      type: Number,
      required: true,
    },
    // Zero-based index of the currently active question
    currentQuestionIndex: {
      type: Number,
      required: true,
      default: 0,
    },
    // Array of question statuses: 'correct', 'incorrect', or 'unanswered'
    questionStatuses: {
      type: Array,
      required: true,
      validator: (value) => value.every((status) =>
        ['correct', 'incorrect', 'unanswered'].includes(status)),
    },
    // Callback function when a question button is clicked
    onQuestionClick: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      // The first visible question index (1-based for display)
      startIndex: 1,
      // Maximum number of buttons to show at once
      maxVisibleButtons: 5,
      // Button width including gap
      buttonWidth: 92, // 84px button + 8px gap
    };
  },
  computed: {
    // Calculate the end index based on start index and max visible buttons
    endIndex() {
      return Math.min(this.startIndex + this.maxVisibleButtons - 1, this.totalQuestions);
    },
    // Calculate the number of complete groups needed
    totalGroups() {
      return Math.ceil(this.totalQuestions / this.maxVisibleButtons);
    },
    // Calculate the wrapper width based on number of groups
    wrapperWidth() {
      return `${this.totalGroups * 100}%`;
    },
    // Calculate the translation for the sliding effect
    translateX() {
      // If 5 or fewer questions, no translation needed
      if (this.totalQuestions <= this.maxVisibleButtons) {
        return 0;
      }

      // Calculate how many buttons we've moved from the start
      const buttonOffset = this.startIndex - 1;

      // Calculate the translation based on button width (44px) and gap (8px)
      const buttonWidthWithGap = 54; // 44px button + 8px gap

      // Apply the translation based on button offset
      return -buttonOffset * buttonWidthWithGap;
    },
  },
  watch: {
    // Watch for changes in the current question to ensure it's visible
    currentQuestionIndex: {
      immediate: true,
      handler(newIndex) {
        this.ensureQuestionVisible(newIndex);
      },
    },
  },
  methods: {
    // Handle click on a question button
    handleQuestionClick(index) {
      this.onQuestionClick(index);
    },
    // Navigate the carousel left or right
    navigateCarousel(direction) {
      if (direction === 'left') {
        // Move back one button
        this.startIndex = Math.max(1, this.startIndex - 1);
      } else {
        // Move forward one button
        const maxStartIndex = this.totalQuestions - this.maxVisibleButtons + 1;
        this.startIndex = Math.min(maxStartIndex, this.startIndex + 1);
      }
    },
    // Ensure the current question is visible in the carousel
    ensureQuestionVisible(questionIndex) {
      // Convert to 1-based index for comparison
      const oneBasedIndex = questionIndex + 1;
      // Calculate the maximum start index
      const maxStartIndex = this.totalQuestions - this.maxVisibleButtons + 1;
      // Set the start index to show the current question
      this.startIndex = Math.min(
          Math.max(1, oneBasedIndex - Math.floor(this.maxVisibleButtons / 2)),
          maxStartIndex
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.question-tracker-buttons-carousel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  min-width: 310px;

  .question-tracker-label {
    color: #666;
    font-size: 0.8rem;
    font-weight: 500;
  }

  .carousel-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
    justify-content: flex-end;
  }

  .question-buttons-container {
    display: flex;
    flex: 1;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    min-height: 44px;
    max-width: calc(5 * 44px + 4 * 0.5rem); // Width of 5 buttons (44px each) plus gaps (0.5rem each)
  }

  .question-buttons-wrapper {
    display: flex;
    gap: 0.5rem;
    transition: transform 0.3s ease;
    position: absolute;
    left: 0;
    top: 0;
  }

  .question-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 44px; // Fixed width of 44px
    border-radius: 0.5rem;
    cursor: pointer;
    position: relative;
    font-weight: 600;
    transition: all 0.2s;
    background-color: #f8f8f8;
    border: 4px solid #dddddd;
    color: #a0a0a0;
    flex: 0 0 44px; // Prevent button from growing or shrinking

    i {
      font-size: 1.2rem;
    }

    // State styling
    &.correct {
      background-color: #6ad6b2;
      &:hover {
        border-color: #6ad6b2;
        border-color: #6ad6b2;
        color: white;
      }
      &.active {
        border-color: #359776;
        color: white;
      }
    }

    &.incorrect {
      background-color: #ff6b6b;
      &:hover {
        border-color: #ff6b6b;
        color: white;
      }

      &.active {
        border-color: #d14d4d;
        color: white;
      }
    }

    &.unanswered {
      &:hover {
        border-color: #a53fff;
        color: #a53fff;
      }

      &.active {
        background-color: #a53fff;
        border-color: #9a00ff;
        color: #fff;
      }
    }

    &:active {
      transform: translateY(2px);
    }
  }

  .carousel-nav-arrow {
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;

    i {
      font-size: 1.5rem;
      color: #ffb350; // Yellow/orange color for enabled state
      transition: all 0.2s;
    }

    &:hover:not(.inactive) i {
      color: #ffa726; // Slightly darker on hover
    }

    &:active:not(.inactive) {
      transform: translateY(2px);
    }

    &.inactive {
      cursor: not-allowed;
      i {
        color: #cccccc;
        opacity: 0.5;
      }
    }
  }
}

// Mobile responsiveness
@media only screen and (max-width: 767px) {
  .question-tracker-buttons-carousel {
    gap: 0.5rem;
    .question-buttons {
      gap: 0.25rem;
    }
    .question-tracker-label {
      display: none;
    }
    .question-button {
      font-size: 0.9rem;
      height: 32px;
    }
    .carousel-nav-arrow {
      height: 32px;
    }
    .question-buttons-container {
      min-height: 32px;
    }
  }
}

// Small mobile devices
@media only screen and (max-width: 480px) {
  .question-tracker-buttons-carousel {
    .maxVisibleButtons {
      max-visible-buttons: 5;
    }
  }
}
</style>
