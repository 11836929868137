<template>
    <button
      class="btn"
      :class="selectedClass"
      @click.prevent="selectChoice"
      :disabled="shouldDisableButton"
      >{{ choice }}</button>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

export default {
  name: 'QuizQuestionChoice',
  components: {
  },
  mixins: [],
  props: {
    choice: String,
    format: String,
    correctAnswer: String,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('quiz', ['choiceSelected', 'answeredStatus']),
    shouldDisableButton() {
      // specifically for speaking active_vocab qns - choice selected will always be '' even if child has already answered
      if (this.answeredStatus !== 'notAnswered') return true;
      return false;
    },
    selectedClass() {
      const toReturn = {
        'chinese-character': this.format === 'word',
        'hypy': this.format === 'pinyin',
      };
      if (this.answeredStatus === 'notAnswered') {
        toReturn['btn-selected'] = this.choiceSelected === this.choice;
        toReturn['btn-not-selected'] = this.choiceSelected !== this.choice;
      } else {
        toReturn['btn-correct'] = this.choice === this.correctAnswer;
        toReturn['btn-wrong'] = this.choiceSelected === this.choice && this.choice !== this.correctAnswer;
      }
      return toReturn;
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations('quiz', ['setChoiceSelected']),
    selectChoice() {
      this.setChoiceSelected(this.choice);
    },
  },
};
</script>

<style lang="scss" scoped>
  .btn {
    white-space: nowrap;
    width: 100%;
    padding: 15px 15px;
    border-radius: .5rem;
    border: 2px solid #d7d7d7;
    border-bottom: 4px solid #d7d7d7;
    font-size: 150%;
    font-weight: 600;
    background: #fff;
    color: #7e7e7e;
    cursor: pointer;
    &:active {
      background-color: #ffe6b4;
      color: #aa7928;
      border: 2px #edc968 solid;
      transform: translateY(2px);
    }
    &:disabled {
      cursor: auto;
      &:active {
        background-color: #fff;
        color: #7e7e7e;
        border: 2px solid #d7d7d7;
        border-bottom: 4px solid #d7d7d7;
        transform: none;
      }
    }
  }

  .btn-selected {
    background-color: #ffe6b4;
    color: #aa7928;
    border: 2px #edc968 solid;
    border-bottom: 4px #edc968 solid;
    &-active {
      border-bottom: 2px #edc968 solid;
      transform: translateY(2px);
    }
    &:disabled {
      &:active {
        background-color: #ffe6b4;
        color: #aa7928;
        border: 2px #edc968 solid;
        border-bottom: 4px #edc968 solid;
      }
    }
  }

  .btn-correct {
    background-color: #54d1b7;
    color: #fff;
    border: 2px #54d1b7 solid;
    border-bottom: 4px #57a292 solid;
  }

  .btn-wrong {
    background-color: #e26e7c;
    color: #fff;
    border: 2px #e26e7c solid;
    border-bottom: 4px #c75361 solid;
  }

  .btn-not-selected {
    &:hover {
      background: #f5f5f5;
    }
    &:active {
      background: #ffe6b4;
    }
    &:disabled:hover {
      background: none;
    }
  }
  @media only screen and (max-width: 699px) {
    .btn {
      padding: 10px;
    }
  }
  @media only screen and (max-height: 722px) {
    .btn {
      padding: 10px;
    }
  }

</style>
