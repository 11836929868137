<template>
  <div class="container">
    <!-- Game area (70%) -->
    <div class="game-area">
      <div class="grid">
        <div v-for="(row, rowIndex) in grid"
          :key="rowIndex"
          class="grid-row">
          <grid-cell v-for="(cell, colIndex) in row"
            :key="colIndex"
            :item-key="cell"
            :row="rowIndex"
            :col="colIndex"
            :is-selected="isCellSelected({row: rowIndex, col: colIndex})"
            @click="selectCell({row: rowIndex, col: colIndex})"
            @cell-dropped="handleCellDrop"
          />
        </div>
      </div>
    </div>

    <!-- Info panel (30%) -->
    <div class="info-panel"
         tabindex="0"
         @keydown="handleKeydown"
         ref="infoPanel">
      <h2 class="kingdom-name">Small Fishing Village</h2>
      <king-info-panel />
      <div class="currency">
        <span>
          <i class="fa fa-bolt"></i>
          Energy: {{ getResource('energy') }}
        </span>
        <br>
        <i class="fa fa-fence"></i>
        <span>Wood: {{ getResource('wood') }}</span>
        <br>

      </div>
      <br>

      <selected-cell-info-panel />

      <!-- Test Panel -->
      <div class="test-panel-container">
        <div v-if="showTestPanel" class="test-panel">
          <h3>Test Panel</h3>
          <button @click="addTestEnergy" class="test-button">
            Add 100 <i class="fa fa-bolt"></i>
          </button>
          <button @click="addTestWood" class="test-button">
            Add 50 <i class="fa fa-fence"></i>
          </button>
        </div>
      </div>

      <building-controls
        @showPurchaseBuildingModal="showPurchaseBuildingModal=true"
      />

      <!-- Delivery Panel -->
      <delivery-panel />
    </div>

    <!-- modals -->
    <purchase-building-modal
      :show="showPurchaseBuildingModal"
      @cancel="showPurchaseBuildingModal=false"
      @close="showPurchaseBuildingModal=false"
    />

    <!-- Energy gained popup -->
    <energy-gained-popup
      :show="showEnergyGainedPopup"
      @close="showEnergyGainedPopup = false"
    />

    <!-- Delivery Completion Modal -->
    <delivery-completion-modal
      v-if="deliveryCompletionData"
      :show="showDeliveryCompletionModal"
      :pet-name="deliveryCompletionData.petName"
      :pet-image-url="deliveryCompletionData.petImageUrl"
      :xp-gained="deliveryCompletionData.xpGained"
      :king-level-up="deliveryCompletionData.kingLevelUp"
      :king-level="deliveryCompletionData.kingLevel"
      @close="showDeliveryCompletionModal = false"
    />
  </div>
</template>

<script>
/* eslint-disable */
import {mapState, mapMutations, mapGetters, mapActions} from 'vuex';
import axios from 'axios';

import GridCellComponent from './components/GridCellComponent.vue';
import BuildingControlsComponent from './components/BuildingControlsComponent.vue';
import PurchaseBuildingModalComponent from './components/PurchaseBuildingModalComponent.vue';
import SelectedCellInfoPanelComponent from './components/SelectedCellInfoPanelComponent.vue';
import KingInfoPanelComponent from './components/KingInfoPanelComponent.vue';
import DeliveryPanelComponent from './components/DeliveryPanelComponent.vue';
import EnergyGainedPopup from './components/EnergyGainedPopup.vue';
import DeliveryCompletionModalComponent from './components/DeliveryCompletionModalComponent.vue';

export default {
  name: 'NewPlayKingdomMain',
  components: {
    'grid-cell': GridCellComponent,
    'purchase-building-modal': PurchaseBuildingModalComponent,
    'building-controls': BuildingControlsComponent,
    'selected-cell-info-panel': SelectedCellInfoPanelComponent,
    'king-info-panel': KingInfoPanelComponent,
    'delivery-panel': DeliveryPanelComponent,
    'energy-gained-popup': EnergyGainedPopup,
    'delivery-completion-modal': DeliveryCompletionModalComponent,
  },
  data() {
    return {
      showPurchaseBuildingModal: false,
      showTestPanel: false,
      showEnergyGainedPopup: false
    }
  },
  computed: {
    ...mapState('kingdom', ['grid', 'selectedCells', 'deliveryCompletionData']),
    ...mapGetters('kingdom', ['getResource', 'isCellSelected', 'selectedCellItemData']),
    showDeliveryCompletionModal: {
      // Getter: Controls modal visibility based on whether deliveryCompletionData exists
      // Returns true if deliveryCompletionData exists, false otherwise
      get() {
        return !!this.deliveryCompletionData;
      },
      // Setter: Called when showDeliveryCompletionModal is assigned a value
      // If the modal is being closed (value=false), clear the completion data
      set(value) {
        if (!value) {
          this.clearDeliveryCompletionData();
        }
      }
    },
  },
  created() {
    this.setShowGlobalLoadingScreen(true);
    this.pullData();
    
    // Listen for drag-cancelled events from grid cells
    this.$root.$on('drag-cancelled', this.handleDragCancelled);
  },
  mounted() {
    // Set focus to the info panel to enable keyboard shortcuts
    this.$refs.infoPanel.focus();
    
    // Note: We don't need to manually add/remove the keydown event listener
    // because Vue automatically handles binding and cleanup for template-declared
    // event handlers (like @keydown="handleKeydown" in our template)
  },
  beforeDestroy() {
    // Clean up event listeners
    this.$root.$off('drag-cancelled', this.handleDragCancelled);
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    ...mapMutations('kingdom', ['addResource', 'setSelectedCells', 'clearDeliveryCompletionData']),
    ...mapActions('kingdom', ['setData', 'selectCell', 'swapCells', 'mergeCells']),
    handleKeydown(event) {
      // Check if the key pressed is the slash key; if so, shows the test panel
      if (event.key === '/') {
        event.preventDefault();
        this.showTestPanel = !this.showTestPanel;
      }
    },
    async pullData() {
      try {
        const response = await axios.post('/minigames/kingdomgame/load/', {});
        console.log('Game data loaded:', response.data);
        this.setData(response.data);

        // checks if we have gained energy since last visit
        this.showEnergyGainedPopup = this.getResource('energy_gained_since_last_visit') > 0;
        
        this.setShowGlobalLoadingScreen(false);
        return response.data;
      } catch (error) {
        console.error('Error loading game data:', error);
        alert(error);
        this.setShowGlobalLoadingScreen(false);
        throw error;
      }
    },
    handleCellDrop({ sourcePosition, targetPosition, sourceItemKey }) {
      // First set the selected cells
      this.setSelectedCells([sourcePosition, targetPosition]);
      
      // Get target cell value
      const targetItemKey = this.grid[targetPosition.row][targetPosition.col];
      
      // Check if cells can be merged (same items)
      if (sourceItemKey && targetItemKey && sourceItemKey === targetItemKey) {
        // The items are identical - try to merge them
        this.mergeCells();
      } else {
        // Either one cell is empty or they're different items - swap them
        this.swapCells();
      }
    },
    addTestEnergy() {
      this.addResource({resourceName: 'energy', value: 100});
      axios.post('/minigames/kingdomgame/add_energy_test/', {amount: 100})
    },
    addTestWood() {
      this.addResource({resourceName: 'wood', value: 50});
      axios.post('/minigames/kingdomgame/add_wood_test/', {amount: 50})
    },
    // Handle when a drag is cancelled (item dropped outside valid grid)
    handleDragCancelled(dragData) {
      console.log('Drag cancelled, ensuring UI is consistent with data model', dragData);
      // Clear any selected cells to keep UI in sync with model
      this.setSelectedCells([]);
      
      // In case the grid state became inconsistent, re-pull data from backend
      // This is an optional safety measure if you find the grid sometimes
      // gets out of sync with the backend
      /*
      this.setShowGlobalLoadingScreen(true);
      this.pullData().then(() => {
        this.setShowGlobalLoadingScreen(false);
      });
      */
    },
  }
}
</script>

<style lang="scss" scoped>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

.container {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100vh;
}

.game-area {
  width: 70%;
  padding: 20px;
  background-color: #f5f5f5;
}

.info-panel {
  width: 30%;
  padding: 20px;
  background-color: #e0e0e0;
  border-left: 1px solid #ccc;
  overflow-y: hidden;
  max-height: 100vh;
  outline: none; /* Remove focus outline */

  .kingdom-name {
    margin-top: 0;
    margin-bottom: 20px;
    color: #333;
    font-size: 1.5em;
    text-align: center;
  }
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 600px;
  margin: 0 auto;
}

.grid-row {
  display: flex;
  gap: 4px;
}

.buy-item-button {
  padding: 10px 20px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #1976D2;
  }
}

.test-panel-container {
  margin-top: 20px;
}

.test-panel {
  padding: 10px;
  border: 1px dashed #999;
  border-radius: 4px;
}

.test-button {
  padding: 8px 16px;
  background-color: #FF5722;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #F4511E;
  }
}
</style>