<template>
  <div class="english-oral-practice">
    <div class="english-oral-practice--header">
      <button class="btn-back" @click="goBack">
        <i class="fa-solid fa-arrow-left"></i>
        Back to Questions
      </button>

      <div class="question-container">
        <div class="question-type-badge" v-if="question">{{ questionTypeLabel }}</div>
        <h2 v-if="question">{{ question.question_text }}</h2>
        <div v-if="loading" class="loading-indicator">Loading question...</div>
      </div>
    </div>

    <div class="english-oral-practice--content" v-if="question && !loading">
      <!-- Side-by-side layout for picture questions -->
      <div v-if="question.question_category === 'describe_picture' && question.image_url" class="picture-question-layout">
        <div class="question-panel">
          <div class="question-image-container">
            <img :src="question.image_url" alt="Question image" class="question-image" />
          </div>
          <div class="answer-method">
            <div>Answer using the PEEL structure</div>
            <button class="peel-help-button" @click="openPeelModal">
              <i class="fa-solid fa-circle-info"></i>
            </button>
          </div>
          <div class="helpful-vocabulary" @click="toggleVocabulary">
            <i class="fa-solid fa-book"></i>
            Helpful Vocabulary
          </div>
        </div>

        <div class="answer-panel">
          <!-- Answer tabs with new styling -->
          <div class="tab-container">
            <div class="answer-tabs">
              <div
                class="answer-tab"
                :class="{active: answerMode === 'form'}"
                @click="setAnswerMode('form')">
                Form Input
              </div>
              <div
                class="answer-tab"
                :class="{active: answerMode === 'audio'}"
                @click="setAnswerMode('audio')">
                Audio Recording
              </div>
            </div>
          </div>

          <!-- PEEL Form (Written Answer) -->
          <div v-if="answerMode === 'form'" class="peel-form-container animated fadeIn faster">
            <div class="peel-form">
              <div class="form-group">
                <label for="point">Point <span class="required">*</span> <span class="word-limit">(max 20 words)</span></label>
                <textarea
                  id="point"
                  v-model="peelResponse.point"
                  placeholder="State your main point that directly answers the question"
                  rows="2"></textarea>
              </div>
              <div class="form-group">
                <label for="explanation">Explanation <span class="required">*</span></label>
                <textarea
                  id="explanation"
                  v-model="peelResponse.explanation"
                  placeholder="Explain why you think this way"
                  rows="3"></textarea>
              </div>
              <div class="form-group">
                <label for="example">Example <span class="required">*</span></label>
                <textarea
                  id="example"
                  v-model="peelResponse.example"
                  placeholder="Give a specific example to support your explanation"
                  rows="3"></textarea>
              </div>
              <div class="form-group">
                <label for="link">Link <span class="required">*</span></label>
                <textarea
                  id="link"
                  v-model="peelResponse.link"
                  placeholder="Connect back to the question or extend to a broader context"
                  rows="2"></textarea>
              </div>
              <div class="submit-container">
                <button
                  class="btn-primary"
                  @click="submitPeelForm"
                  :disabled="!isPeelFormValid">
                  Submit Answer
                </button>
              </div>
            </div>
          </div>

          <!-- Audio Recording (Spoken Answer) -->
          <div v-if="answerMode === 'audio'" class="audio-recorder-container animated fadeIn faster">
            <div class="record-instructions">
              <h3>Record Your Answer</h3>
              <p>Click the microphone button below to start recording. A 3-second countdown will begin before recording starts.</p>
            </div>

            <div class="recorder-controls">
              <button
                class="record-button"
                :class="{ recording: isRecording }"
                @click="toggleRecording">
                <i class="fa-solid" :class="isRecording ? 'fa-stop' : 'fa-microphone'"></i>
              </button>

              <div class="timer-display" v-if="isRecording">
                {{ timerCount }} / {{ totalTime }}s
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Regular layout for non-picture questions with similar styling -->
      <div v-else class="standard-question-layout">
        <!-- Answer tabs with new styling -->
        <div class="tab-container">
          <div class="answer-tabs">
            <div
              class="answer-tab"
              :class="{active: answerMode === 'form'}"
              @click="setAnswerMode('form')">
              Form Input
            </div>
            <div
              class="answer-tab"
              :class="{active: answerMode === 'audio'}"
              @click="setAnswerMode('audio')">
              Audio Recording
            </div>
          </div>
        </div>

        <!-- PEEL Form (Written Answer) -->
        <div v-if="answerMode === 'form'" class="peel-form-container animated fadeIn faster">
          <div class="peel-form">
            <div class="form-group">
              <label for="point">Point <span class="required">*</span> <span class="word-limit">(max 20 words)</span></label>
              <textarea
                id="point"
                v-model="peelResponse.point"
                placeholder="State your main point that directly answers the question"
                rows="2"></textarea>
            </div>
            <div class="form-group">
              <label for="explanation">Explanation <span class="required">*</span></label>
              <textarea
                id="explanation"
                v-model="peelResponse.explanation"
                placeholder="Explain why you think this way"
                rows="3"></textarea>
            </div>
            <div class="form-group">
              <label for="example">Example <span class="required">*</span></label>
              <textarea
                id="example"
                v-model="peelResponse.example"
                placeholder="Give a specific example to support your explanation"
                rows="3"></textarea>
            </div>
            <div class="form-group">
              <label for="link">Link <span class="required">*</span></label>
              <textarea
                id="link"
                v-model="peelResponse.link"
                placeholder="Connect back to the question or extend to a broader context"
                rows="2"></textarea>
            </div>
            <div class="submit-container">
              <button
                class="btn-primary"
                @click="submitPeelForm"
                :disabled="!isPeelFormValid">
                Submit Answer
              </button>
            </div>
          </div>
        </div>

        <!-- Audio Recording (Spoken Answer) -->
        <div v-if="answerMode === 'audio'" class="audio-recorder-container animated fadeIn faster">
          <div class="record-instructions">
            <h3>Record Your Answer</h3>
            <p>Click the microphone button below to start recording. A 3-second countdown will begin before recording starts.</p>
          </div>

          <div class="recorder-controls">
            <button
              class="record-button"
              :class="{ recording: isRecording }"
              @click="toggleRecording">
              <i class="fa-solid" :class="isRecording ? 'fa-stop' : 'fa-microphone'"></i>
            </button>

            <div class="timer-display" v-if="isRecording">
              {{ timerCount }} / {{ totalTime }}s
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading state -->
    <div v-if="loading && !question" class="loading-container">
      <div class="loading-spinner"></div>
      <p>Loading question data...</p>
    </div>

    <!-- Processing state -->
    <div v-if="processingSubmission" class="processing-container">
      <div class="loading-spinner"></div>
      <p>{{ processingMessage }}</p>
    </div>

    <!-- PEEL Modal -->
    <div v-if="isPeelModalOpen" class="peel-modal">
      <div class="peel-modal-content">
        <div class="peel-modal-header">
          <h3>PEEL Structure</h3>
          <button class="close-btn" @click="closePeelModal">
            <i class="fa-solid fa-times"></i>
          </button>
        </div>
        <div class="peel-modal-body">
          <div class="peel-explanation-item">
            <div class="peel-letter">P</div>
            <div class="peel-explanation">
              <h4>Point</h4>
              <p>Make your main point or argument clearly. This should directly address the question.</p>
            </div>
          </div>

          <div class="peel-explanation-item">
            <div class="peel-letter">E</div>
            <div class="peel-explanation">
              <h4>Explanation</h4>
              <p>Explain or elaborate on your point. This helps the listener understand your reasoning.</p>
            </div>
          </div>

          <div class="peel-explanation-item">
            <div class="peel-letter">E</div>
            <div class="peel-explanation">
              <h4>Example</h4>
              <p>Provide specific examples, evidence, or experiences that support your point.</p>
            </div>
          </div>

          <div class="peel-explanation-item">
            <div class="peel-letter">L</div>
            <div class="peel-explanation">
              <h4>Link</h4>
              <p>Link back to the question or your main argument to show how your response directly answers the question.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Vocabulary Modal -->
    <div v-if="showVocabulary" class="vocabulary-modal">
      <div class="vocabulary-modal-content">
        <div class="vocabulary-modal-header">
          <div class="title-group">
            <h3>Helpful Vocabulary & Quotations</h3>
            <p class="subtitle">Use these words and phrases to enhance your response</p>
          </div>
          <button class="close-btn" @click="toggleVocabulary">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="vocabulary-tabs">
          <div
            class="vocabulary-tab"
            :class="{active: vocabTab === 'general'}"
            @click="vocabTab = 'general'">
            General Vocabulary
          </div>
          <div
            class="vocabulary-tab"
            :class="{active: vocabTab === 'quotations'}"
            @click="vocabTab = 'quotations'">
            Quotations
          </div>
        </div>

        <!-- General Vocabulary Tab Content -->
        <div v-if="vocabTab === 'general'" class="vocabulary-content">
          <div class="vocabulary-section" v-if="basicVocabulary.length > 0">
            <h4 class="vocabulary-section-title">Basic Vocabulary</h4>
            <div class="vocabulary-chips">
              <div class="vocabulary-chip" v-for="(word, index) in basicVocabulary" :key="'basic-' + index">{{ word }}</div>
            </div>
          </div>

          <div class="vocabulary-section" v-if="intermediateVocabulary.length > 0">
            <h4 class="vocabulary-section-title">Intermediate Vocabulary</h4>
            <div class="vocabulary-chips">
              <div class="vocabulary-chip" v-for="(word, index) in intermediateVocabulary" :key="'intermediate-' + index">{{ word }}</div>
            </div>
          </div>

          <div class="vocabulary-section" v-if="advancedVocabulary.length > 0">
            <h4 class="vocabulary-section-title">Advanced Vocabulary</h4>
            <div class="vocabulary-chips">
              <div class="vocabulary-chip" v-for="(word, index) in advancedVocabulary" :key="'advanced-' + index">{{ word }}</div>
            </div>
          </div>

          <div class="vocabulary-section" v-if="!hasVocabulary">
            <p class="no-vocab-message">No vocabulary available for this topic.</p>
          </div>
        </div>

        <!-- Quotations Tab Content -->
        <div v-if="vocabTab === 'quotations'" class="vocabulary-content">
          <div class="vocabulary-section" v-if="basicQuotations.length > 0">
            <h4 class="vocabulary-section-title">Basic Quotations</h4>
            <div class="quotation-item" v-for="(quote, index) in basicQuotations" :key="'basic-quote-' + index">
              <div class="quotation-text">"{{ quote.text || quote }}"</div>
              <div class="quotation-usage" v-if="quote.usage">Usage: {{ quote.usage }}</div>
            </div>
          </div>

          <div class="vocabulary-section" v-if="intermediateQuotations.length > 0">
            <h4 class="vocabulary-section-title">Intermediate Quotations</h4>
            <div class="quotation-item" v-for="(quote, index) in intermediateQuotations" :key="'intermediate-quote-' + index">
              <div class="quotation-text">"{{ quote.text || quote }}"</div>
              <div class="quotation-usage" v-if="quote.usage">Usage: {{ quote.usage }}</div>
            </div>
          </div>

          <div class="vocabulary-section" v-if="advancedQuotations.length > 0">
            <h4 class="vocabulary-section-title">Advanced Quotations</h4>
            <div class="quotation-item" v-for="(quote, index) in advancedQuotations" :key="'advanced-quote-' + index">
              <div class="quotation-text">"{{ quote.text || quote }}"</div>
              <div class="quotation-usage" v-if="quote.usage">Usage: {{ quote.usage }}</div>
            </div>
          </div>

          <div class="vocabulary-section" v-if="!hasQuotations">
            <p class="no-vocab-message">No quotations available for this topic.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {mapState} from 'vuex';

export default {
  name: 'EnglishAiPracticeComponent',
  props: {
    questionId: {
      type: String,
      required: true,
    },
    questionType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      loading: true,
      processingSubmission: false,
      processingMessage: '',
      question: null,
      topic: null,
      answerMode: 'form', // 'form' or 'audio'
      peelResponse: {
        point: 'I like doing household chores at home to lend a helping hand to my parents with their weekly cleaning.',
        explanation: 'I would wipe clean or dust areas that are either dirty or dusty in my room or around the house. We are all in charge of cleaning our own rooms and we take turns to clean the common areas as well.  Why: Lending a helping hand to my parents provides me a sense of accomplishment. Doing chores also makes my parents feel that they can always count on me when it comes to household matters. I beam with pride whenever they praise me for doing a fantastic job with the cleaning.',
        example: 'Some of the household duties that I enjoy doing would be: I enjoy vacuuming my bedroom and the living room as it is a relatively easy task and I enjoy seeing my room spick and span after the cleaning session! We also make it a point to only use cleaning products that are environmentally friendly to do our part for the environment.',
        link: 'These are reasons why I am happy to lend a helping hand to my parents with household chores/duties.',
      },
      isPeelModalOpen: false,
      isRecording: false,
      timerCount: 0,
      totalTime: 120,
      recorder: null,
      gumStream: null,
      timerInterval: null,
      sessionId: null,
      startPollingTime: null,
      showVocabulary: false,
      vocabTab: 'general', // 'general' or 'quotations'
    };
  },
  computed: {
    ...mapState('guidedReading', ['freeResponseTopics']),
    questionTypeLabel() {
      if (!this.question) return '';
      
      const labels = {
        'describe_picture': 'Picture-based Question',
        'personal_experience': 'Personal Experience',
        'suggestion': 'Opinion & Suggestion',
      };
      return labels[this.question.question_category] || this.question.question_category;
    },
    isPeelFormValid() {
      return this.peelResponse.point.trim() !== '' &&
        this.peelResponse.explanation.trim() !== '' &&
        this.peelResponse.example.trim() !== '' &&
        this.peelResponse.link.trim() !== '';
    },
    hasVocabulary() {
      return this.basicVocabulary.length > 0 || 
             this.intermediateVocabulary.length > 0 || 
             this.advancedVocabulary.length > 0;
    },
    hasQuotations() {
      return this.basicQuotations.length > 0 || 
             this.intermediateQuotations.length > 0 || 
             this.advancedQuotations.length > 0;
    },
    topicVocabulary() {
      if (!this.topic || !this.topic.reference_vocab) return null;
      return this.topic.reference_vocab;
    },
    basicVocabulary() {
      if (!this.topicVocabulary || !this.topicVocabulary.general || !this.topicVocabulary.general.basic) {
        return [];
      }
      return this.topicVocabulary.general.basic;
    },
    intermediateVocabulary() {
      if (!this.topicVocabulary || !this.topicVocabulary.general || !this.topicVocabulary.general.intermediate) {
        return [];
      }
      return this.topicVocabulary.general.intermediate;
    },
    advancedVocabulary() {
      if (!this.topicVocabulary || !this.topicVocabulary.general || !this.topicVocabulary.general.advanced) {
        return [];
      }
      return this.topicVocabulary.general.advanced;
    },
    basicQuotations() {
      if (!this.topicVocabulary || !this.topicVocabulary.quotations || !this.topicVocabulary.quotations.basic) {
        return [];
      }
      return this.topicVocabulary.quotations.basic;
    },
    intermediateQuotations() {
      if (!this.topicVocabulary || !this.topicVocabulary.quotations || !this.topicVocabulary.quotations.intermediate) {
        return [];
      }
      return this.topicVocabulary.quotations.intermediate;
    },
    advancedQuotations() {
      if (!this.topicVocabulary || !this.topicVocabulary.quotations || !this.topicVocabulary.quotations.advanced) {
        return [];
      }
      return this.topicVocabulary.quotations.advanced;
    },
  },
  mounted() {
    this.fetchQuestionData();
  },
  beforeDestroy() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  },
  methods: {
    fetchQuestionData() {
      this.loading = true;
      
      // Check if we have the question data in Vuex by searching through all topics
      if (this.freeResponseTopics && this.freeResponseTopics.length > 0) {
        const questionId = parseInt(this.questionId);
        let foundQuestion = null;
        
        // Loop through all topics
        for (const topic of this.freeResponseTopics) {
          if (topic.questions && topic.questions.length > 0) {
            // Look for the question with matching ID in this topic
            const question = topic.questions.find((q) => q.id === questionId);
            if (question) {
              foundQuestion = question;
              this.topic = topic;
              break;
            }
          }
        }

        if (foundQuestion) {
          this.question = foundQuestion;
          this.loading = false;
        } else {
          this.loading = false;
          this.$router.push({ name: 'new-oral-english-ai-practice-index' });
        }
      } else {
        this.loading = false;
        this.$router.push({ name: 'new-oral-english-ai-practice-index' });
      }
    },
    goBack() {
      // Navigate back to question selection
      this.$router.push({
        name: 'new-oral-english-ai-practice-index'
      });
    },
    setAnswerMode(mode) {
      this.answerMode = mode;
    },
    openPeelModal() {
      this.isPeelModalOpen = true;
    },
    closePeelModal() {
      this.isPeelModalOpen = false;
    },
    toggleVocabulary() {
      this.showVocabulary = !this.showVocabulary;
    },
    submitPeelForm() {
      if (!this.isPeelFormValid) return;
      
      this.processingSubmission = true;
      this.processingMessage = 'Processing your answer...';
      
      // Combine PEEL structure into a single formatted text answer
      const combinedAnswer = `
${this.peelResponse.point.trim()}

${this.peelResponse.explanation.trim()}

${this.peelResponse.example.trim()}

${this.peelResponse.link.trim()}
      `.trim();
      
      // Prepare form data for submission
      const formData = new FormData();
      formData.append('questionId', this.questionId);
      formData.append('transcription', combinedAnswer);
      formData.append('subject', 'english');
      formData.append('duration', '0'); // No duration for written answers
      formData.append('answerMethod', this.question.answer_methods[0]);
      
      // API endpoint
      const _url = '/api/v1/brain/aipracticesession/';
      
      // Send data to backend
      axios
        .post(_url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.status === 400) {
            this.processingSubmission = false;
            alert('Error submitting your answer. Please try again.');
            return;
          }
          
          console.log(response.data);
          // Navigate to feedback page
          this.$router.push({
            name: 'new-oral-english-ai-practice-feedback',
            params: {sessionId: response.data.session_id},
          });
          // this.startPollingTime = new Date();
          
          // // Start polling for results
          // this.processingMessage = 'Generating feedback...';
          // setTimeout(() => {
          //   this.pollBackendForResults();
          // }, 3000); // Start polling after 3 seconds
        })
        .catch((err) => {
          console.error('Error submitting answer:', err);
          this.processingSubmission = false;
          alert('Failed to submit your answer. Please try again later.');
        });
    },
    
    pollBackendForResults() {
      // Check if we've been polling too long (over 2 minutes)
      const currentTime = new Date();
      const pollingDuration = (currentTime - this.startPollingTime) / 1000;
      
      if (pollingDuration > 120) {
        this.processingSubmission = false;
        alert('Taking too long to process your answer. Please try again later.');
        return;
      }
      
      const _url = `/api/v1/brain/aipracticesession/`;

      // Polling logic
      axios
        .get(`${_url}${this.sessionId}/`)
        .then((response) => {
          if (response.data.completed) {
            // Processing is complete
            this.processingSubmission = false;
            
            if (response.data.is_error) {
              // Handle error case
              alert(response.data.message || 'Error processing your answer. Please try again.');
              return;
            }

            // Navigate to feedback page
            this.$router.push({
              name: 'new-oral-english-ai-practice-feedback',
              params: {sessionId: this.sessionId},
            });
          }
          // else {
          //   // Continue polling
          //   setTimeout(() => {
          //     this.pollBackendForResults();
          //   }, 3000);
          // }
        })
        .catch((err) => {
          console.error('Error polling for results:', err);
          this.processingSubmission = false;
          alert('Failed to retrieve your answer feedback. Please try again later.');
        });
    },
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    startRecording() {
      // Recording implementation will be added
      this.isRecording = true;
      this.timerCount = 0;
      // Start timer
      this.timerInterval = setInterval(() => {
        this.timerCount++;
        if (this.timerCount >= this.totalTime) {
          this.stopRecording();
        }
      }, 1000);
      
      // TODO: Implement actual recording logic
      console.log('Recording started');
    },
    stopRecording() {
      // Stop recording implementation
      this.isRecording = false;
      clearInterval(this.timerInterval);
      
      this.processingSubmission = true;
      this.processingMessage = 'Processing your recording...';
      
      // Simulate transcription and feedback generation
      setTimeout(() => {
        this.processingMessage = 'Generating feedback...';
        
        // After another delay, navigate to the feedback view
        setTimeout(() => {
          this.processingSubmission = false;
          
          // In a real implementation, you would likely navigate to a feedback page
          alert('Recording processed successfully! In a real implementation, this would navigate to a feedback page.');
          
          // For now, we'll just go back to the question selection
          this.goBack();
        }, 2000);
      }, 2000);
    },
    cancelRecording() {
      this.isRecording = false;
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }
      
      // TODO: Clean up recording resources
      console.log('Recording cancelled');
    },
  },
};
</script>

<style lang="scss" scoped>
.english-oral-practice {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: #f8f9fa;
  z-index: 9999;
  overflow-y: auto;
  overflow-x: hidden;
  
  &--header {
    background-color: #fff;
    padding: 1.5rem 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    
    .question-container {
      max-width: 1200px;
      margin: 0 auto;
      width: 100%;
      
      h2 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #333;
        margin: 0.5rem 0;
        line-height: 1.4;
      }
    }
    
    .question-type-badge {
      display: inline-block;
      padding: 0.3rem 0.75rem;
      background-color: #edf2ff;
      color: #4263eb;
      border-radius: 0.75rem;
      font-size: 0.85rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
  }
  
  &--content {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }
}

// Picture question layout
.picture-question-layout {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 2rem;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
  
  .question-panel {
    background-color: #fff;
    border-radius: 0.75rem;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    
    .question-image-container {
      width: 100%;
      text-align: center;
      padding: 1rem;
      border-bottom: 1px solid #eee;
      
      img {
        max-width: 100%;
        border-radius: 0.5rem;
      }
    }
    
    .answer-method {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      font-weight: 500;
      border-bottom: 1px solid #eee;
      
      .peel-help-button {
        background: none;
        border: none;
        color: #4263eb;
        cursor: pointer;
        
        i {
          font-size: 1.25rem;
        }
      }
    }
    
    .helpful-vocabulary {
      padding: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #4263eb;
      font-weight: 500;
      cursor: pointer;
      
      &:hover {
        background-color: #f8f9fa;
      }
    }
  }
  
  .answer-panel {
    background-color: #fff;
    border-radius: 0.75rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }
}

.standard-question-layout {
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

// Tab styling to match design
.tab-container {
  width: 100%;
  overflow: hidden;
  margin-bottom: -1px; /* This ensures the bottom border overlaps correctly */
}

.answer-tabs {
  display: flex;
  width: 100%;
  border: 1px solid #dee2e6;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  
  .answer-tab {
    padding: 1rem;
    font-weight: 500;
    cursor: pointer;
    color: #6c757d;
    position: relative;
    text-align: center;
    flex: 1;
    user-select: none;
    
    &.active {
      color: #000;
      font-weight: 600;
      background-color: #fff;
      border-bottom: 2px solid #4263eb;
      z-index: 1;
    }
    
    &:not(.active) {
      background-color: #f8f9fa;
    }
    
    &:first-child {
      border-top-left-radius: 0.375rem;
    }
    
    &:last-child {
      border-top-right-radius: 0.375rem;
    }
    
    &:not(:last-child) {
      border-right: 1px solid #dee2e6;
    }
  }
}

// Form styling
.peel-form-container {
  padding: 2rem;
  border: 1px solid #dee2e6;
  border-top: none;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-color: #fff;
  
  .peel-form {
    max-width: 800px;
    margin: 0 auto;
    
    .form-group {
      margin-bottom: 1.5rem;
      
      label {
        display: block;
        font-weight: 600;
        color: #495057;
        margin-bottom: 0.5rem;
        
        .required {
          color: #e03131;
        }
        
        .word-limit {
          font-weight: normal;
          color: #868e96;
          font-size: 0.85rem;
        }
      }
      
      textarea {
        width: 100%;
        padding: 0.75rem 1rem;
        border: 1px solid #dee2e6;
        border-radius: 0.5rem;
        resize: vertical;
        font-size: 1rem;
        font-family: 'Inter', sans-serif;
        
        &:focus {
          outline: none;
          border-color: #4263eb;
          box-shadow: 0 0 0 3px rgba(66, 99, 235, 0.1);
        }
        
        &::placeholder {
          color: #adb5bd;
        }
      }
    }
    
    .submit-container {
      text-align: right;
      margin-top: 2rem;
    }
  }
}

// Audio recorder styling
.audio-recorder-container {
  padding: 3rem 2rem;
  text-align: center;
  border: 1px solid #dee2e6;
  border-top: none;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-color: #fff;
  
  .record-instructions {
    max-width: 500px;
    margin: 0 auto 3rem;
    
    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }
    
    p {
      color: #6c757d;
      line-height: 1.5;
    }
  }
  
  .recorder-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    
    .record-button {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      border: none;
      background-color: #4263eb;
      color: white;
      font-size: 1.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 4px 6px rgba(66, 99, 235, 0.2);
      transition: all 0.2s;
      
      &:hover {
        background-color: #3b5bdb;
        transform: scale(1.05);
      }
      
      &.recording {
        background-color: #fa5252;
        animation: pulse 1.5s infinite;
      }
    }
    
    .timer-display {
      font-size: 1.2rem;
      font-weight: 600;
      color: #495057;
    }
  }
}

// Button styling
.btn-back {
  background: none;
  border: none;
  color: #495057;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0;
  margin-bottom: 1rem;
  font-weight: 500;
  cursor: pointer;
  
  &:hover {
    color: #212529;
  }
}

.btn-primary {
  background-color: #4263eb;
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background-color: #3b5bdb;
  }
  
  &:disabled {
    background-color: #adb5bd;
    cursor: not-allowed;
  }
}

.btn-secondary {
  background-color: #f1f3f5;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background-color: #e9ecef;
  }
}

// Loading and processing states
.loading-container,
.processing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  text-align: center;
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 3rem;
  
  p {
    color: #495057;
    margin-top: 1rem;
  }
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(66, 99, 235, 0.1);
  border-radius: 50%;
  border-top-color: #4263eb;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(250, 82, 82, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(250, 82, 82, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(250, 82, 82, 0);
  }
}

// PEEL Modal
.peel-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.peel-modal-content {
  background-color: white;
  border-radius: 0.75rem;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.peel-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid #e9ecef;
  
  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 1.25rem;
    color: #6c757d;
    cursor: pointer;
    
    &:hover {
      color: #343a40;
    }
  }
}

.peel-modal-body {
  padding: 1.5rem;
}

.peel-explanation-item {
  display: flex;
  margin-bottom: 1.5rem;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  .peel-letter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background-color: #4263eb;
    color: white;
    border-radius: 50%;
    font-weight: bold;
    font-size: 1.25rem;
    margin-right: 1rem;
    flex-shrink: 0;
  }
  
  .peel-explanation {
    h4 {
      font-size: 1.125rem;
      font-weight: 600;
      margin: 0 0 0.5rem 0;
    }
    
    p {
      margin: 0;
      color: #495057;
      line-height: 1.5;
    }
  }
}

// Animation classes
.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.fadeIn {
  animation-name: fadeIn;
}

.faster {
  animation-duration: 0.3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Vocabulary Modal
.vocabulary-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.vocabulary-modal-content {
  background-color: white;
  border-radius: 0.75rem;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.vocabulary-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
  
  .title-group {
    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0;
      color: #155eef;
      line-height: 1.3;
    }
    
    .subtitle {
      margin: 0.25rem 0 0;
      color: #6c757d;
      font-size: 0.9rem;
      font-weight: normal;
    }
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 1.25rem;
    color: #98a2b3;
    cursor: pointer;
    margin-top: 0.25rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.2s ease;
    
    i {
      font-size: 1.25rem;
    }
    
    &:hover {
      background-color: #f1f5f9;
      color: #101828;
    }
  }
}

.vocabulary-tabs {
  display: flex;
  border: 1px solid #d0d5dd;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  overflow: hidden;
  background-color: #f9fafb;
  
  .vocabulary-tab {
    flex: 1;
    padding: 0.75rem;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    user-select: none;
    color: #667085;
    
    &.active {
      background-color: white;
      color: #101828;
      font-weight: 600;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    }
  }
}

.vocabulary-content {
  background-color: #f5f8ff;
  border-radius: 0.75rem;
  padding: 1.5rem;
}

.vocabulary-section-title {
  font-size: 1.05rem;
  font-weight: 600;
  color: #155eef;
  margin: 0.75rem 0 1rem;
}

.vocabulary-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  
  .vocabulary-chip {
    background-color: white;
    border: 1px solid #e4e7ec;
    border-radius: 2rem;
    padding: 0.5rem 1.25rem;
    font-size: 0.95rem;
    color: #155eef;
    cursor: pointer;
    
    &:hover {
      background-color: #edf2ff;
      border-color: #d1e0ff;
    }
  }
}

.quotation-item {
  background-color: white;
  border: 1px solid #e4e7ec;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  
  .quotation-text {
    color: #155eef;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .quotation-usage {
    color: #667085;
    font-size: 0.9rem;
  }
}

.no-vocab-message {
  text-align: center;
  color: #667085;
  padding: 1.5rem;
  font-style: italic;
}
</style>
