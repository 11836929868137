<template>
  <div class="multiplayer-room__teaching--component">
    <button
      class="multiplayer-room__teaching--next-btn"
      @click="nextQuestionCallback">
      <span v-if="subject === 'malay'">Soalan seterusnya.</span>
      <span v-else>Next Question</span>
      <i class="fas fa-chevron-right"></i>
    </button>
    <div id="question"
      class="multiplayer-room__teaching--container">
      <div class="chinese-character multiplayer-room__teaching--text
        animated bounceIn"
        v-html="questionText"></div>
    </div>
    <div>
    </div>
    <div class="multiplayer-room__teaching--options-container">
      <ul v-if="shuffledChoices && shuffledChoices.length > 0"
        class="multiplayer-room__teaching--options">
        <li
          v-for="(choice, index) in shuffledChoices"
          :key="index"
          :class="[questionClass, getChoiceClass(choice)]"
          class="multiplayer-room__teaching--btn animated bounceIn faster">
            {{ choice }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

import {mapState} from 'vuex';
import {utilities} from '../../../mixins/utilities';

export default {
  name: 'MultiplayerRoomQuestion',
  components: {
  },
  props: {
    questionData: Object,
    correctAnswer: String,
    studentAnswer: String,
    nextQuestionCallback: Function,
  },
  mixins: [utilities],
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['subject']),
    shuffledChoices() {
      if (!this.questionData) return;

      return this.shuffleArray(this.questionData.choices);
    },
    questionText() {
      if (!this.questionData || this.questionData === {}) return;
      const text = this.hyrateQuestionText(this.questionData.text,
          this.questionData.choices);
      return (text + '\n');
    },
    questionClass() {
      // validation
      if (!this.questionData || this.questionData === {}) return;
      if (!this.questionData.answer_type ||
        this.questionData.answer_type === '') return;

      return this.questionData.answerType === 'hypy' ?
        'hypy' : 'chinese-character';
    },
  },
  mounted() {
  },
  methods: {
    getChoiceClass(choice) {
      if (choice === this.correctAnswer) return 'correct';
      if (choice === this.studentAnswer) return 'wrong';
      return '';
    },
    // only allow answering of questions
    // after timer has expired
    enableAnswering() {
      this.canAnswer = true;
    },
    // syntax for question text
    // {__} means to fill with underline
    // {x} means to fill in the word
    hyrateQuestionText: function(rawText) {
      const questionText = rawText;
      return questionText.replace(/{.+?}/gi, function(entry) {
        const result = entry.substr(1, entry.length - 2);

        if (result === '__') {
          return '______';
        } else {
          return '<u>' + result +'</u>';
        };
      });
    },
    _answerQuestion(choice) {
      if (!this.canAnswer) return;
      console.log('can answer');
      // calls the answerQuestion callback
      this.answerQuestion(choice);
    },
  },
};
</script>

<style lang="scss" scoped>
  .greyed {
    opacity: 0.3;
  }

  .multiplayer-room__teaching {
    &--next-btn {
      font-family: 'baloo da 2';
      cursor: pointer;
      position: absolute;
      width: 100%;
      bottom: 0;
      z-index: 30;
      height: 61px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 800;
      background: #008cde;
      color: #fff;
      i {
        margin-left: 0.5rem;
      }
    }
    &--component {
      height: calc(100% - 122px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      background: #e8e8e8;
    }
    &--container {
      height: 50%;
      position: relative;
      text-align: center;
      margin: 0 20px;
    }
    &--index {
      position: absolute;
      top: 20px;
      width: 100%;
      font-size: 1.2rem;
      font-weight: 900;
      span {
        background: #8d40b5;
        padding: 2.5px 55px;
        color: #fff;
        border-radius: 20px;
      }
    }
    &--text {
      font-size: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    &--options {
      transition: all 0.3s;
      height: 100%;
      // width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 5px;
      &-container {
        height: calc(50% - 10px);
        width: calc(100% - 10px);
        position: relative;
      }
    }
    &--timer {
      left: -50%;
      &-container {
        position: absolute;
        left: 50%;
        top: 25%;
        animation-delay: 0.3s;
      }

    }
    &--btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(50% - 10px);
      height: calc(50% - 10px);
      margin: 5px;
      font-size: 2.25rem;
      color: #fff;
      border-radius: 3px;
      transition: all 0.25s;
      opacity: 0.7;
      background: #cecece;
      color: #dadada;
      &:hover {
        opacity: 1;
      }
      &:nth-child(4) {
        animation-delay: 0.225s;
      }
      &:nth-child(3) {
        animation-delay: 0.2s;
      }
      &:nth-child(2) {
        animation-delay: 0.175s;
      }
      &:nth-child(1) {
        animation-delay: 0.15s;
      }
    }
  }

  .correct {
    background: #14d49f !important;
    color: #fff;
  }

  .wrong {
    background: #fd6969 !important;
    color: #fff;
    opacity: 0.7;
  }
</style>
