<template>
  <block-container
    :title="title"
    subtitle="Oral Practice"
    fa-icon="comments"
    >
    <div class="oral-index__container">
      <filter-buttons class="filter-btns" :options="filterOptions" @select="select" :selected="selectedCategory"></filter-buttons>
      <div class="oral-index__container--item-grp">
        <router-link
        v-if="itemsToShow.indexOf('oralTextbookChapter') > -1"
        :to="{name: 'new-oral-category-index',
        params: {category: 'oralTextbookChapter'}}"
        class="oral-index__container--item">
        <div class="user-settings-content">
          <div class="oral-index__container--item-icon">
            <i class="fas fa-book-open icon-main"></i>
          </div>
          <div class="oral-index__container--text">
            <span class="oral-index__container--text-title">
              Texbook Sentences
            </span>
            <br>
          </div>
        </div>
        </router-link>
        <router-link
          v-if="itemsToShow.indexOf('oralPassage') > -1"
            :to="{name: 'new-oral-category-index',
            params: {category: 'readaloud'}}"
          class="oral-index__container--item">
          <div class="user-settings-content">
            <div class="oral-index__container--item-icon">
              <i class="fas fa-newspaper icon-main"></i>
            </div>
            <div class="oral-index__container--text">
              <span class="oral-index__container--text-title">
                Passages
              </span>
              <br>
            </div>
          </div>
        </router-link>
        <router-link
          v-if="itemsToShow.indexOf('aiPractise') > -1"
            :to="{name: 'new-oral-category-index',
              params: {category: 'aiPractise'}}"
          class="oral-index__container--item">
          <div class="user-settings-content">
            <div class="oral-index__container--item-icon">
              <i class="fas fa-comments icon-main"></i>
            </div>
            <div class="oral-index__container--text">
              <span class="oral-index__container--text-title">
                Mock Exam Practice
              </span>
              <br>
            </div>
          </div>
          <span class="oral-index__container--item__label">Premium</span>
        </router-link>
        <router-link
          v-if="itemsToShow.indexOf('aiVoicebot') > -1"
            :to="{name: 'new-oral-voicebot-index'}"
          class="oral-index__container--item">
          <div class="user-settings-content">
            <div class="oral-index__container--item-icon">
              <i class="fas fa-comments icon-main"></i>
            </div>
            <div class="oral-index__container--text">
              <span class="oral-index__container--text-title">
                Voicebot
              </span>
              <br>
            </div>
          </div>
        </router-link>
        <router-link
          v-if="itemsToShow.indexOf('freeResponse') > -1"
            :to="{name: 'new-oral-category-index',
            params: {category: 'freeResponse'}}"
          class="oral-index__container--item">
          <div class="user-settings-content">
            <div class="oral-index__container--item-icon">
              <i class="fas fa-comments icon-main"></i>
            </div>
            <div class="oral-index__container--text">
              <span class="oral-index__container--text-title">
                Model Answers
              </span>
              <br>
            </div>
          </div>
        </router-link>
        <router-link
          v-if="itemsToShow.indexOf('activeVocab') > -1"
          :to="{name: 'new-oral-category-index',
          params: {category: 'activeVocab'}}"
          class="oral-index__container--item">
          <div class="user-settings-content">
            <div class="oral-index__container--item-icon">
              <i class="fas fa-book icon-main"></i>
            </div>
            <div class="oral-index__container--text">
              <span class="oral-index__container--text-title">
                Active Vocabulary
              </span>
              <br>
            </div>
          </div>
        </router-link>
        <router-link
          v-if="itemsToShow.indexOf('templatedPhrases') > -1"
            :to="{name: 'new-oral-category-index',
            params: {category: 'templatedPhrases'}}"
            class="oral-index__container--item">
          <div class="user-settings-content">
            <div class="oral-index__container--item-icon">
              <i class="fas fa-thumbs-up icon-main"></i>
            </div>
            <div class="oral-index__container--text">
              <span class="oral-index__container--text-title">
                Templates
              </span>
              <br>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </block-container>
</template>

<script>

import FilterButtonsComponent
  from '../common/FilterButtonsComponent.vue';

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';

import {mapState, mapGetters} from 'vuex';

export default {
  name: 'NewOralOverview',
  components: {
    FilterButtons: FilterButtonsComponent,
    BlockContainer: BlockContainerComponent,
  },
  props: {
  },
  data() {
    return {
      selectedCategory: 'readaloud',
    };
  },
  computed: {
    ...mapState(['level', 'studentType']),
    ...mapGetters(['chineseLevel', 'isPrimarySchool']),
    filterOptions() {
      if (this.studentType === 'attending') {
        return [
          {
            label: 'Read Aloud',
            value: 'readaloud',
          },
          {
            label: 'Video Conversation',
            value: 'video_conversation',
          },
        ];
      }
      return [
        {
          label: 'Read Aloud',
          value: 'readaloud',
        },
        {
          label: 'eOral',
          value: 'video_conversation',
        },
        {
          label: 'Voicebot',
          value: 'voicebot',
        },
      ];
    },
    title() {
      return `口试练习 (${this.chineseLevel})`;
    },
    // different levels have different things to show
    itemsToShow() {
      const items = [];

      if (this.selectedCategory === 'readaloud') {
        if (this.isPrimarySchool) {
          items.push('oralTextbookChapter');
        }
        if (this.level === 'primary_two' || this.level === 'primary_three' || this.level === 'primary_four' || this.level === 'primary_five' || this.level === 'primary_six') {
          items.push('oralPassage');
        }
      } else if (this.selectedCategory === 'video_conversation' && ['primary_four', 'primary_five', 'primary_six'].indexOf(this.level) > -1) {
        // mock exam only available for digital trials/subscriptions
        if (this.studentType !== 'attending') {
          items.push('aiPractise'); // mock exam
        }
        items.push('activeVocab');
        items.push('freeResponse'); // model answer
        items.push('templatedPhrases');
      } else if (this.selectedCategory === 'voicebot' && this.studentType !== 'attending') {
        items.push('aiVoicebot');
      }
      return items;
    },
  },
  mounted() {
  },
  methods: {
    select(val) {
      this.selectedCategory = val;
    },
  },
};

</script>

<style lang="scss" scoped>
  .oral-index {
    &__container {
      height: 100%;
      background: linear-gradient(to bottom right, #1d244dc9, #1518293c);
      width: 100%;
      position: absolute;
      text-align: center;
      display: flex;
      flex-direction: column;
      .filter-btns {
        padding: 0 2rem;
        margin: .25rem 0;
        margin-bottom: .75rem;
      }
      &--item {
        &-grp {
          padding: 0 2rem;
          overflow-y: auto;
          overflow-x: hidden;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          justify-content: center;
          width: 100%;
          grid-gap: 1.5rem;
          padding-top: .5rem;
        }
        &__label {
          position: absolute;
          top: -.75rem;
          right: .75rem;
          font-size: .85rem;
          border-radius: .25rem;
          background: rgb(87,225,228);
          background: linear-gradient(167deg, rgb(184 87 228) 34%, rgb(78 158 255) 61%);
          padding: 0 .5rem;
          color: #fff;
        }
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #fff;
        border-radius: 7.5px;
        text-decoration: none;
        border: 6px solid transparent;
        transition: all 0.2s;
        position: relative;
        &:hover {
          background: #fff;
          .oral-index__container--item-icon {
            background: #fff;
            border: 6px solid #2b92f8;
            i {
              color: #2b92f8;
            }
          }
        }
        .user-settings-content {
          height: 100%;
          width: 100%;
        }
        a {
          text-decoration: none;
        }
        &-icon {
          height: 75%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 6px solid #e9e9e9;
          margin: .5rem .5rem;
          transition: all 0.2s;
          padding: 1rem;
          border-radius: 0.5rem;
          background: #fff;
          i {
            transition: all 0.2s;
            color: #b9b6c1;
            font-size: 3.5rem;
            margin-bottom: 5px;
          }
        }
      }
      &--text {
        width: 100%;
        height: calc(25% - 1rem);
        display: flex;
        justify-content: center;
        align-items: center;
        &-title {
          font-weight: 600;
          font-size: 1rem;
          color: #4e4e54;
        }
      }
    }
  }

  .num-words {
    font-size: 0.8em;
  }

  @media only screen and (max-width: 1560px) {
    .oral-index__container--item-grp {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .oral-index {
      &__container {
        &--item {
          padding: .25rem;
          &-grp {
            grid-gap: 1.25rem;
          }
          &-icon {
            padding: .75rem;
            i {
              font-size: 3rem;
            }
          }
        }
        &--text {
          &-title {
            font-size: .9rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1150px),
  screen and (max-height: 690px) {
  }
  @media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
    .oral-index {
      &__container {
        background: none;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media only screen and (max-width: 690px) {
    .oral-index__container--item-grp {
      grid-template-columns: 1fr;
    }
  }
  @media only screen and (max-width: 580px) {
    .oral-index__container--item-grp {
      grid-gap: 1rem;
    }
  }
  @media only screen and (max-width: 480px) {
    .oral-index__container--item-icon {
      padding: .5rem;
      i {
        font-size: 2.5rem;
      }
    }
    .oral-index__container--text-title {
      font-size: .8rem;
    }
  }

</style>
