<template>
  <div class="login-component">
    <!-- loading screen to be shown when we login -->
    <!-- note that min-wait-time won't work here -->
    <!-- as after we login we route to a different component -->
    <loading-screen
      :prop-show="showLoadingScreen"
      :min-wait-time="1000"></loading-screen>
    <div class="login-container row">
      <div class="login-image-container">
        <img src="../../assets/boshipanda.png" />
      </div>
      <div
        class="login-form-content">
        <div class="title">
          <h2>CREATE YOUR CHILD'S ACCOUNT</h2>
        </div>
        <form method="post" class="form">
          <span
            v-if="studentErrorMessage !== ''"
            class="error-message">
            {{ studentErrorMessage }}
          </span>
          <input
            v-model="input.firstName"
            class="input-text"
            type="text"
            name="firstName"
            placeholder="Your first name..."/>
          <input
            v-model="input.lastName"
            class="input-text"
            type="text"
            name="lastName"
            placeholder="Your last name..."/>
          <input
            v-model="input.password"
            class="input-text"
            type="password"
            name="password"
            placeholder="Your password (min 4 characters)..."/>
          <input
            v-model="input.password2"
            class="input-text"
            type="password"
            name="password2"
            placeholder="Retype your password..."/>
          <v-select
            v-model="input.level"
            style="min-width: 300px;"
            :options="levelOptions"></v-select>
          <input
            v-model="input.referral"
            class="input-text"
            type="text"
            name="referral"
            placeholder="(Optional) Referral code..."/>
          <button
            class="btn-login"
            type="submit"
            @click.prevent="createStudent">
            Create
          </button>
          <a class="forgot-password" href="#"
            @click.prevent="backToLogin">
            Already have account? Login
          </a>
        </form>
      </div>


    </div>
    <a href="" class="login-component--signup">
      <p>Having trouble?
          <span class="login-component--signup-btn">
            <a href="https://api.whatsapp.com/send?phone=6598207272&text=Hi I'm looking to create a trial account for VocabKing's online portal.">
              WhatsApp us now for support!
            </a>
            Or email us - <a href="mailto:vocabking@learnsmart.ai">vocabking@learnsmart.ai</a>.
        </span>
      </p>
    </a>
  </div>
</template>

<script>
import axios from 'axios';
import {mapMutations} from 'vuex';
// import {mapState, mapActions} from 'vuex';

import LoadingScreenComponent
  from '../common/LoadingScreenComponent.vue';


// when we come to registration page, we can either have a parentId
// (i.e. created previously) or we have "new"
export default {
  name: 'RegistrationStudent',
  components: {
    LoadingScreen: LoadingScreenComponent,
  },
  props: {
    parentSignupToken: String,
  },
  data() {
    return {
      input: {
        firstName: '',
        lastName: '',
        password: '',
        password2: '',
        referral: '',
        level: null,
      },
      studentErrorMessage: '',
      showLoadingScreen: false,
      levelOptions: [
        {label: 'Nursery Two', code: 'nursery_two'},
        {label: 'Kindergarten One', code: 'kindergarten_one'},
        {label: 'Kindergarten Two', code: 'kindergarten_two'},
        {label: 'Primary One', code: 'primary_one'},
        {label: 'Primary Two', code: 'primary_two'},
        {label: 'Primary Three', code: 'primary_three'},
        {label: 'Primary Four', code: 'primary_four'},
        {label: 'Primary Five', code: 'primary_five'},
        {label: 'Primary Six', code: 'primary_six'},
      ],
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
    ...mapMutations(['setRegistration', 'clearInitialRoute']),
    backToLogin() {
      this.$router.push({name: 'login-main'});
    },
    validPassword(password) {
      if (password.length < 4) return false;
      const re =
        /^[a-zA-Z0-9_]+$/;
      return re.test(password);
    },
    createStudent() {
      if (this.showLoadingScreen) return;
      this.studentErrorMessage = '';
      if (this.input.firstName === '' || this.input.lastName === '' ||
        this.input.password === '' || this.input.password2 === '') {
        this.studentErrorMessage = 'Please fill up all the information';
        return;
      };

      if (!this.input.level || typeof this.input.level['code'] === 'undefined') {
        this.studentErrorMessage = 'Please select a level';
        return;
      };

      if (this.input.password !== this.input.password2) {
        this.studentErrorMessage = 'Your passwords do not match';
        return;
      }
      if (!this.validPassword(this.input.password)) {
        this.studentErrorMessage = 'Your password should be made up of letters, digits and underscore only.';
        return;
      }

      this.showLoadingScreen = true;
      axios
          .post('/api/v1/core/create/student/', {
            firstName: this.input.firstName,
            lastName: this.input.lastName,
            password: this.input.password,
            level: this.input.level['code'],
            parentSignupToken: this.parentSignupToken,
            referral: this.input.referral,
          })
          .then((response) => {
            this.showLoadingScreen = false;
            if (!response.data['created']) {
              this.studentErrorMessage = response.data['error_message'];
            } else {
              this.setRegistration({
                'success_message': response.data['success_message'],
                'created_type': 'student',
                'username': response.data['username'],
              });
              this.clearInitialRoute();
              console.log('clearing');
              this.$router.push({name: 'registration-success'});
            }
            return;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  .login-component {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #9053c7;
    background: -webkit-linear-gradient(-135deg, #c850c0, #4158d0);
    background: -o-linear-gradient(-135deg, #c850c0, #4158d0);
    background: -moz-linear-gradient(-135deg, #c850c0, #4158d0);
    background: linear-gradient(-135deg, #c850c0, #4158d0);
    &--signup {
      background: rgba(240,240,240,0.2);
      color: #e8e8e8;
      width: 730px;
      text-align: center;
      padding: 10px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      font-size: 0.8rem;
      box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.35);
      text-decoration: none;
      transition: all 0.2s;
      &:hover {
        font-weight: bold;
      }
      &-btn {
        margin: 0 5px;
        color: #77fff3;
      }
    }
  }

  .login-container {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .login-image-container {
    img {
      max-width: 100%;
      padding: 40px 60px;
    }
    margin-right: 20px;
  }

  .login-form-content {
    padding: 20px;
    margin-right: 60px;
    text-align: center;
    .title {
      margin-bottom: 20px;
      p {
        font-size: 80%;
      }

    }
    .input-text {
      display: block;
      margin: 8px auto;
      border-radius: 100px;
      background-color: #eee;
      font-size: 72.5%;
      padding: 12.5px 50px;
      width: 100%;
      border: 3px solid #eee;
      transition: all 0.2s;
      &:focus {
        outline: none;
        border: 3px solid #ac2fb1;
      }
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #eee inset !important;
  }
  .btn-login {
    border: none;
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
    margin: 24px auto 0 auto;
    display: block;
    width: 100%;
    height: 45px;
    border-radius: 5px;
    background: #8654c7;
    padding: 0 25px;
    cursor: pointer;
    transition: all 0.2s;
    font-weight: 800;
    &:focus {
      outline: none;
    }
    &:hover {
      transform: scale(1.04);
      background-color: #a570ea;
    }
    &:active {
      transform: scale(1.02);
      background: #9359de;
    }
  }
  .btn-qr {
    border: none;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    margin: 4px auto 0 auto;
    display: block;
    width: 100%;
    height: 30px;
    border-radius: 5px;
    background: #53ccd5;
    padding: 0 25px;
    cursor: pointer;
    transition: all 0.2s;
    &:focus {
      outline: none;
    }
    &:hover {
      transform: scale(1.04);
      background-color: #4ee3ee;
    }
    &:active {
      transform: scale(1.02);
      background: #53ccd5;
    }
  }

  .forgot-password {
    display: block;
    padding-top: 6px;
    color: #bbb;
    font-weight: 500;
    font-size: 65%;
    text-decoration: none;
    transition: all 0.2s;
    &:hover {
      color: #2176c3;
    }
  }
  .error-message {
    font-size: 80%;
    color: red;
  }

  .reset-password-modal,
  .qr-code-modal {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,0.95);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .qr-code-modal i {
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    cursor: pointer;
  }
  .reset-password-content {
    height: 200px;
    width: 400px;
    background: #fff;
    position: relative;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    margin-bottom: 150px;
    .btn-close {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 150%;
      color: #aaa;
      cursor: pointer;
      &:hover {
        color: #c32187;
      }
    }

    .modal-title {
      padding: 10px;
      border-bottom: 1px solid #eee;
      margin-bottom: 10px;
    }

    .reset-password-form {
      .phone-number-input {
        background-color: #eee;
        padding: 15px;
        width: 250px;
        display: block;
        border-radius: 10px;
        outline: none;
        border: 2px solid #ddd;
        &:focus {
          border: 2px solid #ac2fb1;
        }
      }
    }
    .btn-submit {
      font-size: 15px;
      color: #fff;
      text-transform: uppercase;
      margin: 12px auto 0 auto;
      display: block;
      width: 50%;
      height: 35px;
      border-radius: 25px;
      background: #8654c7;
      padding: 0 25px;
      cursor: pointer;
      transition: all 0.2s;
      &:focus {
        outline: none;
      }
      &:hover {
        transform: scale(1.04);
        background-color: #a570ea;
      }
      &:active {
        transform: scale(1.02);
        background: #9359de;
      }
    }
  }

  @media only screen and (max-width: 730px) and (min-height: 560px) {
    .login-container {
      flex-direction: column;
    }
    .login-image-container {
      margin: 0 auto;
      width: 60%;
      img {
        padding: 0;
        margin-top: 20px;
      }
    }
    .login-form-content {
      margin: 0 auto;
    }
    .login-component--signup {
      width: 290px;
    }
  }

  @media only screen and (max-width: 320px) and (min-height: 560px) {
    .login-image-container {
      margin-top: 20px;
      img {
        display: block;
        margin: 0 auto;
        width: 80%;
      }
    }
  }


  @media only screen and (max-height: 450px) {
    .login-image-container {
      margin-right: 0;
      img {
        display: block;
        margin: 0 auto;
        width: 90%;
        padding: 20px 40px;
      }
    }
    .reset-password-content {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-height: 350px) {
    .login-image-container {
      img {
        padding: 20px 20px;
      }
    }
    .login-form-content {
      margin-right: 20px;
      padding-left: 0;
      .title {
        font-size: 100%;
        margin-bottom: 15px;
        p {
          font-size: 70%;
        }
      }
      .input-text {
        padding: 10px 50px;
      }
      .btn-login {
        height: 40px;
      }
    }
  }
  @media only screen and (max-height: 560px) and (max-width: 560px){
    .login-image-container {
      display: none;
    }
    .login-form-content {
      margin-right: 10px;
    }
  }

</style>
