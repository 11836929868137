<template>
  <div class="recording">
    <span class="wave-anim-grp bounceIn animated">
      <ul>
        <li v-for="n in 15" :key="n"></li>
      </ul>
    </span>
  </div>
</template>

<script>
export default {
  name: 'RecordingWaveAnimation',
  props: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.recording {
  width: 100%;
  height: 100%;
  border-radius: .5rem;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 600;
  color: #000;
  border: 2px solid #e7e7e7;
  .wave-anim-grp {
    top: 0;
    animation-delay: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
