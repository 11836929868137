<template>
  <div>
    <nav class="main-nav">
    <router-link
      tag="div"
      class="logo"
      :to="{name: 'profile-main'}">
      <img src="../../../assets/logo-vocabking.png">
    </router-link>
    <div v-if="isPSLESuccessPlan" class="logo-psp">
      PSLE Success Plan
    </div>
    <div class="user-profile">
      <router-link
        tag="div"
        class="user-avatar"
        :to="{name: 'new-play-avatar'}">
        <img class="avatar-img"
          :src='"../../../assets/avatars/" + avatar + ".png"' />
      </router-link>
      <div class="user-name">
        <p>
          {{ studentName }}
          <router-link
            tag="i"
            v-if="siblings.length > 0"
            class="fa-solid fa-arrow-right-arrow-left icon-switch-user"
            :to="{name: 'new-settings-switch-user'}">
          </router-link>
        </p>
      </div>
      <!-- <div class="user-level">
        <p>LVL <span>11</span></p>
      </div> -->
    </div>
    <div class="routes__container">
      <ul class="routes__container--list">
        <router-link
          tag="li"
          :to="{name: 'profile-main'}">
          <div>
            <i class="fas fa-home"></i>
            Home
          </div>
        </router-link>
        <router-link
          tag="li"
          :to="{name: 'new-learn-overview'}">
          <div>
            <i class="fas fa-graduation-cap"></i>
            Learn
          </div>
        </router-link>
        <router-link
          v-if="subject === 'chinese' && !isPreschool"
          tag="li"
          :to="{name: 'new-composition-overview'}">
          <div>
            <i class="fas fa-pen"></i>
            Write
          </div>
        </router-link>
        <router-link
          v-if="subject === 'chinese' && isPreschool"
          tag="li"
          :to="{name: 'library-overview'}">
          <div>
            <i class="fas fa-book-reader"></i>
            Read
          </div>
        </router-link>
        <router-link
          v-if="subject === 'chinese' && !isPreschool"
          tag="li"
          :to="{name: 'new-oral-overview'}">
          <div>
            <i class="fas fa-comments"></i>
            Speak
          </div>
        </router-link>
        <router-link
          tag="li"
          :to="{name: 'new-play-overview'}">
          <div>
            <i class="fas fa-gamepad"></i>
            Play
          </div>
        </router-link>
        <router-link
          tag="li"
          :to="{name: 'new-missions-overview'}">
          <div>
            <i class="fas fa-rocket"></i>
            Missions
            <!-- NOTIFICATION FOR MISSIONS -->
            <div v-if="!isAllDailyMissionsDone" class="icon-notification"></div>
          </div>
        </router-link>
        <router-link
          tag="li"
          :to="{name: 'main-leaderboard-coin'}">
          <div>
            <i class="fas fa-trophy"></i>
            Leaderboard
          </div>
        </router-link>
        <router-link
          v-if="isStoreEnabled"
          tag="li"
          :to="{name: 'new-shop-overview'}">
          <div>
            <i class="fas fa-store"></i>
            Store
          </div>
        </router-link>
        <router-link
          tag="li"
          :to="{name: 'analytics-overview'}">
          <div>
            <i class="fas fa-chart-pie"></i>
            Analytics
          </div>
        </router-link>
      </ul>
      <div class="footer-routes">
        <div class="info">
          <i class="fas fa-question"></i>
        </div>
        <router-link
          tag="div"
          class="settings"
          :to="{name: 'new-settings-overview'}">
          <i class="fas fa-cog"></i>
        </router-link>
      </div>
    </div>
    </nav>
    <nav class="mobile-nav" v-if="showMobileNav">
      <div class="mobile-nav-route--container"
      @click="setIsMobileNavMoreMenuShown(false)">
        <router-link
          class="mobile-nav-route"
          :to="{name: 'profile-main'}">
            <i class="fas fa-home"></i>
            Home
        </router-link>
      </div>
      <div class="mobile-nav-route--container"
      @click="setIsMobileNavMoreMenuShown(false)">
        <router-link
          class="mobile-nav-route"
          :to="{name: 'new-learn-overview'}">
            <i class="fas fa-graduation-cap"></i>
            Learn
        </router-link>
      </div>
      <!-- IF PRESCH, SHOW READ -->
      <div v-if="subject === 'chinese' && isPreschool" class="mobile-nav-route--container"
      @click="setIsMobileNavMoreMenuShown(false)">
        <router-link
          class="mobile-nav-route"
          :to="{name: 'library-overview'}">
            <i class="fas fa-book-reader"></i>
            Read
        </router-link>
      </div>
      <!-- ELSE SHOW SPEAK -->
      <div v-if="subject === 'chinese' && !isPreschool" class="mobile-nav-route--container"
      @click="setIsMobileNavMoreMenuShown(false)">
        <router-link
          class="mobile-nav-route"
          :to="{name: 'new-oral-overview'}">
            <i class="fas fa-comments"></i>
            Speak
        </router-link>
      </div>
      <div class="mobile-nav-route--container"
      @click="setIsMobileNavMoreMenuShown(false)">
        <router-link
          class="mobile-nav-route"
          :to="{name: 'new-missions-overview'}">
            <i class="fas fa-rocket"></i>
            <!-- NOTIFICATION FOR MISSIONS -->
            <div v-if="!isAllDailyMissionsDone" class="icon-notification--mobile"></div>
            Missions
        </router-link>
      </div>
      <div class="mobile-nav-route--container"
        @click="setIsMobileNavMoreMenuShown(false)">
        <router-link
          class="mobile-nav-route"
          :to="{name: 'new-play-overview'}">
            <i class="fas fa-gamepad"></i>
            Play
        </router-link>
      </div>
      <div @click="setIsMobileNavMoreMenuShown(!isMobileNavMoreMenuShown)"
        class="mobile-nav-route">
       <i class="fa-solid fa-ellipsis"></i>
        More
      </div>
    </nav>
  </div>
</template>

<script>

import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
// https://www.npmjs.com/package/large-small-dynamic-viewport-units-polyfill
import 'large-small-dynamic-viewport-units-polyfill';

export default {
  name: 'NewTheNav',
  components: {
  },
  data() {
    return {
      showMobileNav: true,
    };
  },
  computed: {
    ...mapState(['studentName', 'avatar', 'siblings', 'isStoreEnabled', 'isMobileNavMoreMenuShown', 'subject']),
    ...mapGetters(['isPreschool', 'isPSLESuccessPlan', 'isAllDailyMissionsDone']),
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['setIsMobileNavMoreMenuShown']),
    ...mapActions(['resetState']),
    logout() {
      this.resetState();

      // redirects to login page
      this.$router.push({name: 'login-main', params: {subject: this.subject}});
    },
    // toggleMoreRoutes: function(value) {
    //   this.showMoreRoutes = value;
    //   this.$emit('toggleMoreRoutes', this.showMoreRoutes);
    // },
  },
  watch: {
  },
};
</script>

<style lang="scss" scoped>
.icon-switch-user {
  font-size: 1rem;
  background: #a3d5ffcc;
  padding: 0.25rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all .2s;
  &:hover {
    color: #38c5fe;
    background: #fff;
    text-shadow: 0px 3px rgb(19 142 214);
  }
}

.mobile-nav {
  background: linear-gradient(#29E3FF, #6472FB);
  border-top: 5px solid  rgba(0,0,0,.168627451);
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  justify-content: space-evenly;
  align-items: center;
  &-route {
    color: #d9eaff;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    flex-direction: column;
    text-decoration: none;
    font-size: 0.6rem;
    cursor: pointer;
    width: 100%;
    position: relative;
    .icon-notification--mobile {
      position: absolute;
      right: .25rem;
      top: .25rem;
      height: .3rem;
      width: .3rem;
      // background: #ffeb10;
      background: #ff1b1b;
      border-radius: 50%;
    }
    &--container {
      width: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      flex-direction: column;
      text-decoration: none;
      cursor: pointer;
    }
    i {
      margin-top: .3rem;
      font-size: 1rem;
      margin-bottom: 0.05rem;
    }
    &--more {
      position: fixed;
      height: calc(100% - 55px);
      width: 100vw;
      background: #000000db;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      &-route {
        width: 100%;
        background: #4396fd;
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.25rem;
        border-bottom: 3px solid rgba(0,0,0,.168627451);
        color: #d9eaff;
        font-weight: 600;
        i {
          margin-right: .75rem;
          width: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: .5rem;
        }
        &:last-child {
          border-bottom: none;
        }
        &:first-child {
          border-top: 5px solid #5262d1;
        }
      }
    }
  }
  .router-link-exact-active {
    color: #fff;
    background: #233167;
    border-radius: 0;
    position: relative;
    &:hover {
      background: #233167;
      margin-right: 0;
    }
  }
}
.main-nav {
  font-family: "Baloo Da 2";
  background: linear-gradient(#29E3FF, #6472FB);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh; /* For browsers that don't support CSS variables */
  height: calc(var(--1dvh, 1vh) * 100); /* This is the "polyfill" */
  height: 100dvh; /* This is for future browsers that support svh, dvh and lvh viewport units */
  justify-content: center;
  box-shadow: -10px 0px 0px 0px #0000002b inset;
  z-index: 2;

  p {
    margin: 0;
  }
   a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  a:active {
    text-decoration: none;
  }
  .logo, .user-profile {
    margin-right: 10px;
  }
  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: .25rem;
    cursor: pointer;
    &-psp {
      background: #5fa0fb;
      font-size: .75rem;
      margin-bottom: .5rem;
      color: #fff;
      padding: .15rem .75rem;
      border-radius: 0.25rem;
      font-weight: 800;
    }
    img {
      width: 85%;
      margin-top: .5rem;
    }
  }
  .user-profile {
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 3px solid #fff3;
    margin-bottom: 1.45rem;
    .user-avatar {
      img {
        cursor: pointer;
        border-radius: 50%;
        border: 8px solid #FFAF50;
        box-shadow: 0px 5px #B37A38;
        background: linear-gradient(to top, #ffd600, #ffffff);
        width: 90%;
      }
    }
    .user-name {
      margin: -.5rem;
      font-size: 1.4rem;
      font-weight: 500;
      color: #fff;
      text-shadow: 0px 3px #0000007a;
    }
    .user-level {
      background: #FFAF50;
      box-shadow: 0px 4px #B37A38;
      border-radius: 2rem;
      margin: 0.25rem 1.4rem;
      font-weight: 500;
      color: #fff;
      text-shadow: 1px 2px #0000003a;
      line-height: 1.35rem;
      font-size: .85rem;
      span {
        font-size: 1.1rem;
      }
    }
  }
  .routes__container {
    width: 100%;
    flex: 1;
    &--list {
      width: 100%;
      padding: 0;
      li {
        div {
          width: 75%;
          position: relative;
          .icon-notification {
            position: absolute;
            left: 5.4rem;
            top: 0rem;
            height: .25rem;
            width: .25rem;
            // background: #ffeb10;
            background: #ff1b1b;
            border-radius: 50%;
          }
        }
        i {
          width: 10%;
          text-align: center;
          margin-right: 0.5rem;
        }
        display: flex;
        justify-content: center;
        cursor: pointer;
        color: #d9eaff;
        padding: 0.5rem;
        font-size: .95rem;
        font-weight: 500;
        line-height: 1rem;
        &:hover {
          margin-right: 10px;
          background: #2331673b;
          color: #fff;
        }
      }
      .router-link-exact-active {
        color: #fff;
        background: #233167;
        border-radius: 0;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          background-color: transparent;
          bottom: -50px;
          height: 50px;
          width: 25px;
          border-top-right-radius: 15px;
          right: 0;
          box-shadow: 0 -25px 0 0 #233167;
        }
        &::before {
          content: "";
          position: absolute;
          background-color: transparent;
          top: -50px;
          height: 50px;
          width: 25px;
          border-bottom-right-radius: 15px;
          right: 0;
          box-shadow: 0 25px 0 0 #233167;
        }
        &:hover {
          background: #233167;
          margin-right: 0;
        }
      }
    }
  }
  .footer-routes {
    position: absolute;
    bottom: 1rem;
    display: flex;
    left: 1.5rem;
    .info, .settings {
      color: #ffffff43;
      cursor: pointer;
      padding: 0.5rem;
      &:hover {
        color: #fff;
      }
    }
  }
}
@media only screen and (max-width: 1366px),
            screen and (max-height: 870px)  {
  .icon-switch-user {
    font-size: .9rem;
  }
  .main-nav {
    .logo {
      img {
        width: 80%;
      }
    }
    .user-profile {
      margin-bottom: 1rem;
      .user-avatar {
        img {
          width: 80%;
        }
      }
      .user-name {
        font-size: 1.1rem;
      }
    }
    .routes__container--list {
      li {
        font-size: .8rem;
        line-height: .85rem;
        div {
          .icon-notification {
            left: 4.6rem;
          }
        }
      }
    }
    .footer-routes {
      font-size: .8rem;
    }
  }
}

@media only screen and (max-width: 1150px),
            screen and (max-height: 720px) {
  .icon-switch-user {
    font-size: .85rem;
  }

  .main-nav {
    .logo {
      img {
        width: 70%;
      }
    }
    .user-profile {
      margin-bottom: .8rem;
      .user-avatar {
        img {
          width: 70%;
        }
      }
      .user-name {
        font-size: 1rem;
      }
    }
    .routes__container--list {
      li {
        font-size: .7rem;
        line-height: .75rem;
        div {
          .icon-notification {
            left: 4.1rem;
          }
        }
      }
    }
    .footer-routes {
      font-size: .7rem;
    }
  }
}
@media only screen and (max-width: 960px),
           screen and (max-height: 620px) {
  .main-nav {
    display: none;
  }
  .mobile-nav {
    display: flex;
  }
}

</style>
