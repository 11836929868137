<template>
  <div id="result-container" :class="resultWrapperClass">
    <!-- for quiz body -->
    <div class="result-flexbox-container row" v-if="!isTeachingScreenShown">
      <div class="result-status-container"
        v-if="answeredStatus !== 'notAnswered'">
        <div v-if="answeredStatus === 'correct'">
          <div class="result-icon-container animated bounceIn">
            <i class="fas fa-check result-icon-correct"></i>
          </div>
          <div
            class="result-text animated fadeInUp faster"
            :class="answeredStatus">
            <span v-if="subject === 'malay'">Betul!</span>
            <span v-else>You are Correct!</span>
          </div>
        </div>
        <div v-if="answeredStatus === 'wrong'">
          <div class="result-icon-container animated bounceIn">
            <i class="fas fa-times result-icon-wrong"></i>
          </div>
          <div
            class="result-text animated fadeInUp faster"
            :class="answeredStatus">
            <span v-if="subject === 'malay'">Tidak tepat.</span>
            <span v-else>Incorrect.</span>
            <span v-if="subject === 'malay'" class="mobile-block">Cuba lagi!</span>
            <span v-else class="mobile-block">Try again!</span>
          </div>
        </div>
      </div>
      <!-- btn- i don't know -->
      <div v-if="hasSkipButton" class="btn-quiz-skip">
        <button
            v-if="answeredStatus == 'notAnswered'"
            :class="{'malay-btn': subject === 'malay'}"
            @click="clickNotSure">
            {{ subject === 'chinese' ? "I'm Not Sure" : "Saya Tidak Pasti" }}
        </button>
      </div>
      <!-- btn: submit -->
      <div class="btn-quiz-submit">
        <button v-if="subject === 'chinese'"
          :class="resultWrapperClass"
          :disabled="shouldDisableButton"
          @click.prevent="emitCheckEvent">
          {{ answeredStatus === "notAnswered" ? "Check" : "Next" }}
        </button>
        <button v-if="subject === 'malay'"
          :class="resultWrapperClass"
          class="malay-btn"
          :disabled="shouldDisableButton"
          @click.prevent="emitCheckEvent">
          {{ answeredStatus === "notAnswered" ? "Periksa Jawapan" : "Sambung" }}
        </button>
      </div>
    </div>

    <!-- for teaching screen -->
    <div class="result-flexbox-container row" v-else>
      <div class="result-status-container">
        <div>
          <div class="result-icon-container animated bounceIn">
            <i class="fas fa-book result-icon-revise"></i>
          </div>
          <h3 class="result-text animated fadeInUp faster revise">
            Let's revise!
          </h3>
        </div>
      </div>
      <div class="btn-quiz-submit">
        <button
          class="neutral"
          @click.prevent="emitCheckEvent">Next</button>
      </div>
    </div>
    <!-- end for teaching screen -->
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

export default {
  name: 'QuizFooter',
  components: {
  },
  props: {
    choiceSelected: String,
    isTeachingScreenShown: Boolean,
    hasSkipButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['subject']),
    ...mapState('quiz', ['answeredStatus']),
    shouldDisableButton() {
      // specifically for speaking active_vocab qns - choice selected will always be '' even if child has already answered
      if (this.answeredStatus !== 'notAnswered') return false;
      else if (this.choiceSelected === '#N/A') return true;
      return this.choiceSelected === '';
    },
    resultWrapperClass() {
      if (this.isTeachingScreenShown || this.answeredStatus === 'notAnswered') {
        return 'neutral';
      } else {
        return this.answeredStatus;
      }
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations('quiz', ['setChoiceSelected',
      'setAnimateQuestionComponent']),
    clickNotSure() {
      // special value to indicate that student clicked on i'm not sure
      this.setChoiceSelected('#N/A');
      this.emitCheckEvent();
    },
    emitCheckEvent() {
      if (this.isTeachingScreenShown || this.answeredStatus !== 'notAnswered') {
        this.$emit('next');
      } else {
        // set to animateQuestionComponent to false to
        // prepare it for animation in the future
        this.setAnimateQuestionComponent(false);
        this.$emit('check');
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .malay-btn {
    width: 205px !important;
  }

  #result-container {
    position: absolute;
    bottom: 0;
    height: 140px;
    width: 100%;
    transition: background-color 0.25s;
  }

  .result-flexbox-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding: 0 30px;
  }

  .result-status-container {
    margin-right: auto;
  }

  .result-status-container > div {
    display: flex;
    align-items: center;
  }

  .result-icon-container {
    background: white;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 225%;
  }

  .result-text {
    margin-left: 10px;
    font-size: 125%;
    font-weight: bold;
    font-family: 'baloo da 2';
    line-height: 1.75rem;
  }
  // button main stylings
  .btn-quiz-submit > button {
    font-family: 'baloo da 2';
    width: 175px;
    font-weight: 600;
    border-radius: .75rem;
    font-size: 1.05rem;
    border: 2px solid transparent;
    border-bottom: 4px solid #5e46b0;
    text-transform: uppercase;
    padding: 8px 0;
    color: white;
    &:focus {
      outline: none;
    }
    &:active {
      border-bottom: 2px solid #5e46b0;
      transform: translateY(1px);
    }
    &:disabled {
      border-bottom: 4px solid #a6a6a6;
    }
  }

  // neutral result styling
  #result-container.neutral {
    border-top: 2px solid #e7e7e7;
  }

  .btn-quiz-submit > button.neutral {
    background: #826ad6;
    cursor: pointer;
  }

  .btn-quiz-submit > button.neutral:disabled {
    transform: scale(1);
    opacity: 0.5;
    cursor: not-allowed;
    background: #bbb;
  }

  // correct result styling
  #result-container.correct {
    background: #54d1b7;
  }

  .result-icon-correct,
  .result-text.correct, {
    color: #307f75;
  }

  .btn-quiz-submit > button.correct {
    background: #307f75;
    cursor: pointer;
    border-bottom: 4px solid #21665d;
    &:active {
      border-bottom: 2px solid #21665d;
    }
  }

  // wrong result styling
  #result-container.wrong {
    background: #e26e7c;
  }

  .result-icon-wrong,
  .result-text.wrong {
    color: #7e3a46;
  }

  .btn-quiz-submit > button.wrong {
    background: #7e3a46;
    cursor: pointer;
    border-bottom: 4px solid #58242d;
    &:active {
      border-bottom: 2px solid #58242d;
    }
  }

  // revise styling
  .result-text.revise,
  .result-icon-revise {
    color: #634daf
  }
  // skip / i dont know btn
  .btn-quiz-skip {
    margin-right: auto;
    button {
      cursor: pointer;
      font-family: "baloo da 2";
      width: 175px;
      font-weight: 600;
      border-radius: .75rem;
      font-size: 1.05rem;
      border: 2px solid #e7e7e7;
      border-bottom: 4px solid #e7e7e7;
      padding: 8px 0;
      color: #ababab;
      background: #fff;
      &:focus {
        outline: none;
      }
      &:active {
        background: #f2f2f2;
        border-bottom: 2px solid #e7e7e7;
        transform: translateY(1px);
      }
    }
  }
  @media only screen and (max-width: 699px) {
    #result-container {
      height: 132px;
    }
    .result-icon-container {
      display: none;
    }
    .result-text {
      margin-top: .25rem;
      margin-bottom: -.25rem;
    }
    .result-flexbox-container {
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      gap: .5rem;
      padding: 0 16px;
      div {
        width: 100%;
        button {
          width: 100%;
          font-size: 1rem;
          padding: 3px 0;
          &:active {
            transform: none;
            margin-top: 2px;
          }
          &:disabled {
            &:active {
              margin-top: 0;
            }
          }
        }
      }
    }
    #result-container.neutral {
      border-top: none;
    }
  }

</style>
