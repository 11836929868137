<template>
  <div class="free-response__component">
    <div class="free-response__component--header--container">
      <div class="free-response__component--header">
        <div
          @click="handleClose"
          class="free-response__component--header--btn-close">
          <i class="fa-solid fa-xmark"></i>
        </div>
        <div v-if="state == 1"
          class="free-response__component--header--progress-bar">
          <div
            :style="{width: progressBarWidth}"
            class="free-response__component--header--progress-bar--fill"></div>
        </div>
        <div v-if="state == 1"
          class="free-response__component--header--timer">
          <i v-if="state == 0 || isRecordingCountdown" class="fa-solid fa-stopwatch animated bounceIn"></i>
          <div class="free-response__component--header--timer--text animated flipInY"
            v-if="state !== 0 && !isRecordingCountdown">{{ timerCount }}/{{ totalTime }}s</div>
        </div>
        <div v-if="state >= 2" class="free-response__component--header--feedback">
          <h2 v-if="feedbackMode === 0">Mock Answer</h2>
          <h2 v-if="feedbackMode === 1">Structure Feedback</h2>
          <h2 v-if="feedbackMode === 2">Language Feedback</h2>
          <h2 v-if="feedbackMode === 3">Revised Answer</h2>
        </div>
      </div>
    </div>
    <div class="free-response__component--content--container">
      <!-- unanswered question -->
      <div v-if="!isCustomQuestion && (state === -1 || state === 0)"
        class="content--question animated fadeIn faster">
        <h2 class="content--question--header">
          <span v-if="subject === 'chinese'">AI 会话练习</span>
          <span v-if="subject === 'malay'">Latihan Perbualan dengan AI</span>
        </h2>
        <div class="content--question--body">
          <div class="question-audio--container">
            <div class="sound-icon-container tap-btn-highlight"
              @click.prevent="playQuestionAudio">
              <i class="fas fa-volume-up sound-icon"></i>
            </div>
            <p class="question-audio--container--text">
              ( Tap to Play Audio )
            </p>
          </div>
          <div class="content--question--body--qns-instruction">
            <h3 v-if="subject === 'chinese'" class="content--question--body--qns-instruction--header">说明</h3>
            <h3 v-else class="content--question--body--qns-instruction--header">Arahan</h3>
            <div class="content--question--body--qns-instruction--text">
              <p>
                <i class="fa-solid fa-minus"></i>
                <span v-if="subject === 'chinese'">请仔细听题目录音</span>
                <span v-else>Sila dengar rakaman soalan dengan teliti</span>
              </p>
              <p v-if="subject === 'chinese'">
                <i class="fa-solid fa-minus"></i>
                当你准备好时，点击下面的<span>“录音”</span>按钮开始
              </p>
              <p v-else>
                <i class="fa-solid fa-minus"></i>
                Apabila anda bersedia, tekan butang <span>"Merakam" </span>di bawah untuk mula
              </p>
              <!-- note: should not include any mention of evaluation method for malay -->
              <p v-if="subject === 'chinese'">
                <i class="fa-solid fa-minus"></i>
                用<span class="highlight">“{{ evaluationMethod }}”</span>的方式回答问题
              </p>
              <!-- <p>- You will be evaluated based on the following:
                <span>1) Did you include at least 3 supporting points?</span>
                <span>2) Did you include a personal story?</span>
                <span>3) Did you use the "PEEL" method?</span>
              </p> -->
            </div>
          </div>
        </div>
        <!-- <div
          v-if="subject === 'chinese'"
          class="free-response__component--content--btn-prev-attempts">
          <router-link
          :to="{name: 'new-oral-ai-practice-previous-attempts', params: {subject: subject, questionId: questionId}}"
          class="oral-index__container--item">
          <button>
            <i class="fas fa-list"></i>
            <span>Previous Attempts</span>
          </button>
        </router-link>
        </div> -->
      </div>
      <div v-if="isCustomQuestion && state === -1"
        class="question-form">
        <input
          v-if="subject === 'chinese'"
          v-model="parentCustomQuestion"
          type="text"
          placeholder="例如：【你认为保护眼睛重要吗？】"
          class="question-input"
        >
        <input
          v-if="subject === 'english'"
          v-model="parentCustomQuestion"
          type="text"
          placeholder='e.g "Tell us about a place of interest in Singapore that you have visited or would like to visit."'
          class="question-input"
        >
        <h3 class="radio-header">Answer Method</h3>
        <div class="radio-group">
          <label v-if="subject === 'chinese'" class="radio-label">
            <input type="radio" v-model="answerMethod" value="3_points" name="questionType">
            3 points
          </label>
          <label v-if="subject === 'english'" class="radio-label">
            <input type="radio" v-model="answerMethod" value="peel" name="questionType">
            PEEL
          </label>
          <label class="radio-label">
            <input type="radio" v-model="answerMethod" value="personal_story" name="questionType">
            Personal story
          </label>
        </div>
        <button @click="submitParentQuestion" class="submit-button">Submit</button>
      </div>
      <!-- question answered, evaluation mode -->
      <div v-if="state >= 2"
        class="content--feedback">
        <!-- feedback question header -->
        <!-- <div class="content--feedback--header--qns-text chinese-character animated fadeInDown faster">
          Q: {{ selectedQuestion.question_text }}
        </div> -->
        <!-- mock answer -->
         <!-- placeholder - if custom parent mission, don't show mock answer -->
        <div v-if="hasMockAnswer && feedbackMode === 0"
          class="content--feedback--container animated fadeIn faster">
          <div class="content--feedback--container--inner content--feedback--mock-answer">
            <!-- for now, we only have 1 answer per question -->
            <!-- if we have different answers of different difficulty, then replace 0 with something variable -->
            <div class="content--feedback--mock-answer--content--grp">
              <p v-for="(text, index) in selectedQuestion.answers[0]['clean_text']"
              :key="`mock_${index}`"
              :class="{'chinese-character': subject === 'chinese', 'malay-character': subject === 'malay'}"
              class="content--feedback--mock-answer--content">
                {{ text }}
              </p>
            </div>
          </div>
        </div>
        <!-- structure feedback -->
        <div v-if="feedbackMode === 1"
          class="content--feedback--container content--feedback--container--desktop animated fadeIn faster">
          <div class="content--feedback--container--desktop--inner">
            <!-- transcription -->
            <div class="content--feedback--transcription">
              <div v-if="state == 2"
                class="content--feedback--transcription--loading">
                <div class="loading--shape loader-shape-transcribing"></div>
                <div class="loading--text loader-text-transcribing"></div>
              </div>
              <div v-else>
                <h4 class="content--feedback--transcription--header">
                  Your Answer
                </h4>
                <p
                  :class="{'chinese-character': subject === 'chinese', 'malay-character': subject === 'malay'}"
                  class="content--feedback--transcription--text">
                  {{ transcription }}
                </p>
              </div>
            </div>
            <!-- feedback -->
            <div class="content--feedback--feedback">
              <div v-if="structureFeedback === ''"
                class="content--feedback--feedback--loading">
                <div class="loading--shape loader-shape-marking"></div>
                <div class="loading--text loader-text-marking"></div>
              </div>
              <div v-else>
                <h4 class="content--feedback--revision--header">
                  Paragraphs Feedback
                </h4>
                <p v-for="(paraText, index) in structureFeedbackParas" :key="`structure_${index}`" class="content--feedback--revision--text">
                  {{ paraText }}
                </p>
              </div>
            </div>
            <div class="content--feedback--feedback">
              <div v-if="contentFeedback === ''"
                class="content--feedback--feedback--loading">
                <div class="loading--shape loader-shape-marking"></div>
                <div class="loading--text loader-text-marking"></div>
              </div>
              <div v-else>
                <h4 class="content--feedback--revision--header">
                  Content Feedback
                </h4>
                <p v-for="(paraText, index) in contentFeedbackParas" :key="`content_${index}`" class="content--feedback--revision--text">
                  {{ paraText }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- language feedback -->
        <div v-if="feedbackMode === 2"
          class="content--feedback--container content--feedback--container--desktop animated fadeIn faster">
          <div class="content--feedback--container--desktop--inner">
            <!-- transcription -->
            <div class="content--feedback--transcription">
              <div v-if="state === 2"
                class="content--feedback--transcription--loading">
                <div class="loading--shape loader-shape-transcribing"></div>
                <div class="loading--text loader-text-transcribing"></div>
              </div>
              <div v-else>
                <h4 class="content--feedback--transcription--header">
                  Your Answer
                </h4>
                <p
                  :class="{'chinese-character': subject === 'chinese', 'malay-character': subject === 'malay'}"
                  class="content--feedback--transcription--text">
                  {{ transcription }}
                </p>
              </div>
            </div>
            <!-- feedback -->
            <div class="content--feedback--feedback">
              <div v-if="languageBreakdownData == null"
                class="content--feedback--feedback--loading">
                <div class="loading--shape loader-shape-marking"></div>
                <div class="loading--text loader-text-marking"></div>
              </div>
              <div v-else class="feedback-container">
                <div class="header-container">
                  <h4 class="content--feedback--revision--header">
                    Here are some changes I made
                  </h4>
                  <button class="toggle-button" @click="showDeletedWords = !showDeletedWords">
                    <span v-if="showDeletedWords">Hide Deleted Words</span>
                    <span v-if="!showDeletedWords">Show Deleted Words</span>
                  </button>
                </div>
                <ul>
                  <li class="green">Words in green are what I added</li>
                  <li class="red">Words in red are what I deleted</li>
                  <li class="orange-background">Highlighted words are my elaboration</li>
                </ul>
                <language-feedback
                  v-for="(entry, index) in languageBreakdownData"
                  :key="`language_${index}`"
                  :subject="subject"
                  :show-deleted-words="showDeletedWords"
                  :info="entry">
                </language-feedback>
              </div>
            </div>
          </div>
        </div>
        <!-- revision -->
        <div v-if="feedbackMode === 3"
          class="content--feedback--container content--feedback--revised-answer animated fadeIn faster">
          <div class="content--feedback--revision">
            <div v-if="revisedAnswer === ''"
              class="content--feedback--revision--loading">
              <div class="loading--shape loader-shape-revising"></div>
              <div class="loading--text loader-text-revising"></div>
            </div>
            <div v-else>
              <p v-for="(paraText, index) in revisedAnswerParas"
                :key="index"
                :class="{'chinese-character': subject === 'chinese', 'malay-character': subject === 'malay'}"
                class="content--feedback--revision--text">
                {{ paraText }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="feedbackMode === 3 && subject === 'chinese'"
          class="content--feedback--revision--btn-quiz">
          <button
            :disabled="revisedAnswer === ''"
            @click="showRevisionPopup = !showRevisionPopup">
            Practise Reading
          </button>
        </div>
      </div>
    </div>
    <div class="free-response__component--footer--container"
      :class="{'bt-remove': isRecording}">
      <div class="free-response__component--footer--content">
        <!-- Btn cancel record mode -->
        <div v-if="isRecording && !isRecordingCountdown"
          class="free-response__component--footer--btn-cancel">
          <button @click="cancelRecording">
            <i class="fa-solid fa-xmark"></i>
            <span>Cancel</span>
          </button>
        </div>
        <div class="free-response__component--footer--recorder"
          :class="{'mr-200': isRecording && !isRecordingCountdown }"
          v-if="state === -1 || state === 0 || state === 1">
          <!-- Btn Start Record -->
          <div v-if="!isRecording && !isCustomQuestion"
            class="btn-start-quiz">
            <button :disabled="state === -1"
              @click="toggleRecording">
              Start
            </button>
          </div>
          <div v-if="!isRecording && isCustomQuestion && state === 0"
            class="recorder--btn-grp">
            <button class="recorder--btn recorder--btn-start animated bounceIn"
              @click="toggleRecording">
              <i class="fa-solid fa-microphone"></i>
            </button>
            <span class="recorder--btn-text animated fadeInUp faster">
              ( Click to Start Recording )
            </span>
          </div>
          <!-- Btn Stop Record -->
          <div v-if="isRecording">
            <!-- Start Record Countdown -->
            <div v-if="isRecordingCountdown"
              class="recorder--countdown animated flipInX">
              <h2>Get Ready...</h2>
              <p>( Don't Speak Yet )</p>
            </div>
            <!-- Btn Stop Record -->
            <div v-if="!isRecordingCountdown"
              class="recorder--btn-grp">
              <!-- Audio Wave Anim -->
              <span class="wave-anim-grp animated flipInX">
                <ul>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </span>
              <button class="recorder--btn recorder--btn-stop animated bounceIn"
                @click="toggleRecording">
                <i class="fa-solid fa-stop"></i>
              </button>
              <span class="recorder--btn-text animated fadeInUp faster">
                ( Start Recording Now! )
              </span>
            </div>
          </div>
        </div>
        <!-- Btn Feedback Toggle -->
        <div class="feedback-toggle--btn--grp" v-if="state >= 2">
          <button v-if="hasMockAnswer" :class="{'btn-selected': feedbackMode === 0}" class="feedback-toggle--btn"
            @click="feedbackMode = 0">
              Mock Answer
            </button>
          <button :class="{'btn-selected': feedbackMode === 1}" class="feedback-toggle--btn"
            @click="feedbackMode = 1">
              Structure Feedback
          </button>
          <button :class="{'btn-selected': feedbackMode === 2}" class="feedback-toggle--btn"
            @click="feedbackMode = 2">
              Language Feedback
          </button>
          <button :class="{'btn-selected': feedbackMode === 3}" class="feedback-toggle--btn"
            @click="feedbackMode = 3">
              Revised Answer
          </button>
        </div>
      </div>
    </div>
    <audio :src="selectedQuestionAudioSrc" ref="questionTextAudio"></audio>
    <audio src="https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/sound_effects/completion.mp3" ref="completionAudio" />
    <!-- revision popup quiz -->
    <speech-pronunciation-popup v-if="showRevisionPopup"
      @closePopup="showRevisionPopup = !showRevisionPopup"
      :entity-info="{type: 'AiPracticeSession', entityId: sessionId}"
      :texts="revisedAnswerParas"></speech-pronunciation-popup>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import axios from 'axios';
import Recorder from '../../../../vendor/recorder';
import 'large-small-dynamic-viewport-units-polyfill';

import LanguageFeedbackComponent from './components/LanguageFeedbackComponent.vue';
import SpeechPronunciationPopupComponent from '../common/SpeechPronunciationPopupComponent.vue';

const POLLING_INTERVAL = 3000;
const MAXIMUM_POLLING_DURATION = 120000; // 2 min

export default {
  name: 'NewOralAiPractice',
  components: {
    LanguageFeedback: LanguageFeedbackComponent,
    SpeechPronunciationPopup: SpeechPronunciationPopupComponent,
  },
  props: {
    // use string instead of number since it can be a "custom"
    questionId: String,
    subject: String,
  },
  data() {
    return {
      /**
       * -1 - user hasn't clicked on play audio button yet
       * 0 - clicked on audio button not started recording
       * 1 - recording
       * 2 - finished recording, waiting for transcription
       * 3 - received transcription, awaiting feedback
       * 4 - received structure feedback
       * 5 - received language feedback
       * 6 - completed
       */
      state: -1,
      isRecording: false,
      hasMicrophonePermission: false,
      recorder: null,
      gumStream: null,
      fetching: false,
      // will need to keep polling
      sessionId: -1,
      startPollingTime: 0,
      timerInterval: null,
      timerCount: 0, // in seconds for frontend
      totalTime: 120, // in seconds for frontend
      duration: 0, // this is what is actually passed to backedn
      isRecordingCountdown: false,

      /**
       * 0 - mock answer
       * 1 - language feedback
       * 2 - content feedback
       * 3 - revised answer
       */
      feedbackMode: 0,
      testStartTime: null,
      transcription: '',
      revisedAnswer: '',
      fluencyScore: -1,
      numValidPoints: -1,
      structureFeedback: '',
      contentFeedback: '',
      languageBreakdownData: null,

      // for custom parent question
      parentCustomQuestion: '',
      answerMethod: '3_points',

      // for language page
      showDeletedWords: false,
      isDevelopment: false,

      // for revision popup quiz
      showRevisionPopup: false,
      // to avoid having to transcribe from server
      useMockData: false,
      mockData: {
        'transcription': '我平常都会保护自己的眼睛。保护眼睛的方式我有三条。第一，就是我每天都会吃胡萝卜，因为它有维生素A，可以帮助保持眼睛健康。第二是，我都会设置说，一天不能看电话超过半个小时，因为长期看电话会影响到我的眼睛。以上是我保护眼睛的方式，谢谢。',
        // out of 100
        'fluencyScore': 80,
        'numValidPoints': 2,
        'feedback': 'You did a great job mentioning that you protect your eyes by eating carrots, which are beneficial for eye health. This is a good example of how you take care of your eyes.\nIn the future, you could also mention other ways to protect your eyes, such as taking regular breaks from screens and wearing sunglasses to protect against UV rays.\nYour point about eating carrots is well-explained, and you provided a reason for why it is beneficial. There are no significant grammatical or vocabulary errors in your speech. Keep up the good work and continue to take care of your eyes!',
        'revisedAnswer': '我平常都会注意保护自己的眼睛。保护眼睛的方式我有三条。\n\n第一，我每天都会吃胡萝卜，因为它含有丰富的维生素A，可以帮助保持眼睛健康。第二，我每天会限制自己看手机的时间，不超过半个小时，因为长时间看手机会对眼睛造成伤害。第三，在看书或者做功课时，我会选择光线充足的地方，以减少眼睛的疲劳。\n\n以上是我保护眼睛的经验，谢谢。',
        'contentFeedback': 'You did a great job explaining how you protect your eyes in your daily life. Your points about reducing phone usage and maintaining a good sleep schedule are well-explained and directly address the question. Both points are clear and supported with reasons why they help protect your eyes. In the future, you could also mention taking regular breaks when using a computer and eating a balanced diet rich in vitamins that support eye health.',
        'structureFeedback': 'Your speech lacks a clear opening and conclusion, which can cause you to lose marks. Adding a simple opening and conclusion will help frame your ideas better.\nYour speech has clear transitional words like 首先 and 第二. Each valid point starts with these transitional words, making it easy to follow your reasoning.',
        'languageBreakdownData': [{'text': '我平常都会保护自己的眼睛。', 'revised': '我平时都会注意保护自己的眼睛。', 'vocabEdits': {'平常': '平时'}, 'elaboration': '', 'revisedWithHighlights': [{'word': 'mata', 'operation': ' '}, {'word': 'adada', 'operation': ' '}, {'word': 'dragon', 'operation': '-'}, {'word': ',', 'operation': ' '}, {'word': 'phon', 'operation': '+'}]}],
      },
    };
  },
  computed: {
    ...mapState(['studentType']),
    ...mapState('guidedReading', ['freeResponseQuestions']),
    isCustomQuestion() {
      return this.questionId === 'custom';
    },
    // only chinese has mock answer for now
    hasMockAnswer() {
      return !this.isCustomQuestion && this.subject === 'chinese';
    },
    // hardcoded numbers for min characters required - should not use magic numbers in future and should be on backend
    minimumCharactersRequired() {
      return this.isDevelopment ? 2 : 50;
    },
    // to pass to router link
    closeButtonRouterPath() {
      if (this.subject === 'chinese') {
        console.log('chinese close route');
        return {
          name: 'new-oral-category-index',
          params: {category: 'aiPractise'},
        };
      };
      return {
        name: 'new-malay-free-response-index',
      };
    },
    // progress bar width based on timer
    progressBarWidth() {
      const percentage = Math.ceil((this.timerCount) / this.totalTime * 100);
      return `${percentage}%`;
    },
    selectedQuestion() {
      // custom parent question
      if (this.isCustomQuestion) return null;
      return this.freeResponseQuestions.filter((qn) => qn.id === parseInt(this.questionId))[0];
    },
    selectedQuestionAudioSrc() {
      if (!this.selectedQuestion) return '';
      return this.selectedQuestion.audio_url;
    },
    structureFeedbackParas() {
      if (this.structureFeedback === '') return [];
      return this.structureFeedback.split('\n').filter((entry) => entry.length > 0);
    },
    contentFeedbackParas() {
      if (this.contentFeedback === '') return [];
      return this.contentFeedback.split('\n').filter((entry) => entry.length > 0);
    },
    revisedAnswerParas() {
      if (this.revisedAnswer === '') return [];
      return this.revisedAnswer.split('\n').filter((entry) => entry.length > 0);
    },
    // kiv
    evaluationMethod() {
      if (!this.selectedQuestion) return '';
      if (this.selectedQuestion.answer_methods[0] === '3_points') {
        return this.subject === 'chinese' ? '三个要点' : 'tiga perkara utama';
      }
      return this.subject === 'chinese' ? '个人经验' : 'pengalaman peribadi';
    },
  },
  mounted() {
    console.log(this.studentType);
    if (this.studentType !== 'digital_trial' && this.studentType !== 'digital_subscription') {
      alert('Please check with your admin for access');
      this.$router.push({name: 'profile-main'});
    }

    if (this.subject === 'chinese') {
      this.answerMethod = '3_points';
    } else {
      this.answerMethod = 'peel';
    }
    if (this.freeResponseQuestions.length === 0) {
      this.setShowGlobalLoadingScreen(true);
      axios
          .get('/vocab/oralfreeresponse/questions/', {params: {subject: this.subject}})
          .then((response) => {
            this.setFreeResponseQuestions(response.data);
            this.setShowGlobalLoadingScreen(false);
          });
    }
    // need to use nextTick this to reference refs
    this.$nextTick(() => {
      this.$refs.questionTextAudio.addEventListener('ended', (evt) => {
        // shows audio button
        if (this.state === -1) this.state = 0;
      });
    });
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    ...mapMutations('guidedReading', ['setFreeResponseQuestions', 'incrementFreeResponseQuestionAttempts']),
    playQuestionAudio() {
      if (this.selectedQuestion.audio_url === '') return;
      this.$refs.questionTextAudio.play();
    },
    submitParentQuestion() {
      console.log('submitting parent question');
      // checks that there is a question filled
      if (this.isCustomQuestion && this.parentCustomQuestion === '') {
        alert('Please enter a question');
        return;
      }
      // move on to recording
      this.state = 0;
      // we skip mock answer (feedbackMode === 1) for parent custom qn
      this.feedbackMode = 1;
    },
    reset() {
      this.sessionId = -1;
      this.isRecording = false;
      this.state = -1;
      this.timerCount = 0;
      this.recorder = null;
      this.gumStream = null;
      this.transcription = '';

      this.revisedAnswer = '';
      this.fluencyScore = -1;
      this.numValidPoints = -1;
      this.structureFeedback = '';
      this.contentFeedback = '';
      this.languageBreakdownData = null;
    },
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    // timer counter
    startTimer() {
      this.timerInterval = setInterval(() => {
        if (this.timerCount < this.totalTime) {
          this.timerCount += 1;
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timerInterval);
    },
    // https://blog.addpipe.com/using-recorder-js-to-capture-wav-audio-in-your-html5-web-site/
    startRecording() {
      if (this.isRecording) return;
      this.timerCount = 0;
      this.state = 1;
      this.isRecording = true;
      this.result = null;
      this.transcription = '';

      // To start countdown to account for recording latency
      this.isRecordingCountdown = true;
      setTimeout(() => {
        this.isRecordingCountdown = false;
        this.startTimer(); // this is for front end
        this.startRecordingTime = new Date(); // more accurate number to send to backend
      }, 1750);

      // shim for AudioContext when it's not avb.
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      const audioContext = new AudioContext;

      navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((stream) => {
        /* assign to gumStream for later use */
        this.gumStream = stream;
        /* use the stream */
        const input = audioContext.createMediaStreamSource(stream);
        // Create the Recorder object and configure to record mono sound (1 channel)
        this.recorder = new Recorder(input, {
          numChannels: 1,
        });
        // start the recording process
        this.recorder.record();
      }).catch((err) => {
        // enable the record button if getUserMedia() fails
        alert(err);
        this.isRecording = false;
      });
    },
    stopRecording() {
      this.state = 2;
      this.isRecording = false;
      this.stopTimer();
      // tell the recorder to stop the recording
      this.recorder.stop(); // stop microphone access
      this.gumStream.getAudioTracks()[0].stop();
      this.duration = new Date() - this.startRecordingTime;
      // create the wav blob and pass it on to createDownloadLink
      this.recorder.exportWAV(this.createDownloadLink);
    },
    cancelRecording() {
      this.stopTimer();
      this.timerCount = 0;
      this.state = 0;
      this.isRecording = false;
      // tell the recorder to stop the recording
      this.recorder.stop(); // stop microphone access
      this.gumStream.getAudioTracks()[0].stop();
    },
    handleClose() {
      console.log('handleClose');
      // First check if we need to cancel recording
      if (this.isRecording && this.recorder) {
        this.cancelRecording();
      }
      // Then reset state
      this.reset();
      // Incorporate the subject check from closeButtonRouterPath
      if (this.subject === 'chinese') {
        console.log('chinese close route');
        this.$router.push({
          name: 'new-oral-category-index',
          params: {category: 'aiPractise'},
        });
      } else {
        this.$router.push({
          name: 'new-malay-free-response-index',
        });
      }
    },
    // returns true if answer satisfies length requirements
    checkAnswerMinimumLength(transcription) {
      // for chinese, we check characters. for english/malay, we check word count
      const entities = this.subject === 'chinese' ? transcription: transcription.split(' ');
      if (entities.length <= this.minimumCharactersRequired) {
        alert(`Your answer needs to be at least ${this.minimumCharactersRequired} characters long`);
        return false;
      }
      return true;
    },
    createDownloadLink(blob) {
      if (this.fetching) return;
      this.fetching = true;
      const au = document.createElement('audio');
      au.controls = false;
      au.src = URL.createObjectURL(blob);
      this.studentAudio = au;

      const filename = new Date().toISOString();
      const formData = new FormData();
      formData.append('audioData', blob, filename);
      formData.append('language', this.subject);
      this.testStartTime = new Date();

      // only for testing
      if (this.useMockData) {
        this.fetching = false;
        this.transcription = this.mockData['transcription'];
        this.state = 3;
        this.submitToMainBackend();
        return;
      }

      // we skip mock answer (feedbackMode === 1) for malay (or skip if parent created, which is in another function)
      if (this.subject === 'malay') {
        this.feedbackMode = 1;
      }

      // we use a separate webapp on render to handle transcription as python anywhere is incredibly slow
      const renderUrl = this.isDevelopment ? 'http://localhost:8080/transcribe' : 'https://learnsmartapi.onrender.com/transcribe';
      axios
          .post(renderUrl, formData, {
            'Content-Type': 'multipart/form-data',
          })
          .then((response) => {
            this.fetching = false;
            if (!response.data['is_subject']) {
              alert(`Your answer needs to be in ${this.subject}`);
              return;
            }
            if (!this.checkAnswerMinimumLength(response.data['transcription'])) return;
            // const fakeMalayAnswer = 'Mat adalah salah paling berharga dalam tubuh kita. pintu kepada dunia visual, ia memainkan peranan penting dalam seharian kehidupan . Oleh itu, menjaga kesihatan mata adalah tanggungjawab yang tidak boleh diabaikan. Pertama, saya mengamalkan waktu rehat yang mencukupi untuk mata saya. Setiap 20 minit, saya akan berhenti sejenak dari skrin komputer dan memandang objek jauh selama 20 saat. Ini membantu mengurangkan ketegangan mata. Kedua, saya memastikan pemakanan saya kaya dengan nutrien yang baik untuk kesihatan mata. Sayur-sayuran berdaun hijau, ikan berminyak, dan buah-buahan yang tinggi vitamin C sering menjadi pilihan dalam diet saya. Ketiga, saya tidak lupa untuk menjalani pemeriksaan mata secara berkala. Ini membantu mengesan sebarang masalah pada peringkat awal dan memastikan penglihatan saya sentiasa optimum. Kesimpulannya, menjaga kesihatan mata adalah usaha berterusan yang memerlukan kesedaran dan tindakan harian. Dengan mengambil langkah-langkah pencegahan dan penjagaan yang betul, kita dapat memastikan mata kita tetap sihat dan berfungsi dengan baik sepanjang hayat.';
            // console.log(this.transcription);
            // this.transcription = fakeMalayAnswer;
            this.transcription = response.data['transcription'];
            this.state = 3;
            // disable for now
            this.submitToMainBackend();
          })
          .catch((err) => {
            console.log(this.err);
            this.reset();
          });
    },
    submitToMainBackend() {
      const formData = new FormData();
      // refers to free response question
      formData.append('questionId', this.questionId);

      // this is for custom parent questions
      formData.append('parentCustomQuestion', this.parentCustomQuestion);
      formData.append('answerMethod', this.answerMethod);

      formData.append('duration', this.duration);
      formData.append('subject', this.subject);
      formData.append('transcription', this.transcription.trim());
      const _url = `/api/v1/brain/aipracticesession/`;
      console.log('formData', formData);
      axios
          .post(_url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            if (this.checkAndShowError(response)) return;
            console.log(response.data);
            this.sessionId = response.data['session_id'];
            this.startPollingTime = new Date();
            setTimeout(() => {
              this.pollBackendForResults();
            }, POLLING_INTERVAL);
          })
          .catch((err) => {
            console.log(err);
            this.reset();
          });
    },
    checkAndShowError(response) {
      if ('is_error' in response.data && response.data['is_error']) {
        console.log('response.data', response.data);
        alert(response.data['message']);
        this.reset();
        return true;
      };
      return false;
    },
    pollBackendForResults() {
      // only for testing
      if (this.useMockData) {
        this.fetching = false;
        this.transcription = this.mockData['transcription'];
        this.revisedAnswer = this.mockData['revisedAnswer'];
        this.fluencyScore = this.mockData['fluencyScore'];
        this.numValidPoints = this.mockData['numValidPoints'];
        this.structureFeedback = this.mockData['structureFeedback'];
        this.contentFeedback = this.mockData['contentFeedback'];
        this.languageBreakdownData = this.mockData['languageBreakdownData'];
        this.state = 6;

        // this.languageBreakdown
        return;
      }

      if (this.state < 2 || this.state === 6) return;
      const timeElapsed = new Date() - this.startPollingTime;
      if (timeElapsed >= MAXIMUM_POLLING_DURATION) {
        alert('Time out, please try again');
        return;
      }
      const _url = `/api/v1/brain/aipracticesession/${this.sessionId}/`;
      axios
          .get(_url)
          .then((response) => {
            if (this.checkAndShowError(response)) return;
            console.log(response.data);

            if ('transcription' in response.data) {
              this.transcription = response.data['transcription'];
              if (this.state < 3) this.state = 3;
            }

            if ('structure_feedback' in response.data) {
              this.structureFeedback = response.data['structure_feedback'];
              if (this.state < 4) this.state = 4;
            }

            if ('language_breakdown' in response.data) {
              this.languageBreakdownData = response.data['language_breakdown'];
              if (this.state < 5) this.state = 5;
            }

            if ('content_feedback' in response.data) {
              this.contentFeedback = response.data['content_feedback'];
              if (this.state < 4) this.state = 4;
            }

            if ('revised_answer' in response.data) {
              this.revisedAnswer = response.data['revised_answer'];
              if (this.state < 6) {
                this.state = 6;
                this.$refs.completionAudio.play();
                // if not custom question, increement the attempts for the question
                if (!isNaN(this.questionId)) {
                  this.incrementFreeResponseQuestionAttempts(parseInt(this.questionId));
                }
              }
            }
            if (!response.data['completed']) {
              return setTimeout(() => {
                this.pollBackendForResults();
              }, POLLING_INTERVAL);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
  beforeDestroy() {
    this.stopTimer();
  },
};

</script>

<style lang="scss" scoped>
// claude styling for parent form
.question-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
}

.question-input {
  width: 100%;
  min-width: 600px;
  padding: 15px;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
}

.radio-header {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.radio-group {
  display: flex;
  margin-bottom: 15px;
}

.radio-label {
  margin-right: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.radio-label input {
  margin-right: 5px;
}

.submit-button {
  padding: 12px 20px;
  font-size: 18px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: flex-start;
}

.submit-button:hover {
  background-color: #45a049;
}
// end

  // from claude
/* Container styling */
.feedback-container {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Header container */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Toggle button styling */
.toggle-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #45a049;
}

/* Header styling */
.content--feedback--revision--header {
  font-size: 1.2em;
  color: #333;
  margin: 0;
}

/* Legend styling */
ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 4px;
}

li.green {
  background-color: #e6ffe6;
  color: #006600;
}

li.red {
  background-color: #ffe6e6;
  color: #cc0000;
}

li.orange-background {
  background-color: #fff2e6;
  color: #cc6600;
}
// end from claude

  .green {
    color: green;
  }

  .red {
    color: red;
  }

  .orange-background {
    background: orange;
  }

  // dan - taken from question choice - hackish with important!, fix
  .btn-selected {
    background-color: #ffe6b4 !important;
    color: #aa7928 !important;
    border: 2px #edc968 solid !important;
    border-bottom: 4px #edc968 solid !important;
    &-active {
      border-bottom: 2px #edc968 solid !important;
      transform: translateY(2px);
    }
    &:disabled {
      &:active {
        background-color: #ffe6b4 !important;
        color: #aa7928 !important;
        border: 2px #edc968 solid !important;
        border-bottom: 4px #edc968 solid !important;
      }
    }
  }

  .highlight {
    color: #ff0aa7;
  }
  .v-select {
    position: fixed;
    top: 0;
    opacity: 0;
  }
  .free-response__component {
    position: fixed;
    height: calc(100vh); /* For browsers that don't support CSS variables */
    height: calc(var(--1dvh, 1vh) * 100); /* This is the "polyfill" */
    height: calc(100dvh); /* This is for future browsers that support svh, dvh and lvh viewport units */
    width: 100vw;
    background: #fff;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    &--header {
      height: 100px;
      width: 100%;
      max-width: 1080px;
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0 30px;
      &--container {
        width: 100%;
        display: flex;
        justify-content: center;
      };
      &--btn-close {
        opacity: 0.3;
        color: #000;
        font-size: 1.5rem;
        font-family: "Lato","Arial",sans-serif;
        &:hover {
          opacity: .5;
        }
      }
      // progress bar
      &--progress {
        flex: 1;
        &-bar {
          height: 1rem;
          border-radius: 2rem;
          background: #1a1a1a24;
          flex: 1;
          overflow: hidden;
          &--fill {
            // width for progress bar
            transition: .5s all linear;
            width: 50%;
            height: 100%;
            background: #ffb350;
            border-radius: 2rem;
          }
        }
      }
      // timer counter
      &--timer {
        width: 60px;
        text-align: center;
        font-family: 'baloo da 2';
        font-size: .9rem;
        font-weight: bold;
        color: #b1b1b1;
        &--text {
        }
        i {
          font-size: 1.25rem;
        }
        span {
        }
      }
      &--feedback {
        flex: 1;
        margin-left: -42.5px;
        text-align: center;
      }
    }
    &--content {
      &--btn-prev-attempts {
        button {
          cursor: pointer;
          font-family: "baloo da 2";
          width: 100%;
          margin-top: -1rem;
          font-weight: 600;
          border-radius: .75rem;
          font-size: 1.05rem;
          border: 2px solid #e7e7e7;
          border-bottom: 4px solid #e7e7e7;
          padding: 8px 0;
          color: #ababab;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            margin-right: .5rem;
          }
          &:focus {
            outline: none;
          }
          &:active {
            margin-top: calc(-1rem + 2px);
            background: #f2f2f2;
            border-bottom: 2px solid #e7e7e7;
            transform: translateY(1px);
          }
        }
      }
      &--container {
        flex: 1;
        display: flex;
        justify-content: center;
        overflow: auto;
        .content {
          &--question {
            // width: 600px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 3rem;
            &--header {
              font-weight: bold;
              font-size: 1.4rem;
              font-family: "baloo da 2";
              line-height: 1rem;
              span {
                font-size: 1.75rem;
                margin-right: .25rem;
              }
            }
            &--body {
              font-size: 1.4rem;
              margin: 1.5rem auto 2rem auto;
              // width: 90%;
              border-radius: .5rem;
              padding: 2rem 1.5rem;
              box-shadow: #0000002b 0px 4px 9px;
              display: flex;
              align-items: center;
              .question-audio--container {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                &--text {
                  font-size: 0.7rem;
                  font-weight: 500;
                  font-style: italic;
                  margin-top: .25rem;
                }
              }
              &--qns-text {
                text-align: center;
              }
              .sound-icon-container {
                background: #634daf;
                border-radius: 2rem;
                border-bottom: 6px solid #4a3595;
                height: 130px;
                width: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: transform 0.2s;
                color: #fff;
                font-size: 3.5rem;
                cursor: pointer;
                &:active {
                  height: 128px;
                  margin-top: 2px;
                  border-bottom: 4px solid #4a3595;
                }
              }
              &--qns-instruction {
                text-align: left;
                // margin-top: 1.5rem;
                // border-top: 2px solid #E7E7E7;
                padding: 0 1rem;
                margin-left: 1rem;
                &--header {
                  padding-bottom: .25rem;
                  font-size: 1rem;
                }
                &--text {
                  gap: .25rem;
                  display: flex;
                  flex-direction: column;
                  font-style: italic;
                  font-size: .8rem;
                  margin-left: .5rem;
                  font-weight: 500;
                  p {
                    display: flex;
                    align-items: center;
                    i {
                      margin-right: .5rem;
                    }
                    span {
                      margin: 0 .3rem;
                    }
                  }
                }
              }
            }
          }
          &--feedback {
            flex: 1;
            // max-width: 960px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            &--header--qns-text {
              font-size: 1.4rem;
              font-weight: 800;
              text-align: center;
            }
            &--container {
              margin: 1.5rem auto 2rem auto;
              // width: 90%;
              border-radius: .5rem;
              box-shadow: #0000002b 0px 4px 9px;
              overflow: auto;
              height: 100%;
              &--inner {
                height: 100%;
              }
              &--desktop {
                box-shadow: none;
                margin: 20px auto 30px auto;
                padding: 10px;
                width: 100%;
                display: flex;
                justify-content: center;
                &--inner {
                  display: flex;
                  max-width: 1280px;
                  height: 100%;
                  gap: 1rem;
                  width: 100%;
                }
              }
            }
            &--mock-answer {
              max-width: 960px;
              &--header {
                margin: 2rem 3.5rem 0 3.5rem;
                font-size: 1.2rem;
                text-align: center;
              }
              &--content {
                text-align: justify;
                font-size: 1.2rem;
                line-height: 2rem;
                text-indent: 2.5rem;
                margin-bottom: .75rem;
                &--grp {
                  height: 100%;
                  padding: 2rem 4.5rem;
                }
              }
            }
            &--transcription, &--revision, &--feedback {
              flex: 1;
              box-shadow: #0000002b 0px 4px 9px;
              border-radius: .5rem;
              padding: 1rem 1.5rem;
              overflow: auto;
              position: relative;
              &--loading {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                height: 110px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
              }
              &--header {
                font-size: 1.1rem;
                margin-bottom: .25rem;
              }
              &--text {
                text-indent: 2.5rem;
                text-align: justify;
                line-height: 1.5rem;
              }
              .chinese-character {
                font-size: 1.2rem;
                line-height: 2rem;
              }
            }
            &--revised-answer {
              display: flex;
              width: 960px;
            }
            &--revision {
              padding: 2rem 4.5rem;
              display: flex;
              flex-direction: column;
              p {
                text-align: justify;
                font-size: 1.2rem;
                line-height: 2rem;
                text-indent: 2.5rem;
                margin-bottom: .75rem;
              }
              &--btn-quiz {
                button {
                  width: 960px;
                  margin: -1rem auto 2rem;
                  text-align: center;
                  cursor: pointer;
                  font-family: "baloo da 2";
                  font-weight: 600;
                  border-radius: .75rem;
                  font-size: 1rem;
                  border: 2px solid transparent;
                  border-bottom: 4px solid #5e46b0;
                  padding: 8px 0;
                  color: #fff;
                  background: #826ad6;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  line-height: 1.25rem;
                  &:active {
                    margin-top: calc(-1rem + 2px);
                    border-bottom: 2px solid #5e46b0;
                    transform: translateY(1px);
                  }
                  &:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                    background: #bbb;
                    border-bottom: 4px solid #a6a6a6;
                    &:active {
                      margin-top: -1rem;
                      border-bottom: 4px solid #a6a6a6;
                      transform: translateY(0px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &--footer {
      &--container {
        border-top: 2px solid #e7e7e7;
        height: 140px;
        display: flex;
        justify-content: center;
      }
      &--content {
        max-width: 1140px;
        padding: 0 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        // feedback toogle btn grp
        .feedback-toggle {
          &--btn {
            cursor: pointer;
            font-family: "baloo da 2";
            width: 200px;
            font-weight: 600;
            border-radius: .75rem;
            font-size: 1.05rem;
            border: 2px solid #e7e7e7;
            border-bottom: 4px solid #e7e7e7;
            padding: 8px 0;
            color: #ababab;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
              margin-right: .5rem;
            }
            &:focus {
              outline: none;
            }
            &:active {
              background: #f2f2f2;
              border-bottom: 2px solid #e7e7e7;
              transform: translateY(1px);
            }
            &--grp {
              display: flex;
              gap: 1rem;
            }
          }
        }
      }
      &--btn-cancel {
        button {
          cursor: pointer;
          font-family: "baloo da 2";
          width: 200px;
          font-weight: 600;
          border-radius: .75rem;
          font-size: 1.05rem;
          border: 2px solid #e7e7e7;
          border-bottom: 4px solid #e7e7e7;
          padding: 8px 0;
          color: #ababab;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            margin-right: .5rem;
          }
          &:focus {
            outline: none;
          }
          &:active {
            background: #f2f2f2;
            border-bottom: 2px solid #e7e7e7;
            transform: translateY(1px);
          }
        }
      }
      &--recorder {
        flex: 1;
        .btn-start-quiz {
          text-align: right;
          button {
            cursor: pointer;
            font-family: "baloo da 2";
            width: 200px;
            font-weight: 600;
            border-radius: .75rem;
            font-size: 1.05rem;
            border: 2px solid transparent;
            border-bottom: 4px solid #5e46b0;
            padding: 8px 0;
            color: #fff;
            background: #826ad6;
            &:active {
              margin-top: 2px;
              border-bottom: 2px solid #5e46b0;
              transform: translateY(1px);
            }
            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
              background: #bbb;
              border-bottom: 4px solid #a6a6a6;
              &:active {
                margin-top: 0;
                border-bottom: 4px solid #a6a6a6;
                transform: translateY(0px);
              }
            }
          }
        }
        .recorder {
          &--btn {
            border: none;
            width: 125px;
            height: 125px;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-bottom: 1rem;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            cursor: pointer;
            z-index: 2;
            i {
              font-size: 3.5rem;
            }
            &-grp{
              display: flex;
              flex-direction: column;
              margin-bottom: 6.5rem;
              align-items: center;
              position: relative;
            }
            &-start {
              background: #873fff;
              border-top: 3px solid #b786ff;
              border-bottom: 4px solid #582d99;
            }
            &-stop {
              background: #ff3f8d;
              border-top: 3px solid #ffa4f2;
              border-bottom: 4px solid #af1c58;
            }
            &-text {
              font-size: .85rem;
              font-weight: 500;
              font-style: italic;
              font-family: 'baloo da 2';
              color: #686868;
            }
          }
          &--countdown {
            text-align: center;
            font-family: "baloo da 2", "Lato","Arial",sans-serif;
            margin-bottom: 7.5rem;
            h2 {
              font-size: 1.7rem;
              line-height: 1.8rem;
            }
            p {
              font-size: .85rem;
              font-weight: 500;
              font-style: italic;
            }
          }
        }
      }
    }
  }
  // wave anim
  .wave-anim-grp {
    position: absolute;
    top: 2rem;
    animation-delay: .1s;
    ul{
      width:200px;
      height:45px;
      display:flex;
      justify-content:center;
      align-items:center;
      padding:0;
      margin:0;
      transition:ease 0.2s;
      position:relative;
      li{
        list-style:none;
        height:60px;
        width:4px;
        border-radius:10px;
        background: #9385ff;
        margin:0 6px;
        padding:5px;
        animation-name: wave4;
        animation-duration: 0.3s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        transition:ease 0.2s;
      }
      li:nth-child(2) {
        animation-name: wave2;
        animation-delay:0.2s;
      }
      li:nth-child(3) {
        animation-name: wave3;
        animation-delay:0.23s;
        animation-duration: 0.4s;
      }
      li:nth-child(4) {
        animation-name: wave4;
        animation-delay:0.1s;
        animation-duration: 0.3s;
      }
      li:nth-child(5) {
        animation-delay:0.5s;
        margin-right: 1rem;
      }
      li:nth-child(6) {
        animation-name: wave2;
        animation-duration: 0.5s;
      }
      li:nth-child(8) {
        animation-name: wave4;
        animation-delay:0.4s;
        animation-duration: 0.25s;
      }
      li:nth-child(9) {
        animation-name: wave3;
        animation-delay:0.15s;
      }
      li:nth-child(10) {
        animation-delay:0.5s;
      }
      li:nth-child(11) {
        animation-name: wave2;
        animation-duration: 0.5s;
      }
      li:nth-child(12) {
        animation-name: wave3;
        animation-delay:0.4s;
        animation-duration: 0.25s;
      }
      li:nth-child(13) {
        animation-name: wave4;
        animation-delay:0.15s;
      }
      li:nth-child(14) {
        animation-name: wave4;
        animation-duration: 0.5s;
      }
      li:nth-child(15) {
        animation-name: wave4;
        animation-delay:0.1s;
        animation-duration: 0.5s;
      }
    }
  }

  @keyframes wave1 {
      from {transform:scaleY(1);}
      to {transform:scaleY(0.5);}
  }
  @keyframes wave2 {
      from {transform:scaleY(0.3);}
      to {transform:scaleY(0.6);}
  }
  @keyframes wave3 {
      from {transform:scaleY(0.6);}
      to {transform:scaleY(0.8);}
  }
  @keyframes wave4 {
      from {transform:scaleY(0.2);}
      to {transform:scaleY(0.5);}
  }
  // loading anim - text
  .loader-text {
    &-transcribing, &-marking, &-revising {
      font-weight: bold;
      font-family: 'baloo da 2';
      font-size: 1rem;
      animation: l1 1s linear infinite alternate;
    }
  }
  .loader-text-transcribing:before {
    content:"Transcribing..."
  }
  .loader-text-revising:before {
    content:"Revising..."
  }
  .loader-text-marking:before {
    content:"Marking..."
  }

  @keyframes l1 {to{opacity: 0}}

  // loading anim - shape
  .loader-shape {
    &-transcribing, &-marking, &-revising {
      width: 40px;
      height: 40px;
      --c:no-repeat linear-gradient(orange 0 0);
      background: var(--c),var(--c),var(--c),var(--c);
      background-size: 21px 21px;
      animation: l5 1.5s infinite cubic-bezier(0.3,1,0,1);
    }
    &-transcribing {
      --c:no-repeat linear-gradient(orange 0 0);
    }
    &-marking {
      --c:no-repeat linear-gradient(rgb(0, 255, 145) 0 0);
    }
    &-revising {
      --c:no-repeat linear-gradient(rgb(174, 0, 255) 0 0);
    }
  }
  @keyframes l5 {
    0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}
    33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:60px;height: 60px}
    66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:60px;height: 60px}
    100% {background-position: 100% 0,100% 100%,0    100%,0 0   }
  }

  // misc classes
  .mr-200 {
    margin-right: 200px;
  }
  .bt-remove {
    border-top: 2px solid rgba(0,0,0,0)
  }
  // media queries
  @media only screen and (max-width: 699px) {
    .quiz-header__component {
      padding: 0 16px;
      height: 80px;
    }
  }

  /* Mobile layout fixes for content body */
  @media only screen and (max-width: 699px) {
    .content--question--body {
      flex-direction: column !important;
      align-items: center;
      padding: 1rem;
    }

    .content--question--body--qns-instruction {
      margin-left: 0;
      margin-top: 1.5rem;
      width: 100%;
      text-align: center;
    }

    .content--question--body--qns-instruction--header {
      text-align: center;
    }

    .content--question--body--qns-instruction--text {
      margin-left: 0;
      font-size: 0.9rem;
    }

    .question-audio--container {
      margin-bottom: 1rem;
    }

    .sound-icon-container {
      height: 100px;
      width: 120px;
      font-size: 2.5rem;
    }
  }
</style>
