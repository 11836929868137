<!-- for multiplayer play -->
<template>
  <div class="multiplayer-room__end--component"
    :class="{ 'green-bg': isXmasTheme }">
    <h1 class="animated fadeInUp faster">
      {{ title }}
    </h1>
    <div v-if="isXmasTheme"
      class="multiplayer-room__end--img">
      <img src="../../../assets/panda-boshi.png">
    </div>
    <div v-else>
      <i class="fas fa-trophy animated flipInX"></i>
    </div>
    <div class="multiplayer-room__end--result
      animated fadeInUp faster">
      <!-- placeholder for new high score notifcation -->
      <div class="multiplayer-room__end--result-single"
        v-if="!groupSession">
        <h3 v-if="subject === 'malay'">Markah Keseluruhan: {{ endingAnalytics.score }}</h3>
        <h3 v-else>Final Score: {{ endingAnalytics.score }}</h3>
      </div>
      <div class="multiplayer-room__end--result-group"
      v-if="groupSession">
        <p>Rank {{ endingAnalytics.rank }}</p>
        <span>|</span>
        <p v-if="subject === 'malay'">Markah Keseluruhan: {{ endingAnalytics.score }}</p>
        <p v-else>Final Score: {{ endingAnalytics.score }}</p>
      </div>
    </div>
    <div v-if="subject === 'malay'" class="multiplayer-room__end--text
      animated fadeInUp faster">
      <span>Kamu berjaya menjawab </span>
      <span>{{ endingAnalytics.num_questions_correct }}</span>
      <span>daripada </span>
      <span>{{ endingAnalytics.num_questions_attempted }}</span>
      <!-- Needs to be %.2f -->
      <span>({{ formattedCorrectPercentage }}%)</span>
      <span>soalan betul!!</span>
    </div>
    <div v-else class="multiplayer-room__end--text
      animated fadeInUp faster">
      <span>You answered</span>
      <span>{{ endingAnalytics.num_questions_correct }}</span>
      <span>out of </span>
      <span>{{ endingAnalytics.num_questions_attempted }}</span>
      <!-- Needs to be %.2f -->
      <span>({{ formattedCorrectPercentage }}%)</span>
      <span>questions correctly!</span>
    </div>
    <div class="multiplayer-room__end--btns">
      <!-- <i class="fas fa-hand-paper animated bounceIn"></i> -->
      <!-- <p class="animated fadeInUp faster">- 课室结束，下回再见！ -</p> -->
      <router-link
        v-if="groupSession"
        :to="{name:'main-leaderboard-room-session',
        params: {sessionId: sessionId}}"
        class="multiplayer-room__end--btn
        animated fadeInUp faster delay-1s">
        <span v-if="subject === 'malay'">Sambung</span>
        <span v-else>Continue</span>
      </router-link>
      <a
        v-else
        @click="isRewardClicked = !isRewardClicked"
        class="multiplayer-room__end--btn
        animated fadeInUp faster delay-1s">
        <span v-if="subject === 'malay'">Sambung</span>
        <span v-else>Continue</span>
      </a>
      <reward-popup
        v-if="isRewardClicked"
        :quiz-id="quizId"
        :quiz-type="quizType"
        :coins-to-add="coinsToAdd"
        :diamonds-to-add="diamondsToAdd"
        :diamonds-obtained-this-week="diamondsObtainedThisWeek"
      >
      </reward-popup>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex';

import {Howl} from 'howler';

import RewardComponent
  from '../../learningtrack/assessmentbook/RewardComponent.vue';

export default {
  name: 'MultiplayerRoomEnd',
  components: {
    RewardPopup: RewardComponent,
  },
  props: {
    // whether this is a group session (with teacher) or solo play
    groupSession: Boolean,
    endingAnalytics: Object,
    sessionId: Number,
    quizId: Number,
    quizType: String,
    coinsToAdd: {
      type: Number,
      default: 0,
    },
    diamondsToAdd: {
      type: Number,
      default: 0,
    },
    diamondsObtainedThisWeek: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      backgroundSound: null,
      isRewardClicked: false,
      isXmasTheme: true,
    };
  },
  computed: {
    ...mapState(['subject']),
    title() {
      if (this.isNewHighScore) {
        return this.subject === 'malay' ? 'Rekod Tertinggi Baru!' : 'New High Score!';
      }
      return this.subject === 'malay' ? 'Tahniah': 'Congratulations!';
    },
    isNewHighScore() {
      return (typeof this.endingAnalytics['is_new_high_score']
        === 'undefined') ? false : this.endingAnalytics['is_new_high_score'];
    },
    formattedCorrectPercentage() {
      return Math.floor(this.endingAnalytics.correct_percentage * 100);
    },
  },
  mounted: function() {
    this.backgroundSound = new Howl({
      src: ['../../../assets/sounds/xmas.mp3'],
      autoplay: false,
      loop: true,
    });

    this.backgroundSound.play();
  },
  beforeDestroy() {
    console.log('destroying multiplayer room end');
    this.backgroundSound.stop();
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .delay-1s {
    animation-delay: 0.15s;
  }
  .multiplayer-room__end {
    &--img {
      background: #fff;
      border-radius: 50%;
      padding: 2rem;
      margin: .5rem;
      width: 265px;
      height: 265px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--result {
      &-single {
        text-align: center;
        h2 {
          margin-bottom: -20px;
          font-size: 2rem;
        }
        h3 {
          font-size: 1.6rem;
        }
        p {
          margin-bottom: 0.5rem;
        }
      }
      &-group {
        display: flex;
        span {
          margin: 0 1rem;
        }
        font-size: 1.5rem;
        font-weight: 900;
      }
    }
    &--component {
      height: 100vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #fff;
      background: #ffa500;
      .fa-trophy {
        font-size: 8rem;
        margin: .5rem;
        padding: 2rem;
        border-radius: 50%;
        color: #ffa500;
        background: #fff;
      }
      h1 {
        margin-bottom: .5rem;
        border-bottom: 4px solid #ffffff38;
      }
      // .fa-hand-paper {
      //   font-size: 7.5rem;
      //   margin-bottom: 40px;
      //   margin-left: -45px;
      //   &::before {
      //     display: inline-block;
      //     transform: rotate(20deg);
      //     animation: wave 1.65s alternate infinite;
      //     transform-origin: 50% 90%;
      //     animation-timing-function: ease-in-out;
      //   }
      // }
      // p {
      //   font-weight: 400;
      //   font-size: 1.2rem;
      //   margin-bottom: 20px;
      // }
    }
    &--text {
      max-width: 275px;
      text-align: center;
      line-height: 1.25rem;
      margin-bottom: 1rem;
      padding: 1.5rem 0;
      border-top: 4px solid #ffffff38;
      border-bottom: 4px solid #ffffff38;
    }
    &--btns {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &--btn {
      cursor: pointer;
      margin: 0.25rem;
      display: inline-block;
      padding: 0.25rem 1rem;
      font-weight: 900;
      font-size: 1.1rem;
      border-radius: 5px;
      border: 4px solid #22222280;
      transition: all 0.3s;
      outline: none;
      background: #e8e8e8;
      color: #000;
      text-decoration: none;
      &:hover {
        display: inline-block;
        transform: scale(1.1);
        border: 4px solid #222222;
      }
      &:active {
        display: inline-block;
        transform: scale(1.075);
      }
    }
  }
  .green-bg {
    background: #00b97d;
  }
  @media only screen and (max-width: 415px) {
    .multiplayer-room__end {
      &--component {
        .fa-trophy {
          font-size: 4rem;
        }
      }
      &--text {
        padding: 0.5rem;
        margin-bottom: 0.5rem;
      }
      &--result {
        &-single {
          h2 {
            margin-bottom: -15px;
            font-size: 1.6rem;
          }
          h3 {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 320px) {
    .multiplayer-room__end {
      &--btn {
        font-size: 0.9rem;
        padding: 0 1rem;
      }
      &--component {
        h1 {
          font-size: 1.6rem;
        }
        .fa-trophy {
          font-size: 3rem;
          padding: 1rem;
        }
      }
      &--result {
        &-single {
          h2 {
            margin-bottom: -15px;
            font-size: 1.4rem;
          }
          h3 {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

</style>
