import _filter from 'lodash.filter';

const PARENT_AUTHENTICATION_EXPIRY_MINUTES = 1;

export const isPreschool = (state) => {
  const preschoolLevels = ['role-playing', 'nursery_two',
    'kindergarten_one', 'kindergarten_two'];
  return preschoolLevels.indexOf(state.level) > -1;
};

export const isPrimarySchool = (state) => {
  const primarySchoolLevels = ['primary_one', 'primary_two', 'primary_three', 'primary_four', 'primary_five', 'primary_six'];
  return primarySchoolLevels.indexOf(state.level) > -1;
};

export const compareLevels = () => {
  return (aLevel, bLevel) => {
    const SCHOOL_LEVELS_FOR_SORTING = {
      'nursery_two': 0,
      'kindergarten_one': 1,
      'kindergarten_two': 2,
      'primary_one': 3,
      'primary_two': 4,
      'primary_three': 5,
      'primary_four': 6,
      'primary_five': 7,
      'primary_six': 8,
    };

    if (!(aLevel in SCHOOL_LEVELS_FOR_SORTING) || !(bLevel in SCHOOL_LEVELS_FOR_SORTING)) {
      return 0;
    }

    if (aLevel === bLevel) {
      return 0;
    }

    return SCHOOL_LEVELS_FOR_SORTING[aLevel] < SCHOOL_LEVELS_FOR_SORTING[bLevel] ? -1 : 1;
  };
};

export const chineseLevel = (state) => {
  const map = {
    'primary_one': '一年级',
    'primary_two': '二年级',
    'primary_three': '三年级',
    'primary_four': '四年级',
    'primary_five': '五年级',
    'primary_six': '六年级',
  };

  if (state.level in map) {
    return map[state.level];
  }
  return '';
};

export const cocosPetBattleUrl = (state) => {
  const baseUrl = state.subject === 'chinese'
    ? 'https://petbattle.s3-ap-southeast-1.amazonaws.com/index.html'
    : 'https://malaypetbattle.s3-ap-southeast-1.amazonaws.com/index.html';

  if (!state.cocos.quick_token || state.cocos.quick_token === '') {
    return baseUrl;
  }
  return baseUrl + '?quick=' + state.cocos.quick_token;
};

// show levels that are below and include current level but do not show levels that are above student's level
// for instance, if child is P3, show P1, P2 and P3 only
// for preschool, if child is K1, show N2 and K1
export const validLevelOptions = (state, getters) => {
  const PRIMARY_SCHOOL_LEVELS = [
    {label: 'Primary One', code: 'primary_one'},
    {label: 'Primary Two', code: 'primary_two'},
    {label: 'Primary Three', code: 'primary_three'},
    {label: 'Primary Four', code: 'primary_four'},
    {label: 'Primary Five', code: 'primary_five'},
    {label: 'Primary Six', code: 'primary_six'},
  ];

  const PRESCHOOL_LEVELS = [
    {label: 'Nursery Two', code: 'nursery_two'},
    {label: 'Kindergarten One', code: 'kindergarten_one'},
    {label: 'Kindergarten Two', code: 'kindergarten_two'},
  ];

  const NEXT_LEVELS = {
    'primary_one': 'primary_two',
    'primary_two': 'primary_three',
    'primary_three': 'primary_four',
    'primary_four': 'primary_five',
    'primary_five': 'primary_six',
    // only up to P6
    'primary_six': 'primary_six',
  };

  const LEVELS = getters.isPreschool ? PRESCHOOL_LEVELS : PRIMARY_SCHOOL_LEVELS;
  // MAGIC NUMBER - to be turn on durin nov/dec holidays
  const showUpcomingLevel = false;

  let i = 0;
  for (i = 0; i < LEVELS.length; i++) {
    if (!showUpcomingLevel) {
      if (LEVELS[i]['code'] === state.level) {
        break;
      }
    } else {
      if (LEVELS[i]['code'] === NEXT_LEVELS[state.level]) {
        break;
      }
    }
  }

  if (i >= LEVELS.length) return [];
  return LEVELS.slice(0, i + 1);
};

export const validLevels = (state, getters) => {
  const toReturn = [];
  for (const row of getters.validLevelOptions) {
    toReturn.push(row['code']);
  }
  return toReturn;
};

// core level refers to last 3 levels for P6 student (e.g. P4-P6) or last 2 levels for P5 and below
// this is because students need to revise materials from not just their current level
export const coreLevels = (state, getters) => {
  const numLevelsToShow = state.level === 'primary_six' ? 3 : 2;
  return getters.validLevels.slice(Math.max(getters.validLevels.length - numLevelsToShow, 0));
};

// for a P5 child, will show P4-P5
export const coreTitle = (state, getters) => {
  if (getters.coreLevels.length === 0) return '';
  // have to recopy shorternLevel since can't figure out how to get filters to work within getters
  function shorternLevel(val) {
    const map = {
      'nursery_two': 'N2',
      'kindergarten_one': 'K1',
      'kindergarten_two': 'K2',
      'primary_one': 'P1',
      'primary_two': 'P2',
      'primary_three': 'P3',
      'primary_four': 'P4',
      'primary_five': 'P5',
      'primary_six': 'P6',
      'others': 'Others',
    };
    if (val in map) {
      return map[val];
    }
    return val;
  }

  const firstElement = shorternLevel(getters.coreLevels[0]);
  const lastElement = shorternLevel(getters.coreLevels[getters.coreLevels.length - 1]);
  // for N2, P1, just return single element
  return (firstElement === lastElement) ? firstElement : `${firstElement} - ${lastElement}`;
};

export const clickQuests = (state) => {
  return _filter(state.quests, (quest) => {
    return quest.category === 'click';
  });
};

export const isDiamond = (state) => {
  return state.vocabSubscription['plan'] === 'diamond' || state.vocabSubscription['plan'] === 'psle_success_plan' || state.vocabSubscription['plan'] === 'diamond_sibling';
};


export const isPSLESuccessPlan = (state) => {
  return state.vocabSubscription['plan'] === 'psle_success_plan';
};

// method-style access (because we want to pass parameters)
// to be removed
// https://vuex.vuejs.org/guide/getters.html
// takes a learning track id, and returns the learning track object
export const getNotificationByCategories = (state) => {
  return (category, subCategory) => {
    return _filter(state.notifications, (notification) => {
      return notification.category === category &&
        notification.sub_category === subCategory;
    })[0];
  };
};

export const hasUsedFeature = (state) => {
  return (featureName) => {
    // temporary fix  - 5 may
    if (typeof state.usedFeatures === 'undefined') return false;
    if (!(featureName in state.usedFeatures)) return false;
    return state.usedFeatures[featureName];
  };
};

// after parent authenticates, dont need to authenticate for next x secs
export const isParentAuthenticated = (state) => {
  if (!state.timeLastParentAuthenticated) return false;
  return (new Date() - state.timeLastParentAuthenticated) <= 1000 * PARENT_AUTHENTICATION_EXPIRY_MINUTES;
};

export const sortedMissions = (state) => {
  if (!state.missions) return {daily: [], weekly: []};

  const sortMissionArray = (missions) => {
    return [...missions].sort((a, b) => {
      // Status 2 missions go to top (completed but unclaimed)
      if (a.status === 2 && b.status !== 2) return -1;
      if (b.status === 2 && a.status !== 2) return 1;

      // Status 3 missions go to bottom (completed and claimed)
      if (a.status === 3 && b.status !== 3) return 1;
      if (b.status === 3 && a.status !== 3) return -1;

      // For same status or other status, sort alphabetically by name
      return a.name.localeCompare(b.name);
    });
  };

  return {
    daily: sortMissionArray(state.missions.daily || []),
    weekly: sortMissionArray(state.missions.weekly || []),
  };
};

// method style access because we want to pass in a parameter
export const getMissions = (state) => {
  return (missionName) => {
    const dailyMissions = _filter(state.missions.daily, (mission) => {
      return mission.name === missionName;
    });
    const weeklyMissions = _filter(state.missions.weekly, (mission) => {
      return mission.name === missionName;
    });
    return dailyMissions.concat(weeklyMissions);
  };
};

export const isAllDailyMissionsDone = (state) => {
  const uncompletedUnclaimedDailyMissions = _filter(state.missions.daily, (mission) => {
    return mission.status <= 2;
  });
  return uncompletedUnclaimedDailyMissions.length === 0;
};
