import Vue from 'vue';

// used for both comprehension and oral
const getDefaultState = () => {
  return {
    // this tracks all the words in the wordlist, but this takes longer to pull so
    // we only pull it in "My Wordlist"
    wordlist: [],
    // this tracks the ids - we pull this during initial load - useful fro comprehension, etc
    wordlistIds: {},
    // tracks writing proficiency data
    writingProficiency: null,
  };
};

const state = getDefaultState();

export const analyticsModule = {
  namespaced: true,
  state,
  mutations: {
    resetStateMutation(state) {
      Object.assign(state, getDefaultState());
    },
    // start wordlist
    setWordlist(state, value) {
      Vue.set(state, 'wordlist', value);
      const wordIdsDict = {};
      for (const word of value) {
        wordIdsDict[word.word_id] = true;
      }

      this.commit('analytics/setWordlistIds', wordIdsDict);
    },
    modifyIsRevised(state, payload) {
      const index = state.wordlist.findIndex((w) => w.word_id === payload.wordId);
      if (index === -1) return;
      Vue.set(state.wordlist[index], 'is_revised', payload.value);
    },
    removeFromWordlist(state, wordId) {
      const index = state.wordlist.findIndex((w) => w.word_id === wordId);
      state.wordlist.splice(index, 1);
      this.commit('analytics/removeFromWordlistIds', wordId);
    },
    // end wordlist (the array)
    // helper functions for wordlistIds (the dict)
    setWordlistIds(state, value) {
      Vue.set(state, 'wordlistIds', value);
    },
    addToWordlistIds(state, value) {
      Vue.set(state.wordlistIds, value, true);
    },
    removeFromWordlistIds(state, value) {
      Vue.delete(state.wordlistIds, value);
    },
    // end helper function for wordlistIds

    // Writing proficiency
    setWritingProficiency(state, value) {
      Vue.set(state, 'writingProficiency', value);
    },
  },
  getters: {
  },
};
