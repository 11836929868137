<template>
  <div
    @click="showMoreRoutes = !showMoreRoutes"
    class="mobile-nav-route--more animated fadeIn faster">
    <!-- for non preschool, there is no read section on main bar; so show on more -->
    <router-link
      v-if="!isPreschool"
      class="mobile-nav-route--more-route"
      :to="{name: 'library-overview'}"
      @click.native="setIsMobileNavMoreMenuShown(false)">
      <i class="fas fa-book-reader"></i>
      Read
    </router-link>
    <!-- don't need play for now since we can click on avatar; unless we add more stuff to it -->
<!--       <router-link class="mobile-nav-route--more-route"
      :to="{name: 'new-play-avatar'}"
      @click.native="hideMoreRoutes()">
      <i class="fas fa-gamepad"></i>
      Play
    </router-link> -->
    <router-link class="mobile-nav-route--more-route"
      :to="{name: 'main-leaderboard-coin'}"
      @click.native="setIsMobileNavMoreMenuShown(false)">
      <i class="fas fa-trophy"></i>
      Leaderboard
    </router-link>
    <router-link
      v-if="isStoreEnabled"
      class="mobile-nav-route--more-route"
      :to="{name: 'new-shop-overview'}">
        <i class="fas fa-store"></i>
        Store
    </router-link>
    <router-link class="mobile-nav-route--more-route"
      :to="{name: 'analytics-overview'}"
      @click.native="setIsMobileNavMoreMenuShown(false)">
      <i class="fas fa-chart-pie"></i>
      Analytics
    </router-link>
    <router-link class="mobile-nav-route--more-route"
      :to="{name: 'new-settings-overview'}"
      @click.native="setIsMobileNavMoreMenuShown(false)">
      <i class="fas fa-cog"></i>
      Settings
    </router-link>
    <router-link class="mobile-nav-route--more-route"
      v-if="siblings.length > 0"
      :to="{name: 'new-settings-switch-user'}"
      @click.native="setIsMobileNavMoreMenuShown(false)">
      <i class="fa-solid fa-users"></i>
      Switch User
    </router-link>
    <a class="mobile-nav-route--more-route"
      @click.prevent="logout">
      <i class="fa-solid fa-solid fa-right-from-bracket"></i>
      Logout
    </a>
  </div>
</template>

<script>

import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'MobileNavMoreMenu',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['studentName', 'avatar', 'siblings', 'isStoreEnabled', 'isMoreRoutesShown']),
    ...mapGetters(['isPreschool']),
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['setIsMobileNavMoreMenuShown']),
    ...mapActions(['resetState']),
    logout() {
      this.resetState();

      // redirects to login page
      this.$router.push({name: 'login-main'});
    },
  },
};
</script>

<style lang="scss" scoped>
  .mobile-nav-route--more {
    position: fixed;
    height: calc(100% - 55px);
    width: 100vw;
    background: #000000db;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    &-route {
      width: 100%;
      background: #4396fd;
      text-decoration: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0.25rem;
      border-bottom: 3px solid rgba(0,0,0,.168627451);
      color: #d9eaff;
      font-weight: 600;
      i {
        margin-right: .75rem;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: .5rem;
      }
      &:last-child {
        border-bottom: none;
      }
      &:first-child {
        border-top: 5px solid #5262d1;
      }
    }
  }

</style>
