<template>
  <div v-if="selectedWordInfo" class="ai-practice-modal__component animated fadeIn faster">
    <div class="ai-practice-modal__content">
      <i @click="toggleAiPracticeModal"
        class="fa-solid fa-xmark"></i>
      <!-- USER INPUT STATE -->
      <div v-if="state == 0" class="ai-practice-modal__content--user-input">
        <h2>{{ selectedWordInfo.word }}</h2>
        <textarea class="chinese-character" v-model='userAnswer'
          @keyup.enter="submitAnswer"
          :placeholder="placeholderText"></textarea>
        <button @click="submitAnswer"
          :disabled="userAnswer === ''">
          Submit
        </button>
      </div>
      <div v-if="state == 1" @click.prevent="state = 2"
        class="ai-practice-modal__content--loading">
        <div class="loading-anim"></div>
        <p>Analyzing...</p>
      </div>
      <div v-if="state == 2"
        class="ai-practice-modal__content--feedback">
        <h2>AI Feedback</h2>
        <!-- <p class="ai-practice-modal__content--feedback-text chinese-character">
          这位老人<span class="error">上脸</span><span class="correction">脸上</span>总是挂着笑，看起来慈眉善目的。
        </p> -->
        <language-feedback
          v-for="(entry, index) in languageBreakdownData"
          :key="`language_${index}`"
          subject="chinese"
          :show-deleted-words="true"
          :info="entry">
        </language-feedback>
          <button @click="toggleAiPracticeModal">
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex';

import axios from 'axios';

import LanguageFeedbackComponent from '../../oral/components/LanguageFeedbackComponent.vue';

const MINIMUM_CHARACTERS_IN_SENTENCE = 10;

export default {
  name: 'AiPracticeModal',
  components: {
    LanguageFeedback: LanguageFeedbackComponent,
  },
  props: {
  },
  data() {
    return {
      userAnswer: '',
      state: 0,
      fetching: false,
      languageBreakdownData: {},
    };
  },
  computed: {
    ...mapState('composition', ['selectedWordInfo']),
    placeholderText() {
      return `请用【${this.selectedWordInfo.word}】造句...`;
    },
  },
  mounted() {
  },
  methods: {
    toggleAiPracticeModal() {
      this.$emit('toggleAiPracticeModal');
    },
    containsEnglish(sentence) {
      return /[a-zA-Z]/.test(sentence);
    },
    stripPunctuationAndSpaces(text) {
      return text.replace(/[\s\u2000-\u206F\u3000-\u303F\uFF00-\uFFEF。，！？；：""''（）]/g, '');
    },
    submitAnswer() {
      // validations
      this.userAnswer = this.userAnswer.trim();
      if (this.userAnswer.length < MINIMUM_CHARACTERS_IN_SENTENCE) {
        alert('Please enter at least 10 characters.');
        return;
      }
      if (this.containsEnglish(this.userAnswer)) {
        alert('Please do not include English in your sentence.');
        return;
      }

      const cleanUserAnswer = this.stripPunctuationAndSpaces(this.userAnswer);
      const cleanTargetWord = this.stripPunctuationAndSpaces(this.selectedWordInfo.word);

      if (cleanUserAnswer.indexOf(cleanTargetWord) === -1) {
        alert(`Please include ${this.selectedWordInfo.word} in your sentence.`);
        return;
      }

      if (this.fetching) return;
      this.fetching = true;
      this.state = 1;

      axios
          .post(`/vocab/sentence_practice/`, {
            wordInfo: this.selectedWordInfo,
            userAnswer: this.userAnswer,
          }).then((response) => {
            console.log(response.data);
            this.state = 2;
            this.languageBreakdownData = response.data;
            this.fetching = false;
          })
          .catch((error) => {
            console.log(error);
            this.fetching = false;
            this.state = 0;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  .ai-practice-modal {
    &__component {
      position: fixed;
      background: #000000db;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__content {
      background: #fff;
      padding: 2rem 3rem;
      border-radius: .75rem;
      position: relative;
      width: 660px;
      min-height: 316px;
      display: flex;
      div {
        // display: flex;
        gap: .75rem;
        flex-direction: column;
        // flex: 1;
      }
      button {
        font-family: "baloo da 2";
        font-weight: 600;
        border-radius: .75rem;
        font-size: 1.05rem;
        border: 2px solid rgba(0, 0, 0, 0);
        border-bottom: 4px solid #5e46b0;
        text-transform: uppercase;
        padding: 8px 0;
        color: #fff;
        background: #826ad6;
        width: 100%;
        margin-top: .5rem;
        cursor: pointer;
        &:active {
          border-bottom: 2px solid #5e46b0;
          margin-top: calc(.5rem + 2px);
        }
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background: #ddd;
          border-bottom: 4px solid #d2d2d2;
          &:active {
            transform: none;
            margin-top: .5rem;
            border-bottom: 4px solid #d2d2d2;
          }
        }
      }
      .fa-xmark {
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
        font-size: 1.5rem;
      }
      &--user-input {
        textarea {
          border: 2px solid #e7e7e7;
          border-radius: .5rem;
          height: 100px;
          width: 540px;
          padding: .75rem;
          font-size: 1.25rem;
          display: block;
          outline: none;
          resize: none;
          // line-height: 1rem;
          &::placeholder {
            font-size: .9rem;
          }
        }
      }
      &--loading {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
      }
      &--feedback {
        display: flex;
        flex: 1;
        &-text {
          flex: 1;
          border: 2px solid #e7e7e7;
          border-radius: .5rem;
          padding: .75rem;
          font-size: 1.25rem;
          display: block;
          outline: none;
          resize: none;
        }
        .error {
          color: #e26e7c;
          text-decoration: line-through;
        }
        .correction {
          color: #54d1b7;
        }
        button {
          background: #6ad6b8;
          border-bottom: 4px solid #3c9e83;
          &:active {
            border-bottom: 2px solid #3c9e83  ;
          }
        }
      }
    }
  }
  // loading anim
  .loading-anim {
    width: 125px;
    margin-bottom: 1rem;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 15px solid #0000;
    border-right-color: #a900ff97;
    position: relative;
    animation: l24 1s infinite linear;
    // background: #e7e7e7;
  }
  .loading-anim:before,
  .loading-anim:after {
    content: "";
    position: absolute;
    inset: -15px;
    border-radius: 50%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }
  .loading-anim:after {
    animation-duration: 4s;
  }
  @keyframes l24 {
   100% {transform: rotate(1turn)}
  }

  @media only screen and (max-width: 690px) {
    .ai-practice-modal__content {
      margin: 0 1rem;
      padding: 1.5rem 2rem;
    }
  }
  @media only screen and (max-width: 520px) {
    .ai-practice-modal__content {
      margin: 0 1rem;
      padding: 1rem;
    }
  }
</style>
