<template>
  <div class="diamond-shop__item-list--component animated fadeIn faster">
    <div class="text--physical" v-if="shopCategory == 'physical'">
      We provide 1x free shipping/yr - please contact 9820-7272 for delivery.
    </div>
    <ul class="diamond-shop__item-list">
      <shop-item
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        :ordered-counts="orderedCounts"
        :popup-toggle="popupToggle"
      ></shop-item>
    </ul>
  </div>
</template>

<script>

import ShopItemComponent
  from './ItemComponent.vue';

export default {
  name: 'ShopItemList',
  components: {
    ShopItem: ShopItemComponent,
  },
  props: {
    items: Array,
    orderedCounts: Object,
    popupToggle: Function,
    shopCategory: String,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .diamond-shop {
    &__item-list {
      display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
        padding: 0 1rem 5rem 1rem;
      &--component {
        height: 100%;
        overflow: scroll;
      }
    }
  }
  .text--physical {
    width: 100%;
    text-align: center;
    color: #fff;
    font-style: italic;
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 1350px) {
    .diamond-shop__item-list {
      grid-template-columns: 1fr 1fr;
    }
    .text--physical {
      font-size: .8rem;
    }
  }
  @media only screen and (max-width: 1115px) {
    .diamond-shop__item-list--component {
      margin-bottom: 32px;
    }
  }
  @media only screen and (max-width: 1115px) {
  }

  @media only screen and (max-width: 520px) {
    .text--physical {
      font-size: .6rem;
    }
    .diamond-shop__item-list {
      gap: .75rem;
      padding: 0 .75rem 5rem .75rem;
    }
  }
</style>
