<template>
  <div class="multiplayer-room__question--component">
    <div id="question"
      class="multiplayer-room__question--container">
      <div v-if="!questionData.image_url && !isWordQuestion"
        class="multiplayer-room__question--text
        animated bounceIn">
          <div class="chinese-character" v-html="questionText">
          </div>
        </div>
      <div class="multiplayer-room__question--content-container" v-if="questionData.image_url">
        <img :src="questionData.image_url" class="question-image">
        <div class="question-text" v-html="questionText"></div>
      </div>
    </div>
    <div>
    </div>
    <div class="multiplayer-room__question--options-container">
      <ul v-if="shuffledChoices && shuffledChoices.length > 0"
        :class="{greyed: !canAnswer}"
        class="multiplayer-room__question--options">
        <li
          v-for="(choice, index) in shuffledChoices"
          :key="index"
          @click="_answerQuestion(choice)"
          :class="questionClass"
          class="multiplayer-room__question--btn animated bounceIn faster">
            {{ choice }}
        </li>
      </ul>
      <div class="multiplayer-room__question--timer-container
          animated bounceIn faster">
        <timer
          :timer-duration="timerDuration"
          :hide-when-done="true"
          @end="enableAnswering"
          class="multiplayer-room__question--timer"
        ></timer>
      </div>
    </div>
  </div>
</template>
<script>

import TimerComponent
  from './../../common/TimerComponent.vue';

import {utilities} from '../../../mixins/utilities';

export default {
  name: 'MultiplayerRoomQuestion',
  components: {
    Timer: TimerComponent,
  },
  props: {
    questionData: Object,
    answerQuestion: Function,
    timerDuration: Number,
  },
  mixins: [utilities],
  data() {
    return {
      canAnswer: false,
    };
  },
  computed: {
    shuffledChoices() {
      if (!this.questionData) return;

      return this.shuffleArray(this.questionData.choices);
    },
    isWordQuestion() {
      return ['spelling', 'meaning', 'recognition'].indexOf(this.questionData.question_type) !== -1;
    },
    questionText() {
      if (!this.questionData || this.questionData === {}) return;
      if (this.isWordQuestion) return '';
      const text = this.$options.filters.hyrateQuestionText(
          this.questionData.text);
      return (text + '\n');
    },
    questionClass() {
      // validation
      if (!this.questionData || this.questionData === {}) return;
      if (!this.questionData.answer_type ||
        this.questionData.answer_type === '') return;

      return this.questionData.answerType === 'hypy' ?
        'hypy' : 'chinese-character';
    },
  },
  mounted() {
    console.log(this.questionData);
  },
  methods: {
    // only allow answering of questions
    // after timer has expired
    enableAnswering() {
      this.canAnswer = true;
    },
    _answerQuestion(choice) {
      if (!this.canAnswer) return;
      // calls the answerQuestion callback
      this.answerQuestion(choice);
    },
  },
};
</script>

<style lang="scss" scoped>
  .greyed {
    opacity: 0.3;
  }

  .multiplayer-room__question {
    &--component {
      height: calc(100% - 122px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      background: #e8e8e8;
    }
    &--container {
      height: 50%;
      position: relative;
      text-align: center;
      margin: 0 20px;
    }
    &--index {
      position: absolute;
      top: 20px;
      width: 100%;
      font-size: 1.2rem;
      font-weight: 900;
      span {
        background: #8d40b5;
        padding: 2.5px 55px;
        color: #fff;
        border-radius: 20px;
      }
    }
    &--text {
      font-size: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    &--content {
      &-container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .question-image {
          height: 75%;
        }
        .question-text {
          height: 25%;
          display: flex;
          align-items: center;
        }
      }
    }
    &--options {
      transition: all 0.3s;
      height: 100%;
      // width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 5px;
      &-container {
        height: calc(50% - 10px);
        width: calc(100% - 10px);
        position: relative;
      }
    }
    &--timer {
      left: -50%;
      &-container {
        position: absolute;
        left: 50%;
        top: 25%;
        animation-delay: 0.3s;
      }

    }
    &--btn {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(50% - 10px);
      height: calc(50% - 10px);
      cursor: pointer;
      margin: 5px;
      font-size: 2.25rem;
      color: #fff;
      border-radius: 3px;
      transition: all 0.25s;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
      &:nth-child(4) {
        background: #fb5784;
        animation-delay: 0.225s;
      }
      &:nth-child(3) {
        background: #7e55e0;
        animation-delay: 0.2s;
      }
      &:nth-child(2) {
        background: #efad1c;
        animation-delay: 0.175s;
      }
      &:nth-child(1) {
        background: #1abfa1;
        animation-delay: 0.15s;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .multiplayer-room__question--text {
      font-size: 2.25rem;
    }
    .multiplayer-room__question--btn {
      font-size: 2rem;
    }
  }
  @media only screen and (max-width: 860px) {
    .multiplayer-room__question--text {
      font-size: 2rem;
    }
    .multiplayer-room__question--btn {
      font-size: 1.75rem;
    }
  }
  @media only screen and (max-width: 680px) {
    .multiplayer-room__question--text {
      font-size: 1.75rem;
    }
    .multiplayer-room__question--btn {
      font-size: 1.5rem;
    }
  }
  @media only screen and (max-width: 560px) {
    .multiplayer-room__question--text {
      font-size: 1.5rem;
    }
    .multiplayer-room__question--btn {
      font-size: 1.25rem;
    }
  }
  @media only screen and (max-width: 360px) {
    .multiplayer-room__question--text {
      font-size: 1.25rem;
    }
    .multiplayer-room__question--btn {
      font-size: 1rem;
    }
  }

</style>
