<template>
  <div class="main-profile__component">
    <div
      v-if='!fetchingInitialData'
      class="profile__container">
      <div v-if="isPSLESuccessPlan" class="profile__container--label-psp">
        PSLE Success Plan
      </div>
      <!-- normal mode -->
      <div v-if="!isMasteryModeEnabled" class="profile__container--content">
        <div class="profile__container--content-row-1">
          <user-level-progressbar :num-known-words="totalKnownWords"
          class="animated bounceIn"></user-level-progressbar>
          <div class="profile__container--content-row-1--btns">
            <a :href="cocosPetBattleUrl" target="_blank" class="animated bounceIn delay-1">
              <div class="pet-battle">
                <div class="pet-battle--bg-gradient"></div>
                <div class="pet-battle--text">
                  <h2 class="pet-battle--text-main">
                    Pet Battle
                  </h2>
                  <p class="pet-battle--text-sub">
                    Have what it takes to win? Join a Pet Battle now!
                  </p>
                </div>
              </div>
            </a>
            <router-link
              :to="{name: 'new-play-fishing-game'}"
              tag="p"
              class="fishing-game animated bounceIn delay-2">
              <div class="fishing-game--bg-gradient"></div>
                <div class="fishing-game--text">
                  <h2 class="fishing-game--text-main">
                    Fishing Game
                  </h2>
                  <p class="fishing-game--text-sub">
                    Become a grandmaster fisherman! Try it now!
                  </p>
                </div>
            </router-link>
          </div>
          <!-- for now, cannot use component as external a tag -->
        </div>
        <div class="profile__container--content-row-2">
          <profile-page-button
            type="dailyChallenge"
            title="Daily Challenge"
            subtitle="Help repair the Orb of Knowledge! Take a Quiz now!"
            router-link-name="daily-challenge-overview"
            class="animated bounceIn delay-2"
          ></profile-page-button>
          <!-- malay oral -->
          <!-- v-if="subject === 'malay'" -->
          <profile-page-button
            v-if="subject === 'malay'"
            type="malaySpeaking"
            title="Speech King"
            subtitle="Practise Speaking!"
            router-link-name="new-oral-voicebot-index"
            class="animated bounceIn delay-2"
          ></profile-page-button>

          <!-- for preschool, go to library; for P1-P4, show latest video lesson, for P5-P6, show compo king -->
          <div class="misc-carousel animated bounceIn delay-3">
            <profile-page-button
              v-if="subject === 'chinese' && isPreschool"
              type="stories"
              title="Story Lessons"
              subtitle="Watch the latest story!"
              router-link-name="library-overview"
            ></profile-page-button>
            <!-- video-lesson-compo-main-latest -->
            <profile-page-button
              v-if="subject === 'chinese' && !isPreschool && showCompoKing"
              type="videoLessons"
              title="Compo King"
              subtitle="Practise Compo!"
              router-link-name="new-composition-overview"
            ></profile-page-button>
            <!-- need to fix subtitle; should by dynamic -->
            <profile-page-button
              v-if="subject === 'chinese' && !isPreschool && !showCompoKing"
              type="videoLessons"
              title="Video Lessons"
              subtitle="Latest Video lesson!"
              router-link-name="video-lesson-main-latest"
            ></profile-page-button>
          </div>
        </div>
      </div>
      <!-- mastery mode -->
      <div v-if="isMasteryModeEnabled" class="profile__container--content--mastery">
        <div class="profile__container--content-row-1">
          <div class="mastery-mode">
            <div class="mastery-mode--quiz">
              <div class="mastery-mode--quiz--header">
                <div class="mastery-mode--quiz--header-main">
                  <h2>Mastery Mode</h2>
                  <h3>Strengthen Your Foundation</h3>
                </div>
                <div class="mastery-mode--quiz--header-sub">
                  <h3>{{ masteryMode.level | splitCapitalise }}</h3>
                  <router-link
                    :to="{name: 'new-settings-mastery-mode'}">
                    <i class="fa-solid fa-gear"></i>
                  </router-link>
                </div>
              </div>
              <div @click="startQuiz" class="mastery-mode--quiz--btn-start">
                Start Quiz
              </div>
              <img src="../../../assets/profile/bg-mastery-2.png" class="mastery-mode--avatar"/>
            </div>
            <div class="mastery-mode--reward">
              <div class="mastery-mode--reward--diamond">
                <!-- num of diamonds reward -->
                <p class="mastery-mode--reward--diamond--count">5</p>
                <img src="../../../assets/profile/diamond.png" class="mastery-mode--reward--diamond--img"/>
              </div>
              <div class="mastery-mode--reward--progressbar">
                <div class="mastery-mode--reward--progressbar--filled"></div>
              </div>
              <div class="mastery-mode--reward--count--grp">
                <span class="mastery-mode--reward--count">8 / 10</span>
                <i class="fa-solid fa-crown"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="profile__container--content-row-2">
          <a :href="cocosUrl" target="_blank" class="animated bounceIn delay-1">
            <div class="pet-battle">
              <div class="pet-battle--bg-gradient"></div>
              <div class="pet-battle--text">
                <h2 class="pet-battle--text-main">
                  Pet Battle
                </h2>
              </div>
            </div>
          </a>
          <router-link
            :to="{name: 'new-play-fishing-game'}"
            tag="p"
            class="fishing-game animated bounceIn delay-2">
            <div class="fishing-game--bg-gradient"></div>
              <div class="fishing-game--text">
                <h2 class="fishing-game--text-main">
                  Fishing Game
                </h2>
              </div>
          </router-link>
          <profile-page-button
            type="dailyChallenge"
            title="Daily Challenge"
            subtitle="Help repair the Orb of Knowledge! Take a Quiz now!"
            router-link-name="daily-challenge-overview"
            class="animated bounceIn delay-2 daily-challenge"
          ></profile-page-button>
        </div>
      </div>
    </div>
    <narrator-popup
      v-if="showOnboardingPopup"
      :dialogue-data="dialogueData"
      :end-callback="onCloseOnboardingPopup">
    </narrator-popup>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapMutations, mapGetters} from 'vuex';
// https://www.npmjs.com/package/large-small-dynamic-viewport-units-polyfill
import 'large-small-dynamic-viewport-units-polyfill';

// start profile components
import UserLevelProgressbarComponent
  from './components/UserLevelProgressbarComponent.vue';

import ProfilePageButtonComponent
  from './components/ProfilePageButtonComponent.vue';

import NarratorPopupComponent
  from '../../common/NarratorPopupComponent.vue';

export default {
  name: 'ProfileMain',
  components: {
    UserLevelProgressbar: UserLevelProgressbarComponent,
    ProfilePageButton: ProfilePageButtonComponent,
    NarratorPopup: NarratorPopupComponent,
  },
  data() {
    return {
      fetching: false,
      masteryModeData: {
        level: 'primary_one',
      },
    };
  },
  computed: {
    ...mapState(['fetchingInitialData', 'totalKnownWords', 'level', 'studentName', 'subject', 'masteryMode']),
    ...mapGetters(['isPreschool', 'cocosPetBattleUrl', 'isMasteryModeEnabled', 'isPSLESuccessPlan']),
    dialogueData() {
      return [
        {
          narrator: 'flamebun',
          texts: ['Welcome to Boshi Land', 'Boshi Land is inhabited by cute pets like me that you can befriend.'],
        },
        {
          narrator: 'mangsha',
          texts: ['Work with your pets to complete missions to gain diamonds, and exchange them for rewards like Roblox and Popular vouchers!'],
        },
        {
          narrator: 'rakoon',
          texts: ['A world of dreams and adventures is just ahead! Let\'s go!'],
        },
      ];
    },
    // we don't have video lesson for P5-P6, so show compo king
    showCompoKing() {
      return this.level === 'primary_five' || this.level === 'primary_six';
    },
    showOnboardingPopup() {
      return !this.fetchingInitialData && !this.hasUsedFeature()('onboarding_popup_main_page');
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['addToUsedFeatures']),
    ...mapGetters(['hasUsedFeature']),
    startQuiz() {
      this.$router.push({name: 'new-revision-quiz'});
    },
    onCloseOnboardingPopup() {
      axios
          .post('/api/v1/core/student/used_features/', {
            featureName: 'onboarding_popup_main_page',
          });
      // this will automatically hide the popup
      this.addToUsedFeatures('onboarding_popup_main_page');
      this.$router.push({name: 'new-missions-overview'});
    },
  },
};
</script>


<style lang="scss" scoped>
  .delay-1 {
    animation-delay: 0.1s;
  }
  .delay-2 {
    animation-delay: 0.2s;
  }
  .delay-3 {
    animation-delay: 0.3s;
  }

  a {
    text-decoration: none;
  }
  .main-profile__component {
    width: calc(100% - 337px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    &::-webkit-scrollbar {
      display: none;
    }
    flex: 1;
    height: 100vh; /* For browsers that don't support CSS variables */
    height: calc(var(--1dvh, 1vh) * 100); /* This is the "polyfill" */
    height: 100dvh; /* This is for future browsers that support svh, dvh and lvh viewport units */
    color: #000;
  }
  .profile__container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    &--label-psp {
      display: none;
      background: #5fa0fb;
      font-size: .75rem;
      margin-bottom: .5rem;
      color: #fff;
      padding: .15rem .75rem;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      font-weight: 800;
    }
    &--content {
      width: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-row {
        &-1,
        &-2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .pet-battle,
          .fishing-game {
            &:hover {
              transform: scale(1.02);
            }
            transition: 0.2s all linear;
            cursor: pointer;
            position: relative;
            margin-left: 2rem;
            background-position: center;
            background-size: cover;
            border-radius: .75rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 497px;
            height: 200px;
            &--bg-gradient {
              width: 100%;
              position: absolute;
              bottom: 0;
              z-index: 1;
              height: 60%;
              border-radius: 0.75rem;
              overflow: hidden;
              background: linear-gradient(to top, #c200ffb0, transparent)
            }
            &--text {
              z-index: 2;
              border: 4px solid #00000024;
              border-radius: 0.75rem;
              height: 95%;
              width: 95%;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              flex-direction: column;
              color: #fff;
              &-main {
                font-size: 1.75rem;
                margin-bottom: -0.75rem;
                text-shadow: 0px 4px rgb(0 0 0 / 60%);
              }
              &-sub {
                font-size: 1rem;
                margin-bottom: 0.25rem;
                text-shadow: 2px 2px rgb(0 0 0 / 60%);
              }
            }
          }
          .fishing-game {
            background-image: url("../../../assets/profile/btn-fishing.jpg");
            &--bg-gradient {
              background: linear-gradient(to top, rgb(0 125 255 / 69%), transparent);
            }
          }
          .pet-battle {
            background-image: url("../../../assets/profile/btn-petbattle.jpg");
          }
        }
        &-1 {
          margin-bottom: 2rem;
          &--btns {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }
        }
      }
      &--mastery {
        .profile__container--content {
          &-row {
            &-1,
            &-2 {
              gap: 1rem;
              .pet-battle,
              .fishing-game,
              .daily-challenge {
                margin-left: 0;
                margin-right: 0;
                width: 250px;
                height: 200px;
                &--text-main {
                  font-size: 1.5rem;
                }
              }
              .mastery-mode {
                background-image: url("../../../assets/profile/bg-mastery-1.jpg");
                background-size: cover;
                background-position: bottom;
              }
            }
            &-1 {
              margin-bottom: 1rem;
              .mastery-mode {
                width: 100%;
                border-radius: .75rem;
                height: 380px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &--quiz {
                  flex: 1;
                  position: relative;
                  width: 100%;
                  &--header {
                    position: absolute;
                    right: -3rem;
                    top: -3rem;
                    color: #fff;
                    text-align: center;
                    transform: skew(-15deg, 0);
                    border-radius: .75rem;
                    overflow: hidden;
                    border: 6px solid #fff;
                    &-main {
                      background: #8732FF;
                      padding: .75rem 2.5rem;
                      h2 {
                        margin-top: .5rem;
                        font-size: 3rem;
                        line-height: 2.5rem;
                        transform: skew(15deg, 0);
                        text-transform: uppercase ;
                      }
                      h3 {
                        margin-left: 3rem;
                      }
                    }
                    &-sub {
                      background: #F73959;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      h3 {
                        transform: skew(15deg, 0);
                        font-size: 1.5rem;
                      }
                      i {
                        transform: skew(15deg, 0);
                        color: #F73959;
                        background: #fff;
                        padding: .25rem;
                        border-radius: 50%;
                        margin-left: .5rem;
                        border-bottom: 4px #cb0022 solid;
                        cursor: pointer;
                        &:active {
                          border-bottom: 2px #cb0022 solid;
                        }
                      }
                    }
                  }
                  &--btn-start {
                    position: absolute;
                    right: 1rem;
                    bottom: 2rem;
                    background: #2464F9;
                    color: #fff;
                    border-radius: .75rem;
                    padding: .5rem 4rem;
                    font-weight: 500;
                    font-size: 2.5rem;
                    border-bottom: 8px solid #0a3e93;
                    cursor: pointer;
                    &:active {
                      border-bottom: 4px solid #0a3e93;
                    }
                  }
                }
                &--avatar {
                  position: absolute;
                  bottom: .25rem;
                  left: .5rem;
                }
                &--reward {
                  height: 60px;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 0 1.5rem;
                  position: relative;
                  &--diamond {
                    position: absolute;
                    left: 1rem;
                    padding: .35rem;
                    height: 70px;
                    width: 70px;
                    bottom: .25rem;
                    background: linear-gradient(to top right, #49396e, #342732);
                    border-radius: .5rem;
                    border: 4px solid #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &--count {
                      position: absolute;
                      bottom: -3px;
                      right: 5px;
                      color: #fff;
                      font-size: .8rem;
                      font-style: italic;
                    }
                  }
                  &--progressbar {
                    width: 100%;
                    height: 25px;
                    background: #474747;
                    margin-right: .75rem;
                    border-radius: 1rem;
                    border-top: 4px solid #2e2e2e;
                    border-bottom: 4px solid #555;
                    margin-left: 2rem;
                    &--filled {
                      background: #FEAE35;
                      width: 50%;
                      height: 100%;
                      border-radius: 1rem;
                    }
                  }
                  &--count {
                    color: #fff;
                    font-weight: 600;
                    flex: 1;
                    width: 50px;
                    &--grp {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      i {
                        margin-left: .5rem;
                        color: #FE9035;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1560px), screen and (max-height: 800px) {
    .profile__container--content-row-1 .pet-battle,
    .profile__container--content-row-1 .fishing-game {
      width: 425px;
      margin-left: 1.5rem;
      &--text {
        &-main {
          font-size: 1.5rem;
        }
        &-sub {
          font-size: .85rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1366px), screen and (max-height: 690px) {
    .profile__container--content-row-1 .pet-battle,
    .profile__container--content-row-1 .fishing-game {
      width: 375px;
      height: 180px;
      margin-left: 1rem;
      &--text {
        &-main {
          font-size: 1.35rem;
        }
        &-sub {
          font-size: .8rem;
        }
      }
    }
  }

  @media only screen and (max-width: 1366px), screen and (max-height: 800px) {
    .main-profile__component {
      width: calc(100% - 275px);
    }
  }
  @media only screen and (max-width: 1150px), screen and (max-height: 690px) {
    .main-profile__component {
      width: calc(100% - 225px);
    }
  }
  @media only screen and (max-width: 960px), screen and (max-height: 620px) {
    .main-profile__component {
      // https://www.npmjs.com/package/large-small-dynamic-viewport-units-polyfill
      width: 100%;
      height: calc(100vh - 105px); /* For browsers that don't support CSS variables */
      height: calc((var(--1dvh, 1vh) * 100) - 105px); /* This is the "polyfill" */
      height: calc(100dvh - 105px); /* This is for future browsers that support svh, dvh and lvh viewport units */
      margin-top: 50px;
    }
  }

  @media only screen and (max-width: 1080px), screen and (max-height: 620px) {
    .profile__container--content-row-1 .pet-battle,
    .profile__container--content-row-1 .fishing-game {
      width: 335px;
      margin-left: .75rem;
      &--text {
        &-main {
          font-size: 1.25rem;
        }
        &-sub {
          font-size: .65rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1010px), screen and (max-height: 620px) {
    .profile__container--content-row-1 .pet-battle,
    .profile__container--content-row-1 .fishing-game {
      width: 300px;
      height: 160px;
    }
    .profile__container--content-row-1 .pet-battle,
    .profile__container--content-row-1 .fishing-game,
    .profile__container--content-row-2  {
      margin-left: .5rem;
      &--text {
        &-main {
          font-size: 1.2rem;
        }
        &-sub {
          font-size: .6rem;
          margin-top: 0.25rem;
        }
      }
    }
  }
  @media only screen and (max-width: 960px), screen and (max-height: 620px) {
    .misc-carousel {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .user-level-progressbar {
      margin-bottom: 1rem;
    }
    .profile__container {
      align-items: flex-start;
      height: calc(100% - 50px);
      margin-top: 50px;
      width: 100%;
      text-align: left;
      top: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      &--label-psp {
        display: flex;
        width: 100%;
        justify-content: center;
      }
      &--content {
        margin-top: 1rem;
        width: 90%;
        &-row-1 {
          width: 100%;
          display: flex;
          margin-bottom: 1rem;
          flex-direction: column;
          &--btns {
            width: 100%;
          }
          a {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .pet-battle {
            &--bg-gradient {
              height: 100%;
              background: linear-gradient(to top, rgba(194, 0, 255, 0.6901960784), #604bd2c4);
            }
            width: 95%;
            height: 120px;
            margin: auto;
            &--text {
              justify-content: center;
              &-main {
                font-size: 1.7rem;
              }
              &-sub {
                font-size: 1rem;
                display: none;
              }
            }
          }
          .fishing-game {
            &--bg-gradient {
              height: 100%;
              background: linear-gradient(to top, rgba(0, 125, 255, 0.69), #3989f3c4);
            }
            width: 95%;
            height: 120px;
            margin: auto;
            &--text {
              justify-content: center;
              &-main {
                font-size: 1.7rem;
              }
              &-sub {
                font-size: 1rem;
                display: none;
              }
            }
          }

        }
        &-row-2 {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
  @media only screen and (max-width: 505px), screen and (max-height: 620px) {
    .profile__container--content {
      &-row-2 {
        .pet-battle--mobile {
          height: 180px;
        }
        .pet-battle {
          &--text {
            &-main {
              font-size: 1.5rem;
            }
            &-sub {
              font-size: .75rem;
            }
          }
        }

      }
    }
  }
  @media only screen and (max-width: 1560px), screen and (max-height: 860px){
    .profile__container--content--mastery {
      transform: scale(.9);
    }
  }

  @media only screen and (max-width: 1460px), screen and (max-height: 780px) {
    .profile__container--content--mastery {
      transform: scale(.8);
    }
  }
  @media only screen and (max-width: 1280px), screen and (max-height: 680px) {
    .profile__container--content--mastery {
      transform: scale(.7);
    }
  }
  @media only screen and (max-width: 1080px) {
    .profile__container--content--mastery {
      transform: scale(.6);
    }
  }
  @media only screen and (max-width: 960px) {
    .profile__container--content--mastery {
      transform: scale(1);
      width: 90%;
      .profile__container--content-row-1 {
        .mastery-mode {
          height: auto;
        }
        margin-top: 1rem;
        .mastery-mode--quiz--header {
          position: relative;
          top: 0;
          right: 0;
          transform: none;
          &-main {
            padding: .75rem 0;
          }
          &-main, &-sub {
            h2, h3, i {
              transform: none;
              margin: 0;
            }
            h2 {
              font-size: 2rem;
            }
            h3 {
              font-size: 1.15rem;
            }
            i {
              margin-left: .5rem;
            }
          }
        }
        .mastery-mode--quiz--btn-start {
          position: relative;
          text-align: center;
          max-width: 350px;
          padding: 0.5rem;
          margin: 1rem auto;
          right: 0;
          bottom: 0;
          font-size: 1.5rem;
          &:active {
            margin-top: calc(1rem + 4px);
          }
        }
        .mastery-mode--avatar {
          display: none;
        }
        .mastery-mode--reward {
          height: 40px;
          padding: 0 .5rem;
          &--progressbar {
            margin-left: 1.25rem;
            height: 22.5px;
          }
          &--diamond {
            height: 50px;
          }
        }
      }
      .profile__container--content-row-2 {
        a {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        .pet-battle, .fishing-game, .daily-challenge {
          width: 100%;
          width: 95%;
          height: 120px;
          &--text {
            justify-content: center;
          }
        }
        .pet-battle--bg-gradient {
          height: 100%;
          background: linear-gradient(to top, rgba(194, 0, 255, 0.6901960784), rgba(96, 75, 210, 0.768627451));
        }
        .fishing-game--bg-gradient {
          height: 100%;
          background: linear-gradient(to top, rgba(0, 125, 255, 0.69), rgba(57, 137, 243, 0.768627451));
        }
      }
    }
  }
  @media only screen and (max-width: 450px) {
    .profile__container--content--mastery .profile__container--content-row-1 .mastery-mode--quiz--btn-start {
      max-width: 250px;
    }
  }

</style>
<style lang="scss">
  .profile__container--content--mastery {
    .text-sub {
      display: none !important;
    }
    .text-main {
      font-size: 1.5rem !important;
    }
  }
</style>
