<template>
  <div class="grid-cell"
      :class="{selected: isSelected, 'drag-over': isDragOver, dragging: isDragging}"
      @click="handleClick"
      @dragstart="startDrag"
      @dragover.prevent="onDragOver"
      @dragleave="onDragLeave"
      @drop.prevent="onDrop"
      @touchstart="onTouchStart"
      @touchmove.prevent="onTouchMove"
      @touchend.prevent="onTouchEnd"
      :draggable="!!itemKey && !isMobileDevice">
    <!-- {{ itemKey }} -->
    <div v-if="itemKey" class="item" :style="{backgroundColor: color}">
      <i class="fa" :class="faIcon"></i>
      <img class="item-img" :src="imageSrc" />
      <stars v-if="itemLevel > 0" :current-score="itemLevel" />
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';
import StarsComponent from './StarsComponent.vue';

export default {
  name: 'GridCell',
  components: {
    Stars: StarsComponent,
  },
  created() {
    if (this.isMobileDevice) {
      // Listen for events from other cells for mobile drag support
      this.$root.$on('cell-touch-start', this.onCellTouchStart);
    }
  },
  mounted() {
    if (!document.getElementById('disable-scroll-style')) {
      // Add global style for disabling scrolling during touch drag
      const style = document.createElement('style');
      style.id = 'disable-scroll-style';
      style.innerHTML = `
        body.disable-scroll {
          overflow: hidden !important;
          position: fixed;
          width: 100%;
          height: 100%;
        }
      `;
      document.head.appendChild(style);
    }
  },
  beforeDestroy() {
    if (this.isMobileDevice) {
      // Clean up event listeners
      this.$root.$off('cell-touch-start', this.onCellTouchStart);
    }
    // Remove any dragend listeners
    document.removeEventListener('dragend', this.onDragEndCleanup);
    // Make sure we don't leave any lingering dragging classes
    if (this.isDragging) {
      this.onDragEndCleanup();
    }
  },
  props: {
    itemKey: {
      type: String,
      default: '',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Number,
      required: true,
    },
    col: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isDragOver: false,
      isDragging: false,
      touchStartX: 0,
      touchStartY: 0,
      touchEndX: 0,
      touchEndY: 0,
      touchThreshold: 20, // Minimum distance for touch drag
      dropTarget: null,
      draggedElement: null, // Reference to the element being dragged
      draggedCellData: null, // Data about cell being dragged (for mobile)
    };
  },
  computed: {
    ...mapGetters('kingdom', ['getItemData']),
    imageSrc() {
      if (typeof this.itemData.imageSrc === 'undefined') return '';
      return this.itemData.imageSrc;
    },
    itemData() {
      if (!this.itemKey) return null;
      return this.getItemData(this.itemKey);
    },
    // returns true if item is pet or pet part
    isCellCategoryPet() {
      const category = this.itemKey.split('|')[0];
      return category === 'pet';
    },
    itemLevel() {
      if (!this.itemData) return -1;
      // for pets, we want to use petLevel rather than level as level counts egg and eggpart
      // for pet part, we don't want to show any stars
      if (this.isCellCategoryPet) {
        return !this.itemData.info.isPetPart ? this.itemData.info.petLevel : 0;
      }
      return this.itemData.level;
    },
    category() {
      return this.itemKey ? this.itemKey.split('|')[0] : '';
    },
    itemType() {
      return this.itemKey ? this.itemKey.split('|')[1] : '';
    },
    level() {
      return this.itemKey ? parseInt(this.itemKey.split('|')[2]) : 0;
    },
    faIcon() {
      if (this.itemType === 'ruby') {
        return 'fa-gem';
      } else if (this.itemType === 'wood') {
        return 'fa-fence';
      } else if (this.itemType === 'energy') {
        return 'fa-bolt';
      }
      return '';
    },
    color() {
      if (this.isCellCategoryPet) return 'white';
      return this.itemData ? this.itemData.color : 'blue';
    },
    position() {
      return {row: this.row, col: this.col};
    },
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
  methods: {
    handleClick(event) {
      // For mobile, clicks are handled by the touch system
      // For desktop, pass through to parent
      if (!this.isMobileDevice) {
        this.$emit('click');
      }
    },

    // Desktop drag and drop methods
    startDrag(event) {
      if (!this.itemKey) return;

      // Store the current position data in the drag event
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('text/plain', JSON.stringify({
        position: this.position,
        itemKey: this.itemKey,
      }));

      this.isDragging = true;
      // Store reference to the dragged element
      this.draggedElement = event.currentTarget;

      // Add dragend event listener to clean up - ensure it happens on both successful and failed drops
      document.addEventListener('dragend', this.onDragEndCleanup, {once: true});

      // Set a safety timeout to ensure cleanup happens even if dragend event doesn't fire
      setTimeout(() => {
        if (this.isDragging) {
          this.onDragEndCleanup();
        }
      }, 5000); // 5 second timeout as a safety measure
    },
    onDragOver(event) {
      this.isDragOver = true;
    },
    onDragLeave(event) {
      this.isDragOver = false;
    },
    onDrop(event) {
      this.isDragOver = false;
      this.isDragging = false;

      const dragData = JSON.parse(event.dataTransfer.getData('text/plain'));
      const sourcePosition = dragData.position;
      const sourceItemKey = dragData.itemKey;

      // Emit a custom event for the parent to handle the cell exchange
      this.$emit('cell-dropped', {
        sourcePosition,
        targetPosition: this.position,
        sourceItemKey,
      });
    },
    onDragEndCleanup() {
      // Remove the dragging class from all cells
      document.querySelectorAll('.grid-cell.dragging').forEach((element) => {
        element.classList.remove('dragging');
      });

      // Remove any drag-over states that might be lingering
      document.querySelectorAll('.grid-cell.drag-over').forEach((element) => {
        element.classList.remove('drag-over');
      });

      // If this was triggered by a drop outside a valid grid cell,
      // we want to make sure the UI is consistent with the data model
      // by informing the parent that no actual drop occurred
      if (this.isDragging) {
        this.$root.$emit('drag-cancelled', {
          position: this.position,
          itemKey: this.itemKey,
        });
      }

      // Reset drag state
      this.isDragging = false;
      this.draggedElement = null;
    },
    // Mobile touch event handlers
    onTouchStart(event) {
      if (!this.itemKey) return;

      // Store the starting touch coordinates
      const touch = event.touches[0];
      this.touchStartX = touch.clientX;
      this.touchStartY = touch.clientY;

      // Set this cell as the currently dragged item
      this.$root.$emit('cell-touch-start', {
        position: this.position,
        itemKey: this.itemKey,
      });
    },
    onTouchMove(event) {
      if (!this.itemKey) return;

      const touch = event.touches[0];
      this.touchEndX = touch.clientX;
      this.touchEndY = touch.clientY;

      // Calculate distance moved
      const deltaX = this.touchEndX - this.touchStartX;
      const deltaY = this.touchEndY - this.touchStartY;
      const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

      // If we've moved beyond the threshold, mark as dragging
      if (distance > this.touchThreshold && !this.isDragging) {
        this.isDragging = true;
        // Add a class to the body for preventing scrolling
        document.body.classList.add('disable-scroll');
      }

      // Find the element under the touch point
      if (this.isDragging) {
        const elementAtPoint = document.elementFromPoint(touch.clientX, touch.clientY);
        const cellElement = elementAtPoint ? elementAtPoint.closest('.grid-cell') : null;

        if (cellElement && cellElement !== event.target) {
          if (this.dropTarget) {
            this.dropTarget.classList.remove('drag-over');
          }
          cellElement.classList.add('drag-over');
          this.dropTarget = cellElement;
        }
      }
    },
    onTouchEnd(event) {
      if (this.isDragging && this.dropTarget) {
        // Get the Vue component instance
        const cellVm = this.findComponentFromElement(this.dropTarget);
        if (cellVm) {
          // Emit the drop event
          this.$emit('cell-dropped', {
            sourcePosition: this.position,
            targetPosition: cellVm.position,
            sourceItemKey: this.itemKey,
          });
        } else {
          // If we couldn't find a valid cell component but were dragging,
          // this indicates a drop outside a valid target - notify parent
          this.$root.$emit('drag-cancelled', {
            position: this.position,
            itemKey: this.itemKey,
          });
        }
        // Clean up
        if (this.dropTarget) {
          this.dropTarget.classList.remove('drag-over');
        }
      } else if (this.isDragging) {
        // If we were dragging but have no target, it's a cancelled drag
        this.$root.$emit('drag-cancelled', {
          position: this.position,
          itemKey: this.itemKey,
        });
      }

      // Reset touch state
      this.isDragging = false;
      this.dropTarget = null;
      document.body.classList.remove('disable-scroll');
    },
    findComponentFromElement(element) {
      // Find the Vue component instance from a DOM element
      if (!element || !element.__vue__) {
        return null;
      }
      const componentKey = Object.keys(element.__vue__.$refs).find(
          (key) => element.__vue__.$refs[key]
      );
      // If we can't find the component via refs, check for $children
      if (!componentKey && element.__vue__.$children) {
        for (const child of element.__vue__.$children) {
          if (child.$options.name === 'GridCell') {
            return child;
          }
        }
      }
      return componentKey ? element.__vue__.$refs[componentKey] : element.__vue__;
    },
    // Called when another cell starts being dragged (for mobile)
    onCellTouchStart(data) {
      // Store the data about the cell that started the drag
      this.draggedCellData = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-cell {
  aspect-ratio: 1;
  background-color: white;
  border: 1px solid #ccc;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  transition: all 0.2s ease;
  touch-action: none; /* Prevent default touch actions on mobile */
  user-select: none; /* Prevent text selection during drag */
  -webkit-user-select: none;
  -webkit-touch-callout: none;

  &.selected {
    box-shadow: 0 0 0 3px yellow inset;
  }

  &.drag-over {
    box-shadow: 0 0 0 3px #2196F3 inset;
    background-color: rgba(33, 150, 243, 0.1);
  }

  &.dragging {
    opacity: 0.5;
    transform: scale(0.95);
    z-index: 100;
  }

  &:empty {
    cursor: default;
  }
}

.item {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: transform 0.15s ease;

  &:hover {
    transform: scale(1.05);
  }

  .item-img {
    max-width: 100%;
    max-height: 60%;
    object-fit: contain;
    margin: 4px 0;
    pointer-events: none; /* Prevent image drag behavior */
  }
}

/* Global style to add to the document */
:global(.disable-scroll) {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}
</style>
