<template>
  <div class="audio-recorder-container animated fadeIn faster">
    <div class="recorder-controls">
      <!-- Start Recording Button -->
      <div v-if="!isRecording" class="recorder--btn-grp">
        <button
          class="recorder--btn recorder--btn-start animated bounceIn"
          @click="toggleRecording">
          <i class="fa-solid fa-microphone"></i>
        </button>
        <span class="recorder--btn-text animated fadeInUp faster">
          Click to Start Recording
        </span>
      </div>
      <!-- Recording Countdown -->
      <div v-if="isRecording && isCountdown" class="recorder--countdown animated flipInX">
        <h2>Get Ready...</h2>
        <p>(Don't Speak Yet)</p>
      </div>
      <!-- Stop Recording Button with Wave Animation -->
      <div v-if="isRecording && !isCountdown" class="recorder--btn-grp">
        <!-- Use RecordingWaveAnimation component -->
        <div class="wave-animation-container">
          <recording-wave-animation />
        </div>
        <button
          class="recorder--btn recorder--btn-stop animated bounceIn"
          @click="toggleRecording">
          <i class="fa-solid fa-stop"></i>
        </button>
        <span class="recorder--btn-text animated fadeInUp faster">
          Recording in progress... {{ timerCount }} / {{ totalTime }}s
        </span>
      </div>
      <!-- Cancel Recording Button -->
      <div v-if="isRecording" class="cancel-recording-btn">
        <button @click="cancelRecording">
          <i class="fa-solid fa-xmark"></i>
          <span>Cancel</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RecordingWaveAnimationComponent from './RecordingWaveAnimationComponent.vue';
import Recorder from '../../../../../vendor/recorder';

export default {
  name: 'RecordingComponent',
  components: {
    'recording-wave-animation': RecordingWaveAnimationComponent,
  },
  props: {
    maxRecordingTime: {
      type: Number,
      default: 120, // 2 minutes
    },
  },
  data() {
    return {
      isRecording: false,
      isCountdown: false,
      timerCount: 0,
      totalTime: this.maxRecordingTime,
      timerInterval: null,
      recorder: null,
      gumStream: null,
    };
  },
  beforeDestroy() {
    this.cleanupRecording();
  },
  methods: {
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    startRecording() {
      this.isRecording = true;
      this.isCountdown = true;
      this.timerCount = 0;

      // Request microphone access and start recording immediately
      navigator.mediaDevices.getUserMedia({audio: true})
          .then((stream) => {
            this.gumStream = stream;

            // Initialize recorder
            const AudioContext = window.AudioContext || window.webkitAudioContext;
            const audioContext = new AudioContext();
            const input = audioContext.createMediaStreamSource(stream);

            // Create the Recorder object and configure to record mono sound (1 channel)
            this.recorder = new Recorder(input, {
              numChannels: 1,
            });
            console.log('started recording');
            // Start recording immediately
            this.recorder.record();

            // After 1 second, update UI and start the timer
            setTimeout(() => {
              if (!this.isRecording) return; // Check if recording was cancelled during the delay
              this.isCountdown = false; // Hide 'Get Ready...', show recording UI
              // Start timer for max duration
              this.timerInterval = setInterval(() => {
                this.timerCount++;
                if (this.timerCount >= this.totalTime) {
                  this.stopRecording();
                }
              }, 1000);
            }, 1000);
          })
          .catch((err) => {
            console.error('Error accessing microphone:', err);
            this.isRecording = false;
            this.isCountdown = false; // Ensure countdown UI is hidden on error
            alert('Could not access your microphone. Please ensure you have a microphone connected and have granted permission to use it.');
          });
    },
    stopRecording() {
      if (!this.recorder) {
        return;
      }
      this.cleanupRecording();
      this.$emit('recording-stopped', this.timerCount);
    },
    cancelRecording() {
      this.cleanupRecording();
      this.$emit('recording-cancelled');
    },
    cleanupRecording() {
      // Clear timer
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }

      // Stop recorder if active
      if (this.recorder) {
        this.recorder.stop();

        // Export the recorded audio
        this.recorder.exportWAV((blob) => {
          this.$emit('recording-complete', blob, this.timerCount);
        });
      }

      // Stop all audio tracks
      if (this.gumStream) {
        this.gumStream.getTracks().forEach((track) => track.stop());
        this.gumStream = null;
      }

      // Reset state
      this.isRecording = false;
      this.isCountdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// Audio recorder styling
.audio-recorder-container {
  padding: 3rem 2rem;
  text-align: center;
  border: 1px solid #dee2e6;
  border-top: none;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-color: #fff;

  .recorder-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    .recorder--btn-grp {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-bottom: 1rem;
    }

    .recorder--btn {
      border: none;
      width: 125px;
      height: 125px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0 4px 6px rgba(66, 99, 235, 0.2);
      transition: all 0.2s;
      cursor: pointer;

      i {
        font-size: 2.5rem;
      }

      &-start {
        background-color: #4263eb;
        border-top: 3px solid #8da9ff;
        border-bottom: 4px solid #3b5bdb;
        box-shadow: 0 5px 15px rgba(66, 99, 235, 0.4);

        &:hover {
          background-color: #3b5bdb;
          transform: scale(1.05);
        }
      }
    }

    .recorder--btn-text {
      margin-top: 1rem;
      font-size: 1rem;
      font-weight: 500;
      color: #6c757d;
      font-style: italic;
    }

    .recorder--countdown {
      text-align: center;
      margin-bottom: 2rem;

      h2 {
        font-size: 2.5rem;
        font-weight: 600;
        margin: 0;
        color: #4263eb;
      }

      p {
        margin: 0.5rem 0 0;
        color: #6c757d;
        font-size: 1rem;
        font-style: italic;
      }
    }
  }
}

.cancel-recording-btn {
  margin-top: 1.5rem;
  text-align: center;

  button {
    background: none;
    border: none;
    color: #868e96;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0 auto;
    transition: color 0.2s;

    &:hover {
      color: #495057;
    }

    i {
      font-size: 1rem;
    }
  }
}

// Fix the wave animation by ensuring it's positioned correctly
.wave-animation-container {
  position: relative;
  width: 200px;
  height: 60px;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
}

// Animation classes
.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.fadeIn {
  animation-name: fadeIn;
}

.bounceIn {
  animation-name: bounceIn;
}

.fadeInUp {
  animation-name: fadeInUp;
}

.flipInX {
  animation-name: flipInX;
}

.faster {
  animation-duration: 0.3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounceIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}

.recorder--btn-stop {
  background-color: #f366a2;
  border-top: 3px solid #f99bc1;
  border-bottom: 4px solid #d03e77;
  width: 90px;
  height: 90px;
  box-shadow: 0 5px 15px rgba(243, 102, 162, 0.4);
  animation: pulse 1.5s infinite;

  .square-icon {
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 3px;
  }

  &:hover {
    background-color: #e05390;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(243, 102, 162, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(243, 102, 162, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(243, 102, 162, 0);
  }
}
</style>
