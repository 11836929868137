<template>
  <div class="delivery-panel">
    <!-- Tabs -->
    <div class="tabs-container">
      <div class="tab">Quests</div>
      <div class="tab active">Deliveries</div>
    </div>

    <!-- Delivery rows container -->
    <div class="delivery-rows">
      <!-- Delivery row items -->
      <div class="delivery-row"
           v-for="delivery in outstandingDeliveries"
           :key="delivery.id"
      >
        <div class="pet-icon">
          <img :src="`https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/kingdom/${delivery.pet_type}/${delivery.pet_level}.png`"
               :alt="getPetName(delivery.item_key)">
        </div>
        <div class="delivery-details">
          <div class="pet-name">{{ getPetName(delivery.item_key) }}</div>
          <div class="xp-reward">+{{ delivery.xp_reward }} XP</div>
        </div>
        <div class="progress-indicator">
          <div class="progress-text">{{ getProgress(delivery) }}</div>
        </div>
      </div>
    </div>

    <!-- Delivery completion modal -->
    <delivery-completion-modal
      :show="showCompletionModal"
      :pet-name="completionData.petName"
      :pet-image-url="completionData.petImageUrl"
      :xp-gained="completionData.xpGained"
      :king-level-up="completionData.kingLevelUp"
      :king-level="completionData.kingLevel"
      :show-next-delivery="completionData.showNextDelivery"
      :next-delivery-pet-name="completionData.nextDeliveryPetName"
      :next-delivery-image-url="completionData.nextDeliveryImageUrl"
      @close="showCompletionModal = false"
    />
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import DeliveryCompletionModal from './DeliveryCompletionModalComponent.vue';

export default {
  name: 'DeliveryPanel',
  components: {
    'delivery-completion-modal': DeliveryCompletionModal,
  },
  data() {
    return {
      showCompletionModal: false,
      completionData: {
        petName: '',
        petImageUrl: '',
        xpGained: 0,
        kingLevelUp: false,
        kingLevel: 1,
        showNextDelivery: false,
        nextDeliveryPetName: '',
        nextDeliveryImageUrl: '',
      },
    };
  },
  computed: {
    ...mapState('kingdom', ['deliveries', 'selectedCells', 'grid']),
    ...mapGetters('kingdom', ['getCellValue', 'getItemData']),
    outstandingDeliveries() {
      // The backend sends requests directly, not nested inside slots array
      return (this.deliveries && this.deliveries.slots) ? this.deliveries.slots : [];
    },
  },
  mounted() {
  },
  methods: {
    getPetName(petKey) {
      return this.getItemData(petKey).name || '';
    },
    getProgress(request) {
      // Check if the backend delivery request has progress data
      if (request.progress !== undefined && request.required !== undefined) {
        return `${request.progress || 0}/${request.required}`;
      }

      // Fallback to legacy fields if they exist
      if (request.progressCurrent !== undefined && request.progressTotal !== undefined) {
        return `${request.progressCurrent}/${request.progressTotal}`;
      }

      // Last resort fallback
      return `0/${request.required || 3}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery-panel {
  background-color: #eae4d6;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 350px;
  max-height: 450px;
}

.tabs-container {
  display: flex;
  width: 100%;
}

.tab {
  flex: 1;
  text-align: center;
  padding: 10px 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #555;
  cursor: pointer;

  &.active {
    background-color: #ffd966;
    color: #333;
  }

  &:not(.active) {
    background-color: #e9ecef;
  }
}

.delivery-rows {
  padding: 8px 0;
  max-height: 340px;
  overflow: hidden;
}

.delivery-row {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: white;
  margin: 6px 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

.pet-icon {
  width: 45px;
  height: 45px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  img {
    width: 65%;
    height: 65%;
    object-fit: contain;
  }
}

.delivery-details {
  flex: 1;

  .pet-name {
    font-size: 0.95rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 2px;
  }

  .xp-reward {
    font-size: 0.9rem;
    color: #f0a830;
    font-weight: 600;
  }
}

.progress-indicator {
  background-color: #e9ecef;
  border-radius: 15px;
  padding: 5px 12px;
  min-width: 60px;
  text-align: center;

  .progress-text {
    font-size: 0.8rem;
    font-weight: 600;
    color: #555;
  }
}

.empty-state {
  text-align: center;
  padding: 20px 15px;
  color: #888;
  font-style: italic;
  font-size: 0.9rem;
}

// Keep the demo button for development purposes
.demo-button {
  display: block;
  width: calc(100% - 20px);
  margin: 8px 10px 10px;
  padding: 8px;
  background-color: #FF9800;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.9rem;
}
</style>
