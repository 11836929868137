<!-- template for item in ./learningtracks/modules/ -->
<template>
  <div class="term-index__item">
    <!-- <p class="term-index__item--current">Main Track</p> -->
    <h3 class="term-index__item--header">
      {{ learningTrack.level | shorternLevel }} Track
    </h3>
    <p v-if="learningTrack.upcoming_level"
      class="term-index__item--label">
      Headstart
    </p>
    <p class="term-index__item--subheader">
      Click on a Badge to Select the Term:
    </p>
    <div class="term-index__item--badges">
      <!-- TODO: v-if for whether term has been fully completed -->
      <!-- router link will then apply the following classes -->
      <!-- class="term-index__item--badge term-index__item--badge-completed" -->
      <router-link
        v-for="(subtrack, index) in learningTrack.subtracks"
        :key="index"
        class="term-index__item--badge"
        :class="{'term-index__item--badge-completed':
            progressBarWidths[learningTrack.name + '_' + subtrack]
            === '100%'}"
        :to="{name: 'learning-track-subterm-index',
        params: {learningTrackType: learningTrack.learning_track_type,
        learningTrackPk: learningTrack.pk,
        subtrack: subtrack}}">
        <p class="term-index__item--badge-icon">
          <i class="fas fa-medal"></i>
        </p>
        <p
          class="term-index__item--badge-text">
          {{ subtrack | splitCapitalise }}
        </p>
        <div class="term-index__item--progressbar
          term-index__item--progressbar-container">
          <div
            class="term-index__item--progressbar
              term-index__item--progressbar-width"
            :style="{width:
              progressBarWidths[learningTrack.name + '_' + subtrack]}">
          </div>
        </div>
        <p>
        </p>
      </router-link>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import {mapGetters} from 'vuex';

export default {
  name: 'LearningTrackContainer',
  components: {
  },
  props: {
    learningTrack: Object,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('learningTrack', ['subtracksCompletionPercentage']),
    // used computed property rather than method for performance improvement
    progressBarWidths() {
      const results = {};

      for (let i = 0; i < this.learningTrack.subtracks.length; i++) {
        const subtrack = this.learningTrack.subtracks[i];
        const key = this.learningTrack.name + '_' + subtrack;
        results[key] = Math.floor(this.subtracksCompletionPercentage[key]
            * 100) + '%';
      };
      return results;
    },
  },
  mounted: function() {
  },
  methods: {
    // returns floored progress
    getProgress(learningTrackName, subtrack) {
      return Math.floor(this.subtracksCompletionPercentage[
          learningTrackName + '_' + subtrack] * 100) / 100;
    },
  },
};
</script>

<style lang="scss" scoped>
  .term-index__item {
    color: #555;
    background: #fff;
    padding: 35px;
    border-radius: 3px;
    text-decoration: none;
    position: relative;
    // max-width: 550px;
    &--label {
      position: absolute;
      top: -.25rem;
      right: -.25rem;
      padding: .25rem .75rem;
      background: #1ddab8;
      color: #fff;
      font-family: 'baloo da 2';
      border-radius: .5rem;
      font-size: .85rem;
      text-transform: uppercase;
      font-weight: 600;
    }
    &--current {
      position: absolute;
      top: 15px;
      right: 15px;
      color: #6e9fec;
      background: #fff;
      font-size: 60%;
      border: 3px solid #6e9fec;
      // background: #864ede;
      // color: #fff;
      padding: 5px 10px;
      border-radius: 3px;
      font-weight: 700;
      display: none;
    }
    &--header {
      display: inline-block;
      padding: 5px 80px;
      border-bottom: 2px solid #333;
    }
    &--subheader {
      margin-top: 5px;
      font-size: 16px;
      font-style: italic;
    }
    &--badges {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      // display: grid;
      // grid-template-rows: 1fr 1fr 1fr 1fr;
      // grid-column-gap: 15px;
    }
    &--badge {
      background: #f9f9f9;
      color: #ddd;
      border-radius: 10px;
      padding: 10px;
      border: 5px solid transparent;
      text-decoration: none;
      transition: all 0.2s;
      flex-grow: 1;
      margin: 10px 10px;
      position: relative;
      &-icon {
        font-size: 300%;
        margin-bottom: 5px;
      }
      &-text {
        font-weight: 700;
        font-size: 85%;
      }
      &:hover {
        color: #6e9fec;
        background: #fff;
        border: 5px solid #6e9fec;
        .term-index__item--progressbar-container {
          background: #efefef;
        }
      }
      &-completed {
        background: #6e9fec;
        color: #fff;
        border: 5px solid #26ebff;
        // background: #fff;
        // color: #F7B34E;
        // border: 5px solid #ffca51;
        &:hover {
          background: #fff;
          border: 5px solid #6e9fec;
        }
      }
    }
    &--progressbar {
      background-color: #fff;
      border-radius: 20px;
      margin: 10px 0;
      display: flex;
      align-items: center;
      color: white;
      height: 5px;

      &-container {
      }
      &-width {
        background-color: #50F190;
        height: 10px;
      }
    }
    // &:nth-child(1) {
    //   // grid-column: span 2;
    //   .term-index__item--current {
    //     display: block;
    //   }
    // }
  }
  @media only screen and (max-width: 1140px) {
    .term-index__item {
      max-width: 1140px;
      padding: 15px;
    }
  }
  @media only screen and (max-width: 600px) {
    .term-index__item {
      &--badges {
        margin: 5px 15px;
      }
      &--header {
        font-size: 20px;
        padding: 5px 60px;
      }
      &--subheader {
        font-size: 14px;
      }
      &--current {
        top: 10px;
        right: 10px;
        font-size: 50%;
        padding: 5px;
      }
      &--badge {
        color: #b1b1b1;
        &-completed {
          color: #fff;
        }
      }
    }
  }
</style>
