<template>
  <div class="word-list-component">
    <div class="word-list-grid">
      <button
        v-for="(wordInfo, index) in sortedWords"
        :key="`word_${index}`"
        :class="['word-button', {'completed': wordInfo.completed}]"
        @click="selectWord(sortedWords.indexOf(wordInfo))">
        {{ wordInfo.text }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WordListButtonsComponent',
  props: {
    words: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedWordIndex: null,
    };
  },
  computed: {
    sortedWords() {
      // Create a copy of words array and sort it
      // Incomplete words first, completed words at the end
      return [...this.words].sort((a, b) => {
        if (a.completed === b.completed) return 0;
        return a.completed ? 1 : -1;
      });
    },
  },
  methods: {
    selectWord(index) {
      this.selectedWordIndex = index;
      this.$emit('word-selected', this.sortedWords[index]);
    },
  },
  mounted() {
    // Select the first non-completed word by default when component is mounted
    if (this.sortedWords.length > 0) {
      const firstNonCompletedIndex = this.sortedWords.findIndex((word) => !word.completed);
      if (firstNonCompletedIndex !== -1) {
        this.selectWord(firstNonCompletedIndex);
      } else {
        // If all words are completed, select the first one
        this.selectWord(0);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.word-list-component {
  .word-list-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  .word-list-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    gap: 0.25rem;

    .word-button {
      padding: 0.25rem;
      border-radius: 0.5rem;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      font-size: 1rem;
      cursor: pointer;
      transition: all 0.2s ease;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

      &:hover {
        background: #f5f5f5;
      }

      &.completed {
        background: #4cd4a1; /* Teal color for active button like in image */
        color: white;
        border: none;
      }
    }
  }

  /* Properly scoped media query */
  @media only screen and (max-width: 768px) {
    .word-list-grid {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      gap: 0.5rem;
    }
  }
  @media only screen and (max-width: 760px) {
  }
}
</style>
