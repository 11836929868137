<template>
  <div class="start-menu">
    <div class="start-menu--header">
      <h2>Revision Settings</h2>
    </div>
    <div class="start-menu--body">
      <div class="start-menu--body--select-level">
        <h3>Select Academic Level:</h3>
        <div :class="{'level-btns--active': subCategory === 'level'}"
          class="level-btns">
          <div v-for="(level, index) in validLevels" :key="index"
            @click.prevent="clickLevel(level)"
            :class="{'level-btn--active': dailyChallengeSelectedLevel === level }"
            class="level-btn">
            {{ level | shorternLevel }}
          </div>
        </div>
      </div>
      <div class="start-menu--body--select-mode">
        <h3>Select Revision Mode:</h3>
        <div class="radio-container">
          <label for="core" :class="{'label-selected': revisionMode === 'exam'}">
            <i class="fa-solid fa-book" :class="{'icon-selected': revisionMode === 'exam'}"></i>
            <h4>Exam Mode Revision</h4>
            <p>
              Concentrated review on difficult and new words just before an exam
            </p>
            <input type="radio" id="core" name="revisionMode" value="exam" v-model="revisionMode">
          </label>
          <label v-if="!isPreschool && level !== 'primary_one'" for="level" :class="{'label-selected': revisionMode === 'standard'}">
            <i class="fa-solid fa-clock" :class="{'icon-selected': revisionMode === 'standard'}"></i>
            <h4>Spaced-Repetition Revision</h4>
            <p>
              Ideal for normal study that balances reviewing mistakes and revision of known words
            </p>
            <input type="radio" id="level" name="revisionMode" value="standard" v-model="revisionMode">
          </label>
        </div>
      </div>
    </div>
    <div class="start-menu--footer">
      <a href="#" class="btn-start"
        @click="startGame">Start
      </a>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations, mapGetters} from 'vuex';

export default {
  name: 'NewRevisionStart',
  props: {
    startGame: Function,
  },
  data() {
    return {
      revisionMode: 'exam',
    };
  },
  computed: {
    ...mapState(['studentName', 'avatar', 'level']),
    ...mapState('quiz', ['dailyChallengeSubcategory', 'dailyChallengeSelectedLevel']),
    ...mapGetters(['validLevels']),
  },
  mounted() {
    // this.revisionMode = this.dailyChallengeSubcategory;
    if (this.dailyChallengeSelectedLevel === '') {
      this.setDailyChallengeSelectedLevel(this.level);
    }
    this.setDailyChallengeSubcategory('revision|standard');
  },
  watch: {
    // really hackish code to ensure that the revision mode info is updated when the sub category changes
    revisionMode(newValue, oldValue) {
      this.setDailyChallengeSubcategory(`revision|${newValue}`);
    },
  },
  methods: {
    ...mapMutations('quiz', ['setDailyChallengeSubcategory', 'setDailyChallengeSelectedLevel']),
    clickLevel(level) {
      if (level === this.dailyChallengeSelectedLevel) return;
      this.setDailyChallengeSelectedLevel(level);
    },
  },
};
</script>

<style lang="scss" scoped>
  // cursor styling
  .revision-mode-info {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #f0f0f0;
    transition: all 0.3s ease;
    opacity: 0;
    max-height: 0;
    overflow: hidden;

    &.active {
      opacity: 1;
      max-height: 100px;
      background-color: #e6f7ff;
      border-left: 4px solid #1890ff;
    }

    p {
      margin: 0;
      font-size: 0.9rem;
      color: #333;
    }
  }
  // end cursor styling

  .level {
    &-btn {
      border: 4px solid #7b57a9;
      color: #7b57a9;
      font-weight: 500;
      background: #fff;
      padding: .1rem;
      flex: 1;
      border-radius: 0.5rem;
      font-size: .9rem;
      margin-right: .5rem;
      text-align: center;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        box-shadow: 0 0px 1px 3px #ff00dd;
      }
      &--disabled {
        background: #e8e8e8;
        color: #fff;
        border: 4px solid #e8e8e8;
        &:hover {
          box-shadow: none;
        }
      }
      &--active {
        opacity: 1;
        color: #fff;
        background: #7b57a9;
        font-weight: 600;
        border: 4px solid #7b57a9;
        box-shadow: 0 0px 1px 3px #00ffea;
        &:hover {
          box-shadow: 0 0px 1px 3px #00ffea;
        }
      }
    }
    &-btns {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 1rem;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0 1rem;
      &--active {
        .level-btn {
          cursor: pointer;
        }
      }
      &--disabled {
        opacity: .5;
      }
    }
  }

  .start-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background: #fff;
    &--header, &--body, &--footer {
      max-width: 1080px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    &--header{
      height: 100px;
    }
    &--body {
      padding: .5rem;
      flex-direction: column;
      align-items: flex-start;
      &--select {
        &-level, &-mode {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
        &-level {}
        &-mode {
          .radio-container {
            margin-top: 1rem;
            margin-bottom: 1rem;
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;
            width: 100%;
            gap: .5rem;
            color: #797979;
            label {
              border: 2px solid #e7e7e7;
              border-radius: .75rem;
              padding: 1rem;
              width: 100%;
              font-weight: 400;
              display: flex;
              align-items: center;
              cursor: pointer;
              text-align: center;
              flex-direction: column;
              height: 100%;
              i {
                font-size: 5rem;
                color: #797979;
                margin: 1rem;
              }
              h4 {
                font-size: 1.25rem;
                font-weight: 700;
              }
              p {
                font-size: .8rem;
                font-style: italic;
                margin-bottom: 1rem;
              }
              input {
                margin-top: auto;
                margin-right: 1rem;
                display: none;
              }
            }
          }
        }
      }
    }
    &--footer {
      height: 140px;
    }
  }


  .btn-start:link,
  .btn-start:visited {
    margin-bottom: 20px;
    padding: 10px 120px;
    font-size: 150%;
    width: 100%;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;
    background: #7b57a9;
    color: white;
    text-align: center;
    font-weight: 400;
    transition: transform 0.2s;
    text-decoration: none;
  }

  .btn-start:hover,
  .btn-start:active {
    transform: scale(1.03);
    background: #8f69bf;
  }

  .btn-start + a {
    text-decoration: none;
    font-size: 80%;
    color: #4776c1;
    margin-top: 10px;
  }

  @media only screen and (max-width: 1023px) {
  }
  @media only screen and (max-width: 820px) {
    .start-menu-content h2 span {
      display: block;
    }
  }

  @media only screen and (max-width: 600px) {
    .avatar-img {
      img {
        width: 120px;
      }
    }
    .start-menu-container {
      justify-content: center;
    }
    .start-menu-content {
      margin: 0;
      font-size: 80%;
    }
    .btn-start:link,
    .btn-start:visited {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 350px) {
    .start-menu-content {
      font-size: 90%;
    }
    .btn-start:link,
    .btn-start:visited {
      padding: 10px 100px;
    }
  }
  @media only screen and (max-height: 520px) {
    .start-menu-container {
      flex-direction: row;
      height: auto;
      width: auto;
      padding: 10px;
      border-radius: 10px;
    }
    .start-menu-content {
      margin-left: 40px;
      margin-right: 20px;
    }
    .btn-start:link,
    .btn-start:visited {
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-height: 365px) {
    .avatar-img {
      padding: 5px 0;
    }
    .avatar-img + h4 {
      margin-bottom: 5px;
      font-size: 90%;
    }
    .avatar-img img {
      width: 75%;
    }
    .start-menu-content {
      font-size: 85%;
    }
    .start-menu-content h2 {
      padding: 10px 0;
    }
    .btn-start:link,
    .btn-start:visited {
      margin: 10px auto;
    }
  }

  .radio_container {
    margin-top: 15px;
  }

  #core_syllabus_label {
    margin-left: 20px;
  }

  .label-selected {
    border: 2px solid #7b57a9 !important;
    box-shadow: 0 0 8px rgba(123, 87, 169, 0.5);
    background-color: #f8f4fc;
    transition: all 0.3s ease;
  }

  .icon-selected {
    color: #7b57a9 !important;
    transition: color 0.3s ease;
  }

</style>
