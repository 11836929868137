<template>
  <div v-if="selectedCellItemData && !isEmptyCell" class="selected-cell-info-panel">
    <h3>{{ selectedCellItemData.name }}</h3>
    <div class="info-content">
      <!-- {{ selectedCellItemData }} -->
      <div class="info-row" v-if="selectedCellItemData.type">
        <span class="label">Type:</span>
        <span class="value">{{ selectedCellItemData.type }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

export default {
  name: 'SelectedCellInfoPanel',
  props: {
  },
  components: {
  },
  computed: {
    ...mapGetters('kingdom', ['selectedCellItemData', 'isEmptyCell']),
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.selected-cell-info-panel {
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  h3 {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .info-content {
    .info-row {
      display: flex;
      margin-bottom: 0.5rem;

      .label {
        font-weight: bold;
        min-width: 100px;
      }

      .value {
        flex: 1;
      }
    }
  }

  .no-selection {
    color: #666;
    font-style: italic;
  }
}
</style>
