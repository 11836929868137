<template>
  <block-container
    title="朗读课本"
    subtitle="Textbook Sentences"
    previous-page-name="new-oral-category-index"
    :previous-page-params="{category: 'oralTextbookChapter'}"
    fa-icon="newspaper"
    >
    <div v-if="selectedChapter" class="oral-index__container">
      <router-link
        v-for="(quiz, index) in selectedChapter.oral_quizzes" :key="index"
        :to="{name: 'oral-new-passage', params: {type: 'textbookchapter', entityId: quiz.id}}"
        class="oral-index__container--item">
        <div class="user-settings-content">
          <div class="oral-index__container--item-icon">
            <i class="fas fa-book icon-main"></i>
            <rating class="rating-star-container"
              image="star"
              :current-score="getStars(quiz.id)"
              :max-score=3>
            </rating>
          </div>
          <div class="oral-index__container--text">
            <span class="oral-index__container--text-title">
              练习 {{ quiz.part_number + 1 }}
            </span>
          </div>
        </div>
      </router-link>
    </div>
  </block-container>
</template>

<script>

import BlockContainerComponent
  from '../../common/BlockContainerComponent.vue';
import RatingComponent from '../../../common/RatingComponent.vue';

import {mapState, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'NewOralTextbookChapterTopic',
  components: {
    BlockContainer: BlockContainerComponent,
    Rating: RatingComponent,
  },
  props: {
    topicId: Number,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['chineseLevel']),
    ...mapState(['level', 'quizzesCompletedInfo']),
    ...mapState('guidedReading', ['textbookChapters']),
    title() {
      return `口试练习 (${this.chineseLevel})`;
    },
    selectedChapter() {
      for (const chapter of this.textbookChapters) {
        if (chapter.id == this.topicId) {
          return chapter;
        }
      }
      return null;
    },
  },
  mounted() {
    if (!this.textbookChapters || this.textbookChapters.length === 0) {
      this.$router.push({name: 'new-oral-category-index', params: {category: 'oralTextbookChapter'}});
    };
    this.setTextbookChapterId(Number(this.topicId));
  },
  methods: {
    ...mapMutations('guidedReading', ['setTextbookChapterId']),
    // returns 0 to 3 stars; 0 is default
    getStars(quizId) {
      try {
        return this.quizzesCompletedInfo['oral_chapter_sentences'][quizId]['stars'];
      } catch (err) {
        return 0;
      }
    },
  },
};

</script>

<style lang="scss" scoped>
  .oral-index {
    &__container {
      height: 100%;
      background: linear-gradient(to bottom right, #1d244dc9, #1518293c);
      padding: 2rem;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      text-align: center;
      grid-gap: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
      width: 100%;
      grid-gap: 1.5rem;
      &--item {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #fff;
        border-radius: 7.5px;
        text-decoration: none;
        border: 6px solid transparent;
        transition: all 0.2s;
        position: relative;
        &:hover {
          background: #fff;
          .oral-index__container--item-icon {
            background: #fff;
            border: 6px solid #2b92f8;
            i {
              color: #2b92f8;
            }
          }
        }
        .user-settings-content {
          height: 100%;
          width: 100%;
        }
        a {
          text-decoration: none;
        }
        &-icon {
          height: 75%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 6px solid #e9e9e9;
          margin: .5rem .5rem 0 .5rem;
          transition: all 0.2s;
          padding: 1rem;
          border-radius: 0.5rem;
          background: #fff;
          flex-direction: column;
          i {
            transition: all 0.2s;
            color: #b9b6c1;
            font-size: 3.5rem;
            margin-bottom: 5px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      &--text {
        width: 100%;
        height: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &-title {
          font-weight: 600;
          font-size: 1rem;
          color: #4e4e54;
        }
      }
    }
  }

  .num-words {
    font-size: 0.8em;
  }

  @media only screen and (max-width: 1560px) {
    .oral-index__container {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .oral-index {
      &__container {
        padding: 1.5rem;
        grid-gap: 1.25rem;
        &--item {
          padding: .25rem;
          &-icon {
            padding: .75rem;
            i {
              font-size: 3rem;
            }
          }
        }
        &--text {
          &-title {
            font-size: .9rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1150px),
  screen and (max-height: 690px) {
  }
  @media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
    .oral-index {
      &__container {
        background: none;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media only screen and (max-width: 690px) {
    .oral-index__container {
      grid-template-columns: 1fr;
    }
  }
  @media only screen and (max-width: 580px) {
    .oral-index__container {
      grid-gap: 1rem;
    }
  }
  @media only screen and (max-width: 480px) {
    .oral-index__container--item-icon {
      padding: .5rem;
      i {
        font-size: 2.5rem;
      }
    }
    .oral-index__container--text-title {
      font-size: .8rem;
    }
  }

</style>
