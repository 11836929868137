<template>
  <div class="writing-practice__component">
    <!-- Pattern selection view -->
    <writing-pattern-selection
      v-if="!currentPattern"
      @practice-selected="handlePracticeSelection"
    />

    <!-- Scaffolded practice view -->
    <writing-drills-component
      v-else-if="practiceMode === 'scaffolded'"
      :pattern-id="currentPattern.id"
      :question-statuses="[]"
      @back="resetPractice"
    />

    <!-- Free practice view -->
    <free-writing-practice
      v-else-if="practiceMode === 'free'"
      :pattern="currentPattern"
      @back="resetPractice"
    />
  </div>
</template>

<script>
import WritingPatternSelectionComponent from './components/WritingPatternSelectionComponent.vue';
import WritingDrillsComponent from './components/WritingDrillsComponent.vue';
import FreeWritingPracticeComponent from './components/WritingDrillsFreeWritingPracticeComponent.vue';

export default {
  name: 'WritingPracticeComponent',
  components: {
    WritingPatternSelection: WritingPatternSelectionComponent,
    WritingDrillsComponent: WritingDrillsComponent,
    FreeWritingPractice: FreeWritingPracticeComponent,
  },
  data() {
    return {
      currentPattern: null,
      practiceMode: null,
    };
  },
  methods: {
    handlePracticeSelection(selection) {
      this.currentPattern = selection.pattern;
      this.practiceMode = selection.mode;
      // Log the selection for debugging
      console.log(`Selected ${this.practiceMode} practice for pattern: ${this.currentPattern.name}`);
    },
    resetPractice() {
      this.currentPattern = null;
      this.practiceMode = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.writing-practice__component {
  height: 100%;
  width: 100%;
}
</style>
