<template>
  <div v-if="show" class="peel-modal">
    <div class="peel-modal-content">
      <div class="peel-modal-header">
        <h3>PEEL Structure</h3>
        <button class="close-btn" @click="closeModal">
          <i class="fa-solid fa-times"></i>
        </button>
      </div>
      <div class="peel-modal-body">
        <div class="peel-explanation-item">
          <div class="peel-letter">P</div>
          <div class="peel-explanation">
            <h4>Point</h4>
            <p>Make your main point or argument clearly. This should directly address the question.</p>
          </div>
        </div>

        <div class="peel-explanation-item">
          <div class="peel-letter">E</div>
          <div class="peel-explanation">
            <h4>Explanation</h4>
            <p>Explain or elaborate on your point. This helps the listener understand your reasoning.</p>
          </div>
        </div>

        <div class="peel-explanation-item">
          <div class="peel-letter">E</div>
          <div class="peel-explanation">
            <h4>Example</h4>
            <p>Provide specific examples, evidence, or experiences that support your point.</p>
          </div>
        </div>

        <div class="peel-explanation-item">
          <div class="peel-letter">L</div>
          <div class="peel-explanation">
            <h4>Link</h4>
            <p>Link back to the question or your main argument to show how your response directly answers the question.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PeelModalComponent',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
// PEEL Modal
.peel-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.peel-modal-content {
  background-color: white;
  border-radius: 0.75rem;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.peel-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid #e9ecef;

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }
  .close-btn {
    background: none;
    border: none;
    font-size: 1.25rem;
    color: #6c757d;
    cursor: pointer;

    &:hover {
      color: #343a40;
    }
  }
}

.peel-modal-body {
  padding: 1.5rem;
}

.peel-explanation-item {
  display: flex;
  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0;
  }

  .peel-letter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background-color: #4263eb;
    color: white;
    border-radius: 50%;
    font-weight: bold;
    font-size: 1.25rem;
    margin-right: 1rem;
    flex-shrink: 0;
  }

  .peel-explanation {
    h4 {
      font-size: 1.125rem;
      font-weight: 600;
      margin: 0 0 0.5rem 0;
    }
    p {
      margin: 0;
      color: #495057;
      line-height: 1.5;
    }
  }
}
</style>
