<template>
  <transition name="modal">
    <div v-if="show" class="modal-overlay" @click.self="$emit('close')">
      <div class="modal-content animated bounceIn">
        <div class="modal-header">
          <h2>
            <i class="fa fa-crown"></i>
            Delivery Complete!
          </h2>
        </div>

        <div class="delivery-info">
          <div class="pet-delivered">
            <img :src="petImageUrl" alt="Pet Delivered">
            <div class="pet-name">{{ petName }}</div>
          </div>

          <div class="rewards">
            <div class="xp-reward">
              <i class="fa fa-star"></i>
              <span>{{ xpGained }} XP earned</span>
            </div>
            <div v-if="kingLevelUp" class="level-up">
              <i class="fa fa-level-up-alt"></i>
              <span>King leveled up to {{ kingLevel }}!</span>
            </div>
          </div>
        </div>


        <div class="modal-footer">
          <button @click="$emit('close')">Continue</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'DeliveryCompletionModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    petName: {
      type: String,
      default: 'Flamebun Egg',
    },
    petImageUrl: {
      type: String,
      default: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/kingdom/flamebun/0.png',
    },
    xpGained: {
      type: Number,
      default: 5,
    },
    kingLevelUp: {
      type: Boolean,
      default: false,
    },
    kingLevel: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 25px;
  border-radius: 15px;
  width: 400px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.modal-header {
  margin-bottom: 20px;

  h2 {
    margin: 0;
    font-size: 1.8rem;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #FFD700;
      margin-right: 10px;
      font-size: 2rem;
    }
  }
}

.delivery-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
}

.pet-delivered {
  margin-bottom: 15px;

  img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-bottom: 10px;
  }

  .pet-name {
    font-weight: 600;
    font-size: 1.2rem;
  }
}

.rewards {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .xp-reward {
    font-size: 1.3rem;
    font-weight: 600;
    color: #4CAF50;
    display: flex;
    align-items: center;
    gap: 8px;

    i {
      color: #FFD700;
    }
  }

  .level-up {
    font-size: 1.2rem;
    font-weight: 600;
    color: #2196F3;
    display: flex;
    align-items: center;
    gap: 8px;
    animation: glow 2s infinite;

    i {
      color: #2196F3;
    }
  }
}


.modal-footer {
  margin-top: 20px;

  button {
    padding: 10px 25px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: #45a049;
      transform: translateY(-2px);
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    }

    &:active {
      transform: translateY(0);
    }
  }
}

.animated {
  animation-duration: 0.5s;
}

.bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes glow {
  0%, 100% {
    text-shadow: 0 0 5px rgba(33, 150, 243, 0.5);
  }
  50% {
    text-shadow: 0 0 20px rgba(33, 150, 243, 0.8);
  }
}

.modal-enter-active, .modal-leave-active {
  transition: all 0.3s ease;
}

.modal-enter, .modal-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
