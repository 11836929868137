<template>
  <div class="ai-practice-textbox__component">
    <!-- USER INPUT STATE -->
    <div v-if="state == 0" class="ai-practice-textbox__component--user-input">
      <!-- Word info section (optional) -->
      <div v-if="wordInfo" class="ai-practice-textbox__component--word-info">
        <div>
          <span class="word-character chinese-character">{{wordInfo.text }}</span>
          <span class="word-pinyin hypy">【 {{ wordInfo.pinyin }} 】</span>
        </div>
        <div class="word-definition">{{ wordInfo.definition }}</div>
      </div>
      <div class="ai-practice-textbox__component--user-input--method--container">
        <textarea class="ai-practice-textbox__component--user-input--method--text chinese-character"
        v-model='userAnswer'
        :placeholder="placeholderText"
        @paste="preventPaste"></textarea>
        <!-- user can either type or record audio. if record audio, note that we don't auto submit the transcription to be marked as child might make mofifications thereafter -->
        <recording-wave-animation v-if="isRecording && recordingState == 1" />
        <div v-if="!isRecording && recordingState == 2"
          class="ai-practice-textbox__component--user-input--method--transcribing">
          TRANSCRIBING IN PROGRESS...
        </div>
      </div>
      <div class="ai-practice-textbox__component--btn-container">
        <transcription-recorder-button
          @recording-state-change="handleRecordingStateChange"
          @transcription-complete="handleTranscriptionComplete"
        />
        <button @click="submitAnswer"
          class="ai-practice-textbox__component--btn-submit"
          :disabled="userAnswer === ''">
          Submit
        </button>
      </div>
    </div>
    <!-- we submitted text response to system and waiting response -->
    <div v-if="state == 1" @click.prevent="state = 2"
      class="ai-practice-textbox__component--loading">
      <div class="loading-anim"></div>
      <p>Analyzing...</p>
    </div>
    <div v-if="state == 2"
      class="ai-practice-textbox__component--feedback">
      <div class="ai-practice-textbox__component--feedback-header">
        <h2>AI Feedback</h2>
        <rating v-if="wordInfo" :max-score="5" :current-score="scoreStarRating"></rating>
        <!-- this is only shown for free writing, since it could be longer and harder to read -->
        <button v-if="!wordInfo" class="toggle-button"
          :class="{'red-btn': !showDeletedWords}"
          @click="showDeletedWords = !showDeletedWords">
          <span v-if="showDeletedWords">Hide Deleted Words</span>
          <span v-if="!showDeletedWords">Show Deleted Words</span>
        </button>
      </div>
      <language-feedback
        @character-click="onCharacterClick"
        subject="chinese"
        :show-deleted-words="showDeletedWords"
        :info="languageBreakdownData">
      </language-feedback>
      <button class="ai-practice-textbox__component--btn-continue"
        @click="onContinue">
        Continue
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapMutations} from 'vuex';

import {utilities} from '../../../../mixins/utilities';
import RecordingWaveAnimationComponent from '../../oral/components/RecordingWaveAnimationComponent.vue';
import LanguageFeedbackComponent from '../../oral/components/LanguageFeedbackComponent.vue';
import TranscriptionRecorderButtonComponent from '../../oral/components/TranscriptionRecorderButtonComponent.vue';
import RatingComponent from '../../../common/RatingComponent.vue';

const MINIMUM_CHARACTERS_IN_SENTENCE = 10;

export default {
  name: 'AiPracticeTextbox',
  components: {
    LanguageFeedback: LanguageFeedbackComponent,
    RecordingWaveAnimation: RecordingWaveAnimationComponent,
    TranscriptionRecorderButton: TranscriptionRecorderButtonComponent,
    Rating: RatingComponent,
  },
  props: {
    placeholderText: {
      type: String,
      default: '请用中文写一个句子...',
    },
    wordInfo: {
      type: Object,
      default: null,
      // Expected structure: { character: '字', pinyin: 'zì', definition: 'character' }
    },
    // Add a new prop for writing drill
    drill: {
      type: Object,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
  },
  mixins: [utilities],
  data() {
    return {
      userAnswer: '',
      // 0  user hasn't submitted answer
      // 1  user has submitted answer and awaiting backend response
      // 2  user has submitted answer and backend response is ready
      state: 0,
      fetching: false,
      languageBreakdownData: {},
      // this is purely for transcription
      isRecording: false,
      // audio related stuff
      // 0: haven't started recording
      // 1: recording
      // 2: finished recording, gotten transcription back
      recordingState: 0,
      // for testing, we want to allow pasting for convenience
      disablePaste: false,
      // we store the last 3 revised sentences to check if the user answer is too similar
      previousRevisedSentences: [],
      scoreStarRating: 0,
      showDeletedWords: true,
    };
  },
  methods: {
    ...mapMutations(['setMissions']),
    handleRecordingStateChange(newState) {
      this.recordingState = newState;
      //
      this.isRecording = newState === 1; // Recording state is 1 when actively recording
    },
    handleTranscriptionComplete(transcribedText) {
      this.userAnswer += transcribedText;
      this.recordingState = 0;
      this.isRecording = false;
    },
    /**
     * Detects if a target word is excessively repeated in the text
     *
     * @param {string} text - The full text to analyze
     * @param {string} word - The target word to check for repetition
     * @return {boolean} - Returns true if the word is excessively repeated
     *
     * This function determines if a target word (which may consist of multiple characters)
     * is being used excessively in a sentence. It calculates what percentage of the total
     * characters in the text are made up of the target word. If the target word makes up
     * more than 50% of the characters, it's considered excessive repetition.
     *
     * Example: For the word "千奇百怪" in "千奇百怪我千奇百怪我", the word makes up 80% of
     * the characters (8 out of 10), which would be flagged as excessive.
     */
    detectExcessiveRepetition(text, word) {
      if (!word) return false;

      // Count occurrences of the word in the text
      const regex = new RegExp(word, 'g');
      const matches = text.match(regex) || [];
      const occurrences = matches.length;

      // Check if the word makes up more than 50% of the characters
      const cleanText = this.stripPunctuationAndSpaces(text);
      const cleanWord = this.stripPunctuationAndSpaces(word);
      const wordLength = cleanWord.length;
      const totalLength = cleanText.length;
      return (occurrences * wordLength / totalLength > 0.5);
    },
    /**
     * Prevents pasting into the textarea
     *
     * @param {Event} e - The paste event
     */
    preventPaste(e) {
      if (this.disablePaste) {
        e.preventDefault();
        this.$emit('validation-error', 'Please type your answer instead of pasting.');
      }
    },
    submitAnswer() {
      // validations
      this.userAnswer = this.userAnswer.trim();
      if (this.userAnswer.length < MINIMUM_CHARACTERS_IN_SENTENCE) {
        this.$emit('validation-error', 'Please enter at least 10 characters.');
        return;
      }
      if (this.containsEnglish(this.userAnswer)) {
        this.$emit('validation-error', 'Please do not include English in your sentence.');
        return;
      }

      const payload = {
        userAnswer: this.userAnswer,
      };
      // If we have a drill, this is a free writing practice
      if (this.drill) {
        console.log('drill', this.drill);
        // For free writing, we'll use a different endpoint
        if (this.fetching) return;
        this.fetching = true;
        this.state = 1;

        return axios
            .post(`/writing/drills/${this.drill.id}/score_writing_drills_free_writing/`, {
              userAnswer: this.userAnswer,
            })
            .then((response) => {
              console.log('Free writing score response:', response.data);
              this.state = 2;

              this.languageBreakdownData = response.data.info;

              this.fetching = false;
            })
            .catch((error) => {
              console.error('Error scoring free writing:', error);
              this.fetching = false;
              this.state = 0;
              this.$emit('validation-error', 'An error occurred while scoring your answer. Please try again.');
            });
      }

      // the following is for normal sentence practice and general free writing

      // Handle the word info use case (existing code)
      if (this.wordInfo) {
        // check if the user answer contains the target word
        const cleanUserAnswer = this.stripPunctuationAndSpaces(this.userAnswer);
        // bad code - aipracticemodals uses .word while this uses .text
        const cleanTargetWord = this.stripPunctuationAndSpaces(this.wordInfo.text);
        if (cleanUserAnswer.indexOf(cleanTargetWord) === -1) {
          this.$emit('validation-error', `Please include ${this.wordInfo.text} in your sentence.`);
          return;
        }

        // Check for excessive repetition of the target word
        if (this.detectExcessiveRepetition(this.userAnswer, this.wordInfo.text)) {
          this.$emit('validation-error', `Please use ${this.wordInfo.text} in a natural way without excessive repetition.`);
          return;
        }

        // Check similarity against previous sentences
        for (const previousSentence of this.previousRevisedSentences) {
          if (this.checkJaccardSimilarity(this.userAnswer, previousSentence, 0.8)) {
            this.$emit('validation-error', `Your sentence is too similar to a previous sentence. Please try again.`);
            return;
          }
          if (this.checkLevenshteinSimilarity(this.userAnswer, previousSentence, 0.8)) {
            this.$emit('validation-error', `Your sentence is too similar to a previous sentence. Please try again.`);
            return;
          }
        }

        payload.wordInfo = this.wordInfo;
      }

      if (this.fetching) return;
      this.fetching = true;
      this.state = 1;

      axios
          .post(`/vocab/sentence_practice/`, payload).then((response) => {
            console.log(response.data);
            this.state = 2;
            this.languageBreakdownData = response.data.info;
            if (response.data.missions) {
              this.setMissions(response.data.missions);
            }
            if ('score' in response.data) {
              // this is to ensure furture attempts are not too similar to revised sentences (prevent cheating)
              const revisedSentence = response.data.info.hydrated_text.map((item) => item.text).join('');
              // Add the new revised sentence to the array and keep only the last 3
              this.previousRevisedSentences.push(revisedSentence);
              if (this.previousRevisedSentences.length > 3) {
                this.previousRevisedSentences.shift();
              }

              // updates the score star rating
              this.scoreStarRating = Math.round(response.data.score * 5);
              this.$emit('score-change', response.data.score);
            }
            this.fetching = false;
          })
          .catch((error) => {
            console.log(error);
            this.fetching = false;
            this.state = 0;
            this.$emit('validation-error', 'An error occurred. Please try again.');
          });
    },
    onContinue() {
      this.$emit('continue');
      this.resetState();
    },
    onCharacterClick(entry, index) {
      console.log(entry, index);
    },
    resetState() {
      this.state = 0;
      this.userAnswer = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.ai-practice-textbox__component {
  background: #fff;
  border-radius: .75rem;
  height: 100%;
  min-height: inherit;
  display: flex;
  padding: 2rem 3rem;
  > div {
    display: flex;
    gap: .75rem;
    flex-direction: column;
    // flex: 1;
    width: 100%;
  }
  &--btn {
    &-container {
      display: flex;
      margin-top: .5rem;
      gap: .5rem;
    }
    &-submit, &-continue {
      font-family: "baloo da 2";
      font-weight: 600;
      border-radius: .75rem;
      font-size: 1.05rem;
      border: 2px solid rgba(0, 0, 0, 0);
      border-bottom: 4px solid #359776;
      text-transform: uppercase;
      padding: 8px 0;
      color: #fff;
      background: #6ad6b2;
      width: 100%;
      cursor: pointer;
      &:active {
        border-bottom: 2px solid #5e46b0;
        margin-top: 2px;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background: #ddd;
        border-bottom: 4px solid #d2d2d2;
        &:active {
          margin-top: 0;
          transform: none;
          border-bottom: 4px solid #d2d2d2;
        }
      }
    }
  }
  .fa-xmark {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    font-size: 1.5rem;
  }
  &--user-input {
    &--method {
      &--container {
        position: relative;
        flex: 1;
      }
      &--transcribing {
        width: 100%;
        height: 100%;
        border-radius: .5rem;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        font-weight: 600;
        color: #000;
        border: 2px solid #e7e7e7;
      }
      &--text {
        border: 2px solid #e7e7e7;
        border-radius: .5rem;
        height: 100%;
        width: 100%;
        padding: .75rem;
        font-size: 1.1rem;
        display: block;
        outline: none;
        resize: none;
        &::placeholder {
          font-size: 1.1rem;
        }
      }
      &--icon-transcribing {
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
      }
      &--microphone {
        border: 2px solid rgba(0,0,0,0);
        width: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        border-radius: .5rem;
        color: #fff;
        background: #873fff;
        border-bottom: 4px solid #582d99;
        &-stop {
          background: #ff3f99;
          border-bottom: 4px solid #b0175f;
        }
        cursor: pointer;
        &:active {
          border-bottom: 2px solid #5e46b0;
          margin-top: 2px;
        }
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background: #ddd;
          border-bottom: 4px solid #d2d2d2;
          &:active {
            margin-top: 0;
            transform: none;
            border-bottom: 4px solid #d2d2d2;
          }
        }
      }
    }
  }
  &--loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  &--feedback {
    display: flex;
    flex: 1;
    &-header {
      display: flex;
      justify-content: space-between;
    }
    &-text {
      flex: 1;
      border: 2px solid #e7e7e7;
      border-radius: .5rem;
      padding: .75rem;
      font-size: 1.25rem;
      display: block;
      outline: none;
      resize: none;
    }
    .error {
      color: #e26e7c;
      text-decoration: line-through;
    }
    .correction {
      color: #54d1b7;
    }
    button {
      background: #6ad6b8;
      border-bottom: 4px solid #3c9e83;
      &:active {
        border-bottom: 2px solid #3c9e83;
      }
    }
    .toggle-button {
      font-family: "baloo da 2";
      font-weight: 600;
      border-radius: .75rem;
      font-size: .8rem;
      border: 2px solid rgba(0, 0, 0, 0);
      border-bottom: 4px solid #359776;
      text-transform: uppercase;
      padding: 8px;
      color: #fff;
      background: #6ad6b2;
      cursor: pointer;
      width: 187px;;
      &:active {
        border-bottom: 2px solid #5e46b0;
        margin-top: 2px;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background: #ddd;
        border-bottom: 4px solid #d2d2d2;
        &:active {
          margin-top: 0;
          transform: none;
          border-bottom: 4px solid #d2d2d2;
        }
      }
    }
    .red-btn {
      background: #f56387;
      border-bottom: 4px solid #bc3f5e;
    }
  }
  &--word-info {
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;

    .word-character {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .word-pinyin {
      font-size: .9rem;
      color: #873fff;
      margin-bottom: 0.25rem;
    }

    .word-definition {
      font-size: 1rem;
      font-style: italic;
      color: #666;
      line-height: 1rem;
    }
  }
}


@media only screen and (max-width: 1560px), (max-height: 880px) {
  .ai-practice-textbox__component {
    padding: 1.5rem 2rem;
  }
}
@media only screen and (max-width: 760px) {
  .ai-practice-textbox__component {
    padding: 1.25rem;
    &--word-info {
      .word-character {
        font-size: 1.25rem;
      }
      .word-pinyin {
        font-size: .8rem;
      }
      .word-definition {
        font-size: .9rem;
      }
    }
    > div {
      gap: .5rem;
    }
  }
  .ai-practice-textbox__component--btn-submit {
    font-size: .9rem;
    padding: 4px 0;
  }
}

</style>
