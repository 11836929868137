<template>
  <transition name="modal">
    <div v-if="show" class="modal-overlay" @click.self="$emit('close')">
      <div class="modal-content animated bounceIn"
        :style="[
          maxWidth ? {maxWidth: maxWidth} : {},
          minWidth ? {minWidth: minWidth} : {}
        ]">
        <h2 class="title">{{ title }}</h2>
        <!-- Default content if no slot is provided -->
        <p v-if="!$slots.default">{{ message }}</p>
        <!-- Named slot for custom content -->
        <slot></slot>
        <div class="modal-footer">
          <button @click="$emit('close')">{{ closeButtonText }}</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    show: Boolean,
    title: String,
    message: String,
    closeButtonText: {
      type: String,
      default: 'Close',
    },
    minWidth: String,
    maxWidth: String,
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin-bottom: .5rem;
  }
}

.modal-footer {
  margin-top: 20px;
  text-align: right;
}

.modal-footer {
  width: 100%;
  padding-top: 0;
  button {
    cursor: pointer;
    font-family: "baloo da 2";
    width: 100%;
    margin-top: 0;
    font-weight: 600;
    border-radius: .75rem;
    font-size: 1.05rem;
    border: 2px solid #e7e7e7;
    border-bottom: 4px solid #e7e7e7;
    padding: 8px 0;
    color: #ababab;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      margin-right: .5rem;
    }
    &:focus {
      outline: none;
    }
    &:active {
      background: #f2f2f2;
      border-bottom: 2px solid #e7e7e7;
      transform: translateY(1px);
      margin-top: 2px;
    }
  }
}

.modal-enter-active, .modal-leave-active {
  transition: opacity 0.3s;
}

.modal-enter, .modal-leave-to {
  opacity: 0;
}
</style>
