import { render, staticRenderFns } from "./AiPracticeTextboxComponent.vue?vue&type=template&id=09c8f2e2&scoped=true&"
import script from "./AiPracticeTextboxComponent.vue?vue&type=script&lang=js&"
export * from "./AiPracticeTextboxComponent.vue?vue&type=script&lang=js&"
import style0 from "./AiPracticeTextboxComponent.vue?vue&type=style&index=0&id=09c8f2e2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09c8f2e2",
  null
  
)

export default component.exports