<template>
  <block-container
    title="Grammar"
    fa-icon="spell-check"
    >
    <div class="body-content">
      <div class="level-btns">
        <div v-for="(level, index) in validLevels" :key="index"
          @click="clickLevel(level)"
          :class="{'level-btn--active': selectedLevel === level }"
          class="level-btn"
        >
          {{ level | shorternLevel }}
        </div>
      </div>
      <div class="body-content--container">
        <div class="collections-container">
          <div
            v-for="(quiz, index) in filteredGrammarQuizzes"
            :key="`quiz-${index}`"
            @click="navigateToQuiz(quiz)"
            class="body-content--item">
            <div class="body-content--item-icon">
              <i class="fas fa-pencil-alt"></i>
            </div>
            <div class="body-content--item-text">
              {{ quiz.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </block-container>
</template>

<script>
import axios from 'axios';
import {mapState, mapMutations, mapGetters} from 'vuex';
import BlockContainerComponent from '../../common/BlockContainerComponent.vue';

export default {
  name: 'NewMalayGrammarOverview',
  components: {
    BlockContainer: BlockContainerComponent,
  },
  computed: {
    ...mapState('grammar', ['grammarQuizzes']),
    ...mapGetters(['validLevels']),
    filteredGrammarQuizzes() {
      // we only consider the first level for each quiz
      return this.grammarQuizzes.filter((quiz) => quiz.levels[0] === this.selectedLevel);
    },
  },
  data() {
    return {
      fetching: false,
      selectedLevel: 'primary_one',
    };
  },
  mounted() {
    this.pullData();
  },
  methods: {
    ...mapMutations('grammar', ['setGrammarQuizzes', 'setSelectedQuiz']),
    navigateToQuiz(quiz) {
      this.setSelectedQuiz(quiz);
      this.$router.push({
        name: 'new-malay-grammar-quiz',
        params: {quizId: quiz.id},
        query: {quizType: 'malayGrammar'},
      });
    },
    pullData() {
      this.fetching = true;
      axios
          .get('/vocab/grammar/', {params: {subject: 'malay'}})
          .then((response) => {
            console.log('response', response.data);
            this.setGrammarQuizzes(response.data);
          })
          .catch((error) => {
            console.error('Error fetching grammar quizzes:', error);
          })
          .finally(() => {
            this.fetching = false;
          });
    },
    clickLevel(level) {
      this.selectedLevel = level;
    },
  },
};
</script>


<style lang="scss" scoped>
  .body-content {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    .level {
      &-btn {
        border-bottom: 4px solid #193295;
        border-right: 4px solid #193295;
        border-top: 4px solid #5879fe;
        border-left: 4px solid #5879fe;
        color: #fff;
        background: #2c57ff;
        padding: .1rem;
        width: 13%;
        border-radius: 0.5rem;
        font-size: .9rem;
        margin-right: .5rem;
        text-align: center;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          box-shadow: 0 0px 1px 3px #ff00dd;
        }
        &--active {
          color: #fff;
          background: #ffa025;
          font-weight: 600;
          border-top: 4px solid #bf6800;
          border-left: 4px solid #bf6800;
          border-bottom: 4px solid #ffe758;
          border-right: 4px solid #ffe758;
          box-shadow: 0 0px 1px 3px #00ffea;
          &:hover {
            box-shadow: 0 0px 1px 3px #00ffea;
          }
        }
      }
      &-btns {
        display: flex;
        margin-top: 1rem;
        margin-bottom: 1rem;
        justify-content: center;
        align-items: center;
      }
    }
    &--container {
      height: 100%;
      padding: 0rem 2rem 6rem 2rem;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      text-align: center;
      width: 100%;
    }

    .grammar-rule {
      background: #f5f5f5;
      border-radius: 10px;
      padding: 1.5rem;
      margin-bottom: 1.5rem;

      &-title {
        color: #333;
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }

      &-description {
        color: #666;
        margin-bottom: 1.5rem;
      }
    }

    .collections-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
    }

    &--item {
      overflow: hidden;
      background: #fff;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;
      text-align: center;
      border-radius: .5rem;
      text-decoration: none;
      transition: all 0.2s;
      position: relative;
      height: 150px;
      width: 100%;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);

      &:hover {
        box-shadow: 0 0px 1px 7px #00ffd8;
      }

      &-icon {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s;
        background-size: cover;
        background-position: center;
        height: 85%;
        overflow: hidden;
        margin: .75rem .75rem 0;
        border-radius: .5rem;
        border: 3px solid #e7e7e7;
        i {
          transition: all 0.2s;
          color: #2196F3;
          font-size: 2.5rem;
          margin-bottom: 5px;
        }

        img {
          width: 100%;
        }
      }

      &-text {
        background: #fff;
        border-bottom-right-radius: .5rem;
        border-bottom-left-radius: .5rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 1rem;
        color: #6b6b6b;
      }
    }
  }

  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .body-content {
      .collections-container {
        grid-template-columns: 1fr 1fr;
      }

      &-text {
        font-size: .9rem;
      }
    }
  }

  @media only screen and (max-width: 1150px),
  screen and (max-height: 690px) {
  }

  @media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
    .body-content {
      background: none;
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: 690px) {
  }

  @media only screen and (max-width: 580px) {
    .body-content {
      .collections-container {
        grid-template-columns: 1fr;
      }
      &--container {
        padding: 0 .75rem 6rem .75em;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .body-content--item-icon {
      padding: .5rem;
      i {
        font-size: 2.5rem;
      }
    }

    .body-content--item-text {
      font-size: .8rem;
    }
  }

</style>

