<template>
  <div v-if="info" class="container">
    <!-- <span class="chinese-character">{{ info.revised }}</span>
    <br> -->
    <span
      v-for="(entry, index) in filteredRevisedWithHighlights"
      :key="`revised_${index}`"
      :class="{'add': entry.operation === '+', 'delete': entry.operation === '-'}"
      class="chinese-character test"
      v-html="getWord(entry, filteredRevisedWithHighlights[index + 1] || null)"
    >
    </span>
    <span v-if="info.elaboration !== ''" class="elaboration-highlight chinese-character">{{ info.elaboration }}</span>
    <!-- idioms feedback explanation -->
    <p v-if="info.english_explanation !== ''" class="idioms-correction-explanation">
      <i class="fa-solid fa-arrow-turn-up"></i>
      <span>
        {{ info.english_explanation }}
      </span>
    </p>
  </div>
</template>

<script>

export default {
  name: 'LanguageFeedback',
  props: {
    info: Object,
    subject: String,
    showDeletedWords: Boolean,
  },
  data() {
    return {
      idiomCorrections: true,
    };
  },
  mounted() {
  },
  computed: {
    filteredRevisedWithHighlights() {
      if (!this.info) return [];
      if (this.showDeletedWords) return this.info.revisedWithHighlights;
      return this.info.revisedWithHighlights.filter((w) => {
        return w.operation !== '-';
      });
    },
    hasRevisedWithElaborations() {
      if (!this.info) return '';
      return typeof this.info['revisedWithElaborations'] !== 'undefined' && this.info['revisedWithElaborations'] !== '';
    },
  },
  methods: {
    getWord(currentEntry, nextEntry) {
      if (this.subject === 'chinese' || !nextEntry) return currentEntry.word;
      if (this.subject === 'malay') {
        const punctuationNoSpaceBefore = [',', '.', '!', '?', ':', ';', ')', ']', '}'];
        const punctuationNoSpaceAfter = ['(', '[', '{'];

        const result = currentEntry.word;

        // Don't add space if next word is punctuation that shouldn't have space
        // e.g. if next word is "," then don't add a space
        if (punctuationNoSpaceBefore.includes(nextEntry.word)) {
          return result;
        }

        // Don't add space if current word is punctuation that shouldn't have space after
        if (punctuationNoSpaceAfter.includes(currentEntry.word)) {
          return result;
        }

        // Add space for all other cases
        return result + ' ';
      }
    },
    checkWordCount(searchText, sentence) {
      const regex = new RegExp(searchText, 'g');
      const matches = sentence.match(regex);
      return !matches ? 0 : sentence.match(regex).length;
    },
  },
};
</script>

<style lang="scss" scoped>
  .container {
    flex: 1;
    border: 2px solid #e7e7e7;
    border-radius: .5rem;
    padding: .75rem;
    font-size: 1.25rem;
    display: block;
    outline: none;
    resize: none;
  }

  .elaboration-highlight {
    background-color: #fff2e6;
    color: #cc6600;
  }

  .test {
    display: inline-block;
    white-space: pre-wrap;
  }

  .add {
    color: #54d1b7;
    font-weight: bold;
  }

  .delete {
    color: #e26e7c;
    font-weight: bold;
    text-decoration: line-through;
  }
  .idioms-correction-explanation {
    border-top: 2px solid #e7e7e7;
    text-align: justify;
    font-size: .9rem;
    padding: 1rem .5rem;
    i {
      transform: rotate(90deg);
      margin-right: .5rem;
    }
    padding-bottom: 0;
    margin-top: .5rem;
  }

</style>
