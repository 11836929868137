<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'NewStoreMains',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>


<style lang="scss" scoped>
</style>
