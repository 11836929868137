<!-- KIV start timer and countdown not integrated yet; fix in future -->
<template>
  <div class="oral-popup__component">
    <div class="oral-popup__component--container">
      <i @click="closePopup"
        class="fa-solid fa-xmark btn-close"></i>
      <div class="oral-component__content" :class="paragraphClass">
        <div v-if="state !== 4" class="oral-component__content--header">
          <span class="oral-component__content--header-title">
            <!-- Revised Answer Practise -->
          </span>
          <span v-if="paraIndex > -1"
            class="oral-component__content--header-para-number">
            段落: {{ paraIndex + 1 }} / {{ this.numParagraphs }}
          </span>
        </div>
        <!-- body -->
        <div v-if="state !== 4"
          class="oral-component__content--passage">
          <!-- question container -->
          <div v-if="paraIndex > -1 && [0, 1, 2].indexOf(state) > -1"
            class="oral-component__content--passage-qns">
            <p v-for="(word, index) in text"
              class="chinese-character" :key="index">
              {{ word }}
            </p>
          </div>
          <!-- results container -->
          <div v-if="wordsWithPunctuation && state === 3"
            class="oral-component__content--passage-result">
            <entity
              v-for="(word, index) in wordsWithPunctuation"
              :key="index"
              :info="word"></entity>
          </div>
          <div v-if="wordsWithPunctuation && state === 3"
            class="oral-component__content--passage-result--legend">
            <div class="oral-component__content--passage-result--legend-1">
              <i class="fa-solid fa-square"></i>
              <span>Can Improve</span>
            </div>
            <div class="oral-component__content--passage-result--legend-2">
              <i class="fa-solid fa-square"></i>
              <span>Good</span>
            </div>
            <div class="oral-component__content--passage-result--legend-3">
              <i class="fa-solid fa-square"></i>
              <span>Excellent</span>
            </div>
          </div>
        </div>
        <!-- footer -->
        <div v-if="state !==4"
          class="oral-component__content--dashboard">
          <!-- Btn cancel record mode -->
          <div v-if="isRecording && !isRecordingCountdown"
            class="oral-component__content--dashboard--btn-cancel">
            <button @click="cancelRecording">
              <i class="fa-solid fa-xmark"></i>
              <span>Cancel</span>
            </button>
          </div>
          <!-- Recorder -->
          <div class="oral-component__content--dashboard--recorder"
            :class="{'mr-200': isRecording && !isRecordingCountdown }"
            v-if="[0, 1].indexOf(state) > -1">
            <!-- Btn Start Record -->
            <div v-if="!isRecording && state === 0"
              class="recorder--btn-grp">
              <button class="recorder--btn recorder--btn-start animated bounceIn"
                @click="toggleRecording">
                <i class="fa-solid fa-microphone"></i>
              </button>
              <span class="recorder--btn-text animated fadeInUp faster">
                ( Click to Start Recording )
              </span>
            </div>
            <!-- Btn Stop Record -->
            <div v-if="isRecording">
              <!-- Start Record Countdown -->
              <div v-if="isRecordingCountdown"
                class="recorder--countdown animated flipInX">
                <h2>Get Ready...</h2>
                <p>( Don't Speak Yet )</p>
              </div>
              <!-- Btn Stop Record -->
              <div v-if="!isRecordingCountdown"
                class="recorder--btn-grp">
                <!-- Audio Wave Anim -->
                <span class="wave-anim-grp animated flipInX">
                  <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </span>
                <button class="recorder--btn recorder--btn-stop animated bounceIn"
                  @click="toggleRecording">
                  <i class="fa-solid fa-stop"></i>
                </button>
                <span class="recorder--btn-text animated fadeInUp faster">
                  ( Start Recording Now! )
                </span>
              </div>
            </div>
          </div>
          <!-- Loading -->
          <div v-if="state === 2"
            class="oral-component__content--dashboard--loading">
            <!-- <img src="../../../assets/oral/indicator.gif" /> -->
            <div class="loading-anim"></div>
            <span class="oral-component__content--dashboard--loading-text animated fadeInUp faster">
              ( Analyzing Audio... )
            </span>
          </div>
          <!-- <div v-if="state === 2"
            class="oral-component__content--dashboard-loading">
            <img src="../../../assets/oral/indicator.gif" />
          </div> -->
          <!-- Results -->
          <div v-show="state === 3"
            class="oral-component__content--dashboard-results">
            <p class="oral-component__content--dashboard-results-footer">
              ( Click on any word in the passage above to learn more )
            </p>
            <div v-if="results"
              class="oral-component__content--dashboard-results-score">
              <div class="oral-component__content--dashboard-results-score-item">
                <div class="oral-component__content--dashboard-results-score-name">
                  Overall
                </div>
                <div class="oral-component__content--dashboard-results-score-value">
                  {{ results.overall }}
                </div>
              </div>
              <div class="oral-component__content--dashboard-results-score-item">
                <div class="oral-component__content--dashboard-results-score-name">
                  Fluency
                </div>
                <div class="oral-component__content--dashboard-results-score-value">
                  {{ results.fluency }}
                </div>
              </div>
              <div class="oral-component__content--dashboard-results-score-item">
                <div class="oral-component__content--dashboard-results-score-name">
                  Tone
                </div>
                <div class="oral-component__content--dashboard-results-score-value">
                  {{ results.tone }}
                </div>
              </div>
              <div class="oral-component__content--dashboard-results-score-item">
                <div class="oral-component__content--dashboard-results-score-name">
                  Pronunciation
                </div>
                <div class="oral-component__content--dashboard-results-score-value">
                  {{ results.pronunciation }}
                </div>
              </div>
            </div>
            <div class="oral-component__content--dashboard-results-audio">
              <!-- buttons are visible and used to control what is played -->
              <div
                class="oral-component__content--dashboard-results-audio-btn"
                @click.prevent="playAudio('student')">
                <i class="fas fa-volume-up sound-icon"></i>
                <span>Your Recording</span>
              </div>
              <div v-if="teacherAudioSrc && (type !== 'templatedphrases')"
                class="oral-component__content--dashboard-results-audio-btn oral-component__content--dashboard-results-audio-btn-teacher"
                @click.prevent="playAudio('teacher')">
                <i class="fas fa-volume-up sound-icon"></i>
                <span>Teacher's Recording</span>
              </div>
              <audio :src="teacherAudioSrc" ref="teacherAudio"></audio>
            <div
              class="oral-component__content--dashboard-results-audio-btn
              oral-component__content--dashboard-results-audio-btn-rerecord"
              @click.prevent="rerecord">
              <i class="fas fa-arrows-rotate sound-icon"></i>
              <span>Rerecord</span>
            </div>
            </div>
          </div>
        </div>
        <div v-if="state !== 4"
          v-show="state === 3"
          class="oral-component__content--dashboard-results-btn-next"
          @click="goNext()">
          <span v-if="paraIndex + 1 < numParagraphs">Continue</span>
          <span v-else>End</span>
        </div>
      </div>
      <div v-if="state === 3 && selectedEntity"
        class="oral-component__popup-notification animated fadeIn faster"
        @click.self="setSelectedEntity(null)">
        <div class="oral-component__popup-notification--content-container animated fadeInUp faster">
          <!-- teaching popup -->
          <teaching-screen
            class="oral-component__popup-notification--content"
            :word="selectedEntity.text"
            :pk="selectedEntity.pk || -1"
            :definition="selectedEntity.definition"
            :example-sentence="selectedEntity.sentence || ''"
            :src="selectedEntity.src || ''"
            :pinyin="selectedEntity.pinyin"
            :chapter="selectedEntity.chapter || null"
            :story="selectedEntity.story || null"
            :subtopic="selectedEntity.subtopic || null"
            :is-trackable="selectedEntity.is_trackable"
          ></teaching-screen>
        </div>
      </div>
      <basic-reward v-if="showRewardsPage"
        :coins-to-add="results['coins_to_add']"
        :close-rewards-callback="onCloseRewards">
      </basic-reward>
    </div>
  </div>
</template>

<script>

/**
 * copy and pasted code from passage component
 * goal is for this to eventually be a popup that users can click on any sentence or paragraph to practise reading
*/

import {mapState, mapMutations} from 'vuex';
import axios from 'axios';
import Recorder from '../../../../vendor/recorder';

// from oral/common
import EntityComponent
  from '../../oral/EntityComponent.vue';

// start from ../common
import TeachingScreenComponent
  from '../../common/guidedreading/TeachingScreenComponent.vue';

import BasicRewardComponent
  from '../../common/BasicRewardComponent.vue';
// end ../common folder


// miliseconds, to prevent accidental double clicking
const MINIMUM_RECORD_TIME = 500;

export default {
  name: 'SpeechPronunciationPopup',
  components: {
    Entity: EntityComponent,
    BasicReward: BasicRewardComponent,
    TeachingScreen: TeachingScreenComponent,
  },
  props: {
    texts: Array,
    entityInfo: Object,
  },
  data() {
    return {
      // refers to the pinyin of the current text; in array form
      pinyin: [],
      /*
       * 0: untested
       * 1: recording
       * 2: finished recording, waiting for system to return results
       * 3: results returned for the current para
       * 4: finished all the recordings for all the para, time to show overall results
      **/
      state: 0,
      numParagraphs: -1,
      paraIndex: -1, // each para is one question
      isRecording: false,
      // we don't want the record button to change immediately as that can cause the initial word to be cut off
      // hence, when isRecording changes to true, isRecordingBuffered only changes 0.5s later
      isRecordingBuffered: false,
      hasMicrophonePermission: false,
      recorder: null,
      gumStream: null,
      results: null,
      startRecordingTime: null,
      fetching: false,
      studentAudio: null,
      showRewardsPage: false,
      hasClaimedRewards: false,
      isRerecordedQuestion: false,
      isRecordingCountdown: false,
    };
  },
  metaInfo: {
    // using minifed version of recorder caused an error
    script: [
      // {src: 'https://cdnjs.cloudflare.com/ajax/libs/recorderjs/0.1.0/recorder.js', async: true, defer: true},
    ],
  },
  computed: {
    ...mapState(['studentId']),
    ...mapState('guidedReading', ['selectedEntity', 'textbookChapterId']),
    // refers to the text of the current paragraph
    text() {
      if (!this.texts || this.paraIndex < 0) return '';
      return this.texts[this.paraIndex];
    },
    audioIcon() {
      return this.isRecording ? 'fa-stop' : 'fa-microphone';
    },
    teacherAudioSrc() {
      return '';
      // if (!this.paraIndex < 0 || this.paraIndex >= this.numParagraphs) return '';
      // if (this.type === 'readaloud') {
      //   return `https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/oral_passages/${this.passageData.level}/${this.entityId}_${this.paraIndex}.mp3`;
      // } else if (this.type === 'freeresponse') {
      //   return `https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/oral_freeresponse/${this.entityId}_${this.paraIndex}.mp3`;
      // } else if (this.type === 'textbookchapter') {
      //   try {
      //     return this.passageData.sentences[this.paraIndex]['audio_mp3'];
      //   } catch (err) {
      //     return '';
      //   }
      // } else {
      //   return '';
      // }
      // else if (this.type === '') {
      //   return `https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/oral_freeresponse/primary_six/${this.entityId}_${this.questionIndex}_${this.paraIndex}.mp3`;
      // }
    },
    paragraphClass() {
      if (!this.paraIndex < 0) return '';
      if (this.text.length <= 40) return 'paragraph-short';
      return this.text.length <= 60 ? 'paragraph-medium': 'paragraph-long';
    },
    // the result from api doesnt have any punctuation, so we need to add it back
    wordsWithPunctuation() {
      if (!this.results) return [];
      let i = 0; // cleanText counter
      let j = 0; // result counter
      // word token counter (more complicated as double chars like 朋友 counts as one counter)
      // note that word tokens dont contain particles
      let k = 0;
      let charInTokenCounter = 0;
      let final = [];

      final = [];
      try {
        while (i < this.text.length) {
          // if out of bounds or a punctuation particle
          if (j >= this.results.words.length || this.text[i] !== this.results.words[j].word) {
            final.push({
              type: 'particle',
              word: this.text[i],
              details: null,
            });
          } else {
            // note that token can be a phrase like 朋友, while word is singular character
            // thus, 朋 and 友 would be two different characters that have the same token
            const token = this.results.word_tokens[k];
            const details = this.results.words[j];
            // better to use our inputted ref_pinyin
            const info = this.results.words[j];
            info['pinyin'] = this.pinyin[j];
            details['token'] = token;
            final.push({
              type: 'character',
              word: this.results.words[j].word,
              details: info,
            });
            j++;

            // checks whether we need to move to next token
            if (charInTokenCounter + 1 >= token['text'].length) {
              charInTokenCounter = 0;
              k++;
            } else {
              charInTokenCounter++;
            }
          }
          i++;
        };
      } catch (err) {
        console.log(err);
      }
      return final;
    },
  },
  mounted() {
    console.log('mounting speech pronunciation popup');
    // NOTE: safari doesn't support navigator.permissions
    if (navigator.permissions) {
      // checks to see if user has provided permission to use microphone
      navigator.permissions.query({
        name: 'microphone',
      })
          .then((permissionStatus) => {
            this.hasMicrophonePermission = permissionStatus.state !== 'denied';
          })
          .catch((error) => {
            console.log(error);
            this.hasMicrophonePermission = false;
          });
    } else {
      // Check if mediaDevices loaded.
      if (navigator.mediaDevices !== undefined) {
        // Req microphone permissions
        navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
          // Mic permissions granted, handle however you wish
          this.hasMicrophonePermission = true;
        }).catch((err) => {
          // Mic permissions denied, handle however you wish
          this.hasMicrophonePermission = false;
        });
      } else {
        // Out of luck at this point, handle however you wish.
        this.hasMicrophonePermission = false;
      }
    }
    this.initialise();
    // this.pullData();
  },
  methods: {
    ...mapMutations(['setCoins']),
    ...mapMutations('widgets', ['setCoinLeaderboard']),
    ...mapMutations('guidedReading', ['setSelectedEntity']),
    initialise() {
      this.numParagraphs = this.texts.length;
      this.nextPara();
    },
    reset() {
      this.gumStream = null;
      this.state = 0;
    },
    rerecord() {
      this.reset();
      this.isRerecordedQuestion = true;
    },
    // checks to see if we have reached end of passage or can we show next para
    // adjusts the state accordingly
    nextPara() {
      this.paraIndex++;
      // end
      console.log(this.paraIndex, this.numParagraphs);
      if (this.paraIndex >= this.numParagraphs) {
        this.paraIndex = -1;
        this.end();
        return;
      }
      this.state = 0;
      this.isRerecordedQuestion = false;
    },
    // to prevent user from accidentally double clicking, we will record for minimum of at least 1s
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    // https://blog.addpipe.com/using-recorder-js-to-capture-wav-audio-in-your-html5-web-site/
    startRecording() {
      if (this.fetching) return;
      this.state = 1;
      this.isRecording = true;
      // To start countdown to account for recording latency
      this.isRecordingCountdown = true;
      setTimeout(() => {
        this.isRecordingCountdown = false;
        // this.startTimer();
        this.startRecordingTime = new Date(); // more accurate number to send to backend
      }, 1750);
      this.result = null;
      this.startRecordingTime = new Date();

      // shim for AudioContext when it's not avb.
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      const audioContext = new AudioContext;

      navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((stream) => {
        /* assign to gumStream for later use */
        this.gumStream = stream;
        /* use the stream */
        const input = audioContext.createMediaStreamSource(stream);
        // Create the Recorder object and configure to record mono sound (1 channel)
        this.recorder = new Recorder(input, {
          numChannels: 1,
        });
        // start the recording process
        this.recorder.record();
      }).catch((err) => {
        // enable the record button if getUserMedia() fails
        alert(err);
        console.log(err);
        this.isRecording = false;
        this.isRecordingBuffered = false;
      });
    },
    stopRecording() {
      if (new Date() - this.startRecordingTime < MINIMUM_RECORD_TIME) {
        return;
      }
      this.state = 2;

      if (this.fetching) {
        return;
      }
      this.isRecording = false;
      this.isRecordingBuffered = false;

      // tell the recorder to stop the recording
      if (!this.recorder) {
        alert('Error recording, please try again.');
        this.reset();
        return;
      }
      this.recorder.stop(); // stop microphone access
      this.gumStream.getAudioTracks()[0].stop();
      // create the wav blob and pass it on to createDownloadLink
      this.recorder.exportWAV(this.createDownloadLink);
    },
    cancelRecording() {
      this.state = 0;
      this.isRecording = false;
      // tell the recorder to stop the recording
      if (this.recorder) {
        this.recorder.stop(); // stop microphone access
      }
      if (this.gumStream && this.gumStream.getAudioTracks && this.gumStream.getAudioTracks().length > 0) {
        this.gumStream.getAudioTracks()[0].stop();
      }
    },
    createDownloadLink(blob) {
      const au = document.createElement('audio');
      au.controls = false;
      au.src = URL.createObjectURL(blob);
      this.studentAudio = au;

      const filename = new Date().toISOString();
      const formData = new FormData();
      formData.append('audioData', blob, filename);
      formData.append('text', this.text);
      formData.append('entityInfo', JSON.stringify(this.entityInfo));
      formData.append('isRerecordedQuestion', this.isRerecordedQuestion);
      this.fetching = true;

      axios
          .post('/vocab/speech_pronunciation/custom/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            this.results = response.data;
            this.pinyin = response.data.pinyin.split(' ');
            this.fetching = false;
            this.state = 3;
          })
          .catch((err) => {
            console.log(err);
            alert(err);
            this.fetching = false;
            this.reset();
          });
    },
    stopAudio() {
      if (this.$refs.teacherAudio) {
        this.$refs.teacherAudio.pause();
        this.$refs.teacherAudio.currentTime = 0;
      }
      if (this.studentAudio) {
        this.studentAudio.pause();
        this.studentAudio.currentTime = 0;
      }
    },
    // if student audio is selected, stop teacher audio first, then play student audio
    // and vice versa
    playAudio(version) {
      this.stopAudio();
      if (version === 'student') {
        this.studentAudio.play();
      } else {
        this.$refs.teacherAudio.play();
      }
    },
    goNext() {
      this.stopAudio();
      // next para will check if we should go to next paragraph or end
      this.nextPara();
    },
    end() {
      this.state = 4;
      this.closePopup();
    },
    onShowRewardsPage() {
      if (this.hasClaimedRewards) return;
      this.hasClaimedRewards = true;
      this.showRewardsPage = true;
    },
    onCloseRewards() {
      // set coins
      this.setCoins(this.results.coins);
      this.setCoinLeaderboard(this.results.coin_leaderboard);
      this.showRewardsPage = false;
      if (this.type === 'readaloud') {
        this.$router.push({name: 'new-oral-category-index', params: {category: 'readaloud'}});
      } else if (this.type === 'freeresponse') {
        this.$router.push({name: 'new-oral-category-index', params: {category: 'freeResponse'}});
      } else if (this.type === 'textbookchapter') {
        // for textbook, we want to go back to the page where students can select the quiz they want
        this.$router.push({name: 'new-oral-textbook-chapter-topic', params: {
          type: 'oralTextbookChapter',
          topicId: this.textbookChapterId,
        }});
      } else {
        this.$router.push({name: 'new-oral-category-index', params: {category: 'templatedPhrases'}});
      }
    },
    closePopup() {
      try {
        if (this.isRecording && this.recorder) {
          this.cancelRecording();
        }
        this.reset();
        console.log('trying to close popup');
        this.$emit('closePopup');
      } catch (error) {
        console.error('Error closing popup:', error);
        // Ensure the popup still closes even if there's an error
        this.$emit('closePopup');
      }
    },
  },
};

</script>

<style lang="scss" scoped>
  .oral-popup__component {
    background: #000000e6;
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &--container {
      position: relative;
      background: #fff;
      border-radius: 1rem;
      width: calc(100% - 2rem);
      height: calc(100% - 2rem);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .btn-close {
        position: absolute;
        font-size: 1.5rem;
        color: #a8a8a8;
        top: 1rem;
        right: 1rem;
      }
    }
  }
  a {
    text-decoration: none;
  }
  /*
  .paragraph-long {
    max-width: 820px;
  }
  .paragraph-medium {
    max-width: 720px;
  }
  .paragraph-short {
    max-width: 620px;
  }
  */
  .oral-component {
    background: #fff;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    width: 100vw;
    top: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    &__content {
      min-height: -webkit-fill-available;
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      &--header {
        height: 5%;
        margin: .5rem 0 .25rem;
        max-width: 960px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &-title {
          font-family: 'baloo da 2';
          font-size: 1.25rem;
          font-weight: 800;
          flex: 1;
        }
        &-para-number {
          margin-left: auto;
          font-size: 0.9rem;
          font-weight: 600;
          padding: 0.25rem 0.5rem;
          border-radius: 20px;
          background: #ffcf6d;
        }
      }
      &--passage {
        max-width: 960px;
        width: 100%;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        border-top: 2px solid #ccc;
        border-bottom: 2px solid #ccc;
        padding: 0 2rem;
        position: relative;
        &-qns {
          display: flex;
          flex-wrap: wrap;
          :first-child {
            margin-left: 75px;
          }
          p {
            font-size: 1.35rem;
            width: 37.5px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 60px;
          }
        }
        &-result {
          display: flex;
          flex-wrap: wrap;
          font-size: 1.35rem;
          :first-child {
            margin-left: 75px;
          }
          &--legend {
            position: absolute;
            bottom: .5rem;
            display: flex;
            font-size: .65rem;
            font-weight: 500;
            justify-content: space-between;
            width: 340px;
            text-align: center;
            flex-direction: row-reverse;
            &-1, &-2, &-3 {
              width: 33.33%;
              i {
                margin-right: .25rem;
              }
            }
            &-1 {
              color: #ff6666;
            }
            &-2 {
              color: #ff9f0d;
            }
            &-3 {
              color: #22d183;
            }
          }
        }
      }
      &--dashboard {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 225px;
        width: 100%;
        max-width: 960px;
        &-recorder {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          &-timer {
            position: absolute;
            top: 0;
            font-size: 0.9rem;
            font-weight: 600;
            text-align: center;
          }
          &-text {
            font-size: 0.75rem;
            font-weight: 600;
            font-style: italic;
            margin-top: .25rem;
          }
          &-btn {
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              transform: scale(1.05);
              &::after {
                transform: scaleX(1.1) scaleY(1.3);
                opacity: 0;
              }
            }
            &:active {
              transform: translateY(-1px);
            }
          }
        }
        &-loading {
          text-align: center;
          position: absolute;
          z-index: -1;
        }
        &-results {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          height: 100%;
          margin-top: .5rem;
          &-footer {
            font-size: .7rem;
            font-weight: 600;
            font-style: italic;
            text-align: center;
            margin-top: .25rem;
          }
          &-score {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: .5rem 0;
            &-item {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: column;
              height: 60px;
              text-align: center;
              border-right: 1px solid #d2e8ff;
              &:last-child {
                border-right: none;
              }
            }
            &-name {
              font-size: .85rem;
              font-weight: 700;
              color: #333;
            }
            &-value {
              font-size: 1.5rem;
              font-weight: 700;
              color: #1377e1;
            }
          }
          &-audio {
            display: flex;
            justify-content: center;
            margin-top: .5rem;
            margin-bottom: .5rem;
            &-btn {
              display: flex;
              justify-content: center;
              width: 200px;
              text-align: center;
              border-radius: 0.5rem;
              font-size: 0.75rem;
              font-weight: 600;
              margin: 0 0.5rem;
              background: #7a58ed;
              box-shadow: 0px 5px #5f47b0;
              color: #fff;
              padding: .35rem 1rem;
              cursor: pointer;
              transition: all 0.3s;
              border: 2px solid #7a58ed;
              &:hover {
                transform: scale(1.03);
                background: #8f6fff;
                &::after {
                  transform: scaleX(1.1) scaleY(1.3);
                  opacity: 0;
                }
              }
              &-teacher {
                background: #1FAB87;
                border: 2px solid #1FAB87;
                box-shadow: 0px 5px #106852;
                &:hover {
                  background: #2dc49e;
                }
              }
              &-rerecord {
                background: #EA814E;
                border: 2px solid #EA814E;
                box-shadow: 0px 5px #A7603E;

                &:hover {
                  background: #fa915c;
                }
              }
              &:active {
                transform: translateY(-1px);
              }
              i {
                margin-right: 0.5rem;
                font-size: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              span {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          &-btn-next {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            font-weight: 600;
            background: #22b492;
            color: #fff;
            padding: .25rem 0;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              background: #27dfb4;
              &::after {
              }
            }
          }
        }
        &--final-scene {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          &--header {
            height: 5%;
            font-size: 1.2rem;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 1rem;
          }
          &--score {
            width: 100%;
            flex-wrap: wrap;
            height: 25%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 2px solid #00000029;
            border-bottom: 2px solid #00000029;
            &-item {
              min-width: 8rem;
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: column;
              height: 60px;
              text-align: center;
              border-right: 1px solid #d2e8ff;
              margin-bottom: 1rem;
              &:last-child {
                border-right: none;
              }
            }
            &-name {
              font-size: 1rem;
              font-weight: 700;
              color: #333;
            }
            &-value {
              font-size: 1.75rem;
              font-weight: 700;
              color: #1377e1;
            }
          }
          &--revision {
            height: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &-header{
              text-align: center;
              margin-bottom: 1rem;
            }
            &-list{
              display: flex;
              width: 100%;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              li {
                padding: 0.5rem 1rem;
                border: 2px solid #265fcf;
                font-weight: 700;
                color: #265fcf;
                margin: .5rem;
                border-radius: 2rem;
                cursor: pointer;
                &:hover {
                  color: #fff;
                  background: #265fcf;
                }
              }
            }
          }
          &--reward-btn {
            width: 250px;
            text-align: center;
            border-radius: 0.5rem;
            font-size: 1.1rem;
            font-weight: 600;
            background: #22b492;
            box-shadow: 0px 5px #2e8771;
            color: #fff;
            height: 7.5%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              transform: scale(1.03);
              background: #3de7c0;
              box-shadow: 0px 5px #3bc6a4;
              &::after {
                transform: scaleX(1.1) scaleY(1.3);
                opacity: 0;
              }
            }
            &:active {
              transform: translateY(-1px);
            }
          }
        }
        &--btn-cancel {
          button {
            cursor: pointer;
            font-family: "baloo da 2";
            width: 200px;
            font-weight: 600;
            border-radius: .75rem;
            font-size: 1.05rem;
            border: 2px solid #e7e7e7;
            border-bottom: 4px solid #e7e7e7;
            padding: 8px 0;
            color: #ababab;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
              margin-right: .5rem;
            }
            &:focus {
              outline: none;
            }
            &:active {
              background: #f2f2f2;
              border-bottom: 2px solid #e7e7e7;
              transform: translateY(1px);
            }
          }
        }
        &--recorder {
          flex: 1;
          .btn-start-quiz {
            text-align: right;
            button {
              cursor: pointer;
              font-family: "baloo da 2";
              width: 200px;
              font-weight: 600;
              border-radius: .75rem;
              font-size: 1.05rem;
              border: 2px solid transparent;
              border-bottom: 4px solid #5e46b0;
              padding: 8px 0;
              color: #fff;
              background: #826ad6;
              &:active {
                margin-top: 2px;
                border-bottom: 2px solid #5e46b0;
                transform: translateY(1px);
              }
              &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
                background: #bbb;
                border-bottom: 4px solid #a6a6a6;
                &:active {
                  margin-top: 0;
                  border-bottom: 4px solid #a6a6a6;
                  transform: translateY(0px);
                }
              }
            }
          }
          .recorder {
            &--btn {
              border: none;
              width: 125px;
              height: 125px;
              color: #fff;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              margin-bottom: .5rem;
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              cursor: pointer;
              z-index: 2;
              i {
                font-size: 3.5rem;
              }
              &-grp{
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
              }
              &-start {
                background: #873fff;
                border-top: 3px solid #b786ff;
                border-bottom: 4px solid #582d99;
              }
              &-stop {
                background: #ff3f8d;
                border-top: 3px solid #ffa4f2;
                border-bottom: 4px solid #af1c58;
              }
              &-text {
                font-size: .85rem;
                font-weight: 500;
                font-style: italic;
                font-family: 'baloo da 2';
                color: #686868;
              }
            }
            &--countdown {
              text-align: center;
              font-family: "baloo da 2", "Lato","Arial",sans-serif;
              h2 {
                font-size: 1.7rem;
                line-height: 1.8rem;
              }
              p {
                font-size: .85rem;
                font-weight: 500;
                font-style: italic;
              }
            }
          }
        }
        &--loading {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 1;
          &-text {
            font-size: .85rem;
            font-weight: 500;
            font-style: italic;
            font-family: "baloo da 2";
            color: #686868;
          }
        }
      }
    }
    &__popup-notification {
      position: absolute;
      min-height: 100vh;
      min-height: -webkit-fill-available;
      width: 100vw;
      z-index: 2;
      background: #000000e6;
      display: flex;
      justify-content: center;
      align-items: center;
      &--btn-close {
        position: absolute;
        z-index: 3;
        left: 1rem;
        top: 0rem;
        width: 32px;
        height: 32px;
        opacity: 0.3;
        cursor: pointer;
        transition: 0.3s all linear;
        &-container {
          z-index: 3;
          position: absolute;
          top: calc(50% - 400px);
          left: calc(50% - 360px);
        }
        &:hover {
          opacity: 1;
        }
        &:before, &:after {
          position: absolute;
          left: 15px;
          content: ' ';
          height: 33px;
          width: 2px;
          background-color: #333;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
      &--content {
        width: 95%;
        height: 95%;
        &-container {
          width: 820px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }
        background: #fff;
        position: relative;
        padding: 2rem;
        max-width: 720px;
        max-height: 800px;
        border-radius: 1rem;
      }
    }
  }
  // wave anim
  .wave-anim-grp {
    position: absolute;
    top: 2rem;
    animation-delay: .1s;
    ul{
      width:200px;
      height:45px;
      display:flex;
      justify-content:center;
      align-items:center;
      padding:0;
      margin:0;
      transition:ease 0.2s;
      position:relative;
      li{
        list-style:none;
        height:60px;
        width:4px;
        border-radius:10px;
        background: #9385ff;
        margin:0 6px;
        padding:5px;
        animation-name: wave4;
        animation-duration: 0.3s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        transition:ease 0.2s;
      }
      li:nth-child(2) {
        animation-name: wave2;
        animation-delay:0.2s;
      }
      li:nth-child(3) {
        animation-name: wave3;
        animation-delay:0.23s;
        animation-duration: 0.4s;
      }
      li:nth-child(4) {
        animation-name: wave4;
        animation-delay:0.1s;
        animation-duration: 0.3s;
      }
      li:nth-child(5) {
        animation-delay:0.5s;
        margin-right: 1rem;
      }
      li:nth-child(6) {
        animation-name: wave2;
        animation-duration: 0.5s;
      }
      li:nth-child(8) {
        animation-name: wave4;
        animation-delay:0.4s;
        animation-duration: 0.25s;
      }
      li:nth-child(9) {
        animation-name: wave3;
        animation-delay:0.15s;
      }
      li:nth-child(10) {
        animation-delay:0.5s;
      }
      li:nth-child(11) {
        animation-name: wave2;
        animation-duration: 0.5s;
      }
      li:nth-child(12) {
        animation-name: wave3;
        animation-delay:0.4s;
        animation-duration: 0.25s;
      }
      li:nth-child(13) {
        animation-name: wave4;
        animation-delay:0.15s;
      }
      li:nth-child(14) {
        animation-name: wave4;
        animation-duration: 0.5s;
      }
      li:nth-child(15) {
        animation-name: wave4;
        animation-delay:0.1s;
        animation-duration: 0.5s;
      }
    }
  }

  @keyframes wave1 {
      from {transform:scaleY(1);}
      to {transform:scaleY(0.5);}
  }
  @keyframes wave2 {
      from {transform:scaleY(0.3);}
      to {transform:scaleY(0.6);}
  }
  @keyframes wave3 {
      from {transform:scaleY(0.6);}
      to {transform:scaleY(0.8);}
  }
  @keyframes wave4 {
      from {transform:scaleY(0.2);}
      to {transform:scaleY(0.5);}
  }

    // loading anim
    .loading-anim {
    width: 125px;
    margin-bottom: 1rem;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 15px solid #0000;
    border-right-color: #a900ff97;
    position: relative;
    animation: l24 1s infinite linear;
    // background: #e7e7e7;
  }
  .loading-anim:before,
  .loading-anim:after {
    content: "";
    position: absolute;
    inset: -15px;
    border-radius: 50%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }
  .loading-anim:after {
    animation-duration: 4s;
  }
  @keyframes l24 {
    100% {transform: rotate(1turn)}
  }
  // misc classes
  .mr-200 {
    margin-right: 200px;
  }


  @media only screen and (max-width: 1080px) {
    .oral-component__content {
      margin-bottom: 0;
      max-width: 100%;
      &--header-title {
        margin-left: 4rem;
      }
      &--header-para-number {
        margin-right: 1rem;
      }
    }
    .oral-component__content--dashboard-results-footer {
      margin-bottom: 2rem;
    }
    .oral-component__content--dashboard--final-scene--header {
      &-main {
      margin-left: 1rem;
      }
      &-sub {
        margin-right: 1rem;
      }
    }
  }
  @media only screen and (max-width: 760px) {
    .oral-component__popup-notification--content {
      width: 95%;
      height: 95%;
    }
    .oral-component__popup-notification--content-container {
      height: 100%;
      width: 100%;
    }
    .oral-component__popup-notification--btn-close-container {
      top: 2rem;
      left: 0.5rem
    }
  }
  @media only screen and (max-width: 710px) {
    .oral-component__content--passage {
    }
    .oral-component__content--dashboard {
      height: 35%;
    }
  }
  @media only screen and (max-width: 630px) {
    .oral-component__content--passage {
      padding: 0 1rem;
    }
    .oral-component__content--header-title {
      margin-left: 2.5rem;
    }
  }
  @media only screen and (max-width: 540px) {
    .oral-component__content--passage {
    }
    .oral-component__content--dashboard {
      height: 30%;
    }
    .oral-component__content--dashboard {
      &-results {
        &-audio-btn {
          font-size: 0.7rem;
          box-shadow: 0px 3px #5f47b0;
          padding: 0.3rem;
          width: 210px;
        }
        &-btn-next {
          font-size: 0.8rem;
          padding: 0.5rem 0;
        }
        &-score {
          &-name{
            font-size: 0.75rem;
          }
          &-value {
            font-size: 1.25rem;
          }
          &-item {
            height: 50px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 440px) {
    .oral-component__content--passage{
      padding: 0 .75rem;
    }
  }
  @media only screen and (max-width: 375px) {
    .oral-component__content--dashboard {
      &-results {
        &-audio {
          margin-top: 0.5rem;
          margin-bottom: 1.5rem;
        }
        &-audio-btn {
          font-size: 0.6rem;
        }
        &-score {
          &-name{
            font-size: 0.65rem;
          }
          &-value {
            font-size: 1.15rem;
          }
          &-item {
            height: 45px;
          }
        }
      }
    }
    .oral-component__content--passage {
      &-qns {
        p {
          height: 50px;
        }
      }
    }
  }
  .popup-notification {
    &__component {
      position: fixed;
      background: rgba(0,0,0,.85);
      min-height: 100vh;
      min-height: -webkit-fill-available;
      width: 100vw;
      z-index: 50;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__container {
      position: relative;
      max-width: 450px;
      text-align: center;
      padding: 2rem 3rem;
      background: #fff;
      color: #222;
      border-radius: 5px;
      p {
        margin-bottom: 1rem;
      }
    }
    &__close {
      &--icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        color: #ccc;
        font-size: 1.2rem;
      }
      &--btn {
        padding: .25rem .75rem;
        border: 4px solid #000;
        background: #8131ff;
        border-radius: 5px;
        margin: 1rem auto 0 auto;
        max-width: 150px;
        color: #fff;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
  </style>
