<template>
  <block-container
    title="Comprehension"
    fa-icon="gamepad"
    >
    <div class="body-content">
      <div class="body-content--container">
        <router-link
          v-for="(item, index) in comprehensions"
          :key="index"
          :to="{name: 'new-malay-comprehension', params: {comprehensionId: item.id}}"
          tag="p"
          class="body-content--item">
          <div class="body-content--item-icon">
            <img :src="item.image_url">
          </div>
          <div class="body-content--item-text">
            {{ item.title }}
          </div>
        </router-link>
      </div>
    </div>
  </block-container>
</template>

<script>

import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

import BlockContainerComponent
  from '../../common/BlockContainerComponent.vue';

export default {
  name: 'NewMalayComprehensionOverview',
  components: {
    BlockContainer: BlockContainerComponent,
  },
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    ...mapState('guidedReading', ['comprehensions']),
  },
  mounted() {
    if (this.comprehensions.length === 0) {
      this.pullData();
    }
  },
  methods: {
    ...mapMutations('guidedReading', ['setComprehensions']),
    pullData() {
      axios
          .get('/vocab/malaymultiplechoicecomprehension/')
          .then((response) => {
            console.log(response.data);
            this.setComprehensions(response.data);
          });
    },
  },
};
</script>


<style lang="scss" scoped>
  .body-content {
    height: 100%;
    width: 100%;
    padding: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    text-align: center;
    gap: 1rem;
    &--container {
      gap: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    &--item {
      overflow: hidden;
      background: #fff;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;
      text-align: center;
      border-radius: 20px;
      text-decoration: none;
      transition: all 0.2s;
      position: relative;
      height: 175px;
      width: 100%;
      &:hover {
        box-shadow: 0 0px 1px 7px #00ffd8;
      }
      &-icon {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s;
        background-size: cover;
        background-position: center;
        height: 85%;
        overflow: hidden;
        i {
          transition: all 0.2s;
          color: #b9b6c1;
          font-size: 3.5rem;
          margin-bottom: 5px;
        }
        img {
          width: 100%;
        }
      }
      &-text {
        background: #fff;
        border-bottom-right-radius: .5rem;
        border-bottom-left-radius: .5rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 1rem;
        color: #6b6b6b;
      }
    }
  }

  @media only screen and (max-width: 1560px) {
    .body-content {
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .body-content {
      &--container {
        grid-template-columns: 1fr 1fr;
      }
      &--item {
      }
      &-text {
        font-size: .9rem;
      }
    }
  }

  @media only screen and (max-width: 1150px),
  screen and (max-height: 690px) {
  }
  @media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
    .body-content {
      background: none;
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: 690px) {
    .body-content {
      padding: 1.5rem
    }
  }
  @media only screen and (max-width: 580px) {
    .body-content {
      &--container {
        grid-template-columns: 1fr;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .body-content {
      padding: 1rem
    }
    .body-content--item-icon {
      padding: .5rem;
      i {
        font-size: 2.5rem;
      }
    }
    .body-content--item-text {
      font-size: .8rem;
    }
  }

</style>
