import VueScrollTo from 'vue-scrollto';

export const utilities = {
  methods: {
    // https://forum.vuejs.org/t/scroll-position-seems-to-not-work-in-my-app/16147/5
    // on scrolling, note that setting scrollTo(0, 0) won't work
    // since it will just scroll to the top of the page (which is the header)
    // rather than making the app body scroll top
    scrollToTop(element) {
      const options = {
        // set this to #app by default
        container: '#app',
        easing: 'ease-in',
        offset: -100,
        force: true,
        cancelable: true,
        onStart: function(element) {
          // scrolling started
        },
        onDone: function(element) {
          // scrolling is done
        },
        onCancel: function() {
          // scrolling has been interrupted
        },
        x: false,
        y: true,
      };
      VueScrollTo.scrollTo(element, 15, options);
    },
    checkMobileBrowser() {
      const ua = window.navigator.userAgent;
      const iPhone = ua.indexOf('iPhone') !== -1
        || ua.indexOf('iPod') !== -1;
      const iPad = ua.indexOf('iPad') !== -1;
      const iOs = iPhone || iPad;
      const android = ua.indexOf('Android') !== -1;

      return {
        iOs: iOs,
        android: android,
      };
    },
    /* Randomize array element order in-place.
     * Using Fisher-Yates shuffle algorithm.
     */
    shuffleArray: function(arr) {
      for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
      }
      return arr;
    },
    containsEnglish(sentence) {
      return /[a-zA-Z]/.test(sentence);
    },
    stripPunctuationAndSpaces(text) {
      return text.replace(/[\s\u2000-\u206F\u3000-\u303F\uFF00-\uFFEF。，！？；：""''（）]/g, '');
    },
    /**
     * Calculates Jaccard similarity between two sentences
     *
     * @param {string} sentence1 - First sentence to compare
     * @param {string} sentence2 - Second sentence to compare
     * @param {number} threshold - Similarity threshold (0-1) to consider sentences too similar
     * @return {boolean} - Returns true if sentences are too similar (above threshold)
     *
     * Jaccard similarity measures the overlap between two sets by dividing the
     * size of intersection by the size of union. Here we use characters as our elements.
     */
    checkJaccardSimilarity(sentence1, sentence2, threshold = 0.8) {
      // Clean both sentences by removing spaces and punctuation
      const clean1 = this.stripPunctuationAndSpaces(sentence1);
      const clean2 = this.stripPunctuationAndSpaces(sentence2);

      // Convert sentences to character sets
      const set1 = new Set(clean1);
      const set2 = new Set(clean2);

      // Calculate intersection
      const intersection = new Set([...set1].filter((char) => set2.has(char)));

      // Calculate union
      const union = new Set([...set1, ...set2]);

      // Calculate Jaccard similarity
      const similarity = intersection.size / union.size;

      return similarity >= threshold;
    },
    /**
     * Calculates Levenshtein distance between two strings
     *
     * @param {string} str1 - First string to compare
     * @param {string} str2 - Second string to compare
     * @return {number} - Returns the minimum number of single-character edits needed
     *
     * Levenshtein distance measures how many operations (insertions, deletions,
     * or substitutions) are needed to transform one string into another.
     */
    levenshteinDistance(str1, str2) {
      const m = str1.length;
      const n = str2.length;
      // Create a matrix of size (m+1) x (n+1)
      const dp = Array(m + 1).fill().map(() => Array(n + 1).fill(0));
      // Initialize first row and column
      for (let i = 0; i <= m; i++) {
        dp[i][0] = i;
      }
      for (let j = 0; j <= n; j++) {
        dp[0][j] = j;
      }
      // Fill the matrix
      for (let i = 1; i <= m; i++) {
        for (let j = 1; j <= n; j++) {
          if (str1[i - 1] === str2[j - 1]) {
            dp[i][j] = dp[i - 1][j - 1];
          } else {
            dp[i][j] = 1 + Math.min(
                dp[i - 1][j], // deletion
                dp[i][j - 1], // insertion
                dp[i - 1][j - 1], // substitution
            );
          }
        }
      }
      return dp[m][n];
    },
    /**
     * Calculates normalized Levenshtein similarity between two strings
     *
     * @param {string} str1 - First string to compare
     * @param {string} str2 - Second string to compare
     * @param {number} threshold - Similarity threshold (0-1) to consider strings similar
     * @return {boolean} - Returns true if strings are similar (above threshold)
     */
    checkLevenshteinSimilarity(str1, str2, threshold = 0.8) {
      // Clean both strings by removing spaces and punctuation if needed
      const clean1 = this.stripPunctuationAndSpaces(str1);
      const clean2 = this.stripPunctuationAndSpaces(str2);
      const distance = this.levenshteinDistance(clean1, clean2);
      const maxLength = Math.max(clean1.length, clean2.length);
      // Normalize to get similarity (1 - normalized distance)
      // This converts the distance to a similarity score between 0 and 1
      const similarity = maxLength === 0 ? 1 : 1 - (distance / maxLength);
      return similarity >= threshold;
    },
  },
};
