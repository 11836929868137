<template>
  <block-container
    title="Shop"
    subtitle="Exchange Diamonds for Rewards!"
    fa-icon="shopping-cart"
    >
    <div class="body-content">
      <div class="shop-category-btns">
        <div class="shop-category-btn"
          @click="shopCategory = 'digital'"
          :class="{'shop-category-btn--active': shopCategory === 'digital'}">
          <i class="fa-solid fa-treasure-chest"></i>
          <span>Digital</span>
        </div>
        <div class="shop-category-btn"
          @click="shopCategory = 'physical'"
          :class="{'shop-category-btn--active': shopCategory === 'physical'}">
          <i class="fa-sharp fa-solid fa-gift"></i>
          <span>Physical</span>
        </div>
        <div class="shop-category-btn"
          @click="shopCategory = 'orders'"
          :class="{'shop-category-btn--active': shopCategory === 'orders'}">
          <i class="fas fa-truck"></i>
          <span>Orders</span>
          <span
            v-if="numPurchasedItems > 0"
            class="diamond-shop__btn--orders--count">
            <!-- num of outstanding purchases -->
            ({{ numPurchasedItems }})
          </span>
        </div>
      </div>
      <transaction-history
        v-if="shopCategory == 'orders'"
        @close-popup="showPurchased = !showPurchased"
        class="diamond-shop__transaction-history"
        :items-map="itemsMap"
        :ordered-counts="orderedCounts"
        @cancel="cancel"
      >
      </transaction-history>
      <item-list
        v-if="shopCategory !== 'orders'"
        :items="filteredItems"
        :ordered-counts="orderedCounts"
        :popup-toggle="popupToggle"
        :shop-category="shopCategory"
      ></item-list>
      <purchase-popup
        v-if="isItemClicked"
        @close-popup="closePopup"
        :item-clicked="itemClicked"
        :can-user-redeem="canUserRedeem"
        :redeem-item="redeemItem"
        :reason-for-rejection="reasonForRejection"
      ></purchase-popup>
    </div>
  </block-container>
</template>

<script>
import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

import ShopTransactionHistoryComponent
  from './components/TransactionHistoryComponent.vue';
import ShopItemListComponent
  from './components/ItemListComponent.vue';
import PurchasePopupComponent
  from './components/PurchasePopupComponent.vue';

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';


export default {
  name: 'NewShopOverview',
  components: {
    TransactionHistory: ShopTransactionHistoryComponent,
    ItemList: ShopItemListComponent,
    PurchasePopup: PurchasePopupComponent,
    BlockContainer: BlockContainerComponent,
  },
  data() {
    return {
      fetching: false,
      shopCategory: 'digital',
      items: [],
      orderedCounts: {},
      itemsMap: {},
      isItemClicked: false,
      itemClicked: {},
      canUserRedeem: false,
      reasonForRejection: '',
    };
  },
  computed: {
    ...mapState(['diamonds', 'studentType']),
    filteredItems() {
      return this.items.filter((item) => item.category === this.shopCategory);
    },
    numPurchasedItems() {
      if (this.orderedCounts === {}) return 0;
      let total = 0;
      for (const key in this.orderedCounts) {
        total += this.orderedCounts[key];
      }
      return total;
    },
  },
  mounted() {
    axios
        .get('/gamification/redeemableitem/load/')
        .then((response) => {
          this.items = response.data['redeemable_items'];
          // https://coderwall.com/p/ebqhca/javascript-sort-by-two-fields
          // sort first by price, then by index
          this.items.sort((a, b) => {
            if (a.status === 'sold_out' && b.status !== 'sold_out') return 1;
            if (a.status !== 'sold_out' && b.status === 'sold_out') return -1;
            return a.price - b.price || a.index - b.index;
          });
          this.orderedCounts = response.data['ordered_counts'];
          for (let i = 0; i < this.items.length; i++) {
            this.itemsMap[this.items[i].pk] = this.items[i];
          };
        });
  },
  methods: {
    ...mapMutations(['setDiamonds']),
    popupToggle(item) {
      this.$store.state.isOldNavShown = !this.$store.state.isOldNavShown;
      this.itemClicked = item;
      this.reasonForRejection = '';
      if (this.itemClicked.status === 'sold_out') {
        this.canUserRedeem = false;
        this.reasonForRejection = 'sold_out';
      } else if (this.studentType !== 'attending' &&
          this.studentType !== 'digital_subscription') {
        this.canUserRedeem = false;
        this.reasonForRejection = 'not_member';
        // return;
      } else if (item.price > this.diamonds) {
        this.reasonForRejection = 'insufficient_diamonds';
        this.canUserRedeem = false;
      } else {
        this.reasonForRejection = '';
        this.canUserRedeem = true;
      };
      this.isItemClicked = !this.isItemClicked;
    },
    closePopup() {
      this.isItemClicked = !this.isItemClicked;
      this.$store.state.isOldNavShown = !this.$store.state.isOldNavShown;
    },
    redeemItem(item) {
      axios
          .post('/gamification/redeemableitem/' + item.pk + '/purchase/')
          .then((response) => {
            if (response.data['status'] === 1) {
              this.setDiamonds(response.data['new_diamonds']);
              this.orderedCounts = response.data['ordered_counts'];
            } else {
              alert('Error buying item!');
            }
          })
          .catch((err) => {
            this.canUserRedeem = false;
            alert('Could not purchase item');
            console.log(err);
          });
    },
    cancel(itemId) {
      axios
          .post('/gamification/redeemableitem/' + itemId + '/cancel/', {})
          .then((response) => {
            this.orderedCounts = response.data['ordered_counts'];
            this.setDiamonds(response.data['new_diamonds']);
          });
    },
  },
};
</script>


<style lang="scss" scoped>
  .text--physical {
    text-align: center;
    color: #fff;
    font-style: italic;
    margin-bottom: 1rem;
  }
  .body-content {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .mission-items {
    height: 100%;
    width: 100%;
    padding: 0rem 2rem 7rem 2rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .shop-category {
    &-btn {
      border-bottom: 4px solid #193295;
      border-right: 4px solid #193295;
      border-top: 4px solid #5879fe;
      border-left: 4px solid #5879fe;
      color: #fff;
      background: #2c57ff;
      padding: .1rem;
      width: 45%;
      border-radius: 0.5rem;
      font-size: .9rem;
      text-align: center;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        box-shadow: 0 0px 1px 3px #ff00dd;
      }
      &--active {
        color: #fff;
        background: #ffa025;
        font-weight: 600;
        border-top: 4px solid #bf6800;
        border-left: 4px solid #bf6800;
        border-bottom: 4px solid #ffe758;
        border-right: 4px solid #ffe758;
        box-shadow: 0 0px 1px 3px #00ffea;
        &:hover {
          box-shadow: 0 0px 1px 3px #00ffea;
          cursor: auto;
        }
      }
    }
    &-btns {
      display: flex;
      margin: 1rem;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }

  @media only screen and (max-width: 1366px),
  screen and (max-height: 870px)  {
    .mission-item {
      &--col-1 {
        font-size: 1.75rem;
        width: 90px;
      }
      &--col-2 {
        &--progress-bar {
          height: .8rem;
        }
        &--text, &--progress-text {
          font-size: 0.9rem;
        }
      }
      &--col-3 {
        font-size: 1.75rem;
        width: 90px;
        .go-icon {
          font-size: 1.25rem;
        }
      }
    }
  }
  @media only screen and (max-width: 460px) {
    .shop-category-btn {
      font-size: .75rem;
      &s {
        gap: .5rem;
      }
    }

  }
  @media only screen and (max-width: 520px) {
    .mission-items {
      padding: 0rem .75rem 7rem .75rem;
    }
    .mission-category-btns {
      margin-top: 1rem;
    }
  }

  @media only screen and (max-width: 420px) {
    .mission-category-btn {
      width: 5rem;
    }
  }
</style>
