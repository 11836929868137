<template>
  <div class="english-oral-practice">
    <div class="english-oral-practice--header">
      <button class="btn-back" @click="goBack">
        <i class="fa-solid fa-arrow-left"></i>
        Back to Questions
      </button>

      <div class="question-container">
        <div class="question-type-badge" v-if="question">{{ questionTypeLabel }}</div>
        <h2 v-if="question">{{ question.question_text }}</h2>
      </div>
    </div>

    <div class="english-oral-practice--content">
      <!-- Feedback content -->
      <div class="feedback-outer-container">
        <div class="feedback-container">
          <!-- Feedback tabs -->
          <div class="feedback-tabs">
            <button
              class="tab-button"
              :class="{active: activeTab === 'peel'}"
              @click="activeTab = 'peel'"
            >
              PEEL Feedback
            </button>
            <button
              class="tab-button"
              :class="{active: activeTab === 'language'}"
              @click="activeTab = 'language'"
            >
              Language Feedback
            </button>
            <button
              class="tab-button"
              :class="{active: activeTab === 'revised'}"
              @click="activeTab = 'revised'"
            >
              Revised Answer
            </button>
          </div>

          <!-- PEEL Feedback Tab Content -->
          <div class="tab-content" v-if="activeTab === 'peel'">
            <div class="section-titles-row">
              <div class="left-title">Your Answer</div>
              <div class="right-title">PEEL Structure Feedback</div>
            </div>
            <div class="content-container">
              <div class="content-left">
                <div class="answer-box preserve-whitespace-wrap" v-if="session">{{ transcription }}</div>
                <div class="answer-box empty" v-else>
                  No transcription available.
                </div>
              </div>

              <div class="content-right">
                <div v-if="!contentFeedback.peelFeedbackPoint" class="content--feedback--revision--loading">
                  <div class="loading--shape loader-shape-revising"></div>
                  <div class="loading--text">Loading PEEL feedback...</div>
                </div>
                <div v-else class="peel-items-container">
                  <div class="peel-item">
                    <div class="peel-header">
                      <div class="peel-badge">P</div>
                      <h4>Point</h4>
                    </div>
                    <p>{{ contentFeedback.peelFeedbackPoint || ''}}</p>
                  </div>
                  <div class="peel-item">
                    <div class="peel-header">
                      <div class="peel-badge">E</div>
                      <h4>Explanation</h4>
                    </div>
                    <p>{{ contentFeedback.peelFeedbackExplanation || ''}}</p>
                  </div>
                  <div class="peel-item">
                    <div class="peel-header">
                      <div class="peel-badge">E</div>
                      <h4>Example</h4>
                    </div>
                    <p>{{ contentFeedback.peelFeedbackExample || ''}}</p>
                  </div>
                  <div class="peel-item">
                    <div class="peel-header">
                      <div class="peel-badge">L</div>
                      <h4>Link</h4>
                    </div>
                    <p>{{ contentFeedback.peelFeedbackLink || ''}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Language Feedback Tab Content -->
          <div class="tab-content" v-if="activeTab === 'language'">
            <div class="section-header-row">
              <h3 class="section-title left">Your Answer</h3>
              <h3 class="section-title right">Language Feedback</h3>
            </div>
            <div class="feedback-split">
              <div class="original-answer">
                <div class="answer-box preserve-whitespace-wrap" v-if="session">{{ transcription }}</div>
                <div class="answer-box empty" v-else>
                  No transcription available.
                </div>
              </div>

              <div class="feedback-details">
                <div v-if="languageBreakdownData == null" class="content--feedback--revision--loading">
                  <div class="loading--shape loader-shape-revising"></div>
                  <div class="loading--text">Loading language feedback...</div>
                </div>
                <div v-else class="feedback-container">
                  <div class="header-container">
                    <h4 class="content--feedback--revision--header">
                      Here are some changes I made
                    </h4>
                    <button class="toggle-button" @click="showDeletedWords = !showDeletedWords">
                      <span v-if="showDeletedWords">Hide Deleted Words</span>
                      <span v-if="!showDeletedWords">Show Deleted Words</span>
                    </button>
                  </div>
                  <language-feedback
                    v-for="(entry, index) in languageBreakdownData"
                    :key="`language_${index}`"
                    :subject="'english'"
                    :show-deleted-words="showDeletedWords"
                    :info="entry">
                  </language-feedback>
                </div>
              </div>
            </div>
          </div>

          <!-- Revised Answer Tab Content -->
          <div class="tab-content" v-if="activeTab === 'revised'">
            <div class="vertical-content">
              <h3 class="content-section-title">What changed?</h3>
              <div class="improvement-commentary">
                This revised answer demonstrates better structure using the PEEL format, includes more advanced vocabulary, and provides clearer examples to support your point. The conclusion is also stronger and links back to the main idea effectively.
              </div>

              <h3 class="content-section-title">Revised Answer</h3>
              <div v-if="!revisedAnswer" class="content--feedback--revision--loading">
                <div class="loading--shape loader-shape-revising"></div>
                <div class="loading--text">Generating revised answer...</div>
              </div>
              <div v-else class="improved-answer-box preserve-whitespace-wrap">{{ revisedAnswer }}</div>

              <button class="btn-practice" @click="practiceReading">
                Practice Reading This Answer
              </button>
            </div>
          </div>

          <!-- Action Buttons -->
          <div class="feedback-actions">
            <button class="btn-primary" @click="tryAgain">Try Again</button>
            <button class="btn-secondary" @click="goToTopics">Back to Topic Questions</button>
            <button class="btn-secondary" @click="goBack">Choose New Topic</button>
          </div>
        </div>
      </div>
    </div>
    <audio src="https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/sound_effects/completion.mp3" ref="completionAudio" />
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {mapState, mapMutations, mapGetters} from 'vuex';
import LanguageFeedbackComponent from '../components/LanguageFeedbackComponent.vue';

export default {
  name: 'EnglishAiPracticeFeedbackComponent',
  components: {
    'language-feedback': LanguageFeedbackComponent,
  },
  props: {
    sessionId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      session: null,
      question: null,
      startPollingTime: null,
      activeTab: 'peel', // Default active tab
      transcription: '',
      // Language feedback data
      showDeletedWords: true,
      languageBreakdownData: null,
      revisedAnswer: '',
      contentFeedback: {},
    };
  },
  computed: {
    ...mapState('guidedReading', ['freeResponseTopics']),
    ...mapGetters('guidedReading', ['lastAiPracticeSession']),
    questionTypeLabel() {
      if (!this.question) return '';
      
      const labels = {
        'describe_picture': 'Picture-based Question',
        'personal_experience': 'Personal Experience',
        'suggestion': 'Opinion & Suggestion',
      };
      return labels[this.question.question_category] || this.question.question_category;
    },
  },
  mounted() {
    this.setShowGlobalLoadingScreen(true);
    // Initialize polling for feedback data
    this.pollBackendForResults();
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    pollBackendForResults(isInitialCall = true) {
      // Set loading state on first call
      if (isInitialCall) {
        this.startPollingTime = new Date();
      }
      
      // Check if we've been polling too long (over 2 minutes)
      if (!isInitialCall) {
        const currentTime = new Date();
        const pollingDuration = (currentTime - this.startPollingTime) / 1000;
        
        if (pollingDuration > 120) {
          alert('Taking too long to process your feedback. Please try again later.');
          return;
        }
      }
      
      // Get sessionId from props or from Vuex store
      const sessionId = this.sessionId || this.lastAiPracticeSession;
      
      if (!sessionId) {
        console.error('No session ID provided for feedback');
        alert('Session ID is missing. Please try again.');
        this.goBack();
        return;
      }
      
      // Make API call to get session data
      const url = `/api/v1/brain/aipracticesession/${sessionId}/`;
      
      axios
        .get(url)
        .then(response => {
          console.log('Session data:', response.data);
          this.session = response.data;

          // Update component data with partial results if available
          if ('transcription' in response.data) {
            this.transcription = response.data['transcription'];
            // the moment we have transcription, we should show
            this.setShowGlobalLoadingScreen(false);
          }

          if ('language_breakdown' in response.data) {
            this.languageBreakdownData = response.data['language_breakdown'];
          }

          if ('content_feedback' in response.data) {
            this.contentFeedback = response.data['content_feedback'];
          }

          if ('revised_answer' in response.data) {
            this.revisedAnswer = response.data['revised_answer']['revised_text'];
          }
          
          // Check if the session is completed; if not, continue polling
          if (!response.data.completed) {
            setTimeout(() => this.pollBackendForResults(false), 3000);
          } else {
            this.$refs.completionAudio.play();
          }
        })
        .catch(error => {
          console.error('Error polling for feedback data:', error);
          alert('Failed to retrieve feedback data. Please try again later.');
        });
    },
    goBack() {
      // Navigate directly to the questions page instead of browser history
      this.$router.push({
        name: 'new-oral-english-ai-practice-index',
      });
    },
    tryAgain() {
      // Navigate to the question page for a new attempt
      if (this.question) {
        this.$router.push({
          name: 'new-oral-english-ai-practice-question',
          params: { questionId: this.question.id.toString() },
        });
      } else {
        this.goToTopics();
      }
    },
    goToTopics() {
      this.$router.push({
        name: 'new-oral-english-ai-practice-index',
      });
    },
    practiceReading() {
      // This would be implemented to trigger practice functionality
      alert('Practice functionality coming soon!');
    },
  },
};
</script>

<style lang="scss" scoped>
.preserve-whitespace-wrap {
  white-space: pre-wrap;
}

.english-oral-practice {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: #f8f9fa;
  z-index: 9999;
  overflow-y: auto;
  overflow-x: hidden;
  
  &--header {
    background-color: #fff;
    padding: 1.5rem 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    .question-container {
      max-width: 1200px;
      margin: 0 auto;
      width: 100%;

      h2 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #333;
        margin: 0.5rem 0;
        line-height: 1.4;
      }
    }

    .question-type-badge {
      display: inline-block;
      padding: 0.3rem 0.75rem;
      background-color: #edf2ff;
      color: #4263eb;
      border-radius: 0.75rem;
      font-size: 0.85rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
  }
  
  &--content {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }
}

.feedback-outer-container {
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.feedback-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.section-header-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #4263eb;
  margin: 0;
  flex: 1;
}

.feedback-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
}

.feedback-badge {
  display: inline-block;
  padding: 0.3rem 0.75rem;
  background-color: #edf2ff;
  color: #4263eb;
  border-radius: 0.75rem;
  font-size: 0.85rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

// Feedback tabs styling
.feedback-tabs {
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.tab-button {
  background-color: #f1f3f5;
  color: #495057;
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background-color: #e9ecef;
  }
  
  &.active {
    background-color: #4263eb;
    color: white;
  }
}

// Tab content styling
.tab-content {
  padding: 2rem;
}

.feedback-split {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
}

.original-answer {
  flex: 1;
  padding-right: 2rem;
}

.feedback-details {
  flex: 1;
}

.answer-box {
  background-color: #f1f5f9;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  font-style: italic;
  color: #64748b;
}

.empty {
  text-align: center;
  color: #64748b;
}

.peel-item {
  margin-bottom: 1.5rem;
  padding-left: 0;
}

.peel-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.peel-badge-container {
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin: 0;
}

.peel-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background-color: #edf2ff;
  color: #4263eb;
  border-radius: 50%;
  font-weight: 600;
  margin-right: 0.75rem;
  flex-shrink: 0;
}

.peel-header h4 {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
  color: #4263eb;
}

.peel-content {
  h4 {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
    color: #4263eb;
  }
  
  p {
    color: #333;
    line-height: 1.5;
  }
}

.language-item {
  margin-bottom: 1.5rem;
}

.language-item h4 {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
  color: #4263eb;
}

.language-item p {
  color: #333;
  line-height: 1.5;
}

.revised-answer-box {
  background-color: #f1f5f9;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  font-style: italic;
  color: #64748b;
}

// Action buttons
.feedback-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding-top: 1rem;
}

// Loading state
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  text-align: center;
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 3rem;
  
  p {
    color: #495057;
    margin-top: 1rem;
  }
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(66, 99, 235, 0.1);
  border-radius: 50%;
  border-top-color: #4263eb;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// Button styling
.btn-back {
  background: none;
  border: none;
  color: #495057;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0;
  margin-bottom: 1rem;
  font-weight: 500;
  cursor: pointer;
  
  &:hover {
    color: #212529;
  }
}

.btn-primary {
  background-color: #4263eb;
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background-color: #3b5bdb;
  }
}

.btn-secondary {
  background-color: #f1f3f5;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background-color: #e9ecef;
  }
}

.feedback-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.original-answer-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #4263eb;
}

.feedback-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #4263eb;
}

.left {
  flex: 1;
  text-align: left;
}

.right {
  flex: 1;
  text-align: left;
  padding-left: 0;
}

.section-titles-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.left-title, .right-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #4263eb;
}

.left-title {
  flex: 1;
  text-align: left;
}

.right-title {
  flex: 1;
  text-align: left;
  padding-left: 0;
}

.content-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  margin: 0;
  padding: 0;
}

.content-left {
  flex: 1;
  padding-right: 2rem;
}

.content-right {
  flex: 1;
  padding: 0;
  margin: 0;
}

/* Add class for fixing badge alignment */
.peel-badge-container {
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin: 0;
}

.peel-items-container {
  padding: 0;
  margin: 0;
}

.right-title {
  flex: 1;
  text-align: left;
  padding-left: 0;
  margin-left: 0;
}

.toggle-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #45a049;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 4px;
}

li.green {
  background-color: #e6ffe6;
  color: #006600;
}

li.red {
  background-color: #ffe6e6;
  color: #cc0000;
}

li.orange-background {
  background-color: #fff2e6;
  color: #cc6600;
}

.vertical-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content-section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #4263eb;
  margin: 1.5rem 0 0.75rem 0;
}

.improvement-commentary {
  background-color: #f1f5f9;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
  color: #333;
  line-height: 1.5;
}

.improved-answer-box {
  background-color: #f1f5f9;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
  color: #333;
  line-height: 1.5;
}

.btn-practice {
  background-color: #4263eb;
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  margin: 1rem auto 1rem auto;
  cursor: pointer;
  transition: all 0.2s;
  display: block;
  width: 100%;
  max-width: 400px;
  
  &:hover {
    background-color: #3b5bdb;
  }
}

.content--feedback--revision--loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  padding: 2rem;
  text-align: center;
}

.loading--shape {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(66, 99, 235, 0.1);
  border-radius: 50%;
  border-top-color: #4263eb;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 1rem;
}

.loading--text {
  color: #495057;
  font-size: 0.9rem;
  font-weight: 500;
}

.loader-shape-revising {
  position: relative;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style> 
