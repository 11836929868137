<template>
  <div class="item">
    <div class="item-header">
      <h3 class="item-header--text"
        :class="{ 'padding-0': info.title == ''}">
      {{ info.title }}</h3>
      <h4 class="item-header--tag"
        :class="{
          'bg-1': subcategoryIndex == -1,
          'bg-2': subcategoryIndex == 0,
          'bg-3': subcategoryIndex == 1,
          'bg-4': subcategoryIndex == 2,
          'bg-5': subcategoryIndex == 3,
          'bg-6': subcategoryIndex == 4}"
      >
        {{ info.category }}<span v-if="category === 'openings'">开头法</span>
      </h4>
    </div>
    <div class="item-body">
      <div class="chinese-character item-body--content">
        <div class="item-body--content--sentences">
          <p v-if="category === 'idioms' && audioWordUrl" class="pointer"><i class="fa-solid fa-volume-high" @click="playAudio('audioWord')"></i> {{ info.word }} </p>
          <p v-if="category === 'proverbs' || category === 'idioms'"
            class="meaning">
            <span class="label">【解释】</span>
            <span>{{ info.chineseDefinition }}</span>
          </p>
          <p v-if="category === 'proverbs' || category === 'idioms'"
            class="proverbs-sentence chinese-character">
            <span class="label">【例句】</span>
            <span>{{ info.text }}</span>
          </p>
          <!-- nonproverbs or idioms -->
          <p v-if="category !== 'proverbs' && category !== 'idioms'"
            class="chinese-character">{{ info.text }}</p>
        </div>
        <div class="item-body--content--audio">
          <i v-if="audioUrl" class="fa fa-play" aria-hidden="true" @click="playAudio('audio')"></i>
          <audio v-if="audioUrl" ref="audio" :src="audioUrl"></audio>
          <audio v-if="audioWordUrl" ref="audioWord" :src="audioWordUrl"></audio>
        </div>
      </div>
      <!-- AI Practice -->
      <div v-if="isPSLESuccessPlan && (category === 'idioms' || category === 'proverbs')"
        class="item-body--premium-container">
        <button @click="toggleAiPracticeModal(info)"
          class="idiom-ai-practice">
          <i class="fa fa-pen"></i>
          <span>AI练习</span>
          <span class="idiom-ai-practice--label">Premium</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

// specialised component that's for meaning search
// some behavior that is different from the usual passage entity
// import {mapState, mapMutations} from 'vuex';

import {mapMutations, mapGetters} from 'vuex';
import {soundMixin} from '../../../../mixins/sound';

export default {
  name: 'ResourcesParagraph',
  components: {
  },
  props: {
    info: Object,
    category: String,
    subcategoryIndex: Number,
  },
  data() {
    return {
    };
  },
  mixins: [soundMixin],
  computed: {
    ...mapGetters(['isPSLESuccessPlan']),
    // placeholders for now - in future, this will be from backend
    // dont really like this approach since we are assuming that we have this file in the bucket
    audioUrl() {
      const baseUrl = 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/vocab/compo-resources/examples';
      if (this.category === 'idioms') {
        return `${baseUrl}/idioms/${this.info.word}.mp3`;
      } else if (this.category === 'openings') {
        const name = this.info.text.slice(0, 15).replace(/[。“”""… ， ]/g, '');
        return `${baseUrl}/openings/${name}.mp3`;
      } else if (this.category === 'proverbs') {
        return `${baseUrl}/proverbs/${this.info.title}.mp3`;
      } else if (this.category === 'metaphors') {
        const name = this.info.text.slice(0, 15).replace(/[。“”""… ， ]/g, '');
        return `${baseUrl}/metaphors/${name}.mp3`;
      }
      return '';
    },
    audioWordUrl() {
      const baseUrl = 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/vocab/compo-resources/examples';
      if (this.category === 'idioms') {
        return `${baseUrl}/idioms_words/${this.info.word}.mp3`;
      }
      return '';
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations('composition', ['setSelectedWordInfo']),
    playAudio(elementName) {
      this.stopAllAudio();
      this.$refs[elementName].play();
    },
    toggleAiPracticeModal(wordInfo) {
      this.setSelectedWordInfo(wordInfo);
      this.$emit('toggleAiPracticeModal');
    },
  },
};
</script>

<style lang="scss" scoped>
  .pointer {
    cursor: pointer;
  }
  .item {
    width: 100%;
    margin-bottom: 1rem;
    background: #fff;
    border-radius: .5rem;
    padding: .5rem;
    &-header {
      border-bottom: 2px solid #dadada;
      padding: .5rem;
      display: flex;
      &--tag {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .25rem .75rem;
        background: #d9d9d9;
        border-radius: .5rem;
        margin-left: auto;
        font-size: .8rem;
      }
    }
    &-body {
      display: flex;
      gap: .5rem;
      margin-top: 1rem;
      flex-direction: column;
      &--premium {
        &-container {
          padding-top: 1rem;
          border-top: 2px solid #dadada;
          margin-top: .5rem;
        }
        &-header {
          margin: 0 .5rem;
          display: flex;
          gap: .25rem;
          span {
            border-radius: .25rem;
            background: #3366b4;
            padding: 0 .5rem;
            color: #fff;
            font-size: .9rem;
          }
        }
      }
      .idiom-ai-practice {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        font-family: 'baloo da 2';
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .35rem .75rem;
        background: #e5e5e5;
        border-radius: .75rem;
        cursor: pointer;
        margin-left: 1rem;
        margin-top: .5rem;
        margin-bottom: .5rem;
        font-size: .9rem;
        position: relative;
        padding-bottom: .15rem;
        i {
          margin-right: .5rem;
        }
        &--label {
          position: absolute;
          top: -.75rem;
          left: 0%;
          font-size: .6rem;
          border-radius: .25rem;
          background: rgb(87,225,228);
          background: linear-gradient(167deg, rgb(184 87 228) 34%, rgb(78 158 255) 61%);
          padding: 0 .5rem;
          color: #fff;
        }
      }
      &--content {
        flex: 1;
        display: flex;
        align-items: center;
        &--sentences {
          flex: 1;
        }
      }
      .label {
        min-width: 88px;
      }
      .meaning {
        display: flex;
      }
      .fa-play {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .5rem;
        background: #e5e5e5;
        border-radius: 1rem;
        cursor: pointer;
        height: 2.5rem;
        width: 2.5rem;
        margin-left: auto;
        margin-right: .5rem;
      }
      p {
        font-size: 1.1rem;
        flex: .95;
        padding: .5rem;
        margin-left: .5rem;
      }
      .proverbs-sentence {
        display: flex;
        p {
          flex: inherit;
        }
      }
    }
  }
  .bg-1 {
    background: #90c7ff;
  }
  .bg-2 {
    background: #6fe5b8;
  }
  .bg-3 {
    background: #ff90aa;
  }
  .bg-4 {
    background: #ffce90;
  }
  .bg-5 {
    background: #E8FB8D;
  }
  .bg-6 {
    background: #B589FF;
  }
  .padding-0 {
    padding: 0;
  }

  @media only screen and (max-width: 520px) {
    .item-body {
      flex-direction: column;
      &--content {
        flex-direction: column;
        &--audio {
          width: 100%;
          .fa-play {
            width: 100%;
          }
        }
      }
      p {
        font-size: 1rem;
      }
    }
    .item-header {
      font-size: .9rem;
      flex-wrap: wrap;
      &--tag {
        font-size: .75rem;
      }
    }
  }
</style>
