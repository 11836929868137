<!-- a recorder button that shows record and stop button and handles transcription -->
<template>
  <div class="container">
    <button v-if="!isRecording && recordingState == 0" @click="toggleRecording"
      class="microphone">
      <i class="fa-solid fa-microphone"></i>
    </button>
    <button v-if="isRecording && recordingState == 1" @click="toggleRecording"
      class="microphone microphone-stop animated bounceIn">
      <i class="fa-solid fa-stop"></i>
    </button>
    <div v-if="!isRecording && recordingState == 2" @click="toggleRecording"
      class="icon-transcribing rotating">
      <i class="fa-solid fa-loader"></i>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Recorder from '../../../../../vendor/recorder';

import {mapState} from 'vuex';

// miliseconds, to prevent accidental double clicking
const MINIMUM_RECORD_TIME = 500;

export default {
  name: 'TranscriptionRecorderButton',
  data() {
    return {
      isRecording: false,
      recordingState: 0, // 0: initial, 1: recording, 2: transcribing
      // we don't want the record button to change immediately as that can cause the initial word to be cut off
      // hence, when isRecording changes to true, isRecordingBuffered only changes 0.5s later
      isRecordingBuffered: false,
      hasMicrophonePermission: false,
      recorder: null,
      gumStream: null,
      results: null,
      startRecordingTime: null,
    };
  },
  computed: {
    ...mapState(['subject']),
    renderUrl() {
      // we use a separate webapp on render to handle transcription as python anywhere is incredibly slow
      return this.isDevelopment ? 'http://localhost:8080/transcribe' : 'https://learnsmartapi.onrender.com/transcribe';
    },
  },
  methods: {
    // toggleRecording() {
    //   if (this.recordingState === 0) {
    //     this.isRecording = true;
    //     this.recordingState = 1;
    //   } else if (this.recordingState === 1) {
    //     this.isRecording = false;
    //     this.recordingState = 2;
    //   } else {
    //     this.isRecording = false;
    //     this.recordingState = 0;
    //   }
    //   this.$emit('recording-toggled', this.isRecording);
    // },
    // start of transcription code
    // to prevent user from accidentally double clicking, we will record for minimum of at least 1s
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
        this.$emit('recording-state-change', this.recordingState);
      } else {
        this.startRecording();
        this.$emit('recording-state-change', this.recordingState);
      }
    },
    // https://blog.addpipe.com/using-recorder-js-to-capture-wav-audio-in-your-html5-web-site/
    startRecording() {
      if (this.fetching) return;
      this.recordingState = 1;
      this.isRecording = true;
      this.result = null;
      this.startRecordingTime = new Date();

      // shim for AudioContext when it's not avb.
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      const audioContext = new AudioContext;

      navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((stream) => {
        /* assign to gumStream for later use */
        this.gumStream = stream;
        /* use the stream */
        const input = audioContext.createMediaStreamSource(stream);
        // Create the Recorder object and configure to record mono sound (1 channel)
        this.recorder = new Recorder(input, {
          numChannels: 1,
        });
        // start the recording process
        this.recorder.record();
      }).catch((err) => {
        // enable the record button if getUserMedia() fails
        alert(err);
        console.log(err);
        this.isRecording = false;
        this.isRecordingBuffered = false;
      });
    },
    stopRecording() {
      if (new Date() - this.startRecordingTime < MINIMUM_RECORD_TIME) {
        return;
      }
      this.recordingState = 2;

      if (this.fetching) {
        return;
      }
      this.isRecording = false;
      this.isRecordingBuffered = false;

      // tell the recorder to stop the recording
      if (!this.recorder) {
        alert('Error recording, please try again.');
        this.reset();
        return;
      }
      this.recorder.stop(); // stop microphone access
      this.gumStream.getAudioTracks()[0].stop();
      // create the wav blob and pass it on to createDownloadLink
      this.recorder.exportWAV(this.createDownloadLink);
    },
    cancelRecording() {
      this.recordingState = 0;
      this.isRecording = false;
      // tell the recorder to stop the recording
      this.recorder.stop(); // stop microphone access
      this.gumStream.getAudioTracks()[0].stop();
    },
    createDownloadLink(blob) {
      const au = document.createElement('audio');
      au.controls = false;
      au.src = URL.createObjectURL(blob);
      this.studentAudio = au;

      const filename = new Date().toISOString();
      const formData = new FormData();
      formData.append('audioData', blob, filename);
      formData.append('language', this.subject);
      this.fetching = true;

      axios
          .post(this.renderUrl, formData, {
            'Content-Type': 'multipart/form-data',
          })
          .then((response) => {
            this.fetching = false;
            if (!response.data['is_subject']) {
              alert(`Your answer needs to be in ${this.subject}`);
              this.reset();
              return;
            }
            this.$emit('transcription-complete', response.data['transcription'].trim());
            this.reset();
          })
          .catch((err) => {
            console.log(this.err);
            this.reset();
          });
    },
    reset() {
      this.gumStream = null;
      this.recordingState = 0;
      this.$emit('recording-state-change', this.recordingState);
    },
  },
};
</script>

<style lang="scss" scoped>
  .container {
    display: flex;
    gap: .5rem;
    align-items: stretch;
  }
  .icon-transcribing {
    width: 75px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
  }
  .microphone {
    border: 2px solid rgba(0,0,0,0);
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    border-radius: .5rem;
    color: #fff;
    background: #873fff;
    border-bottom: 4px solid #582d99;
    &-stop {
      background: #ff3f99;
      border-bottom: 4px solid #b0175f;
    }
    cursor: pointer;
    &:active {
      border-bottom: 2px solid #5e46b0;
      margin-top: 2px;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background: #ddd;
      border-bottom: 4px solid #d2d2d2;
      &:active {
        transform: none;
        margin-top: .5rem;
        border-bottom: 4px solid #d2d2d2;
      }
    }
  }

  @media only screen and (max-width: 760px) {
    .microphone {
      font-size: 1.25rem;
    }
  }
</style>
