<template>
  <li
    class="diamond-shop__item"
    @click="popupToggle(item)">
    <div v-if="item.status === 'sold_out'"
      class="diamond-shop__item--sold-out">
      <span>SOLD OUT</span>
    </div>
    <h2 class="diamond-shop__item--title">
      {{ item.title }}
    </h2>
    <img class="diamond-shop__item--img"
      :src="item.photo">
    <!-- <p class="diamond-shop__item--desc">
      {{ item.description }}
    </p> -->
    <p class="diamond-shop__item--price">
      <span class="diamond-shop__item--price-icon">
        <i class="fas fa-gem"></i>
      </span>
      <span>{{ item.price }}</span>
    </p>
  </li>
</template>

<script>

export default {
  name: 'ShopItem',
  components: {
  },
  props: {
    item: Object,
    orderedCounts: Object,
    popupToggle: Function,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .diamond-shop{
    &__item {
      position: relative;
      cursor: pointer;
      background: #fff;
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      border-radius: .5rem;
      overflow: hidden;
      height: 400px;
      &--sold-out {
        height: 100%;
        width: 100%;
        background: #ffffffe0;
        position: absolute;
        color: #f44;
        font-weight: 800;
        font-size: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-bottom: 62px;
        }
      }
      &--ordered {
        top: 1.5rem;
        right: 1.5rem;
        position: absolute;
        font-weight: 700;
        color: #25d1aa;
      }
      &--title{
        color: #6b6b6b;
        font-weight: 600;
        margin-left: .5rem;
        font-size: 1.2rem;
      }
      &--img{
        flex: 1;
        width: 100%;
        object-fit: cover;
        margin-bottom: .5rem;
      }
      &--desc{
        max-width: 350px;
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: .5rem;
        font-style: italic;
        font-size: 0.9rem;
        margin-bottom: 2rem;
      }
      &--price{
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0.25rem;
        background: #36a6fe;
        color: #fff;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'baloo da 2';
        font-size: 1.25rem;
        font-weight: 600;
        &-icon {
          background: #fff;
          border-radius: 50%;
          height: 35px;
          width: 35px;
          text-align: center;
          margin-right: .5rem;
          i {
            color: #2390ff;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1115px) {
    .diamond-shop {
      &__item {
        width: 100%;
        &:nth-child(even) {
          margin-left: 0;
        }
      }
    }
  }
</style>
