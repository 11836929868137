import Vue from 'vue';
import moment from 'moment';
import pluralize from 'pluralize';

// splits then capitalises
Vue.filter('splitCapitalise', (val) => {
  if (!val) return '';

  return val.split('_').map(function(word) {
    return word[0].toUpperCase() + word.substr(1);
  }).join(' ');
});

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY');
  };
});

// https://dev.to/webhookrelay/creating-a-simple-vue-pluralize-filter-j9m
Vue.filter('pluralize', function(value, number) {
  return pluralize(value, number);
});

Vue.filter('capitalize', (val) => {
  if (!val) return '';
  val = val.toString();
  return val.charAt(0).toUpperCase() + val.slice(1);
});

// converts a level (e.g. primary_two) into P2 or 2
Vue.filter('shorternLevel', (val, numberOnly) => {
  if (typeof numberOnly === 'undefined') {
    numberOnly = false;
  }

  const map = {
    'nursery_two': 'N2',
    'Nursery Two': 'N2',
    'kindergarten_one': 'K1',
    'Kindergarten One': 'K1',
    'kindergarten_two': 'K2',
    'Kindergarten Two': 'K2',
    'primary_one': 'P1',
    'Primary One': 'P1',
    'primary_two': 'P2',
    'Primary Two': 'P2',
    'primary_three': 'P3',
    'Primary Three': 'P3',
    'primary_four': 'P4',
    'Primary Four': 'P4',
    'primary_five': 'P5',
    'Primary Five': 'P5',
    'primary_six': 'P6',
    'Primary Six': 'P6',
    'others': 'Others',
  };

  if (val in map) {
    return numberOnly ? map[val][1] : map[val];
  }

  return val;
});

// converts english word (primary_one) -> P1
Vue.filter('convertPrimaryLevelToChinese', (val) => {
  const map = {
    'primary_one': '小一',
    'primary_two': '小二',
    'primary_three': '小三',
    'primary_four': '小四',
    'primary_five': '小五',
    'primary_six': '小六',
    'p1': '小一',
    'p2': '小二',
    'p3': '小三',
    'p4': '小四',
    'p5': '小五',
    'p6': '小六',
  };

  const key = val.trim().toLowerCase().split(' ').join('_');
  return key in map ? map[key] : '';
});

// prints the day of date (e.g. 24/6/19 => monday)
Vue.filter('convertToDay', (val) => {
  // in javascript, sunday is 0, monday is 1, etc
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday',
    'Thursday', 'Friday', 'Saturday', 'Sunday'];
  if (!val) return '';

  const d = new Date(val);
  return days[d.getDay()];
});

// for the assessment book questions
// syntax for question text
// {__} means to fill with underline
// {x} means to fill in the word
Vue.filter('hyrateQuestionText', (val) => {
  return val.replace(/{.+?}/gi, function(entry) {
    const result = entry.substr(1, entry.length - 2);

    if (result === '__') {
      return '______';
    } else {
      return '<u>' + result +'</u>';
    };
  });
});

// Abbreviates large numbers to reader-friendly format (e.g. 1100 -> 1.1k)
Vue.filter('abbrNum', (number, decPlaces) => {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  const abbrev = ['k', 'm', 'b', 't'];

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round(number * decPlaces / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if ((number == 1000) && (i < abbrev.length - 1)) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      number += abbrev[i];

      // We are done... stop
      break;
    }
  }
  return number;
});
