<template>
    <div class="tree-climb__component animated fadeIn">
      <iframe :src="cocosTreeClimbUrl"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        width="100%"
        height="100%"
        scrolling="auto"
        class="animated slideInDown faster"></iframe>
    </div>
  </template>
<script>

import {mapState, mapMutations} from 'vuex';
// need to fix chapter info
export default {
  name: 'NewPlayTreeClimb',
  components: {
  },
  data() {
    return {
      // need to find a way to automate this using environment
      isDevelopment: false,
      fetching: false,
    };
  },
  computed: {
    ...mapState(['level', 'cocos', 'listLevelChapters']),
    baseUrl() {
      return this.isDevelopment ? 'http://localhost:7456/' : 'https://treegame.s3.ap-southeast-1.amazonaws.com/index.html';
    },
    cocosTreeClimbUrl() {
      if (!this.cocos.quick_token || this.cocos.quick_token === '') {
        return this.baseUrl;
      } else {
        return `${this.baseUrl}?quick=` + this.cocos.quick_token;
      }
    },
  },
  mounted() {
    this.selectedLevel = this.level;
    // https://medium.com/@mrajaeim/understanding-window-postmessage-and-window-parent-postmessage-in-javascript-f09d4eac68ba
    window.addEventListener('message', this.onEvent);
  },
  methods: {
    ...mapMutations(['setMissions']),
    onEvent(evt) {
      const allowedOrigins = ['http://localhost:7456', 'https://treegame.s3.ap-southeast-1.amazonaws.com'];
      if (!allowedOrigins.includes(event.origin)) return;
      if (typeof evt['data'] === 'undefined' || typeof evt['data']['name'] === 'undefined') return;

      // we listen for a close event from cocos, and then minimise the size
      if (evt.data.name === 'closeGame') {
        window.removeEventListener('message', this.onEvent);
        this.$router.push({name: 'new-play-overview'});
      } else if (evt.data.name === 'loaded') {
        // we wait for a load event from cocos then we send over the chapters student required
        this.sendChapterInfo();
      } else if (evt.data.name === 'updateMissions') {
        this.setMissions(evt['data']['data']);
      }
    },
    sendChapterInfo() {
      // https://www.teamsimmer.com/2023/05/02/how-do-i-use-the-postmessage-method-with-cross-site-iframes/
      // https://javascriptbit.com/transfer-data-between-parent-window-and-iframe-postmessage-api/
      const targetIframe = document.querySelector('iframe');
      if (!!targetIframe) {
        // console.log('sending???');
        targetIframe.contentWindow.postMessage({
          name: 'chapterInfo',
          chapterIds: [],
        }, '*');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  html {
    overflow: auto;
  }

  iframe {
    display: flex;
    border: none;
    overflow-y: auto;
    overflow-x: hidden;
    width: 51vh;
    height: 90vh;
    border-radius: 1rem;
    border: 6px solid #000;
    box-shadow: 0 0 14px 5px #6c44f3;
  }
  .tree-climb__component {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: #000000d3;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tree-climb__settings {
    height: 90%;
    width: 1200px;
    box-shadow: 0 0px 6px 6px #e13eff;
    border-radius: .25rem;
    position: relative;
    display: flex;
    background-image: url("../../../assets/bg-game-fishing.jpg");
    background-size: cover;
    flex-direction: column;
    &--btn-close {
      position: absolute;
      z-index: 3;
      right: 1rem;
      top: 1.5rem;
      width: 32px;
      height: 32px;
      opacity: 0.8;
      cursor: pointer;
      transition: 0.3s all linear;
      &:hover {
        opacity: 1;
      }
      &:before, &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 25px;
        width: 5px;
        background-color: #ffffff;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
    &--header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      font-size: 2rem;
      color: #fff;
      font-weight: 800;
      background: #0f2365c7;
      margin-bottom: .75rem;
      border-top-left-radius: .25rem;
      border-top-right-radius: .25rem;
      .fa-fish {
        padding: 0 .75rem;
      }
    }
    &--body {
      &-play {
        align-self: flex-end;
        padding: 1.5rem;
        &--btn {
          padding: .5rem 5rem;
          font-size: 1.2rem;
          font-weight: 800;
          background: #394d8d;
          border-radius: 2rem;
          color: #fff;
          cursor: pointer;
          border-bottom: 5px solid #00f1ff;
          &:hover {
            background: #445aa2;
            transform: scale(1.02);
            transition: all 0.1s linear;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 470px) {
    iframe {
      width: 100vw;
      height: 100vh;
      border-radius: 0;
    }
  }

  @media only screen and (max-width: 414px) and (max-height: 810px) {
    iframe {
      height: 100%;
      width: 100%;
      box-shadow: none;
    }
  }

</style>
