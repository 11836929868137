<template>
  <div class="parent-registration__component">
    <loading-screen
      :prop-show="showLoadingScreen"
      :min-wait-time="1000"></loading-screen>
    <div class="parent-registration__header">
      <a href="#" class="parent-registration__header--logo">
          <img src="../../assets/parent-registration/logo-registration.png" alt="VocabKing">
      </a>
      <div class="parent-registration__header--container">
          <div class="parent-registration__header--img">
              <img src="../../assets/parent-registration/tab-header.png" alt="Tab&smartphone">
          </div>
          <div class="parent-registration__header--text">
            <h1 class="parent-registration__header--text-1">
                Learn Smarter, Learn Better!
            </h1>
            <p class="parent-registration__header--text-2">
              88+% of students who practise regularly see significant improvement within 2 months.
            </p>
          </div>
      </div>
    </div>
    <div class="parent-registration__content">
      <div class="parent-registration__content-1">
        <div class="parent-registration__content-1--decor">
          <h2 class="parent-registration__content-1--header">
            Start Your 7-Day Free Trial
          </h2>
          <div class="parent-registration__content-1--body">
            <div class="parent-registration__content-1--body-left">
              <div class="parent-registration__content-1--pointers">
                <div class="parent-registration__content-1--pointers-title">
                  <h2 class="parent-registration__content-1--pointers-title-main">Transform your child's Chinese using AI</h2>
                  <!-- <p class="parent-registration__content-1--pointers-title-sub">Try everything for free!</p> -->
                </div>
                <ul class="parent-registration__content-1--pointers-list">
                  <li>
                    Improve and enjoy learning Chinese through fun pet games and animated stories.
                  </li>
                  <li>
                    Personalised quizzes, comprehensions, and video lessons based on latest MOE curriculum.
                  </li>
                  <li>
                    Boosts confidence and motivates your child to practice Chinese.
                  </li>
                </ul>
              </div>
              <div class="parent-registration__content-1--testimonials">
                <div class="parent-registration__content-1--testimonials-header">
                  <h2>What Parents Say</h2>
                  <ul class="slider-paginator">
                      <li @click="slide(-1)"
                        class="button-prev slider-btn"></li>
                      <li class="slider-count">
                        {{ slides[currentTestimonial].num }} of {{ slides.length }}
                      </li>
                      <li @click="slide(1)"
                        class="button-next slider-btn"></li>
                  </ul>
                </div>
                <div class="parent-registration__content-1--testimonial">
                  <transition-group tag="div" :name="transitionName"
                    class="parent-registration__content-1--testimonial-grp">
                    <div :key="currentTestimonial"
                      class="parent-registration__content-1--testimonial-content">
                      <div class="parent-registration__content-1--testimonial-left">
                        <img :src="currentParentImg" alt="Avatar">
                        <p class="testimonial--name">
                          {{ slides[currentTestimonial].parentName }}
                        </p>
                        <p class="testimonial--level">
                          {{ slides[currentTestimonial].studentLevel }}
                        </p>
                      </div>
                      <div
                        class="parent-registration__content-1--testimonial-right">
                        <p>
                          {{ slides[currentTestimonial].testimonial }}
                        </p>
                      </div>

                    </div>
                  </transition-group>
                </div>
              </div>
            </div>
            <div class="parent-registration__content-1--body-right">
              <div class="parent-registration__form-wrapper">
                <div class="parent-registration__form--img1-container">
                    <div class="parent-registration__form--img1">
                        <img src="../../assets/parent-registration/cat.png" alt="Cat">
                    </div>
                </div>
                <h2 class="parent-registration__form-title">
                  Vocab King!
                </h2>
                <p class="parent-registration__form-text">
                  Sign up for Parent Account
                </p>
                <span
                  v-if="parentErrorMessage !== ''"
                  class="error-message">
                  <p>{{ parentErrorMessage }}</p>
                  <p>Please try again or <a href="https://api.whatsapp.com/send?phone=6598207272&text=Hi%20I%27m%20looking%20to%20create%20a%20trial%20account%20for%20VocabKing%27s%20online%20portal.">Whatsapp us for help.</a></p>
                </span>
                <form method="post" class="parent-registration__form">
                  <input
                    v-model="input.firstName"
                    class="input-text"
                    type="text"
                    name="firstName"
                    placeholder="Parent first name..."/>
                  <input
                    v-model="input.lastName"
                    class="input-text"
                    type="text"
                    name="lastName"
                    placeholder="Parent last name..."/>
                  <input
                    v-model="input.email"
                    class="input-text"
                    type="email"
                    name="email"
                    placeholder="Parent email..."/>
                  <input
                    v-model="input.phoneNumber"
                    class="input-text"
                    type="tel"
                    name="tel"
                    placeholder="Mobile Number (+65...)"/>
                  <div class="checkbox-container">
                    <input class="checkbox-custom"
                      v-model="input.addToMailingList"
                      type="checkbox"
                      id="content-1__agree"
                      name="agree"
                      value="">
                    <label for="content-1__agree">
                      I'd like to receive weekly tips on how to help my child excel at Chinese
                    </label>
                  </div>
                  <button
                    class="form-btn"
                    type="submit"
                    @click.prevent="createParent">
                    Create
                  </button>
                </form>
              </div>
            </div>
            <img class="parent-registration__content-1--body-decor" src="../../assets/parent-registration/svg/star-white-without-shadow.svg" alt="Start">
          </div>
        </div>
      </div>
      <div class="parent-registration__content-2">
        <div class="parent-registration__content-2--header">
          <h2 class="parent-registration__content-2--header-text">
             <span>Improve Chinese</span> and Vocab in just <span>10 Minutes a Day</span>
          </h2>
        </div>
        <ul class="parent-registration__content-2--list">
          <li>
            <div>
              <img src="../../assets/parent-registration/svg/icon-education.svg" alt="Education">
            </div>
            <p class="text">
              MOE Aligned Curriculum Aligned
            </p>
          </li>
          <li>
            <div>
              <img src="../../assets/parent-registration/svg/icon-classrooms.svg" alt="Online">
            </div>
            <p class="text">
              Interactive
              Online Quizzes
            </p>
          </li>
          <li>
            <div>
              <img src="../../assets/parent-registration/svg/icon-learning-games.svg" alt="Games">
            </div>
            <p class="text">
              Learning
              Games
            </p>
          </li>
          <li>
            <div>
              <img src="../../assets/parent-registration/svg/icon-diamond.svg" alt="Diamond">
            </div>
            <p class="text">
              Diamond
              Exchange Shop
            </p>
          </li>
          <li>
            <div>
              <img src="../../assets/parent-registration/svg/icon-reward-systems.svg" alt="Reward">
            </div>
            <p class="text">
              Reward Systems to
              Boost Motivation
            </p>
          </li>
          <li>
            <div>
              <img src="../../assets/parent-registration/svg/icon-daily-challenges.svg" alt="Daily">
            </div>
            <p class="text">
              Daily Challenges
            </p>
          </li>
          <li>
            <div>
              <img src="../../assets/parent-registration/svg/icon-video-lessons.svg" alt="Video">
            </div>
            <p class="text">
              Video Lessons
            </p>
          </li>
          <li>
            <div>
              <img src="../../assets/parent-registration/svg/icon-animated-stories.svg" alt="Stories">
            </div>
            <p class="text">
              Animated Stories
            </p>
          </li>
          <li>
            <div>
              <img src="../../assets/parent-registration/svg/icon-worksheets.svg" alt="Worksheets">
            </div>
            <p class="text">
              Worksheets
            </p>
          </li>
          <li>
            <div>
              <img src="../../assets/parent-registration/svg/icon-practice-questions.svg" alt="Practice">
            </div>
            <p class="text">
              Practice Questions
            </p>
          </li>
        </ul>
      </div>
      <div class="parent-registration__content-3">
        <div class="parent-registration__content-3--decor">
          <div class="parent-registration__content-3--header">
            <span class="gradient content-3--header-1"><span class="fw-bold">1+ Million</span> Questions Answered On Our Platform </span>
            <span class="gradient content-3--header-2"> <span class="fw-bold">The Chinese practice</span> your child will actually do</span>
          </div>
          <div class="parent-registration__form-wrapper">
            <div class="parent-registration__form--img2-container">
                <div class="parent-registration__form--img2">
                  <img src="../../assets/parent-registration/bull.png" alt="Bull">
                </div>
            </div>
            <div class="parent-registration__form--img3-container">
                <div class="parent-registration__form--img3">
                  <img src="../../assets/parent-registration/sea-horse.png" alt="Sea horse">
                </div>
            </div>
            <h2 class="parent-registration__form-title">
              Vocab King!
            </h2>
            <p class="parent-registration__form-text">
              Sign up for Parent Account
            </p>
            <span
              v-if="parentErrorMessage !== ''"
              class="error-message">
              <p>{{ parentErrorMessage }}</p>
              <p>Please try again or <a href="https://api.whatsapp.com/send?phone=6598207272&text=Hi%20I%27m%20looking%20to%20create%20a%20trial%20account%20for%VocabKing%27s%20online%20portal.">Whatsapp us for help.</a></p>
            </span>
            <form method="post" class="parent-registration__form">
              <input
                v-model="input.firstName"
                class="input-text"
                type="text"
                name="firstName"
                placeholder="Parent first name..."/>
              <input
                v-model="input.lastName"
                class="input-text"
                type="text"
                name="lastName"
                placeholder="Parent last name..."/>
              <input
                v-model="input.email"
                class="input-text"
                type="email"
                name="email"
                placeholder="Parent email..."/>
              <input
                v-model="input.phoneNumber"
                class="input-text"
                type="tel"
                name="tel"
                placeholder="Mobile Number (+65...)"/>
              <div class="checkbox-container">
                <input class="checkbox-custom"
                  v-model="input.addToMailingList"
                  type="checkbox"
                  id="content-3__agree"
                  name="agree"
                  value="">
                <label for="content-3__agree">
                  I'd like to receive weekly tips on how to help my child excel at Chinese
                </label>
              </div>
              <button
                class="form-btn"
                type="submit"
                @click.prevent="createParent">
                Create
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="parent-registration__footer">
      <div class="parent-registration__footer--content">
        <p>Having trouble?
          <a href="#">WhatsApp us now for support!</a>
        </p>
        <p>Or email us -
          <a href="mailto:vocabking@learnsmart.ai">vocabking@learnsmart.ai</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapMutations} from 'vuex';
// import {mapState, mapActions} from 'vuex';

import LoadingScreenComponent
  from '../common/LoadingScreenComponent.vue';

// when we come to registration page, we can either have a parentId
// (i.e. created previously) or we have "new"
export default {
  name: 'RegistrationParentNew',
  components: {
    LoadingScreen: LoadingScreenComponent,
  },
  props: {
  },
  data() {
    return {
      input: {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        addToMailingList: true,
      },
      parentErrorMessage: '',
      showLoadingScreen: false,
      // carousel data
      currentTestimonial: 0,
      direction: 1,
      transitionName: 'fade',
      slides: [
        {
          num: 1,
          parentName: 'Kevin',
          studentLevel: 'P1',
          src: 'kevin.png',
          testimonial: 'The portal has encouraged my daughter to embrace Chinese more than before. We have tried other online programmes but Vocab King\'s bite-size quizzes have made learning Chinese a breeze.',
        },
        {
          num: 2,
          parentName: 'Mei',
          studentLevel: 'Primary Two',
          src: 'mei.png',
          testimonial: 'Peter loves the self directed learning portal that is fun and intuitive in learning Chinese. The games have an endless variety of words that exposes him to a wide Chinese vocabulary!',
        },
      ],
    };
  },
  computed: {
    currentParentImg() {
      return require('../../assets/parent-registration/' + this.slides[this.currentTestimonial].src);
    },
  },
  mounted: function() {
    // in case users log into globalLoadingScreen directly
    this.setShowGlobalLoadingScreen(false);
  },
  methods: {
    ...mapMutations(['setRegistration', 'setShowGlobalLoadingScreen']),
    slide(dir) {
      this.direction = dir;
      dir === 1
        ? (this.transitionName = 'slide-next')
        : (this.transitionName = 'slide-prev');
      const len = this.slides.length;
      this.currentTestimonial = (this.currentTestimonial + dir % len + len) % len;
    },
    backToLogin() {
      this.$router.push({name: 'login-main'});
    },
    validEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    // returns a tuple. First element is a boolean indiciating if number is valid, and second element is the number
    validNumber(number) {
      if (number.length !== 8 && number.length !== 11) return [false, null];

      if (number.length === 11) {
        if (number.substr(0, 3) !== '+65') {
          return [false, null];
        }
        number = number.substr(3);
      }

      const re = /^[89]\d{7}$/;
      if (re.test(number)) {
        return [true, '+65' + number];
      } else {
        return [false, null];
      }
    },
    // checks if parent has previously been created
    createParent() {
      if (this.showLoadingScreen) return;
      this.parentErrorMessage = '';
      if (this.input.email === '' || this.input.firstName === ''
          || this.input.lastName === '') {
        this.parentErrorMessage = 'Please fill up all the information.';
        return;
      };

      if (!this.validEmail(this.input.email)) {
        this.parentErrorMessage = 'Email seems to be invalid.';
        return;
      }

      const _validNumber = this.validNumber(this.input.phoneNumber);
      if (!_validNumber[0]) {
        this.parentErrorMessage = 'Mobile number is invalid.';
        return;
      }
      this.showLoadingScreen = true;
      axios
          .post('/api/v1/core/create/parent/', {
            email: this.input.email,
            firstName: this.input.firstName,
            lastName: this.input.lastName,
            phoneNumber: _validNumber[1],
            signupSource: 'registrationPage',
            addToMailingList: this.input.addToMailingList,
          })
          .then((response) => {
            this.showLoadingScreen = false;
            if (!response.data['created']) {
              this.parentErrorMessage = response.data['error_message'];
            } else {
              this.setRegistration({
                'success_message': response.data['success_message'],
                'created_type': 'parent',
              });
              this.$router.push({name: 'registration-student', params: {parentSignupToken: response.data['signup_token']}});
              // this.$router.push({name: 'registration-success'});
            }
            return;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  font-size: 0.9rem;
  text-align: center;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  margin-top: -1rem;
  background: #a68ae4;
  color: #fff;
  border-radius: 1rem;
  a{
    display: inline-block;
    position: relative;
    font-weight: 700;
  }
  a:before {
    content: "";
    position: absolute;
    bottom: 3px;
    right: 0;
    display: block;
    background-color: #c2a7ff;
    width: 100%;
    height: 2px;
    transition: 0.3s linear;
  }
  a:hover:before {
    width: 0;
  }
}
/* FADE IN */
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter {
  opacity: 0;
}

/* GO TO NEXT SLIDE */
.slide-next-enter-active,
.slide-next-leave-active {
  transition: transform 0.25s ease-in-out;
}
.slide-next-enter {
  transform: translate(100%);
}
.slide-next-leave-to {
  transform: translate(-100%);
}

/* GO TO PREVIOUS SLIDE */
.slide-prev-enter-active,
.slide-prev-leave-active {
  transition: transform 0.25s ease-in-out;
}
.slide-prev-enter {
  transform: translate(-100%);
}
.slide-prev-leave-to {
  transform: translate(100%);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
ol,
ul,
li,
button {
  margin: 0;
  padding: 0;
}

ol,
ul,
li {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
  outline: none;
  transition: 0.3s ease-in-out;
}

img,
picture {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
  resize: none;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

input,
textarea,
select {
  outline: none;
  border: none;
}

a, button, input {
  transition: all 0.3s ease-out;
}


.parent-registration__component {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Baloo Da 2", sans-serif;
  font-size: 23px;
  font-weight: 400;
  line-height: 1.4em;
  color: #564276;
  background-color: #fff;
}

.parent-registration {
  /* header */
  &__header {
   background-image: url("../../assets/parent-registration/header-bg.jpg");
   background-repeat: no-repeat;
   background-position: top center;
   background-size: cover;
   padding: 1rem 0;
   -moz-box-shadow: 3px 2px 12px rgba(0, 0, 0, 0.4);
   -webkit-box-shadow: 3px 2px 12px rgba(0, 0, 0, 0.4);
   box-shadow: 3px 2px 12px rgba(0, 0, 0, 0.4);
   &--logo {
    display: block;
    margin-bottom: .5rem;
    max-width: 1140px;
    margin: 0 auto 0.5rem auto;
    img {
      width: 200px;
    }
   }
   &--container {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
   }
   &--img {
    max-width: 400px;
   }
   &--text {
    &-1 {
     max-width: 500px;
     margin: 0 auto;
     text-align: center;
     color: white;
     font-weight: 600;
     line-height: 1.2em;
     font-size: 2rem;
     text-shadow: 4px 5px 9px #4d3d83;
     margin-top: -30px;
    }
    &-2 {
      font-size: 1.05rem;
      color: white;
      text-align: center;
      max-width: 500px;
    }
   }
  }
  /* content */
  &__content {
    &-1 {
      background: url("../../assets/parent-registration/svg/star-blue.svg") 60px 70%, url("../../assets/parent-registration/svg/star-blue.svg") 90% 80px;
      background-repeat: no-repeat;
      background-size: 40px;
      padding: 3.5rem 0px 8rem;
      position: relative;
      &--decor {
        position: relative;
        &:before,
        &:after {
          content: "";
          position: absolute;
          display: block;
          background-repeat: no-repeat;
          background-size: contain;
        }
        &:before {
          background-image: url("../../assets/parent-registration/svg/ellipse-thick-border-blue.svg");
          width: 20px;
          height: 20px;
          top: 20%;
          left: 50%;
          z-index: 2;
        }
        &:after {
          background-image: url("../../assets/parent-registration/svg/ellipse-thick-border-blue.svg");
          width: 20px;
          height: 20px;
          bottom: -120px;
          right: 40px;
        }
      }
      &:before,
      &:after {
        content: "";
        position: absolute;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
      }
      &:before {
        background-image: url("../../assets/parent-registration/star5-rose.png");
        width: 60px;
        height: 60px;
        top: 10%;
        left: 3%;
        z-index: 2;
      }
      &:after {
        background-image: url("../../assets/parent-registration/svg/ellipse-blue-big.svg");
        width: 700px;
        height: 700px;
        top: 15%;
        right: -200px;
        z-index: 0;
      }
      &--header {
        margin-bottom: 3.5rem;
        text-align: center;
        position: relative;
        z-index: 2;
        font-size: 2rem;
        color: #564276;
        font-weight: 800;
      }
      &--body {
        display: flex;
        max-width: 1140px;
        margin: 0 auto;
        padding: 0 10px;
        width: 100%;
        &-decor {
          position: absolute;
          bottom: -75px;
          left: 40%;
          display: block;
          width: 45px;
          height: 45px;
          filter: drop-shadow(0rem 0.3rem 0.2rem rgba(0, 0, 0, 0.1));
        }
        &-left {
          flex: 1;
          z-index: 1;
        }
        &-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      &--pointers {
        &-title {
          &-main {
            font-size: 1.7rem;
            margin-bottom: 1rem;
            text-transform: capitalize;
          }
          &-sub {
            font-weight: 400;
            text-transform: capitalize;
            font-size: 1.35rem;
            margin-bottom: 1rem;
          }
        }
        &-list {
          margin-left: 30px;
          li {
            position: relative;
            display: flex;
            align-items: center;
            max-width: 500px;
            font-size: 1rem;
            &:before {
              content: "";
              display: block;
              background-image: url(../../assets/parent-registration/svg/tick.svg);
              background-size: contain;
              background-position: center center;
              background-repeat: no-repeat;
              min-width: 40px;
              height: 40px;
              margin-right: 40px
            }
            &:not(:last-child) {
              margin-bottom: 1rem;
            }
          }
        }
      }
      &--testimonials {
        max-width: 580px;
        margin-top: 2rem;
        &-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;
          h2 {
            font-size: 1.7rem;
            color: #564276;
            font-weight: 800;
          }
          .slider-paginator {
            position: relative;
            display: flex;
            align-items: center;
            .slider-btn {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 15px;
              width: 15px;
              background-size: contain;
              background-repeat: no-repeat;
              cursor: pointer;
            }
            .button-prev {
              background-image: url("../../assets/parent-registration/back-registration.png");
              margin-right: 15px;
            }
            .button-next {
              background-image: url("../../assets/parent-registration/next-registration.png");
              margin-left: 15px;
            }
            li {
              position: relative;
              color: #897b9f;
              font-weight: 500;
              font-size: 1.15rem;
            }
            .slider-count {
              display: flex;
              justify-content: center;
              margin: 0 auto;
              min-width: 80px;
            }
          }
        }
      }
      &--testimonial {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-content {
          width: 560px;
          height: 200px;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &-grp {
          position: relative;
          overflow: hidden;
          width: 560px;
          height: 200px;
        }
        &-left {
          text-align: center;
          text-transform: capitalize;
          img {
            width: 100px;
            height: 100px;
            margin: 0 auto 0.5rem;;
            border-radius: 50%;
            border: 2px solid #f1f5ff;
            background-color: white;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: flex-end;
          }
          .testimonial--name {
            font-size: 1.3rem;
            font-weight: 700;
            color: #564276;
            margin-top: 10px;
          }
        }
        &-right {
          background-color: #f1f5ff;
          border-radius: 30px;
          color: #564276;
          font-size: 1.1rem;
          padding: 1rem 1.5rem;
          max-width: 400px;
        }
      }
    }
    &-2 {
      position: relative;
      background: url("../../assets/parent-registration/star5.png") -120px 100%, url("../../assets/parent-registration/star_big.png") 110% 23px, #7b8bf3;
      background-repeat: no-repeat;
      background-size: 240px;
      -webkit-box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5), inset 0px 11px 8px -10px #737bdf, inset 0px -11px 8px -2px #737bdf;
      -moz-box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5), inset 0px 11px 8px -10px #737bdf, inset 0px -11px 8px -2px #737bdf;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.5), inset 0 11px 8px -10px #737bdf, inset 0 -11px 8px -2px #737bdf;
      padding-bottom: 80px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
      }
      &:before {
        background-image: url("../../assets/parent-registration/svg/star_2.svg");
        width: 80px;
        height: 80px;
        top: calc(35% - 40px);
        left: 8%;
      }
      &:after {
        background-image: url("../../assets/parent-registration/star_small.png");
        width: 68px;
        height: 68px;
        top: calc(65% - 30px);
        right: 8%;
      }
      &--header {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: url("../../assets/parent-registration/svg/boost-skils-bg.svg");
        background-size: 100%;
        background-position: top center;
        background-repeat: no-repeat;
        position: relative;
        width: 100%;
        max-width: 800px;
        height: 184px;
        margin: 0 auto;
        transform: translate(0, -55px);
        &:before {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: -90px;
          background-image: url("../../assets/parent-registration/panda-registration.png");
          width: 290px;
          height: 230px;
          background-repeat: no-repeat;
          background-size: contain;
        }
        &-text {
          position: relative;
          text-align: center;
          max-width: 550px;
          margin: 0 auto;
          z-index: 2;
          font-size: 1.7rem;
          color: #564276;
          font-weight: 400;
          span {
            font-weight: 800;
          }
        }
      }
      &--list {
        position: relative;
        max-width: 1050px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        z-index: 2;
        flex-wrap: wrap;
        transform: translate(0, -25px);
        li {
          display: flex;
          flex-direction: column;
          justify-self: center;
          align-items: center;
          color: white;
          font-size: 1rem;
          text-align: center;
          width: 170px;
          margin: 1rem;
        }
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 60px;
          height: 80px;
          margin: 0 auto;
        }
      }
    }
    &-3 {
      position: relative;
      background: url(../../assets/parent-registration/svg/ellipse-blue.svg) -80px 103%, url(../../assets/parent-registration/svg/line.svg) right 220px;
      background-repeat: no-repeat;
      background-size: 335px;
      padding: 75px 0 110px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
      }
      &:before {
        background-image: url(../../assets/parent-registration/star5-rose.png);
        width: 60px;
        height: 60px;
        top: 20%;
        left: 13%;
        z-index: 2;
      }
      &:after {
        background-image: url(../../assets/parent-registration/svg/ellipse-blue.svg);
        width: 68px;
        height: 68px;
        top: calc(65% - 30px);
        left: 10%;
      }
      &--decor {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:before,
        &:after {
          content: "";
          position: absolute;
          display: block;
          background-repeat: no-repeat;
          background-size: contain;
        }
        &:before {
          background-image: url(../../assets/parent-registration/svg/ellipse-thick-border-blue.svg);
          width: 20px;
          height: 20px;
          top: 56%;
          left: 10%;
          z-index: 2;
        }
        &:after {
          background-image: url(../../assets/parent-registration/svg/ellipse-thick-border-blue-rose.svg);
          width: 20px;
          height: 20px;
          bottom: 110px;
          right: 40px;
        }
      }
      &--header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-weight: 400;
        font-size: 1.8rem;
        margin-bottom: 65px;
        .gradient {
          padding: 3px;
          margin-bottom: 10px;
          background-color: #3f4581;
          background-image: linear-gradient(90deg, #3f4581, #9d11a4);
          background-size: 100%;
          background-repeat: repeat;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
        }
        .fw-bold {
          font-weight: 700;
        }
      }
    }
  }
  &__form {
    margin: 0 1.25rem;
    display: flex;
    flex-direction: column;
    input[name=firstName],
    input[name=lastName], {
      background-image: url(../../assets/parent-registration/name-icon.png);
    }
    input[name=email] {
      background-image: url(../../assets/parent-registration/email-icon.png);
    }
    input[name=tel] {
      background-image: url(../../assets/parent-registration/mobile-icon.png);
    }
    input {
      font-size: 1rem;
      display: block;
      width: 100%;
      border-radius: 30px;
      background-color: #f3f4f9;
      border: 1px solid transparent;
      background-position: 24px center;
      background-repeat: no-repeat;
      background-size: 17.5;
      padding: 7px 5px 7px 60px;
      -webkit-transition: width 0.3s ease-in-out;
      transition: width 0.3s ease-in-out;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    &-title {
      font-size: 1.7rem;
      font-weight: 800;
      margin: 0 auto 1.3rem;
      text-align: center;
      position: relative;
      width: max-content;
      z-index: 1;
      &:after {
        content: "";
        position: absolute;
        display: block;
        background-color: #f3f4f9;
        width: 65px;
        height: 65px;
        border-radius: 50%;
        right: 0;
        top: -18px;
        z-index: -1;
      }
    }
    &-text {
      font-size: 1.15rem;
      font-weight: 500;
      margin-bottom: 1.5rem;
      text-align: center;
      color: #897b9f;
    }
    &-wrapper {
      border-radius: 50px;
      position: relative;
      max-width: 425px;
      display: flex;
      flex-direction: column;
      padding: 2.75rem 1.5rem;
      position: relative;
      background-color: white;
      box-shadow: 0 11px 28.8px 3.2px #1d023433, inset 0 6px 20px 0 #5c007c40;
      z-index: 1;
    }
    .checkbox {
      &-container {
        width: 100%;
        max-width: 372px;
        margin: 0 auto;
      }
      &-custom {
        position: absolute;
        z-index: -1;
        opacity: 0;
        &:not(:disabled):not(:checked) + label:hover::before {
          border-color: #897b9f;
        }
        &:not(:disabled):active + label::before {
          background-color: #897b9f;
          border-color: #897b9f;
        }
        &:focus:not(:checked) + label::before {
          border-color: #897b9f;
        }
        &:checked + label::before {
          border-color: #fff;
          background-color: #897b9f;
        }
        + label {
          color: #897b9f;
          font-size: 0.9rem;
          display: inline-flex;
          align-items: center;
          user-select: none;
          cursor: pointer;
          line-height: 1em;
          margin-top: 20px;
          margin-bottom: 15px;
          &::before {
            content: "";
            display: inline-block;
            transform: translate(0.2em, 0);
            position: relative;
            width: 0.8em;
            height: 0.8em;
            flex-shrink: 0;
            flex-grow: 0;
            border-radius: 50%;
            margin-right: 1em;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
            background-color: #fff;
            z-index: 2;
          }
          &::after {
            content: "";
            position: absolute;
            display: inline-block;
            width: 1.2em;
            height: 1.2em;
            flex-shrink: 0;
            flex-grow: 0;
            border: 2px solid #897b9f;
            border-radius: 50%;
            margin-right: 0.5em;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
            background-color: #fff;
            z-index: 1;
          }
        }
      }
    }
    .form-btn {
      margin-top: 10px;
      display: block;
      max-width: 100%;
      text-transform: uppercase;
      border-radius: 60px;
      color: white;
      font-weight: 700;
      font-size: 25px;
      padding: 10px 5px;
      text-align: center;
      -webkit-box-shadow: inset 2px -5px 31px -11px #fff;
      -moz-box-shadow: inset 2px -5px 31px -11px #fff;
      box-shadow: inset 2px -5px 31px -11px #fff;
      background-image: -moz-linear-gradient(0deg, #8c78e7 0%, #a666cd 100%);
      background-image: -webkit-linear-gradient(0deg, #8c78e7 0%, #a666cd 100%);
      background-image: -ms-linear-gradient(0deg, #8c78e7 0%, #a666cd 100%);
    }
    &--img1-container {
      position: absolute;
      top: 0;
      z-index: 2;
      right: 0;
      transform: translate(35px, -125px);
      width: 330px;
    }
    &--img2-container {
      position: absolute;
      z-index: 2;
      bottom: 0;
      right: 0;
      transform: translate(300px, -18px);
      width: 360px;
    }
    &--img3-container {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 150px;
      transform: translate(-85px, 12px);
    }
  }
  &__footer {
    padding: 20px 0;
    position: relative;
    background-color: #ececff;
    &:before {
      content: "";
      display: block;
      top: -60px;
      left: 0;
      width: 100%;
      height: 80px;
      position: absolute;
      background-size: cover;
      background-image: url("../../assets/parent-registration/bg-footer.png");
      background-repeat: no-repeat;
    }
    &--content {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 1.1rem;
        color: #897b9f;
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
      a {
        display: inline-block;
        position: relative;
        font-weight: 700;
        color: #6f75da;
        padding-bottom: 3px;
      }
      a:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        background-color: #6f75da;
        width: 100%;
        height: 2px;
        transition: 0.3s linear;
      }
      a:hover:before {
        width: 0;
      }
    }
  }
}

@media (max-width: 1480px) {
  .parent-registration__content-3:after {
    display: none;
  }
}

@media (max-width: 1400px) {
  .parent-registration__header--container,
  .parent-registration__header--logo,
  .parent-registration__content-1--body {
    max-width: 1030px;
  }
  .parent-registration__header--text-1,
  .parent-registration__content-1--header {
    font-size: 1.85rem;
  }
  .parent-registration__content-3--header {
    font-size: 1.65rem;
  }
  .parent-registration__content-1--pointers-title-main,
  .parent-registration__content-1--testimonials-header h2,
  .parent-registration__form-title,
  .parent-registration__content-2--header-text {
    font-size: 1.6rem;
  }
  .parent-registration__form .form-btn,
  .parent-registration__content-1--testimonial-left .testimonial--name {
    font-size: 1.1rem;
  }
  .parent-registration__form-text,
  .parent-registration__content-1--testimonial-left .testimonial--level,
  .parent-registration__content-1--testimonial-right {
    font-size: 1.05rem;
  }
  .parent-registration__header--text-2,
  .parent-registration__content-1--pointers-list li,
  .parent-registration__form input,
  .parent-registration__content-2--list li,
  .parent-registration__footer--content p {
    font-size: 0.95rem;
  }
  .parent-registration__form .checkbox-custom+label,
  .parent-registration__form input {
    font-size: 0.85rem;
  }
  .error-message {
    font-size: 0.8rem;
  }

  .parent-registration__content-2--list li,
  .parent-registration__content-1--testimonial-right,
  .error-message {
    line-height: 1.4rem;
  }
  .parent-registration__content-1--testimonial-left .testimonial--name {
    line-height: 1.2rem;
  }

  .parent-registration__content-1--pointers-list li:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  .parent-registration__content-1--pointers-list li {
    margin-right: 35px;
  }
  .parent-registration__form-text {
    margin-bottom: 1.25rem;
  }
  .parent-registration__content-3--header {
    margin-bottom: 45px;
  }
  .parent-registration__content-1--testimonials-header,
  .parent-registration__form-title {
    margin-bottom: 1rem;
  }
  .parent-registration__form-wrapper {
    padding: 2.25rem 1.25rem;
    max-width: 400px;
  }
  .parent-registration__form .form-btn {
    padding: 8px;
  }
  .parent-registration__content-1 {
    padding: 3.5rem 0 7rem;
  }
  .parent-registration__content-3 {
    padding: 60px 0 90px;
  }
  .parent-registration__header--img {
    max-width: 380px;
  }
  .parent-registration__content-1--pointers-list li {
    max-width: 455px;
  }
  .parent-registration__content-2--list div {
    max-width: 55px;
  }
  .parent-registration__content-2--header-text{
    max-width: 500px;
  }
  .parent-registration__content-1--testimonials,
  .parent-registration__content-1--testimonial-content {
    max-width: 540px;
  }
  .parent-registration__form--img1-container {
    width: 310px;
    transform: translate(35px, -115px);
  }
  .parent-registration__form--img2-container {
    width: 350px;
  }
  .parent-registration__form--img3-container {
    width: 140px;
  }
  .parent-registration__content-2--header:before {
    height: 210px;
    left: -60px;
  }
  .parent-registration__footer:before {
    height: 50px;
    top: -45px;
  }
}
@media (max-width: 1224px) {
  .parent-registration__content-1:after {
    right: -220px;
  }
}
@media (max-width: 1200px) {
  .parent-registration__content-1--body {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    &-left {
      display: flex;
      margin-top: 4rem;
    }
  }
  .parent-registration__content-1--pointers-list {
    margin-left: 0;
    li:before {
      margin-right: 20px;
    }
  }
  .parent-registration__content-1--testimonials {
    max-width: 480px;
    margin-top: 0;
  }
  .parent-registration__content-1--testimonial-content {
    max-width: 480px;
  }
  .parent-registration__content-1--testimonial-right {
    max-width: 330px;
  }
  .parent-registration__content-1--body-right {
    width: 100%;
    align-items: center;
    .parent-registration__form-wrapper {
      width: 100%;
      max-width: 100%;
    }
    .parent-registration__form {
      align-items: center;
      input {
        max-width: 95%;
      }
      .checkbox-container {
        max-width: 100%;
        text-align: center;
      }
      .form-btn {
        width: 700px;
      }
    }
  }
  .parent-registration__form--img1-container {
    transform: translate(30px, -95px);
  }
  .parent-registration__content-1:after,
  .parent-registration__content-1:before {
    top: 7%;
  }
  .parent-registration__content-1--decor:before {
    top: 5%;
  }
}
@media (max-width: 1100px) {
  .parent-registration__content-1--body-right .parent-registration__form-wrapper {
    width: 95%;
  }
  .parent-registration__content-1--body-left {
    flex-direction: column;
  }
  .parent-registration__form--img1-container {
    transform: translate(40px, -70px);
  }
  .parent-registration__content-1:after {
    right: -300px;
  }
  .parent-registration__content-1--body-left {
    margin-top: 3rem;
    width: 90%;
  }
  .parent-registration__content-1--pointers {
    margin-bottom: 2.5rem;
    &-list li {
      max-width: 100%;
    }
  }
  .parent-registration__content-1--testimonials {
    max-width: 100%;
  }
  .parent-registration__content-1--testimonial-grp {
    width: 100%;
  }
  .parent-registration__content-1--testimonial-content {
    max-width: 100%;
    width: 100%;
  }

  .parent-registration__content-1--testimonial-right {
    max-width: 80%;
    padding: 1.5rem 3rem;
  }
  .parent-registration__content-1 {
    background-size: 0px;
  }
  .parent-registration__content-2--list {
    max-width: 95%;
    justify-content: center;
  }
}

@media (max-width: 1000px) {
  .parent-registration__header--logo {
    margin-left: 0.75rem;
  }
  .parent-registration__header--container {
    max-width: 95%;
  }
  .parent-registration__form--img2-container {
    width: 300px;
    transform: translate(250px, -18px);
  }
}
@media (max-width: 950px) {
  .parent-registration__content-2--header {
    background-size: 100% 90%;
    width: 710px;
    &-text {
      margin-left: 7rem;
    }
  }
}
@media (max-width: 900px) {
  .parent-registration__content-1--body-right .parent-registration__form {
    .checkbox-container {
      max-width: 372px;
      text-align: left;
    }
    .form-btn {
      max-width: 95%;
    }
  }
    .parent-registration__content-2--header {
  }
  .parent-registration__content-2--header-text {
  }
  .parent-registration__content-2--header:before {
  }
  .parent-registration__form {
    &--img1-container,
    &--img2-container {
      display: none;
    }
  }
  .parent-registration__content-1--decor:before,
  .parent-registration__content-3:before,
  .parent-registration__content-3--decor:before {
    display: none;
  }
  .parent-registration__header--container {
    flex-direction: column;
  }
  .parent-registration__header--text-1 {
    margin-top: 0.5rem;
  }
  .parent-registration__content-1--header {
    margin-bottom: 2rem;
  }
  .parent-registration__content-1 {
    padding: 2.5rem 0 5rem;
  }
  .parent-registration__content-1--body-left {
    margin-top: 2.5rem;
  }
  .parent-registration__content-1:after {
    top: 3%;
  }
  .parent-registration__content-1--testimonial-right {
    padding: 1rem 1.5rem;
    max-width: 75%;
  }
  .parent-registration__header--logo img {
    width: 150px;
  }
  .parent-registration__header--text-1,
  .parent-registration__content-1--header {
    font-size: 1.55rem;
  }
  .parent-registration__content-3--header,
  .parent-registration__form-title {
    font-size: 1.35rem;
  }
  .parent-registration__content-1--pointers-title-main,
  .parent-registration__content-1--testimonials-header h2,
  .parent-registration__content-2--header-text {
    font-size: 1.3rem;
  }
  .parent-registration__content-1--testimonials-header .slider-paginator .slider-count {
    font-size: 1rem;
  }
  .parent-registration__form .form-btn,
  .parent-registration__content-1--testimonial-left .testimonial--name {
    font-size: 0.85rem;
  }
  .parent-registration__form-text,
  .parent-registration__content-1--testimonial-left .testimonial--level,
  .parent-registration__content-1--testimonial-right {
    font-size: 0.8rem;
  }
  .parent-registration__header--text-2,
  .parent-registration__content-1--pointers-list li,
  .parent-registration__form input,
  .parent-registration__content-2--list li,
  .parent-registration__footer--content p,
  .parent-registration__form .checkbox-custom+label,
  .parent-registration__form input,
  .error-message {
    font-size: 0.8rem;
  }
  .parent-registration__header--text-2 {
    max-width: 350px;
    margin: 0 auto;
    line-height: 1.4rem;
    margin-top: 0.25rem;
  }
  .parent-registration__content-1--body-right .parent-registration__form-wrapper,
  .parent-registration__content-1--body-left {
    max-width: 600px;
  }
  .parent-registration__form-text {
    margin-bottom: 0.75rem;
  }
  .parent-registration__form-title {
    margin-bottom: 0.5rem;
  }
  .parent-registration__content-2--header {
    background-size: 95% 85%;
    width: 600px;
    margin-bottom: -1.5rem;
    &:before {
      height: 190px;
      left: -40px;
    }
    &-text {
      margin-left: 6.5rem;
      max-width: 400px;
      margin-top: -20px;
    }
  }
  .parent-registration__content-2 {
    padding-bottom: 2.5rem;
    &--list {
      li {
        width: 130px;
      }
      div {
        max-width: 50px;
      }
    }
  }
  .parent-registration__content-3 {
    padding: 40px 0 60px;
    &--header {
      margin-bottom: 1.5rem;
    }
  }
  .parent-registration__footer {
    padding: 0 0 0.5rem 0;
  }
}
@media (max-width: 780px) {
  .parent-registration__content-1:after {
    right: -400px;
  }
}
@media (max-width: 736px) {
  .parent-registration__header--text-1,
  .parent-registration__content-1--header {
    font-size: 1.45rem;
  }
  .parent-registration__content-3--header,
  .parent-registration__form-title {
    font-size: 1.25rem;
  }
  .parent-registration__content-2--header {
    background-size: 95% 60%;
    width: 400px;
    margin-bottom: -4.5rem;
    &:before {
      height: 120px;
      left: 0px;
      bottom: 75px;
    }
    &-text {
      font-size: 1rem;
      margin-top: -3.25rem;
      margin-left: 5.5rem;
      max-width: 250px;
    }
  }
  .parent-registration__content-3--header {
    font-size: 1rem;
    .gradient {
      margin-bottom: 0;
    }
  }
  .parent-registration__content-2--list {
    max-width: 100%;
    li {
      font-size: 13px;
      margin: 0.5rem 0;
      p {
        line-height: 1rem;
      }
    }
    div {
      max-width: 40px;
      height: 40px;
      margin-bottom: 0.5rem;
    }
  }
  .parent-registration__content-2 {
    padding-bottom: 1rem;
  }
  .parent-registration__header--img {
    max-width: 340px;
  }
}
@media (max-width: 650px) {
  .parent-registration__footer--content {
    flex-direction: column;
    a:before {
      bottom: 7px;
    }
  }
}

@media (max-width: 600px) {
  .parent-registration__content-1:after {
    right: -550px;
    top: 2%;
  }
  .parent-registration__content-1--pointers-list li {
    margin-right: 0;
    line-height: 1.2rem;
  }
  .parent-registration__content-1--testimonial-right {
    line-height: 1.2rem;
    padding: 0.75rem 1rem;
  }
  .parent-registration__header--img {
    max-width: 300px;
  }
  .parent-registration__form-wrapper {
    padding: 1.5rem .5rem;
  }
  .parent-registration__content-1--body-right {
    .parent-registration__form {
      input {
        max-width: 100%;
        padding: 0.2rem 0 0.2rem 3rem;
      }
      .form-btn {
        padding: 0.2rem;
        max-width: 100%;
      }
    }
  }
  .parent-registration__form--img3-container {
    display: none;
  }
  .parent-registration__form-wrapper {
    width: 90%;
    max-width: 600px;
  }
  .parent-registration__form {
    input {
      max-width: 100%;
      padding: 0.2rem 0 0.2rem 3rem;
    }
    .form-btn {
      padding: 0.2rem;
      max-width: 100%;
    }
  }
}
@media (max-width: 530px) {
  .parent-registration__content-1--testimonial-right {
    max-width: 65%;
  }
  .parent-registration__header--text-1,
  .parent-registration__content-1--header {
    font-size: 1.4rem;
  }
  .parent-registration__header--text-2 {
    font-size: 0.75rem;
    line-height: 1.2rem;
    margin-top: 0.4rem;
  }
  .parent-registration__content-1 {
    padding: 1.75rem 0 4rem;
  }
  .parent-registration__content-1--header {
    margin-bottom: 1.5rem;
  }
  .parent-registration__content-1--pointers {
    margin-bottom: 2.25rem;
  }
}
@media (max-width: 490px) {
  .parent-registration__content-1--body-left {
    width: 95%;
  }
  .parent-registration__content-3--header {
    justify-content: center;
    align-items: center;
    .content-3--header-1 {
      width: 250px;
      text-align: center;
      line-height: 1.2rem;
    }
    .content-3--header-2 {
      font-size: 0.8rem;
    }
  }
  .parent-registration__content-1--testimonials-header .slider-paginator {
    .slider-count {
      font-size: 0.9rem;
      min-width: 60px;
    }
    .button-next {
      margin-left: 0.2rem;
    }
    .button-prev {
      margin-right: 0.2rem;
    }
  }
}
</style>
