import { render, staticRenderFns } from "./QuizQuestionComponent.vue?vue&type=template&id=e98c72fe&scoped=true&"
import script from "./QuizQuestionComponent.vue?vue&type=script&lang=js&"
export * from "./QuizQuestionComponent.vue?vue&type=script&lang=js&"
import style0 from "./QuizQuestionComponent.vue?vue&type=style&index=0&id=e98c72fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e98c72fe",
  null
  
)

export default component.exports