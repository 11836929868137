import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import * as mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';
import {library} from './modules/library';
import {quiz} from './modules/quiz';
import {multiplayer} from './modules/multiplayer';
import {pet} from './modules/pet';
import {raid} from './modules/raid';
import {widgets} from './modules/widgets';
import {kingdom} from './modules/kingdom';
import {learningTrackModule} from './modules/learningtrack';
import {dailyChallengeModule} from './modules/dailychallenge';
import {guidedReadingModule} from './modules/guidedreading';
import {dynamicBookModule} from './modules/dynamicbook';
import {analyticsModule} from './modules/analytics';
import {compositionModule} from './modules/composition';
import {voiceBotModule} from './modules/voicebot';
import {grammarModule} from './modules/grammar';

Vue.use(Vuex);

// https://tahazsh.com/vuebyte-reset-module-state
const getDefaultState = () => {
  return {
    authenticated: false,
    fetchingInitialData: true,
    studentId: -1,
    studentName: '',
    studentType: '',
    subject: '',
    parentEmail: '',
    diamonds: 0,
    // remainingDailyAttempts: {},
    coins: -1,
    avatar: '',
    level: '',
    isStoreEnabled: true,
    listLevelChapters: [],
    // ranges from 0-2, where 2 is the hardest (as of 17th Oct 2023)
    currentDifficulty: 2,
    siblings: [],
    missions: [],
    challenges: [],
    coinTransactions: {},
    chineseQuizStars: {},
    totalKnownWords: 0,
    loginStreak: {},
    watchedCompoLessonVideos: {},
    watchedPrimarySchoolLessonVideos: {},
    watchedPreschoolLessonVideos: {},
    // newly added to replace notifications as of 17 april 2024
    usedFeatures: {},
    // to be removed
    notifications: [],
    vocabSubscription: {},
    englishSubtitlesForLibrary: false,
    initialRoute: null,
    isWebApp: false,
    quests: {},
    isSidebarShown: false, // kiv remove in future?
    isShowNavBottom: true, // kiv remove in future?
    isOldNavShown: true,
    isWidgetsShown: true, // kiv remove in future?
    isMobileNavMoreMenuShown: false, // this controls whether mobile more menu is shown
    // controls the boshipanda icon and related functions
    isChallengesPopupShown: false,
    cocos: {},
    masteryMode: {},
    isMoreRoutesShown: false, // for mobile nav more routes

    // for registration
    registration: {},

    // controls app.vue loading screen
    // should be true by default, and is set to false after initialData is loaded in profile main
    showGlobalLoadingScreen: true,
    // should contain a dictionary with two keys - name and callback
    // nameis string that shows component-name, if blankthen don't show any thing
    // callback field is callback function
    fullScreenModalComponentDetails: null,
    timeLastParentAuthenticated: null,
  };
};

const state = getDefaultState();

export const store = new Vuex.Store({
  state,
  mutations: {
    ...mutations,
    resetStateMutation(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions,
  getters,
  modules: {
    library,
    quiz,
    widgets,
    learningTrack: learningTrackModule,
    dailyChallenge: dailyChallengeModule,
    dynamicBook: dynamicBookModule,
    guidedReading: guidedReadingModule,
    analytics: analyticsModule,
    grammar: grammarModule,
    multiplayer,
    raid,
    pet,
    kingdom,
    composition: compositionModule,
    voiceBot: voiceBotModule,
  },
  plugins: [createPersistedState({
    // Specify only these state properties to persist
    paths: ['initialRoute', 'subject'],
  })],
});
