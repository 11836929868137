<template>
  <div v-if="show" class="energy-gained-popup__component animated fadeIn faster">
    <div class="energy-gained-popup__container animated bounceIn faster">
      <div class="energy-gained-popup__content">
        <h3>Energy Gained!</h3>
        <div class="energy-icon">
          <i class="fa fa-bolt"></i>
        </div>
        <div class="energy-amount">+{{ energyGained }}</div>
        <p>You've earned {{ energyGained }} energy from your voicebot sessions!</p>
      </div>
      <i
        @click="closePopup"
        class="fas fa-times energy-gained-popup__close--icon"></i>
      <div
        @click="closePopup"
        class="energy-gained-popup__close--btn">
        Let's use it!
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'EnergyGainedPopup',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('kingdom', ['getResource']),
    energyGained() {
      return this.getResource('energy_gained_since_last_visit') || 0;
    },
  },
  methods: {
    closePopup() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  .energy-gained-popup {
    &__component {
      position: fixed;
      background: rgba(0,0,0,.85);
      height: 100vh;
      width: 100vw;
      z-index: 50;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__container {
      position: relative;
      max-width: 450px;
      text-align: center;
      padding: 2rem 3rem;
      background: #fff;
      color: #222;
      border-radius: 5px;

      h3 {
        color: #FF9800;
        font-size: 1.8rem;
        margin-bottom: 1rem;
      }

      .energy-icon {
        font-size: 2.5rem;
        color: #FF9800;
        margin-bottom: 0.5rem;
      }

      .energy-amount {
        font-size: 3rem;
        font-weight: bold;
        color: #FF9800;
        margin-bottom: 1rem;
      }

      p {
        margin-bottom: 1rem;
      }
    }
    &__close {
      &--icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        color: #ccc;
        font-size: 1.2rem;
      }
      &--btn {
        padding: .5rem 1rem;
        border: 4px solid #000;
        background: #FF9800;
        border-radius: 5px;
        margin: 1rem auto 0 auto;
        max-width: 150px;
        color: #fff;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
</style>
