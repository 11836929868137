<template>
  <block-container
    :title="title"
    subtitle="Voicebot"
    fa-icon="comments"
    >
    <div class="body-content">
      <div class="body-content--container">
        <div
          v-for="topic in topics"
          :key="topic.id"
          class="body-content--item"
          @click="handleTopicClick(topic)">
          <div class="body-content--item--title">
            <span v-if="subject === 'chinese'">{{ topic.title }}</span>
            <span v-if="subject === 'malay'">{{ topic.malay_title }}</span>
          </div>
          <div class="body-content--item-icon">
            <img :src="topic.thumbnail_image" alt="">
          </div>
          <div class="body-content--item-info">
            <div class="body-content--item-info--tags">
              <!-- <span class="oral">口试</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </block-container>
</template>

<script>
import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

import BlockContainerComponent
  from '../../common/BlockContainerComponent.vue';

export default {
  name: 'NewVoicebotIndex',
  components: {
    BlockContainer: BlockContainerComponent,
  },
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    ...mapState(['subject']),
    ...mapState('voiceBot', ['topics']),
    title() {
      return this.subject === 'chinese' ? 'AI交谈' : 'Speech King';
    },
  },
  mounted() {
    // Pull data if topics have not been loaded yet
    if (this.topics.length === 0) {
      this.pullData();
    }
  },
  methods: {
    ...mapMutations('voiceBot', ['setSelectedTopicId', 'setTopics']),
    pullData() {
      console.log('pulling data');
      const url = `/voicebot/topics/?subject=${this.subject}`;

      axios
          .get(url)
          .then((response) => {
            console.log(response.data);
            this.setTopics(response.data);
            // this.setComprehensionId(response.data['id']);
            // this.setEntityData(response.data['hydrated_text']);
            // this.questions = response.data['questions'];
            // console.log(this.questions);
          });
    },
    handleTopicClick(topic) {
      this.setSelectedTopicId(topic.id);
      this.$router.push({
        name: 'new-oral-voicebot',
        params: {topicId: topic.id},
      });
    },
  },
};
</script>


<style lang="scss" scoped>
  .body-content {
    height: 100%;
    width: 100%;
    padding: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    text-align: center;
    &--container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
    }
    &--item {
      background: #fff;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;
      text-align: center;
      border-radius: .25rem;
      text-decoration: none;
      transition: all 0.2s;
      position: relative;
      height: 250px;
      padding-top: .15rem;
      overflow: hidden;
      &:hover {
        box-shadow: 0 0px 1px 7px #00ffd8;
      }
      &--title {
        color: #6b6b6b;
        text-align: left;
        font-weight: 600;
        margin-left: .5rem;
      }
      &-icon {
        height: 85%;
        overflow: hidden;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s;
        img {
          height: auto;
          width: 150%;
        }
      }
      &-info {
        background: #fff;
        border-bottom-right-radius: .5rem;
        border-bottom-left-radius: .5rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 1rem;
        color: #6b6b6b;
        padding-top: .5rem;
        position: relative;
        justify-content: space-between;
        padding: .25rem .5rem;
        &--tags {
          color: #fff;
          font-weight: 800;
          font-size: .7rem;
          .vocab {
            background: #e98a24;
            padding: .1rem .5rem;
            border-radius: .25rem;
          }
          .assessment {
            background: #04cb34;
            padding: .1rem .5rem;
            border-radius: .25rem;
          }
          .zucichengju {
            background: #6f4fff;
            padding: .1rem .5rem;
            border-radius: .25rem;
          }
          .bianzi {
            background: #2bb5d8;
            padding: .1rem .5rem;
            border-radius: .25rem;
          }
          .oral {
            background: #ff4f8f;
            padding: .1rem .5rem;
            border-radius: .25rem;
          }
        }
        &--credits {
          font-size: .9rem;
          i {
            margin-right: .25rem;
          }
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .bg-avatar {
    background-image: url("../../../../assets/profile/btn-avatar.jpg");
  }
  .bg-petbattle {
    background-image: url("../../../../assets/profile/btn-petbattle.jpg");
  }

  .bg-fishing {
    background-image: url("../../../../assets/profile/btn-fishing.jpg");
  }
  .bg-tree {
    background-image: url("../../../../assets/profile/btn-tree.jpg");
  }


  @media only screen and (max-width: 1560px) {
    .body-content {
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .body-content {
      &--container {
        grid-template-columns: 1fr 1fr;
      }
      &--item {
      }
      &-text {
        font-size: .9rem;
      }
    }
  }

  @media only screen and (max-width: 1150px),
  screen and (max-height: 690px) {
  }
  @media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
    .body-content {
      background: none;
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: 690px) {
    .body-content {
      padding: 1.5rem
    }
  }
  @media only screen and (max-width: 580px) {
    .body-content {
      &--container {
        grid-template-columns: 1fr;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .body-content {
      padding: .75rem;
      gap: .75rem;
    }
    .body-content--item-icon {
      padding: .5rem;
      i {
        font-size: 2.5rem;
      }
    }
    .body-content--item-text {
      font-size: .8rem;
    }
  }
  @media only screen and (max-width: 430px) {
    .body-content--item {
      flex-direction: column-reverse;
      padding-top: 0;
      &-info {
        display: none;
      }
      &-icon {
        border: 6px solid #fff;
        border-radius: .5rem;
      }
    }
  }
  @media only screen and (max-width: 400px) {
    .body-content--item-info {
      padding: .25rem;
    }
  }
</style>
