<template>
  <div v-if="info" class="container">
    <!-- <span class="chinese-character">{{ info.revised }}</span>
    <br> -->
    <span
      v-for="(entry, index) in filteredRevisedWithHighlights"
      :key="`revised_${index}`"
      :class="{'add': entry.operation === '+', 'delete': entry.operation === '-', 'chinese-character': subject === 'chinese', 'english-character': subject === 'english'}"
      class="test"
      v-html="getWord(entry, filteredRevisedWithHighlights[index + 1] || null)"
      @click="handleCharacterClick(entry, index)"
    >
    </span>
    <span v-if="info.elaboration !== ''" class="elaboration-highlight chinese-character">{{ info.elaboration }}</span>
    <!-- idioms feedback explanation -->
    <p v-if="typeof info.english_explanation !== 'undefined' && info.english_explanation !== ''" class="idioms-correction-explanation">
      <i class="fa-solid fa-arrow-turn-up"></i>
      <span>
        {{ info.english_explanation }}
      </span>
    </p>
  </div>
</template>

<script>

export default {
  name: 'LanguageFeedback',
  props: {
    info: Object,
    subject: String,
    showDeletedWords: Boolean,
  },
  data() {
    return {
      idiomCorrections: true,
    };
  },
  mounted() {
    console.log('info', this.info);
  },
  computed: {
    filteredRevisedWithHighlights() {
      if (!this.info) return [];
      if (this.showDeletedWords) return this.info.revisedWithHighlights;
      return this.info.revisedWithHighlights.filter((w) => {
        return w.operation !== '-';
      });
    },
    hasRevisedWithElaborations() {
      if (!this.info) return '';
      return typeof this.info['revisedWithElaborations'] !== 'undefined' && this.info['revisedWithElaborations'] !== '';
    },
  },
  methods: {
    getWord(currentEntry, nextEntry) {
    // If the current token is part of a char-level diff, don't append extra space.
      if (currentEntry.is_char) {
        return currentEntry.is_end_of_word ? currentEntry.word + ' ' : currentEntry.word;
      }
      // For Chinese, just return the word as spacing is handled differently.
      if (this.subject === 'chinese' || !nextEntry) return currentEntry.word;
      if (this.subject === 'malay' || this.subject === 'english') {
        const punctuationNoSpaceBefore = [',', '.', '!', '?', ':', ';', ')', ']', '}'];
        const punctuationNoSpaceAfter = ['(', '[', '{'];
        const result = currentEntry.word;

        // Check if the next entry is a character-level token. If yes, do not add whitespace,
        // because that token is likely part of the same logical word.
        if (nextEntry && nextEntry.is_char) {
          return nextEntry.is_start_of_word ? result + ' ' : result;
        }

        // Don't add space if next word is punctuation that shouldn't have space
        if (nextEntry && punctuationNoSpaceBefore.includes(nextEntry.word)) {
          return result;
        }

        // Don't add space if current word is punctuation that shouldn't have space after
        if (punctuationNoSpaceAfter.includes(currentEntry.word)) {
          return result;
        }

        // Add space for all other cases
        return result + ' ';
      }
    },
    checkWordCount(searchText, sentence) {
      const regex = new RegExp(searchText, 'g');
      const matches = sentence.match(regex);
      return !matches ? 0 : sentence.match(regex).length;
    },
    handleCharacterClick(entry, index) {
      this.$emit('character-click', entry, index);
    },
  },
};
</script>

<style lang="scss" scoped>
  .container {
    flex: 1;
    border: 2px solid #e7e7e7;
    border-radius: .5rem;
    padding: .75rem;
    font-size: 1.25rem;
    display: block;
    outline: none;
    resize: none;
    margin-bottom: .5rem;
    overflow: scroll;
  }

  .english-character {
    font-size: 1rem;
  }

  .elaboration-highlight {
    background-color: #fff2e6;
    color: #cc6600;
  }

  .test {
    display: inline-block;
    white-space: pre-wrap;
    cursor: pointer;
  }

  .add {
    color: #54d1b7;
    font-weight: bold;
  }

  .delete {
    color: #e26e7c;
    font-weight: bold;
    text-decoration: line-through double;
  }
  .idioms-correction-explanation {
    border-top: 2px solid #e7e7e7;
    text-align: justify;
    font-size: .9rem;
    padding: 1rem .5rem;
    i {
      transform: rotate(90deg);
      margin-right: .5rem;
    }
    padding-bottom: 0;
    margin-top: .5rem;
  }

</style>
