var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"question-tracker-buttons-carousel"},[_c('div',{staticClass:"question-tracker-label"},[_vm._v("Question Select:")]),_vm._v(" "),_c('div',{staticClass:"carousel-container"},[(_vm.totalQuestions > _vm.maxVisibleButtons)?_c('div',{staticClass:"carousel-nav-arrow",class:{ 'inactive': _vm.startIndex <= 1 },on:{"click":function($event){_vm.startIndex > 1 && _vm.navigateCarousel('left')}}},[_c('i',{staticClass:"fa-solid fa-caret-left"})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"question-buttons-container"},[_c('div',{staticClass:"question-buttons-wrapper",style:({
          transform: ("translateX(" + _vm.translateX + "px)"),
          width: _vm.wrapperWidth
        })},_vm._l((_vm.totalQuestions),function(index){return _c('div',{key:index,class:[
            'question-button',
            {'correct': _vm.questionStatuses[index - 1] === 'correct'},
            {'incorrect': _vm.questionStatuses[index - 1] === 'incorrect'},
            {'unanswered': _vm.questionStatuses[index - 1] === 'unanswered'},
            {'active': _vm.currentQuestionIndex === index - 1}
          ],on:{"click":function($event){return _vm.handleQuestionClick(index - 1)}}},[(_vm.questionStatuses[index - 1] === 'correct')?[_c('i',{staticClass:"fa-solid fa-check"})]:(_vm.questionStatuses[index - 1] === 'incorrect')?[_c('i',{staticClass:"fa-solid fa-xmark"})]:[_c('span',[_vm._v(_vm._s(index))])]],2)}),0)]),_vm._v(" "),(_vm.totalQuestions > _vm.maxVisibleButtons)?_c('div',{staticClass:"carousel-nav-arrow",class:{ 'inactive': _vm.endIndex >= _vm.totalQuestions },on:{"click":function($event){_vm.endIndex < _vm.totalQuestions && _vm.navigateCarousel('right')}}},[_c('i',{staticClass:"fa-solid fa-caret-right"})]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }