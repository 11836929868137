<template>
  <div v-if="drillSentences.length > 0" class="writing-drills__component">
    <div class="writing-drills__content--main">
      <div class="writing-drills__content--header">
        <div class="header-content">
          <div class="header-left">
            <button @click="goBack" class="back-button">
              <i class="fa-solid fa-chevron-left"></i>
            </button>
            <h2>{{ currentPattern ? currentPattern.name : 'Writing Drill' }}</h2>
          </div>
          <p>{{ currentPattern ? currentPattern.description : 'Improve your writing by using descriptive words.' }}</p>
        </div>
        <div class="writing-drills__content--progress">
          <question-tracker-buttons-carousel
            v-if="drillSentences.length > 0"
            :total-questions="drillSentences.length"
            :current-question-index="currentDrillIndex"
            :question-statuses="questionStatusArray"
            :on-question-click="handleQuestionButtonClick"
          />
        </div>
      </div>
      <div class="writing-drills__content--sentence" v-if="currentDrill">
        <span class="chinese-character" v-html="renderSentenceWithBlanks(currentDrill)"></span>
      </div>
      <div class="writing-drills__content--controls">
        <!-- Inline editor component - removed transition wrapper -->
        <inline-editor
          class="inline-editor"
          v-if="showInlineEditor"
          :initial-value="currentBlankInfo.value"
          :blank-index="currentBlankIndex"
          :words-to-avoid="currentDrill.wordsToAvoid"
          :min-length="getCurrentBlankConstraint().min_length"
          :hint="currentHint"
          @word-submitted="handleFilledWord"
          @input-change="handleInputChange"
          @validation-error="handleValidationError"
          @cancel="closeInlineEditor"
        />
        <!-- Actions -->
        <div v-if="!showInlineEditor && !showResultsModal" class="writing-drills__content--actions">
          <!-- <button v-if="drillState === 0" class="drill-example-btn" @click="showExample(currentDrillIndex)">
            <i class="fa-solid fa-lightbulb"></i>
            Show Example
          </button> -->
          <div class="drill-instructions" v-if="!fetching && !hasFilledAllBlanks(currentDrill) && showDrillInstructions">
            [ Tap on the blank(s) to fill in your answer ]
          </div>
          <button
            v-if="!fetching && hasFilledAllBlanks(currentDrill) && !currentDrill.completed"
            class="drill-action-btn"
            @click="manuallyShowScore"
          >
            <i class="fa-solid fa-check"></i>
            Check Answer
          </button>
          <button
            v-if="!fetching && currentDrill.completed"
            class="drill-example-btn"
            @click="redoCurrentDrill"
          >
            Redo Question
          </button>
          <!-- if loading -->
          <div v-if="fetching"
            class="loading">
            <div class="loading-anim"></div>
            <p>Analyzing...</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Results Modal - only shown when explicitly triggered -->
    <modal
      :show="showResultsModal"
      title=""
      closeButtonText="Ok"
      min-width="300px"
      max-width="500px"
      @close="closeResultsModal"
    >
      <div class="results-content">
        <div class="result-icon" :class="{'correct': isCorrect, 'incorrect': !isCorrect}">
          <i v-if="isCorrect" class="fa-solid fa-circle-check"></i>
          <i v-else class="fa-solid fa-circle-xmark"></i>
        </div>
        <div v-html="formattedResultsMessage"></div>
      </div>
    </modal>

    <!-- error Modal -->
    <modal
      :show="showErrorModal"
      title="Try Again"
      closeButtonText="Ok"
      min-width="300px"
      max-width="500px"
        @close="showErrorModal = false"
      >
        <p v-html="errorModalMessage"></p>
      </modal>

    <!-- All drills completed modal -->
    <modal
      :show="showDrillsCompletedModal"
      title="Drills Completed!"
      closeButtonText="Claim Reward"
      min-width="300px"
      max-width="500px"
      @close="claimDrillReward"
    >
      <p>You've completed all writing drills. Claim your reward!</p>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import {mapMutations} from 'vuex';

import ModalComponent from '../../common/ModalComponent.vue';
import InlineEditorComponent from './InlineEditorComponent.vue';
import QuestionTrackerButtonsCarouselComponent from '../../common/QuestionTrackerButtonsCarouselComponent.vue';

export default {
  name: 'WritingDrillsComponent',
  components: {
    Modal: ModalComponent,
    InlineEditor: InlineEditorComponent,
    QuestionTrackerButtonsCarousel: QuestionTrackerButtonsCarouselComponent,
  },
  props: {
    // Optional prop to specify a pattern ID to filter drills
    patternId: {
      type: Number,
      default: null,
    },
    // Optional prop to limit the number of drills
    maxDrills: {
      type: Number,
      default: 10,
    },
    questionStatuses: {
      type: Array,
      required: false,
      default: () => [],
      validator: (value) => {
        return value.every((status) => {
          return ['correct', 'incorrect', 'unanswered'].includes(status);
        });
      },
    },
  },
  data() {
    return {
      fetching: false,
      drillSentences: [],
      showInlineEditor: false,
      currentDrillIndex: 0,
      currentBlankIndex: 0,
      currentBlankInfo: {
        prefix: '',
        suffix: '',
        value: '',
      },
      isCorrect: false,
      drillState: 0, // 0: selection, 1: loading, 2: feedback
      allDrillsCompleted: false,
      showResultsModal: false,
      showErrorModal: false,
      errorModalMessage: '',
      resultsModalMessage: '',
      showDrillsCompletedModal: false,
      numWrongAttempts: 0,
      hints: [],
      // Track last incorrect answer to prevent redundant submissions
      lastIncorrectAnswer: null,
      tempInputValue: '', // Add this to store temporary input
      showDrillInstructions: true,
    };
  },
  computed: {
    currentDrill() {
      return this.drillSentences[this.currentDrillIndex] || null;
    },
    currentPattern() {
      return this.currentDrill.pattern || null;
    },
    formattedResultsMessage() {
      // Handle array of paragraphs
      if (Array.isArray(this.resultsModalMessage)) {
        return this.resultsModalMessage.map((paragraph) => `<p>${paragraph}</p>`).join('');
      }
      // Handle string (for backwards compatibility)
      return `<p>${this.resultsModalMessage}</p>`;
    },
    // Add computed property for question statuses
    questionStatusArray() {
      return this.drillSentences.map((drill) => {
        return drill.status;
      });
    },
    // Add computed property for currentHint
    currentHint() {
      // If no wrong attempts or no hints, don't show a hint
      if (this.numWrongAttempts === 0 || !this.hints || this.hints.length === 0) {
        return '';
      }

      // Find hint for the current blank index
      const hintForCurrentBlank = this.hints[this.currentBlankIndex];
      if (hintForCurrentBlank) {
        return hintForCurrentBlank;
      }
      return '';
    },
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    // Fetch drills from the backend
    async fetchDrills() {
      try {
        // Build the URL with optional pattern filter
        const url = '/writing/drills/';
        const params = {};
        if (this.patternId) {
          params.pattern = this.patternId;
        }
        const response = await axios.get(url, {params});
        // Transform the API response to match our component's data structure
        const drills = response.data.map((drill) => ({
          id: drill.id,
          raw_text: drill.raw_text,
          pattern: drill.pattern,
          examples: this.extractExamplesFromDrill(drill),
          constraints: drill.active_constraints,
          wordsToAvoid: drill.words_to_avoid,
          filledBlanks: [],
          completed: false,
          status: 'unanswered',
        }));

        // Limit the number of drills if maxDrills is set
        this.drillSentences = drills.slice(0, this.maxDrills);
      } catch (error) {
        console.error('Error fetching writing drills:', error);
      } finally {
        this.setShowGlobalLoadingScreen(false);
      }
    },
    // Extract examples from the drill's examples JSON structure
    extractExamplesFromDrill(drill) {
      // If no examples, return empty array
      if (!drill.examples || Object.keys(drill.examples).length === 0) {
        return [];
      }

      // Get the first difficulty level's examples (e.g., "basic")
      const firstLevel = Object.keys(drill.examples)[0];
      const examplesForLevel = drill.examples[firstLevel];

      // For single blank drills, return the first example for each blank
      if (examplesForLevel && Array.isArray(examplesForLevel)) {
        // Return the first example from each blank's example list
        return examplesForLevel.map((blankExamples) => Array.isArray(blankExamples) && blankExamples.length > 0 ? blankExamples[0] : '');
      }

      return [];
    },

    // New method to parse and render the sentence with blanks
    renderSentenceWithBlanks(drill) {
      if (!drill) return '';
      const text = drill.raw_text;
      const blanks = drill.filledBlanks;
      let result = text;

      // Find all blank positions in the text
      const blankRegex = /{__}/g;
      const blankPositions = [];
      let match;
      while ((match = blankRegex.exec(text)) !== null) {
        blankPositions.push({
          index: match.index,
          end: match.index + match[0].length,
        });
      }

      // Replace each blank with either the filled value or the blank placeholder
      // Process from end to start to avoid index shifting
      for (let i = blankPositions.length - 1; i >= 0; i--) {
        const position = blankPositions[i];
        const filledBlank = blanks.find((b) => b.index === i);
        const isActive = this.showInlineEditor && i === this.currentBlankIndex;
        const replacement = filledBlank ?
          `<span class="blank filled ${isActive ? 'active-blank' : ''}" data-blank-index="${i}">${filledBlank.value}</span>` :
          `<span class="blank ${isActive ? 'active-blank' : ''}" data-blank-index="${i}">_____</span>`;
        result = result.slice(0, position.index) + replacement + result.slice(position.end);
      }

      return result;
    },
    // Check if all blanks in a drill have been filled
    hasFilledAllBlanks(drill) {
      if (!drill || !drill.raw_text) return false;

      // Count how many blanks are in the raw text
      const blankCount = (drill.raw_text.match(/{__}/g) || []).length;

      // Check if we have filled all the blanks
      return drill.filledBlanks.length === blankCount;
    },
    // Get blank information (surrounding context) for a specific blank
    getBlankInfo(drillIndex, blankIndex) {
      const drill = this.drillSentences[drillIndex];
      if (!drill) return null;

      // Use regex to find all blank positions
      const regex = /{__}/g;
      const text = drill.raw_text;

      // Find all blank positions
      const blanks = [];
      let match;
      while ((match = regex.exec(text)) !== null) {
        blanks.push({
          index: match.index,
          end: match.index + match[0].length,
        });
      }

      // If blank index is out of range, return null
      if (blankIndex >= blanks.length) {
        return null;
      }

      // Get the blank at the specified index
      const targetBlank = blanks[blankIndex];

      // Extract prefix (everything before this blank)
      const prefix = text.substring(0, targetBlank.index);

      // Extract suffix (everything after this blank)
      const suffix = text.substring(targetBlank.end);

      // Find the value if already filled
      const filledBlank = drill.filledBlanks.find((b) => b.index === blankIndex);
      const value = filledBlank ? filledBlank.value : '';

      return {prefix, suffix, value};
    },
    resetState() {
      this.drillState = 0;
      this.showResultsModal = false;
      this.showDrillsCompletedModal = false;
      this.showInlineEditor = false; // Close inline editor
      this.numWrongAttempts = 0;
      this.hints = [];
      this.lastIncorrectAnswer = null; // Reset tracked answer

      // Reset the current drill's filled blanks if it's not completed
      if (this.currentDrill && !this.currentDrill.completed) {
        this.$set(this.drillSentences[this.currentDrillIndex], 'filledBlanks', []);
      }
    },
    // Handle click on a blank span
    handleBlankClick(event) {
      // Find the closest span with data-blank-index
      const target = event.target.closest('[data-blank-index]');
      if (!target) return;

      const blankIndex = parseInt(target.getAttribute('data-blank-index'), 10);

      // If we're already editing this blank, close the editor
      if (this.showInlineEditor && blankIndex === this.currentBlankIndex) {
        this.closeInlineEditor();
      } else {
        // If we're editing a different blank or not editing at all, open the editor for this blank
        this.openInlineEditor(this.currentDrillIndex, blankIndex);
      }
    },
    openInlineEditor(drillIndex, blankIndex) {
      this.showDrillInstructions = false;
      this.currentDrillIndex = drillIndex;
      this.currentBlankIndex = blankIndex;

      // Get the context information for this blank
      const blankInfo = this.getBlankInfo(drillIndex, blankIndex);
      if (blankInfo) {
        this.currentBlankInfo = blankInfo;
        this.showInlineEditor = true;
        this.tempInputValue = blankInfo.value; // Set initial value

        // Force re-render to update the active blank highlight
        this.$forceUpdate();
      }
    },
    closeInlineEditor() {
      this.showInlineEditor = false;
      this.showDrillInstructions = true;
      this.tempInputValue = '';

      // Clean up any temporary filled blanks
      const drill = this.drillSentences[this.currentDrillIndex];
      if (drill) {
        const tempBlankIndex = drill.filledBlanks.findIndex((b) => b.isTemporary);
        if (tempBlankIndex !== -1) {
          drill.filledBlanks.splice(tempBlankIndex, 1);
        }
      }

      this.$forceUpdate();
    },
    handleFilledWord(data) {
      // Check if we have data
      if (!data) {
        return;
      }

      // const {word, index} = data;
      const word = data['word'];

      const drill = this.drillSentences[this.currentDrillIndex];
      if (!drill) return;

      // Find if this blank already has a value
      const existingBlankIndex = drill.filledBlanks.findIndex((b) => b.index === this.currentBlankIndex);

      // If the word is empty, remove the filled blank (if it exists)
      if (!word) {
        if (existingBlankIndex !== -1) {
          // Remove the blank from filledBlanks array
          drill.filledBlanks.splice(existingBlankIndex, 1);
        }
      } else {
        // Handle non-empty word
        if (existingBlankIndex !== -1) {
          // Update existing filled blank
          this.$set(drill.filledBlanks, existingBlankIndex, {
            index: this.currentBlankIndex,
            value: word,
            isTemporary: false,
          });
        } else {
          // Add new filled blank
          drill.filledBlanks.push({
            index: this.currentBlankIndex,
            value: word,
            isTemporary: false,
          });
        }
      }

      this.showResultsModal = false;
      this.tempInputValue = ''; // Reset temporary value
      // Close the inline editor after submitting a word
      this.closeInlineEditor();
    },
    closeResultsModal() {
      this.showResultsModal = false;

      // If correct, mark as completed and move to next question
      if (this.isCorrect) {
        // Mark current drill as completed
        this.$set(this.drillSentences[this.currentDrillIndex], 'completed', true);
        // Check if all drills are completed
        this.checkIfAllDrillsCompleted();
        // Move to next drill
        this.moveToNextDrill();
      }
    },
    moveToNextDrill() {
      // Find the next incomplete drill
      const nextDrillIndex = this.drillSentences.findIndex(
          (drill, index) => !drill.completed && index > this.currentDrillIndex
      );

      if (nextDrillIndex !== -1) {
        this.currentDrillIndex = nextDrillIndex;
      } else {
        // Loop back to the first incomplete drill
        const firstIncompleteDrill = this.drillSentences.findIndex(
            (drill) => !drill.completed
        );

        if (firstIncompleteDrill !== -1) {
          this.currentDrillIndex = firstIncompleteDrill;
        }
        // If all drills are completed, stay on the current one
      }

      this.isCorrect = false;
      this.errorModalMessage = '';
      this.resultsModalMessage = '';
      this.showResultsModal = false;
      this.showErrorModal = false;
      this.drillState = 0;
      this.numWrongAttempts = 0;
      this.hints = [];
      this.lastIncorrectAnswer = null; // Reset tracked answer
    },
    checkIfAllDrillsCompleted() {
      // Don't check completion if we're in the middle of a validation error
      if (this.isValidationError) {
        return;
      }
      const allCompleted = this.drillSentences.every((drill) => drill.completed);
      if (allCompleted && !this.allDrillsCompleted) {
        this.allDrillsCompleted = true;
        // this.showDrillsCompletedModal = true;
        alert('Congratulations! You have completed all writing drills.');
        // note, backend takes somes time to update the writing proficiency since we are using background tasks
        // this means it might take a while before rrating component is refreshed on pattern selection
        setTimeout(() => {
          this.$emit('back');
        }, 2000);
      }
    },
    claimDrillReward() {
      this.showDrillsCompletedModal = false;
      // In a real implementation, this would trigger a backend request to award diamonds
      // Then possibly redirect to missions page or show a confirmation
      this.$emit('reward-claimed');
    },
    showExample(index) {
      // Get the current drill
      const drill = this.drillSentences[index];
      if (!drill) return;

      // If we have examples, show them
      if (drill.examples && drill.examples.length > 0) {
        // Count blanks in the raw text
        const blankCount = (drill.raw_text.match(/{__}/g) || []).length;

        let message = '';

        // If we have one blank and one example
        if (blankCount === 1 && drill.examples.length === 1) {
          message = `Example: ${drill.examples[0]}`;
        } else if (blankCount > 1 && drill.examples.length === blankCount) {
          // If we have multiple blanks and matching examples count
          message = 'Examples:\n';

          // Use regex to find all blank positions to provide context
          const regex = /{__}/g;
          const text = drill.raw_text;
          const parts = text.split(regex);

          // Create example messages for each blank
          for (let i = 0; i < blankCount; i++) {
            const prefix = parts[i].slice(-5); // Get a short context
            const suffix = parts[i+1].slice(0, 5); // Get a short context
            const example = drill.examples[i];

            message += `Blank ${i+1} (${prefix}___${suffix}): ${example}\n`;
          }
        } else {
          // If example count doesn't match blank count
          message += `Examples: ${drill.examples.join(', ')}`;
        }

        alert(message);
      } else {
        alert('No examples available for this drill.');
      }
    },
    validateConstraints() {
      // If no constraints, return true
      if (!this.currentDrill || !this.currentDrill.constraints || !this.currentDrill.constraints.length) {
        return {
          valid: true,
        };
      }

      // Validate each filled blank against its corresponding constraint
      const constraints = this.currentDrill.constraints;
      const filledBlanks = this.currentDrill.filledBlanks;

      // Sort filled blanks by index to ensure we match them with the right constraints
      const sortedBlanks = [...filledBlanks].sort((a, b) => a.index - b.index);

      // Check each constraint
      for (let i = 0; i < constraints.length; i++) {
        const constraint = constraints[i];
        const filledBlank = sortedBlanks[i];

        // If we don't have a filled blank or it's empty
        if (!filledBlank || !filledBlank.value) {
          return {
            valid: false,
            message: `Answer #${i + 1} is required.`,
            blankIndex: i,
          };
        }

        // If constraint has min_length requirement
        if (constraint && constraint.min_length !== undefined) {
          if (filledBlank.value.length < constraint.min_length) {
            return {
              valid: false,
              message: `Answer #${i + 1} must be at least ${constraint.min_length} characters long.`,
              blankIndex: i,
            };
          }
        }
      }

      return {
        valid: true,
      };
    },
    manuallyShowScore() {
      // Make sure all blanks are filled
      if (!this.currentDrill || !this.hasFilledAllBlanks(this.currentDrill)) {
        return;
      }

      // Validate constraints before submitting
      const validationResult = this.validateConstraints();
      if (!validationResult.valid) {
        // Show error message to user
        this.errorModalMessage = validationResult.message;
        this.showErrorModal = true;

        // Optionally focus on the problematic blank
        if (validationResult.blankIndex !== undefined) {
          this.openInlineEditor(this.currentDrillIndex, validationResult.blankIndex);
        }
        return;
      }

      // Prepare data to send to backend
      const submissionData = {
        filledBlanks: this.currentDrill.filledBlanks,
        numWrongAttempts: this.numWrongAttempts,
      };

      // Check if this is the same as the last incorrect answer
      const currentAnswer = JSON.stringify(submissionData.filledBlanks);
      if (this.lastIncorrectAnswer && currentAnswer === this.lastIncorrectAnswer) {
        // Show error message without sending to backend
        this.errorModalMessage = 'You\'ve already tried this answer. Please try a different answer.';
        this.showErrorModal = true;
        return;
      }

      // Set loading state
      this.drillState = 1;

      // Call backend API for LLM scoring
      this.scoreWithLLM(submissionData, currentAnswer);
    },
    async scoreWithLLM(submissionData, currentAnswerStr) {
      if (this.fetching) return;
      this.fetching = true;
      try {
        // Call the backend API endpoint using axios
        const response = await axios.post(`/writing/drills/${this.currentDrill.id}/score/`, submissionData);

        // Process the response from the LLM
        this.isCorrect = response.data['is_correct'];
        this.hints = response.data['hints'];

        // Store this submission if incorrect
        if (!this.isCorrect && currentAnswerStr) {
          this.lastIncorrectAnswer = currentAnswerStr;
        }

        // Use feedback from LLM if provided, otherwise use default messages
        if (this.isCorrect) {
          this.$set(this.drillSentences[this.currentDrillIndex], 'status', 'correct');
        } else {
          this.$set(this.drillSentences[this.currentDrillIndex], 'status', 'incorrect');
          this.numWrongAttempts += 1;
        }
        this.resultsModalMessage = response.data['english_explanation'];

        // Show the modal with results
        this.showResultsModal = true;
        // this.drillState = 2;
      } catch (error) {
        console.error('Error scoring with LLM:', error);
        // Show error message to user
        this.resultsModalMessage = 'Unable to score your answer. Please try again later.';
        this.showResultsModal = true;
        this.drillState = 2;
      } finally {
        this.fetching = false;
      }
    },
    handleValidationError(errorMessage) {
      // Display the error message in the error modal
      this.errorModalMessage = errorMessage;
      this.showErrorModal = true;

      // Increment wrong attempts
      this.numWrongAttempts += 1;
    },
    getCurrentBlankConstraint() {
      const drill = this.drillSentences[this.currentDrillIndex];
      if (!drill || !drill.constraints || !drill.constraints.length) {
        return {min_length: 2}; // Default minimum length
      }

      // Find the constraint for the current blank index
      const constraint = drill.constraints.find((c) => c.blank_index === this.currentBlankIndex) ||
                        drill.constraints[this.currentBlankIndex];

      // Default if no constraint found
      return constraint || {min_length: 2};
    },
    // Add method to handle question button clicks
    handleQuestionButtonClick(index) {
      // Don't allow navigation if currently editing
      if (this.showInlineEditor) {
        this.closeInlineEditor();
      }

      // Navigate to the selected question
      this.currentDrillIndex = index;
      this.isCorrect = false;
      this.errorModalMessage = '';
      this.resultsModalMessage = '';
      this.showResultsModal = false;
      this.showErrorModal = false;
      this.drillState = 0;
    },
    checkAllBlanksFilled(drill) {
      // Implement the logic to check if all blanks in the drill are filled
      // This is a placeholder and should be replaced with the actual implementation
      return this.hasFilledAllBlanks(drill);
    },
    // Add new method to handle real-time input changes
    handleInputChange(data) {
      const {value} = data;
      this.tempInputValue = value;

      // Temporarily update the filled blank for display
      const drill = this.drillSentences[this.currentDrillIndex];
      if (!drill) return;

      // Find if this blank already has a value
      const existingBlankIndex = drill.filledBlanks.findIndex((b) => b.index === this.currentBlankIndex);

      if (value) {
        if (existingBlankIndex !== -1) {
          // Update existing filled blank temporarily
          this.$set(drill.filledBlanks, existingBlankIndex, {
            index: this.currentBlankIndex,
            value: value,
            isTemporary: true,
          });
        } else {
          // Add new temporary filled blank
          drill.filledBlanks.push({
            index: this.currentBlankIndex,
            value: value,
            isTemporary: true,
          });
        }
      } else if (existingBlankIndex !== -1 && drill.filledBlanks[existingBlankIndex].isTemporary) {
        // Remove temporary blank if empty
        drill.filledBlanks.splice(existingBlankIndex, 1);
      }

      // Force re-render to update the display
      this.$forceUpdate();
    },
    getChineseTextLength(text) {
      // Handle empty or undefined input
      if (!text) return {length: 0, aabbPatterns: []};

      // Convert string to array of Unicode characters (handles surrogate pairs correctly)
      const chars = [...text];

      let count = 0;
      let i = 0;
      const aabbPatterns = [];

      while (i < chars.length) {
        // Check for AABB pattern
        if (i + 3 < chars.length &&
            chars[i] === chars[i + 1] &&
            chars[i + 2] === chars[i + 3] &&
            chars[i] !== chars[i + 2]) {
          // AABB pattern found, count as 2 characters
          const aabbPattern = chars[i] + chars[i + 1] + chars[i + 2] + chars[i + 3];
          aabbPatterns.push(aabbPattern);
          count += 2;
          i += 4; // Skip the entire pattern
        } else {
          // Regular character
          count += 1;
          i += 1;
        }
      }

      return {length: count, aabbPatterns};
    },
    submitWord() {
      const trimmedInput = this.inputValue.trim();

      // if user is trying to clear the input, don't show error
      if (trimmedInput.length === 0) {
        this.$emit('word-submitted', {
          word: trimmedInput,
          index: this.blankIndex,
        });
        return;
      }

      // Check for minimum length
      if (this.minLength && trimmedInput.length < this.minLength) {
        this.$emit('validation-error', `Your answer must be at least ${this.minLength} characters long.`);
        return;
      }

      // For Chinese, check if answer contains English
      if (this.subject === 'chinese') {
        if (this.containsEnglish(this.inputValue)) {
          this.$emit('validation-error', 'Your answer should not contain English characters.');
          return;
        }
        // checks if the answer is too short after accounting for AABB patterns (E.G. 高高兴兴 counts as 2 characters)
        const {length, aabbPatterns} = this.getChineseTextLength(this.inputValue);
        if (length < this.minLength) {
          this.$emit('validation-error', `Your answer must be at least ${this.minLength} characters long (note: ${aabbPatterns} count as 2 characters rather than 4).`);
          return;
        }
      }

      // Check if any word to avoid is contained in the input
      for (const wordToAvoid of this.wordsToAvoid) {
        if (trimmedInput.includes(wordToAvoid)) {
          this.$emit('validation-error', `Your answer should not contain the word "${wordToAvoid}".`);
          return;
        }
      }

      this.$emit('word-submitted', {
        word: trimmedInput,
        index: this.blankIndex,
      });
    },
    handleTranscriptionComplete(transcribedText) {
      // add code to remove ending punctuation from transcribedText
      const endingPunctuation = transcribedText.slice(-1);
      const punctuation = ['。', '，', '.', '，', '！', '？', '！', '？'];
      if (punctuation.includes(endingPunctuation)) {
        transcribedText = transcribedText.slice(0, -1);
      }
      this.inputValue = (this.inputValue + ' ' + transcribedText).trim();
      this.recordingState = 0;
      this.isRecording = false;
      this.$nextTick(() => {
        this.$refs.inputField.focus();
      });
    },
    // Add new method to handle keyboard events
    handleKeyPress(event) {
      // Handle escape key to close inline editor
      if (event.key === 'Escape' && this.showInlineEditor) {
        this.closeInlineEditor();
      }

      // Handle enter key to check answer when available
      if (event.key === 'Enter' &&
          !this.showInlineEditor &&
          !this.showResultsModal &&
          !this.fetching &&
          this.hasFilledAllBlanks(this.currentDrill) &&
          !this.currentDrill.completed) {
        this.manuallyShowScore();
      }
    },
    goBack() {
      this.$emit('back');
    },
    redoCurrentDrill() {
      // Reset the current drill's completion status
      this.$set(this.drillSentences[this.currentDrillIndex], 'completed', false);
      this.$set(this.drillSentences[this.currentDrillIndex], 'status', 'unanswered');
      this.$set(this.drillSentences[this.currentDrillIndex], 'filledBlanks', []);

      // Reset other state variables related to the current drill
      this.isCorrect = false;
      this.errorModalMessage = '';
      this.resultsModalMessage = '';
      this.showResultsModal = false;
      this.showErrorModal = false;
      this.drillState = 0;
      this.numWrongAttempts = 0;
      this.hints = [];
      this.lastIncorrectAnswer = null;
      // Show drill instructions again
      this.showDrillInstructions = true;
    },
  },
  // Add event listeners for blank clicks after component is mounted
  mounted() {
    // Fetch drills from the backend when component is mounted
    this.setShowGlobalLoadingScreen(true);
    this.fetchDrills();

    this.$nextTick(() => {
      // Add event delegation for blank clicks
      document.addEventListener('click', (event) => {
        const target = event.target;
        if (target.classList.contains('blank') || target.classList.contains('filled-blank')) {
          this.handleBlankClick(event);
        }
      });

      // Add keyboard event listeners
      document.addEventListener('keydown', this.handleKeyPress);
    });
  },
  // Remove event listeners before component is destroyed
  beforeDestroy() {
    document.removeEventListener('click', this.handleBlankClick);
    document.removeEventListener('keydown', this.handleKeyPress);
  },
};
</script>

<style lang="scss" scoped>
.writing-drills {
  &__component {
    border-radius: .75rem;
    padding: 0;
    height: 100%;
  }

  &__loading,
  &__error,
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    text-align: center;

    p {
      margin-bottom: 1.5rem;
      color: #666;
    }
  }

  &__content {
    &--main {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      height: 100%;
    }

    &--header {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
      gap: 0.5rem 1rem;

      .header-content {
        flex: 1;
        min-width: 200px;
        .header-left {
          display: flex;
          align-items: center;

          .back-button {
            background: none;
            border: none;
            color: #3a87ff;
            cursor: pointer;
            font-weight: 600;
            padding: 0;
            font-size: 0.9rem;
            margin-right: .5rem;

            &:hover {
              text-decoration: underline;
            }
          }
        }
        h2 {
          font-size: 1.5rem;
        }
        p {
          color: #666;
          font-size: .95rem;
          font-style: italic;
        }
      }
    }

    &--sentence {
      font-size: 1.75rem;
      line-height: 2.5rem;
      padding: 1.5rem;
      background: #f9f9f9;
      border-radius: .75rem;
      span {
        // display: flex;
        // align-items: center;
      }
      ::v-deep .blank {
        display: inline-block;
        min-width: 4rem;
        text-align: center;
        border-bottom: 3px dashed #873fff;
        cursor: pointer;
        color: #ffffff00;
        line-height: 2.5rem;
        margin: 0 .5rem;
        font-weight: bold;
        transition: all 0.2s;
        padding: 0 0.5rem;

        &:hover {
          background: #f0e6ff;
        }

        &.active-blank {
          background: #f0e6ff;
          border-bottom: 3px solid #873fff;
        }

        &.filled {
          color: #873fff;
        }
      }

      ::v-deep .filled-blank {
        display: inline-block;
        min-width: 4rem;
        text-align: center;
        padding: 0 0.5rem;
        border-bottom: 3px dashed #873fff;
        line-height: 2.5rem;
        color: #873fff;
        font-weight: bold;
        margin: 0 .5rem;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          background: #4fffec4d;
        }

        &.active-blank {
          background: #4fffec4d;
          border-bottom: 3px solid #873fff;
        }
      }
    }
    &--controls {
      flex: 1;
      display: flex;
      .inline-editor {
        margin-top: auto;
        width: 100%;
      }
    }
    &--actions {
      display: flex;
      gap: 1rem;
      flex: 1;
      margin-top: auto;
      text-align: center;
      .drill-instructions {
        width: 100%;
        color: #868686;
        font-style: italic;
        font-weight: 500;
      }
      .drill-action-btn, .drill-example-btn {
        font-family: "baloo da 2";
        font-weight: 600;
        border-radius: 0.75rem;
        font-size: 1rem;
        border: none;
        padding: 0.75rem 1.5rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        transition: all 0.2s;

        i {
          font-size: 1.1rem;
        }
      }

      .drill-action-btn {
        background: #6ad6b2;
        color: white;
        border-bottom: 4px solid #359776;
        margin-left: auto;
        &:active {
          border-bottom: 2px solid #359776;
          margin-top: 2px;
        }
      }

      .drill-example-btn {
        background: #f1e7ff;
        color: #873fff;
        border-bottom: 4px solid #d1bdff;

        &:active {
          border-bottom: 2px solid #d1bdff;
          margin-top: 2px;
        }

        &:hover {
          background: #e1cfff;
        }
      }
      .loading {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }

    &--progress {
      // flex: 1;
    }
  }
}

/* Modal content styling */
:deep(.modal-content) {
  p {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* Results modal styling */
.results-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result-icon {
  font-size: 6rem;
  text-align: center;
  line-height: 8rem;

  &.correct {
    color: #4CAF50;
    i {
      animation: pulse-success 0.5s ease-in-out;
    }
  }

  &.incorrect {
    color: #F44336;
    i {
      animation: shake 0.5s ease-in-out;
    }
  }
}

.results-content > div:last-child {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: justify;
}

@keyframes pulse-success {
  0% { transform: scale(0.8); opacity: 0.5; }
  50% { transform: scale(1.2); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  20%, 60% { transform: translateX(-10px); }
  40%, 80% { transform: translateX(10px); }
}

@media only screen and (max-width: 1560px) {
  .writing-drills__content {
    &--header {
      h2 {
        font-size: 1.4rem;
      }
      p {
        font-size: 0.95rem;
      }
    }
  }
}
@media only screen and (max-width: 1366px),
screen and (max-height: 870px) {
  .writing-drills__content {
    &--main {
      gap: 0.875rem;
    }
    &--sentence {
      padding: 1.25rem;
      font-size: 1.6rem;
      line-height: 2.25rem;
    }
  }
}
@media only screen and (max-width: 1150px),
screen and (max-height: 690px) {
  .writing-drills__content {
    &--progress .question-counter {
      font-size: 0.85rem;
    }
    &--header {
      .header-content {
        h2 {
          font-size: 1.3rem;
          margin-bottom: 0.25rem;
        }
        p {
          font-size: 0.9rem;
          line-height: 1.4;
        }
      }
    }
  }
}
@media only screen and (max-width: 960px),
screen and (max-height: 620px) {
  .writing-drills__content {
    &--progress .question-counter {
      font-size: 0.75rem;
    }
    &--header h2 {
      font-size: 1.25rem;
    }
    &--sentence {
      font-size: 1.4rem;
      line-height: 2rem;
      padding: 1rem;
    }
    &--actions {
      .drill-action-btn, .drill-example-btn {
        padding: 0.625rem 1.25rem;
        font-size: 0.95rem;
      }
    }
  }
}
@media only screen and (max-width: 760px) {
  .writing-drills {
    &__component {
      border-radius: 0.5rem;
    }
    &__content {
      &--header {
        gap: 0.35rem 0.75rem;
        flex-direction: column;
        .header-content {
          min-width: 160px;
          margin-right: auto;
          h2 {
            font-size: 1.2rem;
            margin-bottom: 0.25rem;
          }
          p {
            font-size: 0.85rem;
          }
        }
      }
      &--progress {
        .question-counter {
          &::before {
            content: "Q ";
          }
        }
      }
      &--main {
        gap: 0.75rem;
      }
      &--sentence {
        font-size: 1.2rem;
        line-height: 1.8rem;
        padding: 0.875rem;
        ::v-deep .blank,
        ::v-deep .filled-blank {
          min-width: 3rem;
          line-height: 2rem;
          margin: 0 0.25rem;
        }
      }
      &--actions {
        .drill-instructions {
          font-size: 0.9rem;
        }
        .drill-action-btn, .drill-example-btn {
          padding: 0.5rem 1rem;
          font-size: 0.9rem;
          border-radius: 0.5rem;
          i {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 580px) {
  .writing-drills__content {
    &--header {
      gap: 0.25rem;
      h2 {
        font-size: 1.1rem;
        margin-bottom: 0;
      }
      p {
        font-size: 0.8rem;
        margin-bottom: 0.2rem;
      }
    }
    &--main {
      gap: 0.625rem;
    }
    &--sentence {
      font-size: 1.1rem;
      line-height: 1.6rem;
      padding: 0.75rem;
    }
    &--actions {
      .drill-instructions {
        font-size: 0.85rem;
      }
      .drill-action-btn, .drill-example-btn {
        padding: 0.5rem 0.875rem;
        font-size: 0.85rem;
        i {
          font-size: 0.9rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .writing-drills__content {
    &--header {
      gap: 0.2rem;
      h2 {
        font-size: 1rem;
        margin-bottom: 0;
      }
      p {
        font-size: 0.75rem;
        margin-bottom: 0.15rem;
      }
    }
    &--main {
      gap: 0.5rem;
    }
    &--sentence {
      font-size: 1rem;
      line-height: 1.5rem;
      padding: 0.625rem;

      ::v-deep .blank,
      ::v-deep .filled-blank {
        min-width: 2.5rem;
        line-height: 1.75rem;
        margin: 0 0.2rem;
        border-bottom-width: 2px;
      }
    }
    &--actions {
      .drill-instructions {
        font-size: 0.8rem;
      }
      .drill-action-btn, .drill-example-btn {
        padding: 0.4rem 0.75rem;
        font-size: 0.8rem;
        border-bottom-width: 3px;
        &:active {
          border-bottom-width: 1px;
        }

        i {
          font-size: 0.85rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .writing-drills__content {
    &--header {
      align-items: center;
    }
  }
  .writing-drills__content--header .header-content h2 {
    font-size: 1.1rem;
  }
}
</style>
