<template>
  <div style="background: white">
    <div id="game-container" v-if="!showExplanation">
      <div class="game">
        <div class="game_header">
          <h2>VocabKing Wordle</h2>
          <span class="game_header--info-icon"
            @click="showTutorial = !showTutorial">
            ?
          </span>
        </div>
        <div class="game_info">
          <p class="game_info--text-1">This is inspired by <a class="link-style" href="https://www.powerlanguage.co.uk/wordle/">Wordle</a>, and modified to make it suitable for Singaporean primary school students learning Chinese.</p>
          <p class="game_info--text-2">Play daily to revise words taken from the MOE syllabus.</p>
        </div>
        <div v-if="showTutorial"
          class="game_tutorial--overlay animated fadeIn faster">
          <div class="game_tutorial--content">
            <i @click="showTutorial = !showTutorial"
              class="fas fa-times game_tutorial--icon"></i>
            <div class="game_tutorial--content-instructions">
              <h3>HOW TO PLAY</h3>
              <p>Guess the <b>WORDLE</b> in six tries.</p>
              <p>Each guess must be a valid 2 word phrase in Chinese. Tap the 'Enter' button to submit.</p>
              <p>After each guess, the color of the tiles will change to show how close your guess was to the answer.</p>
            </div>
            <div class="game_tutorial--content-examples">
              <h4>Examples:</h4>
              <div class="game_tutorial--content-example-1">
                <p class="tile chinese-character green">
                  成
                </p>
                <p class="tile chinese-character">
                  绩
                </p>
                <p class="game_tutorial--content-examples--text">
                  The word ‘成’ is in the correct spot.
                </p>
              </div>
              <div class="game_tutorial--content-example-2">
                <p class="tile chinese-character yellow">
                  进
                </p>
                <p class="tile chinese-character">
                  步
                </p>
                <p class="game_tutorial--content-examples--text">
                  The word ‘进’ is part of the answer but in the wrong spot.
                </p>
              </div>
              <div class="game_tutorial--content-example-3">
                <p class="tile chinese-character">
                  加
                </p>
                <p class="tile chinese-character dark-grey">
                  油
                </p>
                <p class="game_tutorial--content-examples--text">
                  The word ‘油’ is not part of the answer in any spot.
                </p>
              </div>
            </div>
            <div class="game_tutorial--content-footer">
              <b>A new WORDLE will be available daily!</b>
            </div>
          </div>
        </div>
        <div class="game_container">
          <div class="board_container">
            <div class="board">
              <div v-if="showAlert" class="alert-message">
                {{ errorMessage }}
              </div>
              <div v-for="(rowData, rowIndex) in selected" :key="rowIndex"
                class="game-row" :class="{'shake-anim': showWrongAnim, 'anim': rowIndex === attemptIndex}">
                <div class="tile chinese-character" :class="getTileClass(rowIndex, colIndex)"
                  v-for="(word, colIndex) in rowData" :key="colIndex" @click="flip">{{ word }}
                </div>
              </div>
            </div>
          </div>
          <div class="keyboard">
            <div class="keyboard-words">
              <div class="keyboard-button chinese-character" :class="choice['classColor']"
                v-for="(choice, index) in choices" :key="index"
                @click="selectChoice(choice['word'])">{{ choice['word'] }}</div>
            </div>
            <div class="keyboard-buttons">
              <div class="keyboard-button one-and-half" @click="enter">ENTER</div>
              <div class="keyboard-button one-and-half" @click="del">DEL</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- show explanation if wrong, show stats if correct -->
    <div id="explanation-container" v-if="showExplanation">
      <a
        href="#"
        class="close"
        @click.prevent="showExplanation = false"></a>
      <div class="teaching-screen-container margin-bottom animated pulse">
        <h2>TRY AGAIN TOMORROW!</h2>
        <div id="revise-word-container"
          class="tap-btn-highlight"
          @click.prevent="playSound">
          <p class="chinese-character revise-word">{{ answer }}</p>
          <p class="hypy">
            [{{ pinyin }}] <i class="fas fa-volume-up revise-icon"></i>
          </p>
        </div>
        <div id="revise-word-translate">
          <p><b>Definition: </b>{{ definition }} </p>
          <div class="revise-word--header">
            <p><b>{{ chapter.level }}</b></p>
            <p>{{ chapter.number }}《{{ chapter.title }}》</p>
          </div>
        </div>
      </div>
    </div>

    <!-- The Modal -->
    <!-- https://www.w3schools.com/howto/howto_css_modals.asp -->
    <div id="myModal" class="modal" :class="{show: showStats}">
      <!-- Modal content -->
      <div class="game_statistics--overlay">
        <div class="game_statistics--container">
          <i @click.prevent="closeStatsModal"
            class="fas fa-times game_statistics--icon"></i>
          <div class="game_statistics--content animated pulse">
            <h2>STATISTICS</h2>
            <div id="statistics">
              <div class="statistics-container">
                <div class="statistics">{{ stats['played'] }}</div>
                <div class="label">Played</div>
              </div>
              <div class="statistics-container">
                <div class="statistics">{{ stats['winRate'] }}</div>
                <div class="label">Win %</div>
              </div>
              <div class="statistics-container">
                <div class="statistics">{{ stats['currentStreak'] }}</div>
                <div class="label">Current Streak</div>
              </div>
              <div class="statistics-container">
                <div class="statistics">{{ stats['maxStreak'] }}</div>
                <div class="label">Max Streak</div>
              </div>
            </div>
            <div>
              <h3 class="revise-word--wordle-header">WORDLE OF THE DAY:</h3>
              <div id="revise-word-container"
                class="tap-btn-highlight"
                @click.prevent="playSound">
                <div class="revise-word--header">
                  <p><b>{{ chapter.level }}</b></p>
                  <p>{{ chapter.number }}《{{ chapter.title }}》</p>
                </div>
                <div class="revise-word--group">
                  <p class="chinese-character revise-word">{{ answer }}</p>
                  <p class="hypy">
                    [{{ pinyin }}] <i class="fas fa-volume-up revise-icon"></i>
                  </p>
                </div>
                <div id="revise-word-translate">
                  <p><b>Definition: </b>{{ definition }} </p>
                </div>
              </div>
            </div>
          </div>
          <div class="game_statistics--footer">
            <div class="countdown">
              <p><b>Next Wordle</b></p>
              <div id="timer">
                <vue-countdown :time="timeToNewWordle" v-slot="{ days, hours, minutes, seconds }">
                  {{ hours }}:{{ minutes }}:{{ seconds }}
                </vue-countdown>
              </div>
            </div>
            <div class="share">
              <button id="share-button" @click="generateShareText">{{ shareButtonText }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueCountdown from '@chenfengyuan/vue-countdown';

import {utilities} from '../mixins/utilities';
import {soundMixin} from '../mixins/sound';
const WRONG_ANIMATION_TIMING = 0.3 * 2 * 1000;
// num of rows
const NUMBER_OF_ATTEMPTS_ALLOWED = 5;
const NUMBER_OF_CHARS_PER_ROW = 2;

// 朋友，贺卡，习惯， 尾巴

export default {
  name: 'Wordle',
  components: {
    VueCountdown,
  },
  props: {
  },
  mixins: [utilities, soundMixin],
  data() {
    return {
      showTutorial: false,
      showAlert: false,
      // post game explanation
      showExplanation: false,
      // show stats
      showStats: false,
      stats: {
        currentStreak: 0,
        maxStreak: 0,
        played: 0,
        won: 0,
        winRate: 0,
      },
      timeToNewWordle: 0,
      errorMessage: '',
      status: 'ongoing',
      attemptIndex: 0,
      charIndex: 0,
      answer: '',
      validWords: [],
      choices: [],
      selected: [],
      showWrongAnim: false,
      id: 0,
      pinyin: '',
      definition: '',
      chapter: '',
      // contains sounds to be played
      sounds: null,
      shareButtonText: 'SHARE',
    };
  },
  computed: {
    // ...mapState(['initialRoute']),
    // sortedChoices() {
    //   return this.shuffleArray(this.choices);
    // },
    currentSelectedRow() {
      return this.selected[this.attemptIndex];
    },
  },
  mounted: function() {
    for (let i = 0; i < NUMBER_OF_ATTEMPTS_ALLOWED; i++) {
      this.selected.push([]);
      for (let j = 0; j < NUMBER_OF_CHARS_PER_ROW; j++) {
        this.selected[i].push('');
      }
    }
    // sets timing
    // https://stackoverflow.com/questions/15141762/how-to-initialize-a-javascript-date-to-a-particular-time-zone
    const resetTime = new Date(new Date().toLocaleString('en-US', {timeZone: 'Asia/Singapore'}));
    resetTime.setHours(23);
    resetTime.setMinutes(59, 59);
    this.timeToNewWordle = resetTime - new Date();
    axios
        .get('/vocab/wordle/')
        .then((response) => {
          // console.log(response.data);
          // gets word of the day
          this.answer = response.data['answer'];
          this.validWords = response.data['valid_words'].split(',');
          this.choices = response.data['choices'].split(',').map((char) => {
            return {word: char, classColor: ''};
          });
          this.id = response.data['id'];
          this.pinyin = response.data['pinyin'];
          this.definition = response.data['definition'];
          this.chapter = response.data['chapter'];
          this.checkCookie(this.id);
          this.preload(JSON.parse(response.data['manifest']), (data) => {
            this.sounds = data;
          });
        });

    // sets events
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Backspace' || e.key === 'Delete') {
        this.del();
      } else if (e.key === 'Enter') {
        this.enter();
      }
    });
  },
  methods: {
    // ...mapActions(['pullData']),
    checkCookie(todayId) {
      const storedId = Vue.$cookies.get('wordle-id');
      if (!storedId) return;

      // as long as it's not a brand new player, we should pull stored stats
      const _stats = Vue.$cookies.get('wordle-stats');
      if (_stats) {
        this.stats = Vue.$cookies.get('wordle-stats');
      }

      // resets if it's a new day's attempt
      // if (storedId && (storedId != todayId || storedId == 50)) {
      if (storedId && storedId != todayId ) {
        Vue.$cookies.set('wordle-selected', null);
        Vue.$cookies.set('wordle-id', null);
        Vue.$cookies.set('wordle-status', null);
        Vue.$cookies.set('wordle-attempt-index', null);
        return;
      }

      // already attempted, so load values
      this.status = Vue.$cookies.get('wordle-status');
      this.selected = JSON.parse(Vue.$cookies.get('wordle-selected'));
      this.attemptIndex = Vue.$cookies.get('wordle-attempt-index');
      this.showExplanation = this.status === 'lost';
      this.showStats = this.status === 'won';
      for (let i = 0; i < this.selected.length; i++) {
        this.updateKeyboardChoices(this.selected[i]);
      }
      return;
    },
    saveToCookie() {
      Vue.$cookies.set('wordle-selected', JSON.stringify(this.selected));
      Vue.$cookies.set('wordle-stats', JSON.stringify(this.stats));
      Vue.$cookies.set('wordle-id', this.id);
      Vue.$cookies.set('wordle-status', this.status);
      Vue.$cookies.set('wordle-attempt-index', this.attemptIndex);
    },
    playSound() {
      this.sounds[this.answer + '_字'].play();
    },
    generateEmojiGrid() {
      let txt = '';
      let i = 0;
      let abort = false;
      // bad code, should refactor and combine with getTileClass
      for (i = 0; i < this.selected.length; i++) {
        for (let j = 0; j < this.selected[i].length; j++) {
          if (this.selected[i][j] == '') {
            abort = true;
            break;
          }
          const color = this.getTileClass(i, j);
          if (color === 'green') {
            txt += '🟩';
          } else if (color === 'yellow') {
            txt += '🟨';
          } else {
            txt += '⬜';
          }
          if (j == this.selected[i].length - 1) txt += '\n';
        }
        // double breaks
        if (abort) break;
        console.log(i);
      }
      return {
        emoji: txt,
        attempts: i + '/' + this.selected.length,
      };
    },
    generateShareText() {
      // forces https on production servers to ensure clipboard works
      if (location.host.indexOf('localhost') === -1 && location.protocol !== 'https:') {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
      }

      const grid = this.generateEmojiGrid();
      // returns format as Feb 10, we want to reverse it
      const today = new Date().toLocaleString('en-US', {timeZone: 'Asia/Singapore', month: 'short', day: 'numeric'});
      const temp = today.split(' ');
      let msg = `KSN Wordle (${temp[1]}-${temp[0]}) ${grid.attempts}\n\n${grid.emoji}\n`;
      // let msg = `KSN Wordle ${this.id} ${grid.attempts}\n\n${grid.emoji}\n`;
      msg += 'https://learn.vocabking.com.sg/wordle';
      navigator.clipboard.writeText(msg);
      this.shareButtonText = 'COPIED TO CLIPBOARD';
    },
    closeStatsModal() {
      this.showStats = false;
      this.shareButtonText = 'SHARE';
    },
    flip() {
      // console.log('flip');
      // /vocab/wordle/
    },
    getTileClass(rowIndex, colIndex) {
      // we haven't finished answering yet, default color
      if (this.attemptIndex <= rowIndex) return '';
      const char = this.selected[rowIndex][colIndex];
      if (this.answer[colIndex] === char) return 'green';
      if (this.answer.indexOf(char) > -1) return 'yellow';
      return 'dark-grey';
    },
    selectChoice(word) {
      if (this.status !== 'ongoing' || this.charIndex >= 2) return;
      Vue.set(this.currentSelectedRow, this.charIndex, word);
      this.charIndex++;
    },
    del() {
      if (this.status !== 'ongoing' || this.charIndex === 0) return;
      Vue.set(this.selected[this.attemptIndex], this.charIndex - 1, '');
      this.charIndex--;
    },
    playWrongAnimation(goNextRowAfterDone) {
      this.showWrongAnim = true;
      setTimeout(() => {
        this.showWrongAnim = false;
      }, WRONG_ANIMATION_TIMING);
    },
    playCorrect() {
      this.attemptIndex++;
      this.status = 'won';
      this.updateStatsObject(true);
      this.saveToCookie();
      this.sendDone(true);
      setTimeout(() => {
        this.showStats = true;
      }, 1000);
    },
    updateStatsObject(isWon) {
      this.stats['played']++;
      this.stats['won'] += Number(isWon);
      // we store this as 67 for 67% for easier displaying
      this.stats['winRate'] = Math.floor(this.stats['won'] / this.stats['played'] * 100);
      if (isWon) {
        this.stats['currentStreak']++;
        this.stats['maxStreak'] = Math.max(this.stats['maxStreak'], this.stats['currentStreak']);
      } else {
        this.stats['currentStreak'] = 0;
      }
      console.log(this.stats);
    },
    sendDone(isWon) {
      axios.post('/vocab/wordle/', {isWon: isWon});
    },
    updateKeyboardChoices(row) {
      for (let i = 0; i < row.length; i++) {
        const character = row[i];
        const index = this.choices.findIndex((x) => x['word'] === character);
        let classColor = '';

        // if previously found that the correct position of char, don't reupdate it
        if (character === '' || this.choices[index]['classColor'] === 'green') continue;

        if (this.answer[i] === character) {
          classColor = 'green';
        } else if (this.answer.indexOf(character) > -1) {
          classColor = 'yellow';
        } else {
          classColor = 'dark-grey';
        }
        Vue.set(this.choices[index], 'classColor', classColor);
      }
    },
    enter() {
      if (this.status !== 'ongoing') return;
      const attemptedAnswer = this.currentSelectedRow.join('');

      if (attemptedAnswer === this.answer) {
        this.playCorrect();
        return;
      }

      // if insufficient chars or word not accepted, doesn't count
      // play wrong animation and try again
      if (attemptedAnswer.length !== this.answer.length) {
        this.playWrongAnimation();
        this.errorMessage = 'Insufficient Chars!';
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 1500);
        return;
      }
      //

      // word not accepted, doesn't count
      if (this.validWords.indexOf(attemptedAnswer) === -1) {
        this.playWrongAnimation();
        this.errorMessage = 'Invalid Word/Phrase';
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 1500);
        return;
      }

      // left with students inputting a valid word but is wrong answer
      // counts as an attempt, and we go next row
      this.updateKeyboardChoices(this.currentSelectedRow);
      this.attemptIndex++;
      this.charIndex = 0;
      if (this.attemptIndex === NUMBER_OF_ATTEMPTS_ALLOWED) {
        this.status = 'lost';
        alert(`The correct word was ${this.answer}. Try again tomorrow!`);
        this.updateStatsObject(false);
        this.sendDone(false);
        this.showExplanation = true;
      }
      this.saveToCookie();
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.link-style {
  color: #007eff;
  font-weight: 600;
  text-decoration: none;
}
.chinese-character {
  font-family: "WenQuanYi Micro Hei", "UKai", "STKaiTi", "KaiTi", "Heiti SC" !important;
}

.alert-message {
  width: 226px;
  position: absolute;
  margin-left: -113px;
  top: 14.5%;
  padding: 0.75rem 1rem;
  left: 50%;
  color: #fff;
  background-color: #555;
  display: flex;
  font-weight: 600;
  justify-content: center;
  /* padding: 0.5rem 1rem; */
  border-radius: 4px;
  align-items: center;
}

#game-container {
  width: 100%;
  min-height: 100vh;
  background: #fff;
}

a {
  color: blue;
}

.game {
  &_header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid #dfdfdf;
    &--info-icon {
      cursor: pointer;
      margin-left: 1rem;
      border: 3px solid #555;
      border-radius: 54%;
      width: 25px;
      height: 25px;
      text-align: center;
      font-weight: 800;
      font-size: 0.8rem;
    }
  }
  &_info {
    margin: .5rem auto .5rem auto;
    &--text-1 {
      margin-bottom: .25rem;
    }
    &--text-1,
    &--text-2 {
      text-align: center;
      font-weight: 500;
      font-size: 0.8rem;
    }
  }
  &_statistics {
    &--overlay {
      position: fixed;
      left: 0;
      height: 100vh;
      z-index: 30;
      width: 100vw;
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--container {
      background: #fff;
      padding: 2rem 4rem;
      position: relative;
      border-radius: 8px;
      border: 1px solid #efefef;
      box-shadow: 0 4px 23px 0 rgba(0,0,0,.1);
      width: 30rem;
      max-width: 90%;
      max-height: 90%;
      overflow-y: auto;
      animation: SlideIn 200ms;
      box-sizing: border-box;
      margin-bottom: 2rem;
    }
    &--icon {
      position: absolute;
      right: 22px;
      top: 22px;
      font-size: 2rem;
      opacity: 0.3;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    &--content {
      text-align: center;
      h2 {
        margin: 0.5rem 0;
      }
    }
    &--footer {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
    }
  }
  &_tutorial {
    &--content {
      padding: 1rem;
      font-size: 80%;
      position: relative;
      width: 500px;
      &-instructions {
        h3 {
          text-align: center;
          margin-bottom: 1rem;
        }
        p {
          margin-bottom: 0.75rem;
        }
      }
      &-examples {
        padding: 0.5rem 0;
        border-top: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        h4 {
          padding-bottom: 1rem;
        }
        .tile {
          width: 55px;
          height: 55px;
          font-size: 1.5rem;
          margin: 0;
        }
        &--text {
          margin: 0.5rem 0 1rem 0;
        }
      }
      &-footer {
        margin-top: 0.5rem;
      }
    }
    &--overlay {
      position: fixed;
      top: 57px;
      left: 0;
      z-index: 30;
      width: 100vw;
      height: calc(100vh - 85px);
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--icon {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 1.5rem;
      opacity: 0.3;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.game-footer {
  font-size: 0.85rem;
  background: #fff;
  padding: 0.3rem 0 0.5rem 0;
  position: fixed;
  width: 100%;
  bottom: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #007eff;
    .link-style {
      color: #fff;
      transition: all 0.2s linear;
    }
    transition: all 0.2s linear;
  }
}
.game_container {
  height: 85vh;
}
.board {
  &_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
    height: 75%;
  }
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-gap: .25rem;
  margin-bottom: .25rem;
}
.game-row {
  display: block;
  margin-bottom: .25rem;
}

.tile {
  margin: 0 .25rem;
  border-radius: .2rem;
  border: 2.5px solid #ccc;
  width: 72px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  vertical-align: middle;
  box-sizing: border-box;
  color: #555;
  text-transform: uppercase;
  user-select: none;
  height: 72px;
}

.invisible {
  border: none;
}

.keyboard {
  margin: 0 8px;
  user-select: none;
  height: 130px;
  justify-content: center;
  align-items: center;
  display: flex;

  .keyboard-words,
  .keyboard-buttons {
    display: flex;
    flex-wrap: wrap;
    touch-action: manipulation;
  }
  .keyboard-button {
    font-family: inherit;
    font-weight: bold;
    border: 0;
    padding: 0;
    margin: 0 6px 0 0;
    height: 58px;
    width: 58px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    background-color: rgb(239, 239, 239);
    color: var(--key-text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    -webkit-tap-highlight-color: rgba(0,0,0,0.3);
  }
  .one-and-half {
    width: 87px;
    font-size: 12px;
  }
}

.yellow {
  background-color: #c9b458 !important;
  color: #fff !important;
  border: none;
  transition: all 0.2s linear;
}

.green {
  background-color: #6aaa64 !important;
  color: #fff !important;
  border: none;
  transition: all 0.2s linear;

}

.grey {
  background-color: #86888a !important;
  color: #fff !important;
  border: none;
  transition: all 0.2s linear;

}

.dark-grey {
  background-color: #787c7e !important;
  color: #fff !important;
  border: none;
  transition: all 0.2s linear;

}

.anim.shake-anim {
  animation: 0.3s ease diamond-anim 2;
}

@keyframes diamond-anim {
  0% {
    transform: translate(0%, 0);
  }
  25% {
    transform: translate(-10%, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  75% {
    transform: translate(10%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

#explanation-container {
  height: 100vh;
  margin: 0 auto;
  max-width: 500px;
  position: relative;
  background: #fff;
  padding-top: 100px;
}

.teaching-screen-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
}

.teaching-screen-container > h2 {
  text-transform: uppercase;
  font-size: 90%;
  padding: 20px;
  border-top: 2px solid #eee;
  border-bottom: 2px solid #eee;
}

.teaching-screen-container.margin-bottom {
  margin-bottom: 140px;
}

#revise-word-container {
  border: 2px solid black;
  border-radius: 12px;
  cursor: pointer;
  opacity: 0.5;
  text-align: center;
  margin: 16px auto;
  position: relative;
  transition: opacity 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#revise-word-container:hover {
  opacity: 1;
}

#revise-word-container .revise-word {
  font-size: 700%;
  padding: 20px;
}

.revise-word--group {
  border-bottom: 2px solid #e9e9e9;
  border-top: 2px solid #e9e9e9;
}
.revise-word--header {
  padding: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.8rem;
}
.revise-word + p {
  padding: 0 0 20px 0;
  font-size: 90%;
}

.revise-word + p i {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 120%;
  color: #634daf;
}

#revise-word-translate {
  font-size: 80%;
  text-align: center;
  margin: 1rem 30px;
}

.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  &.show {
    display: block;
  }
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 10% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  max-width: 600px;

  /* The Close Button */
  .modal-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .modal-close:hover,
  .modal-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}


.countdown {
  border-right: 2px solid #e9e9e9;
  padding-right: 12px;
  width: 35%;
  text-align: center;
}

.share {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  width: 35%;
  flex: 1;

  #share-button {
    background-color: rgb(106, 170, 100);
    color: #fff;
    font-family: inherit;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    -webkit-tap-highlight-color: rgba(0,0,0,0.3);
    width: 100%;
    font-size: 20px;
    height: 52px;
    -webkit-filter: brightness(100%);
  }
}

#statistics {
  padding: 0.5rem 0;
  display: flex;
  border-top: 2px solid #efefef;
  border-bottom: 2px solid #efefef  ;
  margin-bottom: 1rem;

  .statistics-container {
    flex: 1;
    display: block;

    .statistics {
      font-size: 36px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      letter-spacing: 0.05em;
    }
    .label {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}
  @media only screen and (max-width: 920px) {
    .game_info {
      max-width: 500px;
    }
    .game_container {
      height: 80vh;
    }
  }

  @media only screen and (max-width: 720px) {
    .keyboard {
      width: 350px;
      margin: auto;
      align-items: stretch;
      &-words {
        align-content: stretch;
        justify-content: space-between;
      }
    }
    .keyboard .keyboard-words {
      margin: 0;
    }
  }
  @media only screen and (max-width: 620px) {
    #revise-word-container .revise-word {
      font-size: 600%;
    }
    .game_statistics--content h2 {
      font-size: 1.4rem;
    }
    #statistics .statistics-container .statistics {
      font-size: 30px;
    }
    .revise-word--wordle-header {
      font-size: 1.1rem;
    }
  }
  @media only screen and (max-width: 520px) {
    #revise-word-container .revise-word {
      font-size: 500%;
    }
    .game_statistics--container {
      padding: 2rem;
    }
    .game_statistics--icon {
      font-size: 1.5rem;
    }
  }
  @media only screen and (max-width: 480px) {
    .game_info {
      max-width: 360px;
      &--text-1,
      &--text-2 {
        font-size: 0.7rem;
      }
    }
    .game_tutorial--icon {
      font-size: 1.5rem;
    }
  }
  @media only screen and (max-width: 380px) {
    .game-footer {
      font-size: 0.8rem;
    }
    .game_header {
      font-size: 0.9rem;
      &--info-icon {
        margin-left: 0.5rem;
      }
    }
  }
  @media only screen and (max-height: 760px) {
    #revise-word-container .revise-word {
      font-size: 400%;
      padding: 5px;
    }
    #statistics .statistics-container .label {
      font-size: 10px;
    }
    .game_statistics--content h2 {
      font-size: 1.1rem;
    }
    #statistics .statistics-container .statistics {
      font-size: 20px;
    }
    .revise-word--wordle-header {
      font-size: 0.8rem;
    }
    .revise-word--header {
      font-size:0.7rem;
    }
    .revise-word+p {
      font-size: 70%;
      padding: 10px;
    }
    #revise-word-translate {
      font-size: 70%;
    }
    .game_statistics--footer {
      font-size: 80%;
    }
    .game_statistics--container {
      padding: 2rem 1rem;
    }
  }
  @media only screen and (max-height: 760px) {
    .tile {
      height: 60px;
      width: 60px;
      font-size: 1.5rem;
    }
    .board_container {
      height: 65%;
    }
    .game_tutorial--content {
      font-size: 60%;
    }
  }
  @media only screen and (max-height: 600px) {
    .tile {
      height: 50px;
      width: 50px;
      font-size: 1.2rem;
    }
    .game_header {
      font-size: 0.8rem;
    }
    .game-footer {
      font-size: 0.75rem;
    }
  }
  @media only screen and (max-height: 670px) and (max-width: 380px) {
  }

</style>
