<template>
  <div id="quiz-main-container">
    <!-- to use loading-screen component, set show to true -->
    <loading-screen
      :prop-show="showLoadingScreen"
      :min-wait-time="1000"></loading-screen>
    <div
      v-if="gameRunning && !answeredAllQuestions"
      id="quiz-running-container">
      <!-- quiz-header component -->
      <quiz-header
        @endQuizCallback="endQuizCallback"
        :total-number-of-questions="totalNumberOfQuestions"
        :question-submitted="questionSubmitted">
      </quiz-header>
      <!-- quiz-question component -->
      <quiz-question
        v-if="!isTeachingScreenShown"
        :word="word"
        :choice-options="choiceOptions"
        :format="format"
        :total-number-of-questions="totalNumberOfQuestions"
        :other-info="otherInfo"
        :sounds="sounds"
        @finishedRecording="finishedRecording">
      </quiz-question>
      <!--
        quiz footer component when we are showing question, this will show "check button at the bottom"
        however, for active_usage qn, don't show check (since after child has recorded, should automatically submit) if not answered
        can show for active_usage qn after child has answered
      -->
      <quiz-footer
        v-if="!isTeachingScreenShown && (otherInfo.question_type !== 'active_usage' || answeredStatus !== 'notAnswered') "
       :is-teaching-screen-shown="isTeachingScreenShown"
        :choice-selected="choiceSelected"
        @check="check"
        @next="next">
      </quiz-footer>
      <!-- note vue doens't support camel case for @ -->
      <!-- @closeteachingscreen="nextQuestion" -->
      <quiz-teaching-screen
        v-if="isTeachingScreenShown"
        :word="word"
        :word-id="wordId"
        :definition="responseData.previous_word_definition"
        :pinyin="responseData.previous_word_pinyin"
        :example-sentence="responseData.previous_word_example_sentence"
        :chapter="responseData.previous_word_chapter"
        :sounds="sounds">
      </quiz-teaching-screen>
      <quiz-footer
        v-if="isTeachingScreenShown"
        :is-teaching-screen-shown="isTeachingScreenShown"
        @next="nextQuestion">
      </quiz-footer>
      <!-- report component -->
      <quiz-report-modal
        v-if="showReportModal"
        :quiz-attempt-id="quizAttemptId"
        :word="word">
      </quiz-report-modal>
    </div>
    <!--
      start menu component
      use dynamic component
    -->
    <component
      :is="startComponent"
      v-if="!gameRunning"
      :start-game="startGame"
    >
    </component>
    <quiz-completion-screen
      v-if="gameRunning && answeredAllQuestions"
      :new-coins="newCoins"
      :coins-to-add="coinsToAdd"
      :loot="loot"
      :quiz-type="quizType"
      :end-quiz-callback="endQuizCallback">
    </quiz-completion-screen>
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex';
import axios from 'axios';

// various start components (what is shown is determined dynamically)
import QuizStartComponent from './QuizStartComponent.vue';
import DailyChallengeStartComponent
  from '../../dailychallenge/DailyChallengeStartComponent.vue';
import SubtopicStartComponent
  from '../../learningtrack/SubtopicStartComponent.vue';
import LearningTrackRevisionStartComponent
  from '../../learningtrack/LearningTrackRevisionStartComponent.vue';

import RevisionStartComponent from
  '../../new/revision/components/StartComponent.vue';

import LoadingScreenComponent
  from '../LoadingScreenComponent.vue';

import QuizQuestionComponent from './QuizQuestionComponent.vue';
import QuizHeaderComponent from './QuizHeaderComponent.vue';
import QuizFooterComponent from './QuizFooterComponent.vue';
import QuizTeachingScreenComponent from './QuizTeachingScreenComponent.vue';
import QuizCompletionScreenComponent from './QuizCompletionScreenComponent.vue';
import QuizReportModalComponent from './QuizReportModalComponent.vue';
// import MissionCompletionComponent from '../../new/missions/components/EmbeddedMissionCompletionComponent.vue';

import {utilities} from '../../../mixins/utilities';
import {soundMixin} from '../../../mixins/sound';

export default {
  name: 'QuizMain',
  components: {
    QuizStart: QuizStartComponent,
    DailyChallengeStart: DailyChallengeStartComponent,
    SubtopicStart: SubtopicStartComponent,
    LearningTrackRevisionStart: LearningTrackRevisionStartComponent,
    RevisionStart: RevisionStartComponent,
    QuizQuestion: QuizQuestionComponent,
    QuizHeader: QuizHeaderComponent,
    QuizFooter: QuizFooterComponent,
    QuizTeachingScreen: QuizTeachingScreenComponent,
    QuizCompletionScreen: QuizCompletionScreenComponent,
    QuizReportModal: QuizReportModalComponent,
    LoadingScreen: LoadingScreenComponent,
    // MissionCompletion: MissionCompletionComponent,
  },
  mixins: [utilities, soundMixin],
  props: {
    quizType: String,
    quizId: Number,
    storyId: Number,
    dailyChallengeUnlockedDate: String,
  },
  data() {
    return {
      quizAttemptId: '',
      questionAttemptId: '',
      // isHybridQuiz: false,
      // any additional loot received after completing quiz
      loot: [],
      word: '',
      wordId: '',
      otherInfo: {},
      // variable that stores the latest response.data
      // (for when a child gets a question wrong)
      // since we will be showing the teaching screen, and then calling
      // nextQuestion after child is done revising
      responseData: {},
      choices: [],
      format: '',
      totalNumberOfQuestions: 0,
      questionSubmitted: 0,
      timeStartedQuestion: 0,
      winStreak: 0,
      isTeachingScreenShown: false,
      showLoadingScreen: false,
      // used to prevent multiple calls to server
      // before question attempt is returend
      fetchingData: false,
      sounds: {},
      coinsToAdd: 0,
      newCoins: 0,
      recordingData: null,
    };
  },
  computed: {
    ...mapState('quiz', ['numberOfQuestionsAttempted', 'choiceSelected',
      'answeredAllQuestions', 'gameRunning', 'answeredStatus',
      'showReportModal', 'dailyChallengeSubcategory', 'dailyChallengeSelectedLevel']),
    ...mapState('learningTrack', ['routePriorToStartingQuiz']),
    choiceOptions() {
      if (!this.choices || this.choices.length === 0) return [];
      return this.shuffleArray(this.choices.concat(this.word));
    },
    startComponent() {
      if (this.quizType === 'subtopic') {
        return 'subtopic-start';
      } else if (this.quizType === 'dailyChallenge') {
        return 'daily-challenge-start';
      } else if (this.quizType === 'learningTrackRevision' || this.quizType === 'oralActiveVocab') {
        return 'learning-track-revision-start';
      } else if (this.quizType === 'revision') {
        return 'revision-start';
      } else {
        return 'quiz-start';
      };
    },
  },
  mounted() {
  },
  beforeDestroy() {
    this.reset();
    this.setGameRunning(false);
  },
  methods: {
    ...mapMutations(['addPet', 'incrementPetCounts', 'setIsShowNavBottom', 'setTotalKnownWords', 'updateStarsIfBetter', 'setMissions', 'addToUsedFeatures']),
    ...mapMutations('quiz', ['setChoiceSelected',
      'setAnimateQuestionComponent', 'setGameRunning',
      'resetNumberOfQuestionsAttempted', 'setAnsweredStatus',
      'setShowReportModal', 'setAnsweredAllQuestions',
      'setNumberOfQuestionsAttempted']),
    ...mapMutations('dailyChallenge', ['updateDailyQuizStarsIfBetter', 'markDailyQuizCompleted']),
    ...mapMutations('widgets', ['setCoinLeaderboard']),
    ...mapActions(['addCoinTransaction']),
    reset() {
      this.loot = [];
      this.format = '';
      this.setAnsweredAllQuestions(false);
      this.resetNumberOfQuestionsAttempted();
      this.isTeachingScreenShown = false;
      this.setAnsweredStatus('notAnswered');
      this.setShowReportModal(false);
      this.setAnimateQuestionComponent(true);
      this.fetchingData = false;
      this.showLoadingScreen = false;
      this.setIsShowNavBottom(true);
      this.questionSubmitted = 0;
      this.recordingData = null;
    },
    updateData() {
      this.quizAttemptId = this.responseData['quiz_attempt_id'];
      this.questionAttemptId = this.responseData['next_question_attempt_id'];
      this.word = this.responseData['next_word'];
      this.wordId = this.responseData['other_info']['word_id'];
      this.choices = this.responseData['choices'];
      this.otherInfo = this.responseData['other_info'];
    },
    // this block of code is only run after user clicks
    // end quiz button on completion screen
    endQuizCallback() {
      const nonDailyChallengeQuiz = this.quizType === 'weekly_story_preschool'
        || this.quizType === 'weekly_chapter_primary'
        || this.quizType === 'subtopic';

      // for daily challenge, we want to route back to overview page
      if (this.quizType === 'dailyChallenge') {
        this.addToUsedFeatures('daily_challenge');
        this.$router.push({name: 'daily-challenge-overview'});
      } else if (this.quizType === 'revision') {
        // this.addToUsedFeatures('daily_challenge');
        // not sure why, but we need to set this to false specifically whereas in other cases, we don't need to
        // this.setGameRunning(false);
        // this.setAnsweredAllQuestions(false);
        console.log('calling reset');
        // bug - cannot exit out of revision quiz
        this.reset();
        this.$router.push({name: 'new-revision-quiz'});
      } else if (nonDailyChallengeQuiz && this.routePriorToStartingQuiz) {
        this.$router.push({
          name: this.routePriorToStartingQuiz.name,
          params: this.routePriorToStartingQuiz.params,
        });
      } else {
        console.log(this.quizType);
        console.error('invalid quiz type');
        this.$router.push({name: 'profile-main'});
      }
      this.reset();
    },
    nextQuestion() {
      // resetting setChoiceSelected
      this.setChoiceSelected('');
      // set to animateQuestionComponent to true to animate its entrance
      this.setAnimateQuestionComponent(true);
      this.format = this.responseData['format'];

      // first check if quiz has ended, if so, then show completion screen
      if (this.responseData['quiz_ended']) {
        // kiv -> assume all loot dropped are pets; update this in the future
        // when we have other types of pets
        this.loot = this.responseData.loot || [];
        // updates Vuex
        for (let i = 0; i < this.loot.length; i++) {
          this.addPet(this.loot[i]);
          this.incrementPetCounts(this.loot[i].pet_key);
        };

        const nonDailyChallengeQuiz = this.quizType === 'weekly_story_preschool'
          || this.quizType === 'weekly_chapter_primary'
          || this.quizType === 'subtopic';

        // kiv -> need to be more explicit about the other quiz options
        if (this.quizType === 'dailyChallenge') {
          this.setMissions(this.responseData['missions']);
          this.updateDailyQuizStarsIfBetter(
              this.responseData['stars_from_quiz']);
          this.markDailyQuizCompleted();
        } else if (nonDailyChallengeQuiz) {
          this.updateStarsIfBetter({
            chineseQuizPK: this.quizId,
            starsFromQuiz: this.responseData['stars_from_quiz'],
          });
        } else {
          console.error('error creating quiz');
        };
        this.setTotalKnownWords(this.responseData['total_known_words']);
        this.newCoins = this.responseData['coins'];
        this.setCoinLeaderboard(this.responseData['coin_leaderboard']);
        this.showCompletionScreen(this.responseData);
        return;
      }
      const manifest = JSON.parse(this.responseData['manifest']);
      // quiz hasn't ended, show next question
      this.preload(manifest, (data) => {
        this.sounds = data;
        this.setGameRunning(true);
        // unhides loading screen at the start
        if (this.showLoadingScreen) this.showLoadingScreen = false;

        this.setAnsweredAllQuestions(false);
        this.setNumberOfQuestionsAttempted(
            this.responseData['num_questions_attempted']);
        this.updateData(this.responseData);
        this.setAnsweredStatus('notAnswered');

        // this needs to be below setAnsweredStatus ->
        // so we want to update all the relevant UI elements,
        // then set isTeachingScreenShown to be false
        // which is telling the app to not show the teaching screen
        this.isTeachingScreenShown = false;
        this.timeStartedQuestion = new Date();
      }, (error) => {
        alert('Sorry, there was an issue loading the audio.' +
          'Going back to main page.');
        this.$router.push({name: 'profile-main'});
      });
    },
    // creates quiz, and also first question
    startGame() {
      this.reset();
      let promise;

      // show loading screen until we have loaded data
      this.showLoadingScreen = true;
      this.setIsShowNavBottom(false);

      // pulls data from back-end, creates first question and preloads sounds
      // depending on what sort of qiuz, pulls from different end point
      // start component to show is determined dynamically
      if (this.quizType === 'dailyChallenge') {
        const selectedLevel = this.dailyChallengeSubcategory === 'level' ?
          this.dailyChallengeSelectedLevel : '';
        promise = axios.post('/api/v1/brain/chinesedailyquiz/', {
          dailyChallengeUnlockedDate: this.dailyChallengeUnlockedDate,
          userAgent: window.navigator.userAgent,
          category: 'recognition',
          subCategory: this.dailyChallengeSubcategory,
          selectedLevel: selectedLevel,
        });
      } else if (this.quizType === 'revision') {
        // put in a separate else block for revision for now as this is bad code and needs to be refactored eventually
        const selectedLevel = this.dailyChallengeSelectedLevel;
        const revisionMode = this.dailyChallengeSubcategory.split('|')[1];
        promise = axios.post('/api/v1/brain/chinesedailyquiz/', {
          dailyChallengeUnlockedDate: this.dailyChallengeUnlockedDate,
          userAgent: window.navigator.userAgent,
          category: 'recognition',
          subCategory: 'revision',
          revisionMode: revisionMode,
          selectedLevel: selectedLevel,
        });
      } else if (this.quizType === 'oralActiveVocab') {
        promise = axios.post('/vocab/oralactivevocab/', {
          questionCollectionId: this.quizId,
          // for now, stick to recognition/application split
          category: 'recognition',
          userAgent: window.navigator.userAgent,
        });
      } else {
        promise = axios.post('/api/v1/brain/chinesequiz/', {
          quizId: this.quizId,
          userAgent: window.navigator.userAgent,
        });
      };

      promise.then((response) => {
        console.log(response);
        if (response.data.status === 400) {
          alert(response.data['message']);
          this.$router.push({name: 'profile-main'});
          return;
        }
        this.totalNumberOfQuestions =
          response.data['total_number_of_questions'];
        this.responseData = response.data;

        // note -> there was a bug when we put the code to hide loading
        // screen before nextQuestion
        // when the json data from backend is received but sound isn't preloaded
        // which causes the start button to be shown again, and if user
        // clicks on it, will cause 2 quiz attempts to be sent out
        // so now we moved this.showLoadingScreen = false; to nextQuestion
        this.nextQuestion();
      })
          .catch((error) => {
            console.log(error);
            console.error(error.message);
            this.$router.push({name: 'profile-main'});
          });
    },
    showCompletionScreen(data) {
      // passed as a prop to completion component (to display)
      this.coinsToAdd = data.coins_to_add;
      this.addCoinTransaction({
        coinsToAdd: data.coins_to_add,
        category: this.quizType,
      });
      this.setAnsweredAllQuestions(true);
    },
    // called when we want to proceed to next
    // question or to show teaching screen
    next() {
      if (this.answeredStatus === 'wrong') {
        this.isTeachingScreenShown = true;
      } else {
        this.nextQuestion();
      }
    },
    finishedRecording(args) {
      this.recordingData = args;
      this.setAnimateQuestionComponent(false);
      this.check();
    },
    // called when user wants to submit answer
    check() {
      if (this.fetchingData) {
        return;
      }
      this.fetchingData = true;
      const formData = new FormData();
      formData.append('question_attempt_id', this.questionAttemptId);
      if (this.otherInfo.question_type === 'active_usage' && this.recordingData['keyboardAnswer'] !== '') {
        // removes new line
        formData.append('attempted_answer', this.recordingData['keyboardAnswer'].replaceAll('\n', ''));
      } else {
        formData.append('attempted_answer', this.choiceSelected);
      }
      formData.append('timeTakenToAnswer', new Date() - this.timeStartedQuestion);
      // if active_vocab qn and student has recorded an audio, then include it
      if (this.otherInfo.question_type === 'active_usage' && this.recordingData) {
        // e.g. user clicked on "im not sure"
        if (!this.recordingData.audioData) {
          formData.append('audio_data', null);
        } else {
          formData.append('audio_data', this.recordingData.audioData, this.recordingData.fileName);
        }
        if (('clickedNotSure' in this.recordingData) && this.recordingData.clickedNotSure) {
          // show answer before response from server if clicked "i'm not sure"
          this.setAnsweredStatus('wrong');
        }
      }
      axios
          .post('/api/v1/brain/quizattempt/' + this.quizAttemptId +
            '/questionattempt/', formData)
          .then((response) => {
            this.recordingData = null;
            this.responseData = response.data;
            this.fetchingData = false;
            // progress bar increases on question submitted / response from server, regardless of right or wrong for now
            this.questionSubmitted = this.questionSubmitted + 1;

            // for active_usage qns, we play the sound of the audio here since teaching screen is not shown
            if (this.otherInfo.question_type === 'active_usage') {
              if ((this.word + '_字') in this.sounds) this.sounds[this.word + '_字'].play();
            }

            if (response.data['previous_question_is_correct']) {
              // if correct, play correct sound and move on to next question
              this.winStreak++;
              this.setAnsweredStatus('correct');

              // we want to add more variety to the sounds
              let soundToPlay;
              // play 'correct' sound if correct; 'perfect' only if we
              // got all questions correct (at the end of the quiz)
              if (this.winStreak === this.totalNumberOfQuestions) {
                soundToPlay = 'perfect';
              } else if (this.winStreak % 3 === 0) {
                soundToPlay = 'correct_2';
              } else if (this.winstreak % 5 === 0) {
                soundToPlay = 'correct_3';
              } else {
                soundToPlay = 'correct';
              }
              // only play correct audio if not active_usage qn
              // this.sounds[this.word + '_字'].play();
              if (this.otherInfo.question_type !== 'active_usage') {
                this.sounds[soundToPlay].play();
                // play the audio of the word after 500ms to allow for correct sound effect to finish
                setTimeout(() => {
                  this.sounds[this.word + '_字'].play();
                }, 500);
              }
            } else {
              // if wrong, reset winstreak and show teaching screen
              this.winStreak = 0;
              this.setAnsweredStatus('wrong');
              // only play wrong audio if not active_usage qn
              if (this.otherInfo.question_type !== 'active_usage') this.sounds['wrong'].play();
              // we don't play the audio of the word here since teaching screen will handle that
            }
          })
          // previously had a bug where if the first request fails
          // user cannot send a second request since fetchinData is still true
          // set fetchingData back to true to allow user to make second request
          .catch((error) => {
            console.error(error);
            this.fetchingData = false;
            console.error(error.message);
            alert('There is an error sending data to the server,' +
              'please try again.');
          });
    },
  },
};
</script>

<!-- scoped styles -->
<style lang="scss" scoped>
  #quiz-main-container {
    height: 100%;
  }
  #quiz-running-container{
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  #btn-quit-quiz-container {
    max-width: 1140px;
    width: 100%;
    height: 75vh;
    position: absolute;
    font-size: 180%;
    color: #dadada;
  }
  #btn-quit-quiz {
    float: right;
    margin-right: 16px;
    cursor: pointer;
  }
  #quiz-running-container div {
    z-index: 2;
  }

  #quiz-running-container div:first-of-type {
    z-index: 1;
  }

</style>
