// import Vue from 'vue';

const getDefaultState = () => {
  return {
    grammarQuizzes: [],
    selectedQuiz: null,
  };
};

const state = getDefaultState();

export const grammarModule = {
  namespaced: true,
  state,
  mutations: {
    resetStateMutation(state) {
      Object.assign(state, getDefaultState());
    },
    setGrammarQuizzes(state, value) {
      state.grammarQuizzes = value;
    },
    setSelectedQuiz(state, value) {
      state.selectedQuiz = value;
    },
  },
  getters: {
  },
};
