<template>
  <div class="login-component">
    <div class="login-container row">
      <div class="login-image-container">
        <img src="../assets/boshipanda.png" />
      </div>
      <div class="login-form-content">
        <div class="title">
          <h2>Nusa Champion</h2>
          <p>Your Personal AI Tutor!</p>
        </div>
        <form method="post" class="form">
          <span
            v-if="loginErrorMessage !== ''"
            class="error-message">
            {{ loginErrorMessage }}
          </span>
          <input
            v-model="input.username"
            class="input-text"
            type="text"
            name="username"
            placeholder="Username..."/>
          <input
            v-model="input.password"
            class="input-text"
            type="password"
            placeholder="Password..."/>
          <button
            class="btn-login"
            type="submit"
            @click.prevent="login">
            Login
          </button>
          <a class="forgot-password" href="#"
            @click.prevent="toggleResetModal">
            Forgot your Username or Password?
          </a>
        </form>
      </div>
    </div>
    <!-- <a href="" class="login-component--signup"
      @click.prevent="goToRegistration">
      <p>Don't have an account? Create a free trial account now!
          <span class="login-component--signup-btn">
        </span>
      </p>
    </a> -->

    <div v-if="showLoginResetModal"
      class="reset-password-modal animated fadeIn faster">
      <div class="reset-password-content
        animated slideInDown faster">
        <i @click.prevent="toggleResetModal"
          class="fas fa-times btn-close"></i>
        <h4 class="modal-title">Reset Your Password</h4>
        <form class="reset-password-form">
          <input
            v-model="contactNumber"
            class="phone-number-input"
            type="text"
            placeholder="Please enter your phone number..."/>
            <p class="spacing">or</p>
          <input
            v-model="email"
            class="phone-number-input"
            type="email"
            placeholder="Please enter your email..."/>
          <button
            type="submit"
            class="btn-submit"
            @click.prevent="submitPasswordReset">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapActions, mapMutations} from 'vuex';

export default {
  name: 'MalayLoginMain',
  components: {
  },
  data() {
    return {
      input: {
        username: '',
        password: '',
      },
      contactNumber: '',
      email: '',
      loginErrorMessage: '',
      showLoginResetModal: false,
    };
  },
  computed: {
    ...mapState(['initialRoute']),
  },
  created() {
    this.setShowGlobalLoadingScreen(false);
  },
  mounted: function() {
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen', 'setFetchingInitialData']),
    ...mapActions(['pullData']),
    login() {
      const p = this.loginHelperVerifyUsernamePassword();
      this.loginHelperProcess(p)
          .catch((error) => {
            console.error('Error logging in.');
            console.log(error);
            this.setFetchingInitialData(false);
            this.loginErrorMessage = error.response.data.detail;
            this.setShowGlobalLoadingScreen(false);
          });
    },
    // verifies username and password
    loginHelperVerifyUsernamePassword() {
      // https://www.datchley.name/promise-patterns-anti-patterns/
      this.loginErrorMessage = '';
      if (this.input.username != '' && this.input.password != '') {
        // gets token, which is used for future backend calls
        return axios
            .post('/api-token-auth/', {
              username: this.input.username,
              password: this.input.password,
            });
      } else {
        this.loginErrorMessage = 'A username and password must be present.';
      };
    },
    loginHelperProcess(promise) {
      return promise
          .then((response) => {
            if (response.data.redirect) {
              window.location = response.data.redirect;
            } else {
              localStorage.setItem('user-token', response.data.token);
              axios.defaults.headers.common['Authorization'] = 'Token '
                + response.data.token;
            }
          })
          .then((response) => {
            // we should only show loading screen when
            // username and password are correct
            this.setShowGlobalLoadingScreen(true);
            return this.pullData({subject: 'malay'});
          })
          .then((response) => {
            this.loginErrorMessage = '';
            // initial route refers to the situation when user tries
            // to access specific route when they first access portal
            // after we pull data, then we go to the requested route
            if (this.initialRoute && this.initialRoute.name) {
              this.$router.push({
                name: this.initialRoute.name,
                params: this.initialRoute.params,
              });
              // then reset the initialRoute
              this.$store.commit('setInitialRoute', null);
            } else {
              this.$router.push({
                // name: 'profile-main',
                name: 'profile-main',
              });
            };

            // need to wrap in a timeout to prevent weird graphical glitches
            setTimeout(() => {
              this.setShowGlobalLoadingScreen(false);
            }, 300);
          });
    },
    toggleResetModal() {
      this.showLoginResetModal = !this.showLoginResetModal;
    },
    toggleQRCodeModal() {
      this.showQRCodeModal = !this.showQRCodeModal;
    },
    validEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    submitPasswordReset() {
      let resetMode = '';
      if (this.contactNumber === '' && this.email === '') {
        alert('Please fill in either your contact number or email.');
        return;
      } else {
        resetMode = this.contactNumber !== '' ? 'contactNumber' : 'email';
      }

      // validation
      if (resetMode === 'contactNumber' && (this.contactNumber.length !== 8 ||
        this.contactNumber[0] !== '8' && this.contactNumber[0] !== '9')) {
        // break into lines to avoid 80 lines limit
        let message = 'Invalid mobile number - ';
        message += 'should start with 8 or 9 and have 8 digits.';
        alert(message);
        return;
      }

      if (resetMode === 'email' && !this.validEmail(this.email)) {
        alert('Invalid email format');
        return;
      }

      axios.post('/api/v1/core/request_password_reset/', {
        contactNumber: this.contactNumber,
        email: this.email,
      })
          .then((response) => {
            console.log(response);
            // break into lines to avoid 80 lines limit
            let message = 'We have successfully reset your child\'s password ';
            message += 'and will be sending you an email with login information. ';
            message += 'Thank you!';
            alert(message);

            // reset number and close modal
            this.contactNumber = '';
            this.showLoginResetModal = false;
          })
          .catch((error) => {
            let message = 'Error sending password reset - please contact ';
            message += 'vocabking@learnsmart.ai';
            alert(message);
          });
    },
    goToRegistration() {
      this.$router.push({name: 'registration-parent'});
    },
  },
};
</script>

<style lang="scss" scoped>
  .login-component {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #9053c7;
    background: -webkit-linear-gradient(-135deg, #c850c0, #4158d0);
    background: -o-linear-gradient(-135deg, #c850c0, #4158d0);
    background: -moz-linear-gradient(-135deg, #c850c0, #4158d0);
    background: linear-gradient(-135deg, #c850c0, #4158d0);
    &--signup {
      background: rgba(240,240,240,0.2);
      color: #e8e8e8;
      width: 730px;
      text-align: center;
      padding: 10px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      font-size: 0.8rem;
      box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.35);
      text-decoration: none;
      transition: all 0.2s;
      &:hover {
        font-weight: bold;
      }
      &-btn {
        margin: 0 5px;
        color: #77fff3;
      }
    }
  }

  .login-container {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .login-image-container {
    img {
      max-width: 100%;
      padding: 40px 60px;
    }
    margin-right: 20px;
  }

  .login-form-content {
    padding: 20px;
    margin-right: 60px;
    text-align: center;
    .title {
      margin-bottom: 20px;
      p {
        font-size: 80%;
      }

    }
    .input-text {
      display: block;
      margin: 8px auto;
      border-radius: 100px;
      background-color: #eee;
      font-size: 72.5%;
      padding: 12.5px 50px;
      width: 100%;
      border: 3px solid #eee;
      transition: all 0.2s;
      &:focus {
        outline: none;
        border: 3px solid #ac2fb1;
      }
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #eee inset !important;
  }
  .btn-login {
    border: none;
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
    margin: 24px auto 0 auto;
    display: block;
    width: 100%;
    height: 45px;
    border-radius: 5px;
    background: #8654c7;
    padding: 0 25px;
    cursor: pointer;
    transition: all 0.2s;
    font-weight: 800;
    &:focus {
      outline: none;
    }
    &:hover {
      transform: scale(1.04);
      background-color: #a570ea;
    }
    &:active {
      transform: scale(1.02);
      background: #9359de;
    }
  }
  .btn-qr {
    border: none;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    margin: 4px auto 0 auto;
    display: block;
    width: 100%;
    height: 30px;
    border-radius: 5px;
    background: #53ccd5;
    padding: 0 25px;
    cursor: pointer;
    transition: all 0.2s;
    &:focus {
      outline: none;
    }
    &:hover {
      transform: scale(1.04);
      background-color: #4ee3ee;
    }
    &:active {
      transform: scale(1.02);
      background: #53ccd5;
    }
  }

  .forgot-password {
    display: block;
    padding-top: 6px;
    color: #bbb;
    font-weight: 500;
    font-size: 65%;
    text-decoration: none;
    transition: all 0.2s;
    &:hover {
      color: #2176c3;
    }
  }
  .error-message {
    font-size: 80%;
    color: red;
  }

  .reset-password-modal,
  .qr-code-modal {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,0.95);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .qr-code-modal i {
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    cursor: pointer;
  }
  .reset-password-content {
    height: 300px;
    width: 400px;
    background: #fff;
    position: relative;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    margin-bottom: 150px;
    .btn-close {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 150%;
      color: #aaa;
      cursor: pointer;
      &:hover {
        color: #c32187;
      }
    }

    .modal-title {
      padding: 10px;
      border-bottom: 1px solid #eee;
      margin-bottom: 10px;
    }

    .reset-password-form {
      .phone-number-input {
        background-color: #eee;
        padding: 15px;
        width: 250px;
        display: block;
        border-radius: 10px;
        outline: none;
        border: 2px solid #ddd;
        &:focus {
          border: 2px solid #ac2fb1;
        }
      }
    }
    .btn-submit {
      font-size: 15px;
      color: #fff;
      text-transform: uppercase;
      margin: 12px auto 0 auto;
      display: block;
      width: 50%;
      height: 35px;
      border-radius: 25px;
      background: #8654c7;
      padding: 0 25px;
      cursor: pointer;
      transition: all 0.2s;
      &:focus {
        outline: none;
      }
      &:hover {
        transform: scale(1.04);
        background-color: #a570ea;
      }
      &:active {
        transform: scale(1.02);
        background: #9359de;
      }
    }
  }

  .spacing {
    text-align: center;
    padding: 10px 0px;
  }

  @media only screen and (max-width: 730px) and (min-height: 560px) {
    .login-container {
      flex-direction: column;
    }
    .login-image-container {
      margin: 0 auto;
      width: 60%;
      img {
        padding: 0;
        margin-top: 20px;
      }
    }
    .login-form-content {
      margin: 0 auto;
    }
    .login-component--signup {
      width: 290px;
    }
  }

  @media only screen and (max-width: 320px) and (min-height: 560px) {
    .login-image-container {
      margin-top: 20px;
      img {
        display: block;
        margin: 0 auto;
        width: 80%;
      }
    }
  }


  @media only screen and (max-height: 450px) {
    .login-image-container {
      margin-right: 0;
      img {
        display: block;
        margin: 0 auto;
        width: 90%;
        padding: 20px 40px;
      }
    }
    .reset-password-content {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-height: 350px) {
    .login-image-container {
      img {
        padding: 20px 20px;
      }
    }
    .login-form-content {
      margin-right: 20px;
      padding-left: 0;
      .title {
        font-size: 100%;
        margin-bottom: 15px;
        p {
          font-size: 70%;
        }
      }
      .input-text {
        padding: 10px 50px;
      }
      .btn-login {
        height: 40px;
      }
    }
  }
  @media only screen and (max-height: 560px) and (max-width: 560px){
    .login-image-container {
      display: none;
    }
    .login-form-content {
      margin-right: 10px;
    }
  }

</style>
