<template>
  <div class="quiz-question-container animated fadeIn faster">
    <div class="quiz-question--header">
      <p v-if="otherInfo.question_type === 'meaning' && otherInfo.image_url === ''"
        class="question--header">
        <span v-if="subject === 'chinese'">What is the Chinese translation?</span>
        <span v-if="subject === 'malay' && otherInfo.question_subtype === 'meaning'">Pilih perkataan yang tepat.</span>
        <span v-if="subject === 'malay' && otherInfo.question_subtype === 'spelling'">Pilih ejaan yang tepat.</span>
      </p>
      <p v-if="subject === 'malay' && otherInfo.question_type === 'meaning' && otherInfo.image_url !== ''"
        class="question--header header-mg-lf">
        {{otherInfo.question_subtype === 'meaning' ? 'Pilih perkataan yang tepat.' : 'Pilih ejaan yang tepat.'}}
      </p>
      <p v-if="subject === 'chinese' && otherInfo.question_type === 'recognition'"
        class="question--header">
        What do you hear?
      </p>
      <p v-if="subject === 'chinese' && otherInfo.question_type === 'active_usage'"
        class="question--header">
        Say or type the Chinese phrase:
      </p>
    </div>
    <div class="question-content__container">
      <!-- recognition question audio btn -->
      <div
        v-if="otherInfo.question_type === 'recognition'"
        class="question-content__recognition">
        <div class="sound-icon-container tap-btn-highlight"
          @click.prevent="playSound">
          <i class="fas fa-volume-up sound-icon"></i>
        </div>
      </div>
      <!-- active vocab with img -->
      <div v-if="otherInfo.question_type === 'active_usage'" class="question-content__meaning">
        <div v-if="otherInfo.image_url !== ''" class="meaning-image--container">
          <img :src="otherInfo.image_url">
          <!-- Answer Reveal -->
          <div v-if="answeredStatus !== 'notAnswered'" class="meaning-image--container--answer">
            <span v-if="subject === 'chinese'" :class="answerFontSizeClass"
              class="meaning-image--container--answer-chinese chinese-character">{{ wordAndSimilarWords }}</span>
            <span v-if="subject === 'chinese'" class="meaning-image--container--answer-hypy hypy">【 {{ otherInfo.pinyin }} 】</span>
            <span v-if="subject === 'malay'" :class="answerFontSizeClass"
              class="meaning-image--container--answer-chinese malay-character">{{ wordAndSimilarWords }}</span>
          </div>
        </div>
        <!-- malay doesnt want definition -->
        <h4
          v-if="subject === 'chinese' && otherInfo.image_url !== ''"
          class="meaning-text">
          <span v-if="subject === 'chinese'">Definition: </span> {{ otherInfo.meaning }}
        </h4>
      </div>
      <!-- meaning multiple choice question with img -->
      <div v-if="otherInfo.question_type === 'meaning' && otherInfo.image_url !== ''"
        class="question-content__meaning--mc-pic">
        <div class="question-content__meaning--mc-pic-1">
          <div class="meaning-image--container">
            <img :src="otherInfo.image_url">
            <!-- Answer Reveal -->
            <div v-if="answeredStatus !== 'notAnswered'" class="meaning-image--container--answer">
              <span v-if="subject === 'chinese'" :class="answerFontSizeClass"
                class="meaning-image--container--answer-chinese chinese-character">{{ wordAndSimilarWords }}</span>
              <span v-if="subject === 'chinese'" class="meaning-image--container--answer-hypy hypy">【 {{ otherInfo.pinyin }} 】</span>
              <span v-if="subject === 'malay'" :class="answerFontSizeClass"
                class="meaning-image--container--answer-chinese malay-character">{{ wordAndSimilarWords }}</span>
            </div>
          </div>
          <h4
            v-if="subject === 'chinese' && otherInfo.image_url !== ''"
            class="meaning-text">
            <span v-if="subject === 'chinese'">Definition: </span> {{ otherInfo.meaning }}
          </h4>
        </div>
        <!-- Choices: for with image -->
        <ul v-if="choiceOptions.length > 0 && otherInfo.image_url !== ''" id="choices-container"
          class="question-content__meaning--mc-pic-2">
          <li
            v-for="(choice, index) in choiceOptions"
            :key="index"
            class="animated fadeIn">
            <quiz-question-choice
              class="mc-pic2-btn"
              :choice="choice"
              :index="index"
              :format="format"
              :correct-answer="word">
            </quiz-question-choice>
          </li>
        </ul>
      </div>
      <!-- meaning question with only text -->
      <div v-if="otherInfo.question_type === 'meaning' && otherInfo.image_url === ''"
        class="question-content__meaning">
        <div class="meaning-text meaning-text-only">
          <!-- <b>{{ otherInfo.meaning }}</b> -->
          <h4 v-if="subject === 'chinese'" class="meaning-text-only__header">Definition:</h4>
          <ul class="meaning-text-only__list">
            <li class="meaning-text-only__list-item"
              v-for="(text, index) in meaning" :key="index">
              <span class="meaning-text-only__list-item--index">
                {{ index + 1}}
              </span>
              <span class="meaning-text-only__list-item--text">
                {{ text }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Choices: for no image -->
    <ul v-if="choiceOptions.length > 0 && (otherInfo.image_url === '' || otherInfo.question_type === 'recognition')" id="choices-container">
      <li
        v-for="(choice, index) in choiceOptions"
        :key="index"
        class="animated fadeIn">
        <quiz-question-choice
          :choice="choice"
          :index="index"
          :format="format"
          :correct-answer="word">
        </quiz-question-choice>
      </li>
    </ul>
    <!-- oral-recorder -->
    <div v-if="otherInfo.question_type === 'active_usage'"
      class="quiz-question__component--oral--container"
      :class="{ 'quiz-question__component--oral--container--no-border': isRecording || recordingState === 2 }">
      <div class="quiz-question__component--oral">
        <!-- Button: Skip Question -->
        <div v-if="!isRecording && recordingState != 2"
          class="quiz-question__component--oral-btn-skip">
          <button @click="clickNotSure">
            I'm Not Sure
          </button>
        </div>
        <!-- Button: Cancel Record -->
        <div v-if="isRecording && !isRecordingCountdown"
          class="quiz-question__component--oral-btn-cancel-record">
          <button @click="cancelRecording">
            <i class="fa-solid fa-xmark"></i>
            <span>Cancel</span>
          </button>
        </div>
        <div class="quiz-question__component--oral--input-container">
          <!-- microphone input -->
          <!-- Recorder -->
          <div v-if="[0, 1].indexOf(recordingState) > -1 && showMicrophone"
            class="quiz-question__component--oral-btn-grp--container">
            <!-- not recording start mode -->
            <div v-if="!isRecording"
              class="quiz-question__component--oral-btn-grp">
              <button class="quiz-question__component--oral-btn
              quiz-question__component--oral-btn--start animated bounceIn"
                @click="toggleRecording">
                <i class="fa-solid fa-microphone"></i>
              </button>
              <span class="quiz-question__component--oral-btn--text animated fadeInUp faster">
                ( Click to Start Recording )
              </span>
            </div>
            <!-- recording mode -->
            <div v-if="isRecording" class="quiz-question__component--oral-recording--grp">
              <!-- countdown -->
              <div v-if="isRecordingCountdown"
                class="quiz-question__component--oral-recording--countdown animated flipInX">
                <h2>Get Ready...</h2>
                <p>( Don't Speak Yet )</p>
              </div>
              <!-- recording stop btn -->
              <div v-if="!isRecordingCountdown" class="quiz-question__component--oral-btn-grp
                quiz-question__component--oral-btn-grp--recorder">
                <span class="wave-anim-grp flipInX animated">
                  <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </span>
                <button class="quiz-question__component--oral-btn
                  quiz-question__component--oral-btn--stop animated bounceIn"
                  @click="toggleRecording">
                  <i class="fa-solid fa-stop"></i>
                </button>
                <span class="quiz-question__component--oral-btn--text animated fadeInUp faster">
                  ( Start Recording Now! )
                </span>
              </div>
            </div>
          </div>
          <!-- keyboard input -->
          <transition name="keyboard-anim" mode="out-in"
            leave-active-class="animated bounceOut faster">
            <div v-if="showKeyboard && !isNotSureClicked" class="quiz-question__component--oral--keyboard-grp">
              <textarea v-model="keyboardAnswer" class="animated flipInX faster chinese-character" placeholder="Type here..."></textarea>
              <button @click.prevent="submitKeyboardAnswer"
                :disabled="trimmedKeyboardAnswer === ''"
                class="animated fadeInUp faster">
                Submit
              </button>
            </div>
          </transition>
          <!-- Loading -->
          <div v-if="recordingState === 2 && answeredStatus === 'notAnswered' && !isNotSureClicked"
            class="quiz-question__component--oral-loading">
            <!-- <img src="../../../assets/oral/indicator.gif" /> -->
            <div class="loading-anim"></div>
            <span class="quiz-question__component--oral-btn--text animated fadeInUp faster">
              Analyzing Audio...
            </span>
          </div>
        </div>
        <!-- Button: Toggle Input -->
        <div v-if="!isRecording && recordingState != 2"
          class="quiz-question__component--oral-btn-toggle-input">
          <button @click="toggleInputType"
            v-if="inputMethod === 'keyboard'">
            <i class="fas fa-microphone"></i>
            <span>Use Microphone</span>
          </button>
          <button @click="toggleInputType"
            v-if="inputMethod === 'microphone'">
            <i class="fas fa-keyboard"></i>
            <span>Use Keyboard</span>
          </button>
        </div>
      </div>
    </div>
    <!-- more for mcq questions; active_usage doesnt require -->
    <a v-if="otherInfo.question_type !== 'active_usage' || otherInfo.question_type === 'meaning'"
      id="report-bug-link" href="#" @click.prevent="openReportModal">
      <i class="fas fa-question-circle"></i> Having issues?
    </a>
  </div>
</template>

<!-- updated in jun 2024 to include active vocab -->

<script>
import {mapState, mapMutations} from 'vuex';
import Recorder from '../../../../vendor/recorder';

import QuizQuestionChoiceComponent from './QuizQuestionChoiceComponent.vue';

// miliseconds, to prevent accidental double clicking
const MINIMUM_RECORD_TIME = 500;

export default {
  name: 'QuizQuestion',
  components: {
    QuizQuestionChoice: QuizQuestionChoiceComponent,
  },
  props: {
    choiceOptions: Array,
    totalNumberOfQuestions: Number,
    animateQuestionComponent: Boolean,
    format: String,
    word: String,
    sounds: Object,
    otherInfo: Object,
  },
  watch: {
    answeredStatus: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === 'notAnswered') {
          this.recordingState = 0;
          this.keyboardAnswer = '';
          this.resetInputTypes();
        }
      },
    },
  },
  data() {
    return {
      // audio related stuff
      // 0: haven't started recording
      // 1: recording
      // 2: finished recording, uploading
      recordingState: 0,
      isRecording: false,
      hasMicrophonePermission: false,
      recorder: null,
      gumStream: null,
      isRecordingCountdown: false,
      isNotSureClicked: false,
      keyboardAnswer: '',
      keyboardSubmitPressed: false,
      showMicrophone: true,
      showKeyboard: false,
    };
  },
  computed: {
    ...mapState(['subject']),
    ...mapState('quiz', ['numberOfQuestionsAttempted', 'answeredStatus', 'inputMethod']),
    // ...mapState(['coins', 'avatar', 'studentName', 'level', 'studentId']),
    // for meaning only questions
    meaning() {
      if (!('meaning' in this.otherInfo)) return;
      // returns only the first three meanings
      return this.otherInfo['meaning'].split(';').map(function(sentence) {
        return sentence;
      }).slice(0, 2);
    },
    // for active vocab, after child has gotten it correct, show word and potential similar words
    wordAndSimilarWords() {
      if (this.otherInfo.similar_words.length === 0) return this.word;
      return ([this.word].concat(this.otherInfo.similar_words).join('; '));
    },
    answerFontSizeClass() {
      if ( this.wordAndSimilarWords.length <= 8) {
        return 'answer-font-char-' + this.wordAndSimilarWords.length;
      }
      return 'answer-font-8';
    },
    trimmedKeyboardAnswer() {
      return this.keyboardAnswer.replaceAll('\n', '').trim();
    },
  },
  mounted() {
    document.addEventListener('keydown', this.onKeydown);
    // subtle bug - when user gets qn wrong, this toggles teaching screen and this causes this component to be remounted
    // hence the watch condition isn't called
    this.resetInputTypes();
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeydown);
  },
  methods: {
    ...mapMutations('quiz', ['setShowReportModal', 'setInputMethod']),
    resetInputTypes() {
      if (this.inputMethod === 'microphone') {
        this.showMicrophone = true;
        this.showKeyboard = false;
      } else {
        this.showMicrophone = false;
        this.showKeyboard = true;
      }
      this.keyboardSubmitPressed = false;
    },
    toggleInputType() {
      this.keyboardAnswer = '';
      if (this.inputMethod === 'keyboard') {
        this.setInputMethod('microphone');
        this.showKeyboard = false;
        // hack - force microphone to only appear after a short while because can't get transition css to work
        setTimeout(() => {
          this.showMicrophone = true;
        }, 475);
      } else {
        this.setInputMethod('keyboard');
        this.showMicrophone = false;
        this.showKeyboard = true;
      }
    },
    playSound() {
      if ((this.word + '_字') in this.sounds) this.sounds[this.word + '_字'].play();
    },

    openReportModal() {
      this.setShowReportModal(true);
    },
    // active_usage questions
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    // https://blog.addpipe.com/using-recorder-js-to-capture-wav-audio-in-your-html5-web-site/
    startRecording() {
      if (this.isRecording) return;
      this.recordingState = 1;
      this.isRecording = true;
      this.result = null;
      this.startRecordingTime = new Date();

      // To start countdown to account for recording latency
      this.isRecordingCountdown = true;
      setTimeout(() => {
        this.isRecordingCountdown = false;
      }, 1750);

      // shim for AudioContext when it's not avb.
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      const audioContext = new AudioContext;

      navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((stream) => {
        /* assign to gumStream for later use */
        this.gumStream = stream;
        /* use the stream */
        const input = audioContext.createMediaStreamSource(stream);
        // Create the Recorder object and configure to record mono sound (1 channel)
        this.recorder = new Recorder(input, {
          numChannels: 1,
        });
        // start the recording process
        this.recorder.record();
      }).catch((err) => {
        // enable the record button if getUserMedia() fails
        alert(err);
        this.isRecording = false;
      });
    },
    stopRecording() {
      if (new Date() - this.startRecordingTime < MINIMUM_RECORD_TIME) {
        return;
      }
      this.recordingState = 2;
      this.isRecording = false;

      // tell the recorder to stop the recording
      this.recorder.stop(); // stop microphone access
      this.gumStream.getAudioTracks()[0].stop();
      // create the wav blob and pass it on to createDownloadLink
      this.recorder.exportWAV(this.createDownloadLink);
    },
    createDownloadLink(blob) {
      const au = document.createElement('audio');
      au.controls = false;
      au.src = URL.createObjectURL(blob);
      this.studentAudio = au;
      this.keyboardAnswer = '';
      this.$emit('finishedRecording', {
        audioData: blob,
        keyboardAnswer: '',
        fileName: new Date().toISOString(),
      });
    },
    clickNotSure() {
      this.recordingState = 2;
      this.isNotSureClicked = true;
      this.keyboardAnswer = '';
      this.$emit('finishedRecording', {
        audioData: null,
        keyboardAnswer: '',
        fileName: null,
        clickedNotSure: true,
      });
    },
    // cancel recording return to recordingstate=0
    cancelRecording() {
      this.recordingState = 0;
      this.isRecording = false;
      // tell the recorder to stop the recording
      this.recorder.stop(); // stop microphone access
      this.gumStream.getAudioTracks()[0].stop();
      this.keyboardAnswer = '';
    },
    submitKeyboardAnswer() {
      // prevents multiple submits
      if (this.keyboardSubmitPressed) return;
      this.showKeyboard = false;
      this.keyboardSubmitPressed = true;
      this.$emit('finishedRecording', {
        audioData: null,
        keyboardAnswer: this.trimmedKeyboardAnswer,
        fileName: null,
      });
    },
    // closes popup when user presses escape
    onKeydown(event) {
      if (event.key === 'Enter') {
        if (this.otherInfo.question_type === 'active_usage' && this.inputMethod === 'keyboard' && this.trimmedKeyboardAnswer !== '') {
          this.submitKeyboardAnswer();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .quiz-question--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .question--header {
    font-weight: bold;
    font-size: 1.4rem;
    font-family: 'baloo da 2'
  }
  .question-content {
    &__container {
      height: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1.5rem auto 2rem auto;
    }
    &__meaning {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__recognition {
    }
  }
  .meaning-image--container {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    position: relative;
    &--answer {
      position: absolute;
      font-size: 3rem;
      background: #ffffffeb;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      &-hypy {
        font-size: 1rem;
      }
    }
  }
  // FONT ANSWER FOR IMAGE ACTIVE ORAL
  .answer-font-char-1 {
    font-size: 10rem;
    line-height: 10.5rem;
  }
  .answer-font-char-2 {
    font-size: 8rem;
    line-height: 8.5rem;
  }
  .answer-font-char-3 {
    font-size: 6rem;
  }
  .answer-font-char-4 {
    font-size: 4rem;
  }
  .answer-font-char-5, .answer-font-char-6 {
    font-size: 3rem;
  }
  .answer-font-char-7 {
    font-size: 2.5rem;
  }
  .answer-font-char-8 {
    font-size: 2.25rem;
  }

  // OTHERS
  .meaning-text {
    font-size: 90%;
    font-style: italic;
    font-family: "Lato","Arial",sans-serif;
  }
  .meaning-text-only {
    font-size: 1rem;
    border-radius: 0.75rem;
    border: 2px solid #e7e7e7;
    width: 100%;
    &__header {
      padding: 0.5rem 1rem;
      background: #634daf;
      color: #fff;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      font-style: normal;
    }
    &__list {
      margin-left: 1rem;
      max-height: 140px;
      overflow-y: auto;
      &-item {
        padding: 0.5rem 0;
        &--index {
          font-style: normal;
          font-size: 0.8rem;
          border-radius: 50%;
          background-color: #ffcf6d;
          width: 25px;
          height: 25px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-weight: 800;
          margin-right: 0.35rem;
        }
        &--text {
          font-style: normal;
          font-weight: 600;
        }
      }
    }
  }
  .quiz-question-container {
    width: 600px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    margin-bottom: 3rem;
  }

  #question-tracker {
    text-align: right;
    font-size: 70%;
    font-weight: bold;
    padding: 0.25rem 0.5rem;
    border-radius: 20px;
    background: #ffcf6d;
  }

  #choices-container {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    gap: .3rem;
    // display: grid;
    // grid-gap: .3rem;
    // grid-template-columns: 1fr 1fr;
    li {
      width: calc(50% - 3px);
    }
  }

  .sound-icon-container {
    background: #634daf;
    border-radius: 2rem;
    border-bottom: 6px solid #4a3595;
    height: 140px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s;
    color: #fff;
    font-size: 375%;
    cursor: pointer;
    &:hover {
    }
    &:active {
      height: 138px;
      margin-top: 2px;
      border-bottom: 4px solid #4a3595;
    }
  }

  #report-bug-link {
    margin: 10px auto 0 auto;
    color: #d9534f;
    text-decoration: none;
    font-size: 70%;
    font-weight: bold;
    font-family: 'Baloo Da 2';
  }

  .meaning-container {
    text-align: center;
    padding: 25px 0;
  }

  // for oral btns
  .quiz-question__component--oral {
    max-width: 1140px;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 0 30px;
    &--input-container {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    &--keyboard-grp {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: .5rem;
      top: -80px;
      position: absolute;
      width: 100%;
      &--submitted {
        text-align: center;
        position: absolute;
        top: -80px;
        font-family: 'baloo da 2';
        font-weight: 800;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        i {
          font-size: 2rem;
        }
      }
      textarea {
        border: 2px solid #e7e7e7;
        border-radius: .5rem;
        height: 100px;
        width: 540px;
        padding: .75rem;
        font-size: 1.5rem;
        outline: none;
        resize: none;
        line-height: 1rem;
        &::-webkit-input-placeholder {
          font-size: 1.1rem;
          line-height: 1.5rem;
          font-family: 'baloo da 2', Arial, Helvetica, sans-serif;
        }

        &:-ms-input-placeholder {
          font-size: 1.1rem;
          line-height: 1.5rem;
          font-family: 'baloo da 2', Arial, Helvetica, sans-serif;
        }

        &:-moz-placeholder {
          font-size: 1.1rem;
          line-height: 1.5rem;
          font-family: 'baloo da 2', Arial, Helvetica, sans-serif;
        }

        &::-moz-placeholder {
          font-size: 1.1rem;
          line-height: 1.5rem;
          font-family: 'baloo da 2', Arial, Helvetica, sans-serif;
        }

      }
      button {
        font-family: "baloo da 2";
        width: 175px;
        font-weight: 600;
        border-radius: .75rem;
        font-size: 1.05rem;
        border: 2px solid rgba(0, 0, 0, 0);
        border-bottom: 4px solid #5e46b0;
        text-transform: uppercase;
        padding: 8px 0;
        color: #fff;
        background: #826ad6;
        cursor: pointer;
        &:active {
          border-bottom: 2px solid #5e46b0;
          transform: translateY(2px);
          margin-top: 2px;
        }
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background: #ddd;
          border-bottom: 4px solid #d2d2d2;
          &:active {
            transform: none;
            margin-top: 0;
            border-bottom: 4px solid #d2d2d2;
          }
        }
      }
    }
    &-btn-skip, &-btn-toggle-input, &-btn-cancel-record {
      z-index: 2;
      button {
        cursor: pointer;
        font-family: "baloo da 2";
        width: 200px;
        font-weight: 600;
        border-radius: .75rem;
        font-size: 1.05rem;
        border: 2px solid #e7e7e7;
        border-bottom: 4px solid #e7e7e7;
        padding: 8px 0;
        color: #ababab;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          margin-right: .5rem;
        }
        &:focus {
          outline: none;
        }
        &:active {
          background: #f2f2f2;
          border-bottom: 2px solid #e7e7e7;
          transform: translateY(1px);
        }
      }
    }
    &--container {
      border-top: 2px solid #e7e7e7;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      &--no-border {
        border-top: 2px solid transparent;
      }
    }
    &-btn {
      border: none;
      width: 125px;
      height: 125px;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-bottom: 1rem;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      cursor: pointer;
      i {
        font-size: 3.5rem;
      }
      &--start {
        background: #873fff;
        border-top: 3px solid #b786ff;
        border-bottom: 4px solid #582d99;
      }
      &--stop {
        background: #ff3f8d;
        border-top: 3px solid #ffa4f2;
        border-bottom: 4px solid #af1c58;
      }
      &--text {
        font-size: .85rem;
        font-weight: 500;
        font-style: italic;
        font-family: 'baloo da 2';
        color: #686868;
      }
      &-grp {
        display: flex;
        flex-direction: column;
        margin-bottom: 6.5rem;
        align-items: center;
        &--container {
          width: 100%;
          height: 100%;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &--recorder {
          margin-right: 200px;
        }
      }
    }
    &-loading {
      display: flex;
      flex-direction: column;
      margin-bottom: 6.5rem;
      align-items: center;
      flex: 1;
    }
    &-recording {
      &--grp {}
      &--countdown {
        text-align: center;
        font-family: "baloo da 2", "Lato","Arial",sans-serif;
        margin-bottom: 7.5rem;
        h2 {
          font-size: 1.7rem;
          line-height: 1.8rem;
        }
        p {
          font-size: .85rem;
          font-weight: 500;
          font-style: italic;
        }
      }
    }
  }
  // question image content
  .meaning-image--container {
    height: 250px;
    width: 80%;
    border-radius: .5rem;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: .75rem;
    img {
      object-fit: cover;
      width: 100%;
    }
    + .meaning-text {
      text-align: center;
    }
  }

  // for mcq image questions
  .question-content__meaning--mc-pic {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-bottom: -1rem;
    &-2 {
      flex-direction: column;
      li {
        width: 300px !important;
        .mc-pic2-btn {
          padding: .5rem;
          &:active {
            margin-top: 2px;
          }
        }
      }
    }
    .meaning-image--container {
      width: 384px;
    }
  }

  .header-mg-lf {
    margin-left: -10%;
  }
  // loading anim
  .loading-anim {
    width: 125px;
    margin-bottom: 1rem;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 15px solid #0000;
    border-right-color: #a900ff97;
    position: relative;
    animation: l24 1s infinite linear;
    // background: #e7e7e7;
  }
  .loading-anim:before,
  .loading-anim:after {
    content: "";
    position: absolute;
    inset: -15px;
    border-radius: 50%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }
  .loading-anim:after {
    animation-duration: 4s;
  }

  // wave anim
  .wave-anim-grp {
    animation-delay: .1s;
    ul{
      width:200px;
      height:45px;
      display:flex;
      justify-content:center;
      align-items:center;
      padding:0;
      margin:0;
      transition:ease 0.2s;
      position:relative;
      li{
        list-style:none;
        height:60px;
        width:4px;
        border-radius:10px;
        background: #9385ff;
        margin:0 6px;
        padding:5px;
        animation-name: wave4;
        animation-duration: 0.3s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        transition:ease 0.2s;
      }
      li:nth-child(2) {
        animation-name: wave2;
        animation-delay:0.2s;
      }
      li:nth-child(3) {
        animation-name: wave3;
        animation-delay:0.23s;
        animation-duration: 0.4s;
      }
      li:nth-child(4) {
        animation-name: wave4;
        animation-delay:0.1s;
        animation-duration: 0.3s;
      }
      li:nth-child(5) {
        animation-delay:0.5s;
        margin-right: 1rem;
      }
      li:nth-child(6) {
        animation-name: wave2;
        animation-duration: 0.5s;
      }
      li:nth-child(8) {
        animation-name: wave4;
        animation-delay:0.4s;
        animation-duration: 0.25s;
      }
      li:nth-child(9) {
        animation-name: wave3;
        animation-delay:0.15s;
      }
      li:nth-child(10) {
        animation-delay:0.5s;
      }
      li:nth-child(11) {
        animation-name: wave2;
        animation-duration: 0.5s;
      }
      li:nth-child(12) {
        animation-name: wave3;
        animation-delay:0.4s;
        animation-duration: 0.25s;
      }
      li:nth-child(13) {
        animation-name: wave4;
        animation-delay:0.15s;
      }
      li:nth-child(14) {
        animation-name: wave4;
        animation-duration: 0.5s;
      }
      li:nth-child(15) {
        animation-name: wave4;
        animation-delay:0.1s;
        animation-duration: 0.5s;
      }
    }
  }

@keyframes wave1 {
    from {transform:scaleY(1);}
    to {transform:scaleY(0.5);}
}
@keyframes wave2 {
    from {transform:scaleY(0.3);}
    to {transform:scaleY(0.6);}
}
@keyframes wave3 {
    from {transform:scaleY(0.6);}
    to {transform:scaleY(0.8);}
}
@keyframes wave4 {
    from {transform:scaleY(0.2);}
    to {transform:scaleY(0.5);}
}


@keyframes l24 {
  100% {transform: rotate(1turn)}
}
  @media only screen and (max-width: 880px) {
    .question-content__meaning--mc-pic {
      flex-direction: column;
      margin: auto;
      width: 100%;
      margin-bottom: -1.5rem;
      gap: 1rem;
      &-1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .meaning-image--container {
          height: 220px;
        }
      }
      &-2 {
        width: 100%;
        flex-direction: row;
        li {
          width: calc(50% - 3px) !important;
        }
      }
    }
    .header-mg-lf {
      margin: 0;
    }

  }

  @media only screen and (max-width: 699px) {
    .quiz-question-container {
      width: 100%;
      padding: 0 16px;
      margin-top: 80px;
      justify-content: flex-start;
    }
    .question--header {
      font-size: 1.15rem;
    }
    .question-content__meaning {
      width: 100%;
    }
    .question-content__container {
      margin: 1rem auto 1.5rem auto;
    }
    .sound-icon-container {
      height: 130px;
      &:active {
        height: 128px;
      }
    }
    .meaning-image--container {
      max-width: 385px;
      width: 100%;
    }
    .quiz-question__component--oral {
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      gap: .5rem;
      padding: 0 16px 24px 16px;
      &--container {
        border-top: none;
        height: auto;
      }
      &-loading {
        margin-bottom: 112px;
      }
      &-recording--countdown {
        margin-bottom: 187px;
      }
      &-btn {
        margin-bottom: .5rem;
        &--start {
        }
        &-grp {
          margin-bottom: .5rem;
          &--container {
            order: 1;
          }
          &--recorder {
            margin-right: 0;
            margin-bottom: 67px;
          }
        }
        &-skip, &-toggle-input, &-cancel-record {
          order: 2;
          button {
            width: 100%;
            font-size: 1rem;
            padding: 3px 0;
            &:active {
              transform: none;
              margin-top: 2px;
            }
          }
        }
      }
      &--keyboard-grp {
        position: unset;
        textarea {
          width: 100%;
        }
        button {
          width: 100%;
          font-size: 1rem;
          padding: 3px 0;
        }
      }
    }
    .wave-anim-grp {
      top: 35px;
    }
    .question-content__meaning--mc-pic {
      .meaning-image--container {
        width: 100%;
      }
    }
  }

</style>
