<template>
  <div class="chatbot__component">
    <div class="chatbot__component--container">
      <div class="chatbot__component--body">
        <div class="chatbot__component--body--ai-container">
          <img class="" src="../../../../assets/oral/ai_speech_2.png">
          <div v-if="!isWaitingTranscription" class="chatbot__component--body--ai-container--text">
            <!-- finished loading already -->
            <span v-if="!isWaitingBackendResponse && latestBotMessage">
              <!-- to hide latency of waiting for audio, we use typewriter effect for bot message -->
               <!-- however, once audio is fully received, we can show full text -->
              {{ showFullText ? latestBotMessage.text : typewriterText }}
            </span>
            <span v-if="isWaitingBackendResponse">
              <LoadingDots />
            </span>
          </div>
        </div>
        <div class="chatbot__component--nav">
          <!-- <router-link
            class="chatbot__component--nav--btn"
            :to="{name: 'profile-main'}"
            @click.native="reset">
            <i class="fa-solid fa-xmark"></i>
          </router-link> -->
          <button
            class="chatbot__component--nav--btn"
            @click="handleExit">
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="chatbot__component--nav--score">
            <span>SCORE:</span>
            <span>{{ scores.cumulative }}</span>
          </div>
          <div class="chatbot__component--nav--tokens">
            <i class="fas fa-coins"></i>
            <span>{{ remainingAttempts }}</span>
          </div>
        </div>
        <transition name="bounce" mode="out-in">
          <!-- Word List -->
           <objective-word-list
            v-if="showRecordingControls && objectiveWords.length > 0 && !showConclusion"
            :words="objectiveWords"
            @word-click="handleWordClick">
          </objective-word-list>
          <!-- recording results -->
          <div class="chatbot__component--body--results--container"
            v-if="!showRecordingControls && latestUserMessage && showScores">
            <h3 class="chatbot__component--body--results--title">{{ subject === 'malay' ? 'Analisis Bahasa' : '语文分析' }}</h3>
            <div class="chatbot__component--body--results">
              <div class="chatbot__component--body--results--breakdown">
                <div class="chatbot__component--body--results--breakdown-score">
                  <div class="chatbot__component--body--results--breakdown-score-topic">
                    <span class="chatbot__component--body--results--breakdown-score-topic--header">
                      {{ subject === 'malay' ? 'Tema' : '相关主题' }}
                    </span>
                    <span class="chatbot__component--body--results--breakdown-score-topic--num">
                      <span v-if="scores['is_relevant']">+5</span>
                      <span v-else>+0</span>
                    </span>
                  </div>
                  <div class="chatbot__component--body--results--breakdown-score-language">
                    <span class="chatbot__component--body--results--breakdown-score-language--header">
                      {{ subject === 'malay' ? 'Bahasa' : '语言应用' }}
                    </span>
                    <span class="chatbot__component--body--results--breakdown-score-language--num">
                      +{{ scores['language'] }}
                    </span>
                  </div>
                  <div class="chatbot__component--body--results--breakdown-score-wordlist">
                    <span class="chatbot__component--body--results--breakdown-score-wordlist--header">
                      {{ subject === 'malay' ? 'Kata' : '单词应用' }}
                    </span>
                    <span class="chatbot__component--body--results--breakdown-score-wordlist--num">
                      +{{ scores['vocab'] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <!-- transcription -->
        <div class="chatbot__component--body--transcription--container animated fadeInUp faster"
          v-if="!showRecordingControls && latestUserMessage">
          <i class="fa-solid fa-caret-down"></i>
          <p class="chatbot__component--body--transcription">
            {{ latestUserMessage.text }}
          </p>
        </div>
      </div>
      <!-- <div>
        <h3>test console</h3>
        <button @click="testInitialiseObjectiveWords">Show objectives</button>
        <button @click="testAddObjectiveWords">Add objectives</button>
        <button @click="testRemoveObjectiveWords">Remove objectives</button>
      </div> -->
      <!-- <button @click="showDictionary = true">
        TEST DICTIONARY
      </button> -->
      <div class="chatbot__component--recorder" v-if="!showConclusion">
        <transition name="bounce" mode="out-in">
          <button v-if="showRecordingControls && !isRecording" key="start" @click="toggleRecording"
            class="chatbot__component--recorder--btn chatbot__component--recorder--btn-start">
            <i class="fa-solid fa-microphone"></i>
          </button>
          <button v-if="showRecordingControls && isRecording" key="stop" @click="toggleRecording"
            class="chatbot__component--recorder--btn chatbot__component--recorder--btn-stop">
            <i class="fa-solid fa-stop"></i>
          </button>
          <LoadingDots v-if="!showRecordingControls && isWaitingTranscription" key="loading" class="" />
          <div class="chatbot__component--recorder--avatar" key="avatar"
            v-if="!showRecordingControls && latestUserMessage">
            <img class="avatar-img" v-if="avatar !== ''"
              :src='"../../../../assets/avatars/" + avatar + ".png"' />
          </div>
        </transition>
      </div>
      <div v-if="showConclusion" class="chatbot__component--btn-end">
        <button @click="handleExit">End Session</button>
      </div>
      <!-- TODO: ADD CLICKEVENT FOR IOS SECURITY BYPASS IN ORDER TO PLAY AUDIO -->
      <div v-if="showTapToContinue"
        @click="showTapToContinue = false"
        class="chatbot__component--container--btn-ios">
        ( Tap to continue... )
      </div>
    </div>
    <dictionary-modal
      :show="showDictionary"
      :word="selectedWord"
      :subject="subject"
      :selectedWordInfo="wordsInfo[selectedWord]"
      @close="closeDictionary"
    ></dictionary-modal>
    <modal
        :show="showModal"
        :title="modalTitle"
        :message="modalMessage"
        :closeButtonText="modalCloseButtonText"
        @close="closeModal"
      />
    <audio ref="sayAgainAudio"
      :src="sayAgainAudioUrl"
      playsinline
      webkit-playsinline
    />
    <audio ref="introductionAudio"
      src="https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/sound_effects/introduction.mp3"
      playsinline
      webkit-playsinline
    />
  </div>
</template>

<script>
import axios from 'axios';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import {mapState, mapMutations, mapGetters} from 'vuex';
import Recorder from '../../../../../vendor/recorder.js';
import 'large-small-dynamic-viewport-units-polyfill';

import LoadingDots from './components/LoadingDotsComponent.vue';
import ObjectiveWordListComponent from './components/ObjectiveWordListComponent.vue';
import ModalComponent from '../../common/ModalComponent.vue';
import DictionaryModalComponent from './components/DictionaryModalComponent.vue';

import {soundMixin} from '../../../../mixins/sound.js';

export default Vue.extend({
  name: 'VoiceBot',
  props: {
    topicId: Number,
  },
  data() {
    return {
      isDevelopment: false,
      messages: [],
      userInput: '',
      isWaitingTranscription: false,
      // waiting for backend response
      isWaitingBackendResponse: false,
      showTapToContinue: false,
      // recorder related code
      state: -1,
      isRecording: false,
      recorder: null,
      audioContext: null,
      gumStream: null,
      // end record related code
      fetching: false,
      // store audio players for each message
      audioPlayers: [],
      // modal related code
      showModal: false,
      modalTitle: '',
      modalMessage: '',
      modalCloseButtonText: 'Close',
      sessionId: '',
      startTime: 0,
      latestUserMessage: null,
      latestBotMessage: null,
      showRecordingControls: true,
      // Dictionary related code
      showDictionary: false,
      // typewriter related code to hide latency of waiting for audio
      typewriterText: '',
      typewriterIndex: 0,
      typewriterSpeed: 100, // milliseconds between each character
      showFullText: true, // show full text when audio is ready; starts as true since there is initial text
      objectiveWords: [],
      remainingAttempts: 5,
      showScores: false,
      showConclusion: false,
      scores: {cumulative: 0, vocab: 0, language: 0, is_relevant: 0},
      wordsInfo: {},
    };
  },
  components: {
    LoadingDots,
    Modal: ModalComponent,
    DictionaryModal: DictionaryModalComponent,
    ObjectiveWordList: ObjectiveWordListComponent,
  },
  mixins: [soundMixin],
  computed: {
    ...mapState(['avatar', 'subject', 'studentId']),
    ...mapState('voiceBot', ['isExceedDailyAudioLimit', 'selectedTopicId']),
    ...mapGetters('voiceBot', ['selectedTopic']),
    numUserMessages() {
      return this.messages.filter((m) => m.sender === 'user').length;
    },
    sayAgainAudioUrl() {
      return this.subject === 'malay' ? 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/sound_effects/say_again_malay.mp3' : 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/sound_effects/say_again_chinese.mp3';
    },
  },
  mounted() {
    // Add check for selectedTopic before reset
    if (!this.selectedTopic) {
      this.$router.push({name: 'new-oral-voicebot-index'});
      return;
    }

    this.reset();
    // Set up initial audio context unlock
    const unlockAudioContext = async () => {
      if (this.audioContext && this.audioContext.state === 'suspended') {
        await this.audioContext.resume();
        this.debugLog('Debug: AudioContext resumed by user interaction');
      }
      ['touchstart', 'click'].forEach((event) => {
        document.removeEventListener(event, unlockAudioContext);
      });
    };
    ['touchstart', 'click'].forEach((event) => {
      document.addEventListener(event, unlockAudioContext, {once: true});
    });
  },
  watch: {
    $route: {
      immediate: false,
      handler(to, from) {
        if (to && to.name === 'new-voicebot') {
          this.reset();
        };
      },
    },
  },
  methods: {
    ...mapMutations(['setMissions']),
    ...mapMutations('voiceBot', ['setIsExceedDailyAudioLimit']),
    // best practice to only create audio context once
    reset() {
      this.messages = [];
      this.userInput = '';
      this.isWaitingTranscription = false;
      // waiting for backend response
      this.isWaitingBackendResponse = false;
      // recorder related code
      this.state = -1;
      this.isRecording = false;
      this.recorder = null;
      this.audioContext = null;
      this.gumStream = null;
      // end record related code
      this.fetching = false;
      // store audio players for each message
      this.audioPlayers = [];
      // modal related code
      this.showModal = false;
      this.modalTitle = '';
      this.modalMessage = '';
      this.modalCloseButtonText = 'Close';
      this.sessionId = '';
      this.startTime = 0;
      this.latestUserMessage = null;
      this.latestBotMessage = null;
      this.showRecordingControls = true;
      // typewriter related code to hide latency of waiting for audio
      this.typewriterText = '';
      this.showFullText = true; // show full text when audio is ready; starts as true since there is initial text
      this.objectiveWords = [];
      this.remainingAttempts = 5;
      this.showScores = false;
      this.showConclusion = false;
      this.scores = {cumulative: 0, vocab: 0, language: 0, is_relevant: 0};
      this.showTapToContinue = false;

      this.initializeAudioContext();
      if (this.isExceedDailyAudioLimit) {
        this.showExceedUsageModal();
      }
      if (this.subject === 'malay') {
        this.handleMessage('Hai, nama saya Shark.', 'bot');
      } else {
        this.handleMessage('你好，我叫做鲨鱼。', 'bot');
        this.$refs.introductionAudio.play();
      }
    },
    handleWordClick(word) {
      this.selectedWord = word;
      this.showDictionary = true;
    },
    handleExit() {
      axios.post('/voicebot/conversations/end_session/', {session_id: this.sessionId})
          .then((response) => {
            console.log('response', response.data);
            this.setMissions(response.data.missions);
            this.reset();
            this.$nextTick(() => {
              this.$router.push({name: 'new-oral-voicebot-index'});
            });
          })
          .catch((error) => {
            console.error('Error ending session:', error);
            this.reset();
            this.$nextTick(() => {
              this.$router.push({name: 'new-oral-voicebot-index'});
            });
          });
    },
    initializeAudioContext() {
      if (!this.audioContext) {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        this.audioContext = new AudioContext();

        // Add this: Resume audio context on user interaction
        document.addEventListener('touchstart', () => {
          if (this.audioContext.state === 'suspended') {
            this.audioContext.resume();
          }
        }, {once: true});
      }
    },
    // https://blog.addpipe.com/using-recorder-js-to-capture-wav-audio-in-your-html5-web-site/
    startRecording() {
      // const startTime = new Date();
      if (this.isRecording) return;
      this.state = 1;
      this.isRecording = true;
      this.result = null;

      if (this.audioContext.state === 'suspended') {
        this.audioContext.resume();
      }

      navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((stream) => {
        // console.log((new Date()).getTime() - startTime.getTime());
        /* assign to gumStream for later use */
        this.gumStream = stream;
        /* use the stream */
        const input = this.audioContext.createMediaStreamSource(stream);
        // Create the Recorder object and configure to record mono sound (1 channel)
        this.recorder = new Recorder(input, {
          numChannels: 1,
        });
        this.recorder.record();
      }).catch((err) => {
        // enable the record button if getUserMedia() fails
        alert(err);
        this.isRecording = false;
      });
    },
    stopRecording() {
      this.state = 2;
      this.isRecording = false;

      // tell the recorder to stop the recording
      this.recorder.stop(); // stop microphone access
      // Stop all tracks
      this.gumStream.getTracks().forEach((track) => track.stop());
      this.gumStream = null; // Clear the stream
      // create the wav blob and pass it on to createDownloadLink
      this.recorder.exportWAV(this.createDownloadLinkWS);
    },
    cancelRecording() {
      this.state = 0;
      this.isRecording = false;
      // tell the recorder to stop the recording
      this.recorder.stop(); // stop microphone access
      this.gumStream.getAudioTracks()[0].stop();
    },
    toggleRecording() {
      if (this.isWaitingTranscription) return;
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
        // whenever we start recording, we stop showing the scores
        this.showScores = false;
      }
    },
    // uses websocket   to send audio to backend rather than restful api
    createDownloadLinkWS(blob) {
      if (this.fetching) return;
      this.isWaitingTranscription = true;
      this.fetching = true;
      const au = document.createElement('audio');
      au.controls = false;
      au.src = URL.createObjectURL(blob);
      this.studentAudio = au;
      this.latestBotMessage = null;
      this.showRecordingControls = false;

      // Use FileReader to read the blob as a data URL
      const reader = new FileReader();
      reader.readAsDataURL(blob); // Change to readAsDataURL
      this.startTime = new Date().getTime();
      reader.onloadend = () => {
        const audioData = reader.result.split(',')[1]; // Get base64 part
        const renderUrl = this.isDevelopment ? 'ws://localhost:8080/ws/transcribe' : 'wss://learnsmartapi.onrender.com/ws/transcribe';
        // Connect to WebSocket
        const socket = new WebSocket(renderUrl);

        socket.onopen = () => {
          socket.send(JSON.stringify({
            audioData: audioData, // Send base64 audio data
            language: this.subject,
            checkLanguage: false,
            studentId: this.studentId,
            sessionId: this.sessionId,
            topicInfo: this.selectedTopic,
          }));
        };

        socket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          this.handleWebSocketMessage(message);
          this.sessionId = message.session_id;
          // Also set the active session ID so it persists through resets
          if (message.session_id) {
            this.activatedSessionId = message.session_id;
          }
          console.log('sessionId', this.sessionId);
        };

        socket.onerror = (error) => {
          console.error('WebSocket error:', error);
          this.cancelRecording();
          this.fetching = false;
          this.isWaitingTranscription = false;
        };

        socket.onclose = () => {
          console.log('WebSocket connection closed');
          this.isWaitingTranscription = false;
        };
      };
    },
    // catchall method to handle different types of WebSocket messages
    handleWebSocketMessage(message) {
      switch (message.type) {
        case 'TRANSCRIPTION_RESULT':
          this.handleTranscriptionResult(message.data);
          break;
        case 'CHAT_RESPONSE':
          this.handleChatResponse(message.data);
          break;
        case 'AUDIO_RESPONSE':
          this.handleAudioResponse(message.data);
          break;
        case 'KEYWORDS_RESPONSE':
          this.handleKeywordsResponse(message.data);
          break;
        case 'ERROR':
          this.handleError(message.payload);
          break;
        default:
          console.warn('Unknown message type:', message.type);
      }
    },
    handleTranscriptionResult(payload) {
      this.fetching = false;
      this.isWaitingTranscription = false;
      if (payload.transcription.trim() === '') {
        this.$refs.sayAgainAudio.play();
        this.showRecordingControls = true;
        this.latestUserMessage = null;
        return;
      }

      this.handleMessage(payload.transcription, 'user');
      this.isWaitingBackendResponse = true;
      // this.sendTextMessageToBackend(payload.transcription);
      // console.log('Transcription time:', (new Date().getTime() - this.startTime));
      this.startTime = new Date().getTime();
    },
    handleChatResponse(payload) {
      console.log('handleChatResponse', payload);
      this.isWaitingBackendResponse = false;
      if ('is_exceed_daily_audio_limit' in payload && payload.is_exceed_daily_audio_limit) {
        this.showExceedUsageModal();
        this.setIsExceedDailyAudioLimit(true);
        return;
      }
      this.handleMessage(payload.text, 'bot', payload.message_index);
      this.showFullText = false;
      this.startTypewriter(payload.text);
      // this.startTime = new Date().getTime();
      console.log(payload);

      if (payload.objectives_data) {
        this.handleObjectivesData(payload.objectives_data);
      }
      // console.log('Chat response time:', (new Date().getTime() - this.startTime));
      this.startTime = new Date().getTime();
    },
    handleObjectivesData(objectivesData) {
      // console.log('Objectives response:', objectivesData);
      if (typeof objectivesData.scores !== 'undefined') {
        this.scores['cumulative'] += objectivesData.scores.total;
        this.scores['language'] = objectivesData.scores.language;
        this.scores['is_relevant'] = objectivesData.scores.is_relevant;
        this.scores['vocab'] = objectivesData.scores.vocab;
        this.remainingAttempts--;
        this.$nextTick(() => {
          this.showScores = true;
        });
      }
    },
    handleKeywordsResponse(keywordsData) {
      console.log('Keywords response:', keywordsData);
      const targetWords = keywordsData.target_words;
      const scoreMap = keywordsData.score_map;
      this.wordsInfo = keywordsData.words_info;
      // adds words to objectiveWords with a delay of 500 miliseconds
      const addWordWithDelay = (words, scoreMap, index) => {
        if (index < words.length) {
          setTimeout(() => {
            const word = words[index];
            const score = scoreMap[word];
            const inTextbook = this.wordsInfo[word].in_textbook || false;
            this.objectiveWords.push([word, score, inTextbook]);
            addWordWithDelay(words, scoreMap, index + 1);
          // }, 500)
          }, 1);
        }
      };

      // otherwise, we first remove words that are no longer needed, then add new words
      this.objectiveWords = [];
      if (targetWords.length > 0) {
        addWordWithDelay(targetWords, scoreMap, 0);
      }
    },
    handleAudioResponse(payload) {
      const messageIndex = this.messages.findIndex((m) => m.messageIndex === payload.message_index);
      if (messageIndex !== -1) {
        try {
          // add safeguards when audio_data is not present (exampe tts fails)
          if (!payload.audio_data) {
            this.showFullText = true;
            this.showTapToContinue = true;
            this.showRecordingControls = true;
            this.latestUserMessage = null;
            if (this.remainingAttempts === 0) {
              this.showConclusion = true;
              this.showRewardUsageModal();
            }
            return;
          }

          const audioBlob = this.base64ToBlob(payload.audio_data, 'audio/mp3');
          const audioUrl = URL.createObjectURL(audioBlob);

          this.$set(this.messages[messageIndex], 'audioUrl', audioUrl);
          this.showFullText = true;

          // for mobile
          this.showTapToContinue = true;
          this.playAudio(messageIndex, () => {
            // alert('Debug: Audio playback completed');
            this.showRecordingControls = true;
            this.latestUserMessage = null;

            if (this.remainingAttempts === 0) {
              this.showConclusion = true;
              this.showRewardUsageModal();
            }
          });
        } catch (error) {
          alert(`Debug: Error in handleAudioResponse: ${error.message}`);
        }
      } else {
        alert('Debug: Message index not found');
      }
    },
    handleError(payload) {
      this.messages.push({text: '对不起，我遇到了一点问题。请再说一次。', sender: 'bot'});
    },
    handleMessage(text, sender, messageIndex=-1) {
      const message = {
        text: text,
        sender: sender,
      };
      if (messageIndex > -1) {
        message.messageIndex = messageIndex;
      }

      if (sender === 'bot') {
        this.latestBotMessage = message;
      } else {
        this.latestUserMessage = message;
      }

      this.messages.push(message);

      // this.$nextTick(() => {
      //   this.scrollToBottom();
      // });
    },
    startTypewriter(text) {
      this.typewriterText = '';
      this.typewriterIndex = 0;
      this.typewriterEffect(text);
    },
    typewriterEffect(text) {
      if (this.typewriterIndex < text.length && !this.showFullText) {
        this.typewriterText += text.charAt(this.typewriterIndex);
        this.typewriterIndex++;
        setTimeout(() => this.typewriterEffect(text), this.typewriterSpeed);
      } else if (this.showFullText) {
        this.typewriterText = text; // Ensure full text is shown if interrupted
      }
    },
    base64ToBlob(base64, mimeType) {
      try {
        // alert('Debug: Starting base64 conversion');
        const byteCharacters = atob(base64);
        // alert(`Debug: Decoded base64 length: ${byteCharacters.length}`);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: mimeType});
        // alert(`Debug: Created blob of size: ${blob.size}`);
        return blob;
      } catch (error) {
        // alert(`Debug: Error in base64ToBlob: ${error.message}`);
        throw error;
      }
    },
    playAudio(index, callback) {
      const message = this.messages[index];

      if (!message || !message.audioUrl) {
        this.debugLog('Debug: No audio URL found');
        return;
      }

      this.stopAllAudio();
      let audio = this.audioPlayers[index];
      if (!audio) {
        audio = new Audio();
        audio.preload = 'auto';
        audio.playsinline = true;
        audio.setAttribute('webkit-playsinline', 'true');
        audio.src = message.audioUrl;
        this.audioPlayers[index] = audio;
      }

      // Create a function to handle the actual play attempt
      const attemptPlay = async () => {
        try {
          // First ensure audio context is running
          if (this.audioContext.state === 'suspended') {
            await this.audioContext.resume();
          }

          audio.currentTime = 0;
          await audio.play();
          this.debugLog('Debug: Audio playing successfully');

          // Set up the callback for when audio ends
          audio.onended = () => {
            if (callback && typeof callback === 'function') {
              callback();
            }
          };
        } catch (error) {
          this.debugLog(`Debug: Play failed: ${error.message}`);
          // If playback fails, we'll try again with user interaction
          this.setupPlayOnUserInteraction(audio, callback);
        }
      };

      // Try immediate playback first
      attemptPlay();
    },
    // New method to handle user interaction-based playback
    setupPlayOnUserInteraction(audio, callback) {
      const playHandler = async () => {
        try {
          await this.audioContext.resume();
          audio.currentTime = 0;
          await audio.play();
          this.debugLog('Debug: Audio playing after user interaction');

          // Set up the callback for when audio ends
          audio.onended = () => {
            if (callback && typeof callback === 'function') {
              callback();
            }
          };

          // Remove the event listeners once played successfully
          ['touchstart', 'click'].forEach((event) => {
            document.removeEventListener(event, playHandler);
          });
        } catch (error) {
          this.debugLog(`Debug: Play failed even with user interaction: ${error.message}`);
        }
      };

      // Add event listeners for both touch and click events
      ['touchstart', 'click'].forEach((event) => {
        document.addEventListener(event, playHandler, {once: true});
      });

      this.debugLog('Debug: Waiting for user interaction to play audio');
    },
    // New modal-related methods
    openModal(title, message, closeButtonText = 'Close') {
      this.modalTitle = title;
      this.modalMessage = message;
      this.modalCloseButtonText = closeButtonText;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    // Dictionary Related Methods
    closeDictionary() {
      this.showDictionary = false;
    },
    showExceedUsageModal() {
      this.openModal(
          'Daily Limit Reached',
          'Come back tomorrow.',
          'OK',
      );
    },
    showRewardUsageModal() {
      this.openModal(
          'Congratulation!',
          'Total score: ' + this.scores.cumulative,
          'OK',
      );
    },
    // Helper method for debugging
    debugLog(message) {
      if (this.isDevelopment) {
        // alert(message);
        console.log(message);
      }
    },
  },
  beforeDestroy() {
    this.audioPlayers.forEach((audio) => {
      audio.pause();
    });
    if (this.audioContext) {
      this.audioContext.close();
    }
    // if we have ended conversation, sessionId would be set to ''
    if (this.sessionId) {
      axios.post('/voicebot/conversations/end_session/', {session_id: this.sessionId});
    }
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// NEW LAYOUT
.chatbot__component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh); /* For browsers that don't support CSS variables */
  height: calc((var(--1dvh, 1vh) * 100)); /* This is the "polyfill" */
  height: calc(100dvh); /* This is for future browsers that support svh, dvh and lvh viewport units */
  background: #000000d3;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  &--btn-end {
    display: flex;
    justify-content: center;
    button {
      cursor: pointer;
      font-family: "baloo da 2";
      width: 90%;
      margin-top: 0;
      font-weight: 600;
      border-radius: .75rem;
      font-size: 1.05rem;
      border: 2px solid #e7e7e7;
      border-bottom: 4px solid #e7e7e7;
      margin-bottom: 1rem;
      padding: 8px 0;
      color: #ababab;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        margin-right: .5rem;
      }
      &:focus {
        outline: none;
      }
      &:active {
        background: #f2f2f2;
        border-bottom: 2px solid #e7e7e7;
        transform: translateY(1px);
        margin-top: 2px;
      }
    }
  }
  &--container {
    display: flex;
    flex-direction: column;
    border: none;
    overflow-y: auto;
    overflow-x: hidden;
    width: 52vh;
    height: 96vh;
    border-radius: 1rem;
    border: 6px solid #000;
    background: linear-gradient(to bottom, #4122B4, #3E97D7);
    box-shadow: 0 0 14px 5px #6c44f3;
    position: relative;
    &--btn-ios {
      position: absolute;
      height: 100%;
      width: 100%;
      display: none;
      justify-content: center;
      align-items: center;
      background: #000000e3;
      color: #fff;
    }
  }
  &--nav {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #353961b5;
    margin-bottom: 0;
    h3 {
      color: #fff;
      i {
        margin-right: .5rem;
      }
    }
    &--btn {
      width: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: none;
      font-size: 1.25rem;
      color: #fff;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        color: #fff;
      }
      .fa-xmark {
        font-size: 1.5rem;
      }
    }
    &--score {
      color: #00ffd7;
      font-size: 1.2rem;
      font-weight: 600;
    }
    &--tokens {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
      color: #fff;
      i {
        color: #ffc700;
        margin-right: .25rem;
      }
    }
  }
  &--body {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    &--ai-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      position: relative;
      img {
        width: 100%;
      }
      &--text {
        position: absolute;
        bottom: 1rem;
        display: flex;
        justify-content: flex-start;
        padding: .25rem .5rem;
        background: #000000a1;
        border-radius: .75rem;
        color: #fff;
        width: 95%;
        font-size: 18px;
      }
    }
    &--results {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &--container {
        display: flex;
        flex-direction: column;
        width:95%;
        background: #25418c;
        padding: .5rem;
        border-radius: .5rem;
        color: #fff;
      }
      &--title {
        font-size: 1rem;
      }
      &--breakdown {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        &-header {
          font-size: .8rem;
        }
        &-score {
          display: flex;
          &-topic, &-language, &-wordlist {
            padding: 0 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-right: 2px solid #ffffff2b;
            &:last-child {
              border-right: none;
            }
            &--header {
              font-size: .9rem;
              font-weight: 600;
            }
            &--num {
              font-style: italic;
              font-size: .8rem;
            }
          }
        }
      }
    }
    &--transcription {
      overflow: scroll;
      height: 100%;
      width: 100%;
      padding: 15px;
      font-size: 18px;
      &--container {
        margin-top: auto;
        margin-bottom: 1rem;
        width: 90%;
        border: 2px solid #ddd;
        color: #4f4f4f;
        background: #fff;
        border-radius: .75rem;
        -webkit-border-radius: .75rem;
        -moz-border-radius: .75rem;
        max-height: 300px;
        display: flex;
        justify-content: center;
        .fa-caret-down {
          position: absolute;
          font-size: 2rem;
          bottom: -1.15rem;
          color: #fff;
        }
      }
    }
  }
  &--recorder {
    background: #185ea7;
    height: 4.5rem;
    border-radius: .75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &--btn {
      border-radius: 50%;
      height: 3rem;
      width: 3rem;
      margin-bottom: .25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      border: none;
      color: #185ea7;
      cursor: pointer;
      &:active {
        height: 2.8rem;
        width: 2.8rem;
        font-size: 1.4rem;
      }
      &-start {
        background: #fff;
      }
      &-stop {
        background: #d44983;
        color: #fff;
      }
    }
    &--avatar {
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: .25rem;
      img {
        width: 100%;
        height: 100%;
        padding: .15rem;
        margin-top: .35rem;
      }
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 600px) {
  .chatbot-container {
    width: 100%;
    height: 100vh;
  }
}
@media only screen and (max-width: 470px) {
    .chatbot-container {
      width: 100vw;
      height: 100vh;
      border-radius: 0;
    }
  }

  @media only screen and (max-width: 414px) and (max-height: 810px) {
    .chatbot-container {
      height: 100%;
      width: 100%;
      box-shadow: none;
    }
  }
  @media only screen and (max-width: 540px) and (max-height: 960px) {
    .chatbot__component--container--btn-ios {
      display: flex;
    }
    .chatbot__component--body--results--breakdown-score {
      &-wordlist, &-language, &-topic {
        padding: 0 .5rem;
      }
    }
  }


  @media only screen and (max-height: 960px) {
    .chatbot__component--body--transcription--container {
      max-height: 250px;
    }
  }
  @media only screen and (max-height: 870px) {
    .chatbot__component--body--transcription--container {
      max-height: 225px;
    }
  }
  @media only screen and (max-height: 810px) {
    .chatbot__component--body--transcription--container {
      max-height: 200px;
    }
  }
</style>
