<template>
  <div>
    <!-- for when user clicks on a single cell -->
    <div v-if="isRedeemable">
      <button @click="redeemItem">
        <span v-if="selectedCellItemData.key.includes('resource')">Redeem Item</span>
        <span v-else>Feed Pet</span>
      </button>
    </div>
    <!-- construct building -->
    <div v-if="isEmptyCell">
      <button @click="$emit('showPurchaseBuildingModal')">Construct Building</button>
    </div>
    <!-- click on pet generator building to train pe-->
    <div v-if="isPetGeneratorBuilding">
      Daily Trains Left: {{ remainingDailyAttempts }} / 20
      <br>
      <button @click="trainPet(selectedCellItemData.key)" class="buy-item-button">
        Train ({{ requiredEnergyToGenerateItem }} <i class="fa fa-bolt"></i>)
      </button>
    </div>
    <!-- click on pet to feed to king -->
    <div v-if="isFeedable">
      <button @click="feedPetToKing(selectedCellItemData.key)">
        Sacrifice Pet {{ selectedCellItemData.info.xpGeneratedWhenFedToKing }}<i class="fa fa-wheat"></i>
      </button>
    </div>

    <!-- Merge and swap functionality now handled by drag and drop -->
    <div v-if="selectedCells.length > 0" class="tip-text">
      <small>💡 Tip: Drag and drop items to move or merge them!</small>
    </div>
  </div>
</template>

<script>

import {mapState, mapGetters, mapActions} from 'vuex';

import {TRAIN_PET_ENERGY_COSTS} from '../gamevalues.js';

export default {
  name: 'BuildingControls',
  props: {
  },
  components: {
  },
  computed: {
    ...mapState('kingdom', ['selectedCells', 'remainingDailyAttempts']),
    ...mapGetters('kingdom', ['isRedeemable', 'isEmptyCell', 'isFeedable', 'isPetGeneratorBuilding', 'selectedCellItemData']),
    requiredEnergyToGenerateItem() {
      return TRAIN_PET_ENERGY_COSTS['building|fire_valley|0'];
    },
  },
  methods: {
    ...mapActions('kingdom', ['redeemItem', 'trainPet', 'feedPetToKing']),
  },
};
</script>

<style lang="scss" scoped>
.tip-text {
  margin-top: 10px;
  padding: 8px;
  background-color: #f8f9fa;
  border-left: 3px solid #2196F3;
  border-radius: 4px;

  small {
    color: #666;
  }
}

button {
  margin-top: 5px;
  margin-right: 5px;
  padding: 8px 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #3c9f40;
  }
}
</style>
