<!-- the difference between this and AiPracticeModal
 is that this is a general AI practice component, while AIPracticeModal is used in ResourcesComponent as a popup
 -->
<template>
  <div class="ai-practice-writing__component animated fadeIn faster">
    <!-- mobile header -->
    <div class="mobile-nav-icons">
      <i @click="isMobileSideNavShown = !isMobileSideNavShown" class="fa-solid fa-bars"></i>
      <span v-if="selectedCategory === 'weekly-missions'">Weekly Missions</span>
      <span v-if="selectedCategory === 'free-writing'">Free Writing</span>
      <span v-if="selectedCategory === 'writing-drills'">Writing Drills</span>
      <i @click="goCompoIndex" class="fa-solid fa-xmark"></i>
    </div>
    <!-- CONTENT -->
    <div class="ai-practice-writing__content--container">
      <span v-if="selectedCategory === 'weekly-missions'"
        class="ai-practice-writing__content--weeklymissions">
        <div class="ai-practice-writing__content--weeklymissions--progress">
          <div class="ai-practice-writing__content--weeklymissions--progress-bar--container">
            <div class="ai-practice-writing__content--weeklymissions--progress-bar">
              <div :style="{width: progressBarWidth}"
                class="ai-practice-writing__content--weeklymissions--progress-bar--fill">
              </div>
            </div>
            <div class="ai-practice-writing__content--weeklymissions--progress-bar-icon">
              <div class="diamond-container">
                <img class="ai-practice-writing__content--weeklymissions--progress-bar-icon-img" src="../../../assets/profile/diamond.png">
                <!-- show checkmark if mission is completed and claimed -->
                <i v-if="idiomMission && idiomMission.status === 3" class="fa-solid fa-check reward-claimed-tick"></i>
              </div>
              <span class="ai-practice-writing__content--weeklymissions--progress-bar-icon-text">x10</span>
            </div>
          </div>
          <div class="ai-practice-writing__content--weeklymissions--textbox--container">
            <h2 class="ai-practice-writing__content--weeklymissions--textbox--header">
              Form sentences and win 10 diamonds!
            </h2>
            <ai-practice-textbox
              class="ai-practice-writing__content--weeklymissions--textbox"
              :word-info="selectedWord"
              category="sentence-idioms"
              :placeholder-text="placeholderText"
              @score-change="handleScoreChange"
              @continue="handleContinue"
              @validation-error="handleValidationError"
            />
          </div>
          <word-list-buttons
            v-if="state === 0"
            :words="idiomMission.options.idioms"
            @word-selected="handleWordSelected"
          />
        </div>
      </span>
      <ai-practice-textbox
        v-if="selectedCategory === 'free-writing'"
        :placeholder-text="placeholderText"
        category="general-free-writing"
        @validation-error="handleValidationError"
      />
      <!-- Writing Drills Section -->
      <writing-practice-component
        v-if="selectedCategory === 'writing-drills'"
      />
    </div>
    <!-- desktop nav -->
    <div class="ai-practice-writing__component--nav-container">
    <div class="ai-practice-writing__component--nav animated slideInLeft">
      <div class="ai-practice-writing__component--nav--header">
        <!-- Premium Label  -->
        <span class="ai-practice-writing__component--nav--header-label">Premium</span>
        <!-- Header -->
        <span class="ai-practice-writing__component--nav--header-text">
          <i class="fa-solid fa-pen"></i>
          AI Writing Practice
        </span>
      </div>
      <ul class="ai-practice-writing__component--nav--routes">
        <li
          :class="{active: selectedCategory === 'weekly-missions'}"
          @click="selectCategory('weekly-missions')">
          Weekly Missions
        </li>
        <li :class="{active: selectedCategory === 'free-writing'}"
          @click="selectCategory('free-writing')">
          Free Writing
        </li>
        <li :class="{active: selectedCategory === 'writing-drills'}"
          @click="selectCategory('writing-drills')">
          Writing Drills
        </li>
      </ul>
      <div @click="goCompoIndex" class="ai-practice-writing__component--nav--btn-home">
        <i class="fa-solid fa-house"></i>
        Return
      </div>
    </div>
    </div>
    <!-- mobile nav -->
    <div v-if="isMobileSideNavShown" class="ai-practice-writing__component--nav-container--mobile">
      <div class="ai-practice-writing__component--nav animated slideInLeft">
        <div class="ai-practice-writing__component--nav--header">
          <!-- Premium Label  -->
          <span class="ai-practice-writing__component--nav--header-label">Premium</span>
          <!-- Header -->
          <span class="ai-practice-writing__component--nav--header-text">
            <i class="fa-solid fa-pen"></i>
            AI Writing Practice
          </span>
        </div>
        <ul class="ai-practice-writing__component--nav--routes">
          <li :class="{active: selectedCategory === 'weekly-missions'}"
            @click="selectCategory('weekly-missions'); isMobileSideNavShown = !isMobileSideNavShown">
            Weekly Missions
          </li>
          <li
            :class="{active: selectedCategory === 'free-writing'}"
            @click="selectCategory('free-writing'); isMobileSideNavShown = !isMobileSideNavShown">
            Free Writing
          </li>
          <li
            :class="{active: selectedCategory === 'writing-drills'}"
            @click="selectCategory('writing-drills'); isMobileSideNavShown = !isMobileSideNavShown">
            Writing Drills
          </li>
        </ul>
        <div @click="isMobileSideNavShown = !isMobileSideNavShown"
          class="ai-practice-writing__component--nav--btn-close">
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
    </div>
    <!-- to show score -->
    <modal
      :show="showScoreModal"
      title="Your Score"
      closeButtonText="Ok"
      min-width="300px"
      max-width="500px"
      @close="closeModal"
    >
      <p>{{ scoreModalMessage }}</p>
      <rating :max-score="5" :current-score="scoreStarRating"></rating>
    </modal>

    <!-- mission completed modal -->
    <modal
      :show="showMissionCompletedModal"
      title="Mission Completed!"
      closeButtonText="Claim Reward"
      min-width="300px"
      max-width="500px"
      @close="claimReward"
    >
      <p>You've completed the weekly mission. Claim your reward!</p>
    </modal>

    <!-- Error Modal -->
    <modal
      :show="showErrorModal"
      title="Try Again"
      closeButtonText="Ok"
      min-width="300px"
      max-width="500px"
      @close="showErrorModal = false"
    >
      <p>{{ errorMessage }}</p>
    </modal>
  </div>
</template>

<script>

import {mapGetters, mapMutations} from 'vuex';

import AiPracticeTextboxComponent from './components/AiPracticeTextboxComponent.vue';
import WordListButtonsComponent from './components/WordListButtonsComponent.vue';
import ModalComponent from '../common/ModalComponent.vue';
import RatingComponent from '../../common/RatingComponent.vue';
import WritingPracticeComponent from './WritingPracticeComponent.vue';

export default {
  name: 'NewCompositionGeneralAiPractice',
  components: {
    AiPracticeTextbox: AiPracticeTextboxComponent,
    WordListButtons: WordListButtonsComponent,
    Modal: ModalComponent,
    Rating: RatingComponent,
    WritingPracticeComponent,
  },
  data() {
    return {
      userAnswer: '',
      // separate from recordingState
      // 0  user hasn't submitted answer
      // 1  user has submitted answer and awaiting backend response
      // 2  user has submitted answer and backend response is ready
      state: 0,
      fetching: false,
      languageBreakdownData: {},
      // this is purely for transcription
      isRecording: false,
      // audio related stuff
      // 0: haven't started recording
      // 1: recording
      // 2: finished recording, gotten transcription back
      recordingState: 0,
      isMobileSideNavShown: false,
      selectedCategory: 'weekly-missions',
      // Section ID for when a specific section is selected (used for pattern-id in writing drills)
      sectionId: null,
      // this controls the AI popup revision for idioms
      showAiPracticeModal: false,
      idioms: [],
      placeholderText: '请造一个句子...',
      selectedWord: null,
      showScoreModal: false,
      scoreModalMessage: '',
      scoreStarRating: 0,
      progressBarWidth: '20%',
      showMissionCompletedModal: false,
      showErrorModal: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters('composition', ['markingResults']),
    idiomMission() {
      const _missions = this.getMissions()('writing') || [];
      if (_missions && _missions.length > 0) {
        return _missions[0];
      }
      return null;
    },
  },
  mounted() {
    // Check if there are query parameters and set the category accordingly
    const {category, section} = this.$route.query;

    if (category) {
      // Check if the category is valid
      const validCategories = ['weekly-missions', 'free-writing', 'writing-drills'];
      if (validCategories.includes(category)) {
        this.selectCategory(category, section);
      } else {
        // Invalid category, set to default and update URL
        this.selectCategory('weekly-missions');
      }
    } else {
      // No category parameter, set to default and update URL
      this.selectCategory('weekly-missions');
    }

    this.updateProgressBar();
    this.checkIfMissionCompleted();
  },
  methods: {
    ...mapGetters(['getMissions']),
    ...mapMutations('composition', ['setSelectedWordInfo']),
    updateProgressBar() {
      if (!this.idiomMission) return;
      const currentTimesDone = this.idiomMission.current_times_done || 0;
      const targetTimesDone = this.idiomMission.target_times_done || 1;
      const percentage = Math.round((currentTimesDone / targetTimesDone) * 100);
      this.progressBarWidth = `${percentage}%`;
    },
    playAudio(word) {
      // Prevent the click event from bubbling up to parent elements
      event.stopPropagation();
      // Implement audio playback logic here
      console.log(`Playing audio for: ${word}`);
      // Example implementation could use Text-to-Speech API
    },
    // added changes
    handleRecordingStateChange(newState) {
      // console.log('handleRecordingStateChange', newState);
      this.recordingState = newState;
      this.isRecording = newState === 1; // Recording state is 1 when actively recording
    },
    handleTranscriptionComplete(transcribedText) {
      // console.log('handling transcription complete');
      // console.log(transcribedText);
      this.userAnswer += transcribedText;
      this.recordingState = 0;
      this.isRecording = false;
    },
    /**
     * Selects a category and updates the URL with query parameters
     * @param {string} val - The category to select
     * @param {string} section - Optional section parameter
     */
    selectCategory(val, section) {
      this.selectedCategory = val;
      this.selectedWord = null;
      this.placeholderText = '请造一个句子...';

      // Handle section parameter for writing-drills category
      if (val === 'writing-drills' && section) {
        // Try to convert section to a number if it looks numeric
        const sectionNum = parseInt(section, 10);
        this.sectionId = !isNaN(sectionNum) ? sectionNum : null;
      } else {
        this.sectionId = null;
      }

      // Update URL with the selected category
      const query = {category: val};

      // Add section parameter if provided
      if (section) {
        query.section = section;
      }

      // Update the URL without forcing a navigation
      // Use path to preserve the current route path
      const currentPath = this.$route.path;
      this.$router.push({
        path: currentPath,
        query: query,
      }).catch((err) => {
        // Ignore navigation duplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.error('Navigation error:', err);
        }
      });
    },
    onDrillRewardClaimed() {
      // Handle reward claim logic here - possibly redirect to mission page
      this.$router.push({name: 'new-missions-overview', query: {category: 'weekly'}});
    },
    goCompoIndex() {
      this.$router.push({name: 'new-composition-overview'});
    },
    handleWordSelected(wordInfo) {
      this.selectedWord = wordInfo;
      this.placeholderText = `请用"${wordInfo.text}"造句...`;
    },
    handleScoreChange(score) {
      // Convert score from 0-1 range to 0-5 star rating
      this.scoreStarRating = Math.round(score * 5);

      // Different messages based on score
      if (this.scoreStarRating < 4) {
        this.scoreModalMessage = `Try again… Minimum of 3* required!`;
      } else {
        this.scoreModalMessage = `Well Done!`;
      }
      this.showScoreModal = true;

      // Update state to indicate user has submitted answer
      this.state = 2;
    },
    handleContinue() {
      // Reset state when user clicks continue
      this.state = 0;
      this.userAnswer = '';
      this.selectedWord = null;
      this.placeholderText = '请造一个句子...';
      // check if mission is completed
      this.updateProgressBar();
      this.checkIfMissionCompleted();
    },
    closeModal() {
      this.showScoreModal = false;
    },
    checkIfMissionCompleted() {
      if (this.idiomMission && this.idiomMission.status === 2) {
        this.showMissionCompletedModal = true;
      }
    },
    claimReward() {
      this.showMissionCompletedModal = false;
      this.$router.push({name: 'new-missions-overview', query: {category: 'weekly'}});
    },
    handleValidationError(error) {
      this.errorMessage = error;
      this.showErrorModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.ai-practice-writing {

  &__component {
    display: flex;
    background: #fff;
    width: 100%;
    height: 100%;
    position: fixed;
    flex-direction: row-reverse;
    top: 0;
    left: 0;
    .mobile-nav-icons {
      justify-content: center;
      align-items: center;
      flex-direction: row;
      font-size: 1.35rem;
      display: none;
      width: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      padding: .5rem;
      background: #e9e9e9;
      .fa-xmark {
        font-size: 1.8rem;
        margin-left: auto;
      }
      span {
        margin-left: 1rem;
        font-weight: 800;
      }
    }
    &--nav {
      &--header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #fff;
        font-weight: 600;
        font-size: 1.2rem;
        i {
          margin-right: .25rem;
        }
        &-label {
          font-size: .85rem;
          border-radius: .25rem;
          background: rgb(87,225,228);
          background: linear-gradient(167deg, rgb(184 87 228) 34%, rgb(78 158 255) 61%);
          padding: 0 .5rem;
          color: #fff;
          margin-bottom: .25rem;
        }
      }
      &-container {
        background: #e9e9e9;
        height: 100%;
        &--mobile {
          // FOR RESPONSIVENESS SLIDE IN NAV
          background: #000000db;
          width: 100vw;
          position: absolute;
          height: 100%;
          display: none;
        }
      }
      padding: 2rem 2rem 2rem 1rem;
      background: #3c79d3;
      position: relative;
      height: 100%;
      width: 300px;
      &--profile {
        display: flex;
        justify-content: center;
        align-items: center;
        .user-avatar {
          img {
            background: #fff;
            border-radius: 50%;
            box-shadow: 0px 5px #7a7a7aa8;
            width: 75px;
            margin-right: .5rem;
          }
        }
        .user-name {
          font-size: 1.4rem;
          font-weight: 600;
          color: #fff;
          line-height: 1.2rem;
          // text-shadow: 0px 3px #0000007a;
          text-transform: capitalize;
        }
        .user-level {
          text-transform: capitalize;
          color: #fff;
          font-style: italic;
        }
      }
      &--routes {
        position: absolute;
        right: 0;
        margin-top: 3.5rem;
        li {
          i {
            margin-right: .5rem;
          }
          cursor: pointer;
          padding: 1rem 2rem;
          font-weight: 600;
          min-width: 250px;
          font-size: .9rem;
          color: #fff;
        }
        .active {
          border-top-left-radius: .5rem;
          border-bottom-left-radius: .5rem;
          background: #e9e9e9;
          color: #3c79d3;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            background-color: rgba(0, 0, 0, 0);
            top: -50px;
            height: 50px;
            width: 25px;
            border-bottom-right-radius: .5rem;
            right: 0;
            box-shadow: 0 25px 0 0 #e9e9e9;
          }
          &::after {
            content: "";
            position: absolute;
            background-color: rgba(0, 0, 0, 0);
            bottom: -50px;
            height: 50px;
            width: 25px;
            border-top-right-radius: .5rem;
            right: 0;
            box-shadow: 0 -25px 0 0 #e9e9e9;
          }
        }
      }
      &--btn-home {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #3366b4;
        left: 0;
        padding: 1rem;
        padding-left: 2rem;
        font-weight: 600;
        color: #fff;
        font-size: .9rem;
        cursor: pointer;
        i {
          margin-right: .25rem;
        }
      }
      &--btn-close {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #3366b4;
        left: 0;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        font-weight: 600;
        color: #ffffff8a;
        font-size: 1.5rem;
        cursor: pointer;
        &:hover {
          color: #fff;
        }
        i {
          margin-right: .25rem;
        }
      }
    }
  }
  &__content {
    &--container {
      background: #e9e9e9;
      width: 100%;
      padding: 2rem;
      overflow-x: hidden;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari and Opera */
      }
    }
    &--weeklymissions {
      &--textbox {
        &--container {
          min-height: 400px;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
      &--progress {
        width: 100%;
        justify-content: center;
        align-items: center;
        &-text {
          // width: 45px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-left: .5rem;
          font-size: .9rem;
        }
        &-bar {
          height: 1.25rem;
          border-radius: 2rem;
          background: #bebbbb5c;
          flex: 1;
          &--container {
            gap: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
            height: 1.75rem;
          }
          }
          &--fill {
            // width for mission completion
            // width: 50%;
            height: 100%;
            background: #ffb350;
            border-radius: 2rem;
            transition: width 0.5s ease-in-out;
          }
          &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            &-text {
              font-weight: 600;
              margin-left: .25rem;
            }
            &-img {
              height: 1.75rem;
            }
            .diamond-container {
              position: relative;
              display: inline-block;
            }
            .reward-claimed-tick {
              position: absolute;
              top: -5px;
              right: -5px;
              background-color: #4CAF50;
              color: white;
              border-radius: 50%;
              padding: 2px;
              font-size: 0.7rem;
              box-shadow: 0 1px 3px rgba(0,0,0,0.2);
            }
          }
        }
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        li {
          background: #fff;
          padding: .5rem;
          border-radius: .5rem;
        }
        .item {
          &-body {
            display: flex;
            gap: .5rem;
            margin-top: 1rem;
            flex-direction: column;
            &--content {
              flex: 1;
              display: flex;
              gap: .5rem;
              &--sentences {
                flex: 1;
                p {
                  font-size: 1.1rem;
                  flex: .95;
                  padding: .5rem;
                  margin-left: .5rem;
                }
              }
              &--mission-btn {
                font-family: 'baloo da 2';
                font-weight: 800;
                &-start {
                  gap: .5rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 2rem 1.5rem;
                  border-radius: .5rem;
                  height: 100%;
                  width: 100%;
                  border: 2px solid rgba(0, 0, 0, 0);
                  border-bottom: 4px solid #c0863b;
                  text-transform: uppercase;
                  color: #fff;
                  background: #ffb350;
                  width: 100%;
                  cursor: pointer;
                  &:active {
                    border-bottom: 2px solid #c0863b;
                    margin-top: 2px;
                  }
                  &:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                    background: #ddd;
                    border-bottom: 4px solid #d2d2d2;
                    &:active {
                      margin-top: 0;
                      transform: none;
                      border-bottom: 4px solid #d2d2d2;
                    }
                  }
                }
                &-done {
                  gap: .5rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 2rem 1.5rem;
                  border-radius: .5rem;
                  height: 100%;
                  width: 100%;
                  border: 2px solid rgba(0, 0, 0, 0);
                  border-bottom: 4px solid #359776;
                  text-transform: uppercase;
                  color: #fff;
                  background: #6ad6b2;
                  width: 100%;
                  cursor: pointer;
                  &:active {
                    border-bottom: 2px solid #359776;
                    margin-top: 2px;
                  }
                  &:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                    background: #ddd;
                    border-bottom: 4px solid #d2d2d2;
                    &:active {
                      margin-top: 0;
                      transform: none;
                      border-bottom: 4px solid #d2d2d2;
                    }
                  }
                }
              }
            }
            .label {
              min-width: 88px;
            }
            .meaning {
              display: flex;
            }
            .fa-play {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: .5rem;
              background: #e5e5e5;
              border-radius: 1rem;
              cursor: pointer;
              height: 2.5rem;
              width: 2.5rem;
              margin-left: auto;
              margin-right: .5rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 690px) {
  .ai-practice-writing {
    &__container {
      margin: 1rem;
      padding: 1.5rem;
    }
  }
}
@media only screen and (max-width: 1560px), (max-height: 880px) {
  .ai-practice-writing__content--weeklymissions--textbox--header {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 1250px) {
  .ai-practice-writing__content--weeklymissions--textbox--header {
    display: none;
  }
  .ai-practice-writing__component--nav-container {
    display: none;
  }
  .ai-practice-writing__component--nav-container--mobile {
    display: block;
    .ai-practice-writing__component--nav {
      padding: 2rem 1rem;
      width: 310px;
    }
    .ai-practice-writing__component--nav--routes {
      right: auto;
      margin-top: 2.5rem;
      .active {
        border-radius: .5rem;
        &::before {
          content: none;
        }
        &::after {
          content: none;
        }
      }
    }
  }
  .ai-practice-writing__component--content {
    height: 100%;
    justify-content: flex-start;
    padding: 1rem;
    &>div {
      justify-content: flex-start;
    }
  }
  .ai-practice-writing__component {
    .mobile-nav-icons {
      display: flex;
      height: auto;
        i {
        cursor: pointer;
      }
    }
  }
  .ai-practice-writing__content--container {
    padding: 1rem;
    margin-top: 66px;
    padding-top: 0;
  }
}
@media only screen and (max-width: 930px) {
  .ai-practice-writing__content--weeklymissions ul .item-body--content {
    flex-direction: column;
    &--mission-btn-start, &--mission-btn-done {
      padding: .25rem;
      font-size: .9rem;
    }
  }
}
@media only screen and (max-width: 760px) {
  .ai-practice-writing__content--weeklymissions--progress-bar--container img {
    height: 1.25rem;
  }
  .ai-practice-writing__content--weeklymissions--progress-bar {
    height: 1rem;
  }
  .ai-practice-writing__content--weeklymissions--textbox--container {
    margin-top: .5rem;
    margin-bottom: .75rem;
  }
  .ai-practice-writing__component {
    .mobile-nav-icons {
      font-size: 1.2rem;
    }
  }
  .ai-practice-writing__content--container {
    margin-top: 60px;
    padding: 12px;
  }
}
</style>
