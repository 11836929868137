// used for both comprehension and oral
const getDefaultState = () => {
  return {
    // for zaoju resources paragraph
    selectedWordInfo: null,

    // for writing drills
    patterns: [],
  };
};

const state = getDefaultState();

export const compositionModule = {
  namespaced: true,
  state,
  mutations: {
    resetStateMutation(state) {
      Object.assign(state, getDefaultState());
    },
    setSelectedWordInfo(state, value) {
      state.selectedWordInfo = value;
    },
    setPatterns(state, value) {
      state.patterns = value;
    },
  },
  getters: {
  },
};
