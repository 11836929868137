
/**
 */

const TRAIN_PET_ENERGY_COSTS = {
  'building|fire_valley|0': 10,
};

const BUILDING_CHAINS_DATA = {
  'fire_valley': [{
    key: 'building|fire_valley|0',
    name: 'Fire Valley',
    cost: [{type: 'wood', value: 5}],
    level: 0,
    isMergeable: false,
    color: 'grey', // kiv
    info: {
      buildingType: 'pet_generator',
      spawnningOptions: [{key: 'pet|flamebun|0', probability: 1}],
    },
  }],
};

// all pet parts can be fed to King for bonuses
const PET_CHAINS_DATA = {
  'flamebun': [
    {
      key: 'pet|flamebun|0',
      name: 'Flamebun Egg',
      level: 0,
      isMergeable: true,
      imageSrc: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/kingdom/flamebun/0.png',
      info: {
        isPetPart: true,
        xpGeneratedWhenFedToKing: 1,
      },
    },
    {
      key: 'pet|flamebun|1',
      name: 'Flamebun Egg',
      level: 1,
      isMergeable: true,
      imageSrc: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/kingdom/flamebun/1.png',
      info: {
        isPetPart: true,
        element: 'fire',
        xpGeneratedWhenFedToKing: 2,
      },
    },
    {
      key: 'pet|flamebun|2',
      name: 'Flamebun',
      level: 2,
      isMergeable: true,
      imageSrc: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/kingdom/flamebun/2.png',
      info: {
        // note that level and petLevel differ as pet will have egg and previous petparts
        // whereas pet star start from when pet is hatched
        petLevel: 0,
        isPetPart: false,
        element: 'fire',
        xpGeneratedWhenFedToKing: 5,
      },
    },
    {
      key: 'pet|flamebun|3',
      name: 'Flamebun 1*',
      level: 3,
      isMergeable: false,
      imageSrc: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/kingdom/flamebun/2.png',
      info: {
        petLevel: 1,
        isPetPart: false,
        element: 'fire',
        xpGeneratedWhenFedToKing: 12,
      },
    },
  ],
};

const RESOURCE_CHAINS_DATA = {
  wood: [
    {
      key: 'resource|wood|0',
      level: 0,
      name: 'Wood Log',
      color: 'brown',
      // can click to obtain
      isRedeemable: true,
      isMergeable: true,
      redeemptionValue: [{type: 'wood', value: 2}],
    },
    {
      key: 'resource|wood|1',
      level: 1,
      name: 'Handful of Wood',
      color: 'brown',
      isRedeemable: true,
      isMergeable: true,
      redeemptionValue: [{type: 'wood', value: 5}],
    },
    {
      key: 'resource|wood|2',
      level: 2,
      name: 'Pack of Wood',
      color: 'brown',
      isRedeemable: true,
      isMergeable: false,
      redeemptionValue: [{type: 'wood', value: 12}],
    },
  ],
  energy: [
    {
      key: 'resource|energy|0',
      level: 0,
      name: 'Energy Gem',
      color: 'orange',
      // can click to obtain
      isRedeemable: true,
      isMergeable: true,
      redeemptionValue: [{type: 'energy', value: 2}],
    },
    {
      key: 'resource|energy|1',
      level: 1,
      name: 'Handful of Energy',
      color: 'orange',
      // can click to obtain
      isRedeemable: true,
      isMergeable: true,
      redeemptionValue: [{type: 'energy', value: 5}],
    },
    {
      key: 'resource|energy|2',
      level: 2,
      name: 'Pack of Energy',
      color: 'orange',
      isRedeemable: true,
      isMergeable: false,
      redeemptionValue: [{type: 'energy', value: 12}],
    },
  ],
};

export {
  RESOURCE_CHAINS_DATA,
  TRAIN_PET_ENERGY_COSTS,
  BUILDING_CHAINS_DATA,
  PET_CHAINS_DATA,
};
